import m from "mithril";
import { getItem, getItemAttributes, getItemContents } from "../../api-client";
import dayjs from "dayjs";
import link from "../../utils/link";

export default {
    $component: {
        oninit(vnode) {
            this.id = m.prop(vnode.attrs.id);
            this.loading = m.prop(true);
            this.item = m.prop(null);
            this.contents = m.prop([]);
            this.attributes = m.prop([]);
            this.initialLoad();
        },

        initialLoad() {
            this.load();
            this.loadAttributes();
            this.loadContents();
        },

        onupdate(vnode) {
            if (vnode.attrs.id !== this.id()) {
                this.id(vnode.attrs.id);
                this.loading(true);
                m.redraw();
                this.initialLoad();
            }
        },

        load() {
            this.loading(true);
            getItem(this.id).then(res => {
                this.loading(false);

                this.item(res.shop_item);
                m.redraw();
            });
        },

        loadAttributes() {
            this.loading(true);
            getItemAttributes(this.id).then(res => {
                this.loading(false);
                this.attributes(res.attributes);
                m.redraw();
            });
        },

        loadContents() {
            this.loading(true);
            getItemContents(this.id).then(res => {
                this.loading(false);
                this.contents(res.contents);
                m.redraw();
            });
        },

        view() {
            const item = this.item();
            const attributes = [...this.attributes()].sort(
                (a, b) => a.weight < b.weight,
            );

            if (item === null) {
                return m("div", [
                    m(".loading", [m(".load"), m("p", "Loading...")]),
                ]);
            }

            return m("div", [
                m("h1", "Item"),

                m("p", link(`/items/${this.id()}/edit`, "Edit")),

                m("div", [m("span", "ID"), m("span", item.id)]),

                m("div", [m("span", "Name"), m("span", item.name)]),

                m("div", [m("span", "Type"), m("span", item.type)]),

                m("div", [
                    m("span", "Published"),
                    m("span", dayjs(item.published).format("YYYY.MM.DD HH:mm")),
                ]),

                m("div", [
                    m("span", "Expiry"),
                    m(
                        "span",
                        dayjs(item.expiry_date).format("YYYY.MM.DD HH:mm"),
                    ),
                ]),

                m("div", [m("span", "Public"), m("span", item.public)]),

                m("div", [
                    m("span", "Quantity Total"),
                    m("span", item.quantity_total),
                ]),

                m("div", [
                    m("span", "Quantity Sold"),
                    m("span", item.quantity_sold),
                ]),


                m("div", [
                    m("span", "Quantity inventory"),
                    m("span", item.quantity_total - item.quantity_sold),
                ]),

                m("div", [
                    m("span", "User Max Qty"),
                    m("span", item.user_max_qty),
                ]),

                m("div", [
                    m("span", "User Own Qty"),
                    m("span", item.user_own_qty),
                ]),

                m("div", [m("span", "Price ID"), m("span", item.price_id)]),

                m("hr"),

                m("h2", "Contents"),

                this.contents().map(content =>
                    m("div", [
                        m(
                            "a",
                            {
                                href: `/content/${content.id}/edit`,
                                oncreate: m.route.link,
                                onupdate: m.route.link,
                            },
                            content.data.title,
                        ),
                    ]),
                ),

                m("hr"),
                m("h2", "Attributes"),
                m("table", [
                    m("tr", [
                        m("th", "ID"),
                        m("th", "Name"),
                        m("th", "Weight"),
                    ]),
                    attributes.map(attribute => {
                        const { attr } = attribute;

                        return m("tr", [
                            m("td", [
                                m(
                                    "a",
                                    {
                                        href: `/attribute/${attr.id}/view`,
                                        oncreate: m.route.link,
                                        onupdate: m.route.link,
                                    },
                                    attr.id,
                                ),
                            ]),
                            m("td", attr.data.name),
                            m("td", attribute.weight),
                        ]);
                    }),
                ]),
            ]);
        },
    },
};
