"use strict";

import m from "mithril";
import { createUserAdmin } from "../../api-client";

export default {
    $navTitle: "Create new",
    $component: {
        oninit(vnode) {
            this.password = m.prop("");
            this.email = m.prop("");
            this.access_level = m.prop("1");
            this.error = m.prop("");
            this.success = m.prop("");

            this.save = (e) => {
                e.stopPropagation()
                e.preventDefault()
                const email  = this.email()
                const access_level = this.access_level()
                const password = this.password()
                createUserAdmin(email, access_level, password).then(
                    (res) => {
                        alert("Please note the following password, as it will display only once.");
                        alert(res.password);
                        m.route.set(`/user/id/${res.user.id}/edit`);
                    },
                    (err) => {
                        this.error(err);
                        alert(JSON.stringify(err));
                    }
                );
            };
        },

        view() {
            return m("form", {
                onsubmit: this.save,
            }, [
                m("h2", "Create User"),
                m("div.error", this.error()),
                m("div", [
                    m("label", "Email"),
                    m("input[type=text][placeholder=Email address][name=email]", {
                        oninput: m.withAttr("value", this.email),
                        value: this.email(),
                    }),
                ]),
                m("div", [
                    m("label", "Password"),
                    m("input[type=text][placeholder=Password]", {
                        oninput: m.withAttr("value", this.password),
                        value: this.password(),
                    }),
                    m("br"),
                    m("label", "Leave blank to generate a temporary password")
                ]),
                m("div", [
                    m("label", "Access level"),
                    m("select", {
                        onchange: m.withAttr("value", this.access_level),
                    }, [
                        //m("option[value=0]", "Anonymous"),
                        m("option[value=1]", { selected: "selected" }, "User"),
                        m("option[value=2]", "Artist/Subscriber"),
                        // m("option[value=2]", "Subscriber"),
                        m("option[value=3]", "Business"),
                        m("option[value=8]", "Publisher"),
                        m("option[value=10]", "Editor"),
                        m("option[value=15]", "Admin"),
                        // m("option[value=20]", "Root"),
                    ]),
                ]),
                m("div", [
                    m("button[type=submit]", "Create")
                ]),
            ]);
        },
    }
};
