'use strict';

import m from 'mithril';

import { model, mainView, bulkItemView, individuallyItemView } from './editor';

export default {
    $navTitle: 'Create new',
    $component: {
        oninit() {
            model.reset();
        },
        view() {
            return m('div', 
                m('form', 
                    m('h2', 'Create Coupon'),
                    mainView(),
                ),
                m('hr'),
                m('h2', 'Applicable Items'),
                bulkItemView(),
                m('p', '--------------------'),
                individuallyItemView(),
                m('hr'),
                m('div', m('button', { onclick: e => {
                    e.preventDefault();
                    model.create();
                }}, 'Create coupon')),
            );
        },
    },
};
