"use strict";

import m from "mithril";
import config from "../../config";
import { createContent, getAttributeContent, getAttributeChildren, getContent, getContentByType, patchContent, getIsbnSearch } from "../../api-client";
import { timeSelector } from "../../utils/input-component";
import dayjs from "dayjs";
import { AttrTitleCopier } from "./edit_copy";
import { PresetSelector } from "./edit_preset";

export default {
    $navTitle: "Create new",
    $component: {
        oninit(vnode) {
            this.type = m.prop("");
            this.published = m.prop("");
            this.publishedTime = m.prop("");
            this.expiry = m.prop("");
            this.slug = m.prop("");
            this.body = m.prop("");
            this.is_public = m.prop(false);
            this.public_scope = m.prop("public")
            this.premium = m.prop(false);

            this.title = m.prop("");
            this.title_kana = "";
            this.download_type = m.prop("");
            this.category = m.prop("");
            this.censorship = "";
            this.url = m.prop("");

            this.magazine_titles = m.prop([]);
            this.articles = m.prop([]);
            this.magazine_issues = m.prop([]);
            this.magazine = m.prop(null);
            this.content = m.prop(null);
            this.content_id = m.prop(null);
            this.chapters = m.prop([]);
            this.position = m.prop("");

            this.attribute_ids = [];

            this.size = m.prop("");
            this.release_date = m.prop("");
            this.link_store = m.prop("");
            this.link_wani = m.prop("");
            this.link_amazon = m.prop("");
            this.link_fanzagames = m.prop("");
            this.link_dmm = m.prop("");
            this.link_dlsite = m.prop("");
            this.msrp = m.prop("");
            this.notproducts = m.prop(false);
            this.duration_month = m.prop(0);
            this.duration_day = m.prop(0);
            this.artist = m.prop("");

            this.isbn = m.prop("");
            this.isbnLoading = m.prop(false);
            this.isbnPopulated = m.prop(false);

            this.link_amazon_auto = m.prop(null);

            this.parent = {
                set: false,
                published: dayjs("invalid"),
                expiry: dayjs("invalid"),
                hasDifferentPublished: function(date) {
                    if (!this.set) {
                        return false;
                    }
                    if (!this.published.isValid() && !date.isValid()) {
                        return false;
                    }

                    return !this.published.isSame(date);
                },
                hasDifferentExpiry: function(date) {
                    if (!this.set) {
                        return false;
                    }
                    if (!this.expiry.isValid() && !date.isValid()) {
                        return false;
                    }

                    return !this.expiry.isSame(date);
                },
            };

            this.lander_text_agegate1_ja = m.prop("");
            this.lander_text_button1_ja = m.prop("");
            this.lander_text_button2_ja = m.prop("");
            this.lander_text_appeal1_ja = m.prop("");
            this.lander_text_agegate1_en = m.prop("");
            this.lander_text_button1_en = m.prop("");
            this.lander_text_button2_en = m.prop("");
            this.lander_text_appeal1_en = m.prop("");
            this.lander_img_d = m.prop("");
            this.lander_img_d_size = m.prop("50");
            this.lander_img_d_position = m.prop("50, 50");
            this.lander_img_m = m.prop("");
            this.lander_img_m_size = m.prop("50");
            this.lander_img_m_position = m.prop("50, 50");
            this.register_anime1 = m.prop("0");
            this.register_text_appeal1_ja = m.prop("");
            this.register_text_appeal1_en = m.prop("");

            this.isbnLookup = e => {
                e.preventDefault();
                this.isbnLoading(true);
                this.isbnPopulated(false);

                getIsbnSearch(this.isbn()).then(
                    res => {
                        if (res.response.title) {
                            this.title(res.response.title || "");
                            this.published(res.response.published || "");
                            this.release_date(res.response.published || "");
                            this.artist(res.response.artist || "");
                            this.link_wani(res.response.link_wani || "");
                            this.link_store(res.response.link_store|| "");
                        } else {
                            alert("no data.");
                        }

                        this.isbnPopulated(true);
                        this.isbnLoading(false);
                        m.redraw();
                    },
                    err => {
                        console.log(err);
                        this.isbnLoading(false);
                        m.redraw();
                    },
                );
            };

            this.isbnSkip = e => {
                e.preventDefault();
                this.isbnPopulated(true);
            };

            this.useLinkAmazonAuto = e => {
                e.preventDefault();
                if (this.link_amazon_auto) {
                    this.link_amazon(this.link_amazon_auto);
                }
                m.redraw();
            };

            // If ?type is defined, set it
            if (vnode.attrs.type) {
                this.type(vnode.attrs.type);

                if (this.type() == "tankoubon") {
                    this.premium(true)
                }

                // If &magazine is defined, set it
                if (vnode.attrs.magazine) {
                    this.magazine(vnode.attrs.magazine);

                    if (this.magazine() === "tankoubon_mode") {
                        this.premium(true)
                        this.censorship = "単行本修正"
                    }

                    // If &issue is defined, set it
                    if (vnode.attrs.issue) {
                        this.content(vnode.attrs.issue);
                    }
                } else if (vnode.attrs.content) {
                    // If &content is defined, set it
                    this.content(vnode.attrs.content);
                }
            }

            // When user selects a magazine issue, populate the chapters dropdown
            this.content.run(() => {
                this.chapters([]);

                if (this.content() && this.type() != "asset") {
                    getContent(this.content()).then(
                        res => {
                            this.chapters(res.content.children);

                            this.parent.set = true;

                            // if issue has expiry date, set it
                            this.parent.expiry = dayjs(res.content.expiry);
                            if (this.parent.expiry.isValid()) {
                                this.expiry(this.parent.expiry.format("ddd MMM DD YYYY"));
                            }

                            // if issue has publish date, set it
                            this.parent.published = dayjs(res.content.published);
                            if (this.parent.published.isValid()) {
                                this.published(this.parent.published.format("ddd MMM DD YYYY"));
                                this.publishedTime(this.parent.published.format("H:00"));
                            }

                            m.redraw();
                        },
                        err => {
                            console.error(err);
                        },
                    );
                }
            });

            this.magazine.run(() => {
                this.magazine_issues([]);
                this.chapters([]);

                // Get the issues/volumes for this magazine title
                if (this.magazine() && this.magazine() !== "magazine_titles") {
                    getAttributeContent(this.magazine(), 0, 999).then(
                        res => {
                            this.magazine_issues(res.results);
                            m.redraw();
                        },
                        err => {
                            console.error(err);
                        },
                    );
                }

                if (this.magazine() === "magazine_titles") {
                    this.content("");
                }
            });

            this.type.run(() => {
                this.magazine_titles([]);
                this.articles([]);
                this.magazine_issues([]);
                this.chapters([]);

                if ((this.type() == "chapter" || this.type() == "sample") && this.magazine_titles().length < 1) {
                    // Get the list of magazine titles
                    getAttributeChildren(config.magazines_attribute_id).then(
                        res => {
                            this.magazine_titles(res.children);
                            m.redraw();
                        },
                        err => {
                            console.error(err);
                            alert(JSON.stringify(err));
                        },
                    );
                } else if (this.type() == "asset" && this.articles().length < 1) {
                    // Get the list of magazine titles
                    getContentByType(0, 999, "article").then(
                        res => {
                            this.articles(res.results);
                            m.redraw();
                        },
                        err => {
                            console.error(err);
                            alert(JSON.stringify(err));
                        },
                    );
                }
            });

            this.submit = () => {
                let publish_date = dayjs(`${this.published()} ${this.publishedTime()}`);
                let expiry_date = dayjs(this.expiry());

                if (this.parent.hasDifferentPublished(publish_date) || this.parent.hasDifferentExpiry(expiry_date)) {
                    if (!confirm("You are creating a content with different published and/or expiry from the parent's. Are you sure?")) {
                        return false;
                    }
                }

                if (!publish_date.isValid()) {
                    publish_date = null;
                }
                if (!expiry_date.isValid()) {
                    expiry_date = null;
                }

                const data = {
                    isbn: this.isbn(),
                    title: this.title(),
                    title_kana: this.title_kana,
                    category: this.category(),
                    censorship: this.censorship,
                    url: this.url(),
                    download_type: this.download_type(),
                    size: this.size(),
                    release_date: this.release_date(),
                    link_wani: this.link_wani(),
                    link_store: this.link_store(),
                    link_amazon: this.link_amazon(),
                    link_fanzagames: this.link_fanzagames(),
                    link_dmm: this.link_dmm(),
                    link_dlsite: this.link_dlsite(),
                    msrp: this.msrp(),
                    notproducts: this.notproducts(),
                };

                if (this.link_fanzagames().length > 0) {
                    // These links cannot exist at the same time
                    data.link_dmm = "";
                    data.link_dlsite = "";
                }

                if (this.type() === "sample") {
                    this.is_public(true)
                } else if (this.type() === "chapter" || this.type() === 'volume_affiliate') {
                    if (this.public_scope == 'naughty') {
                        this.is_public(true)
                        data.naughty = true
                        data.restricted = false
                    } else if (this.public_scope == 'access') {
                        this.is_public(false)
                        data.naughty = false
                        data.restricted = true
                    } else if (this.public_scope == 'authenticated_only') {
                        this.is_public(false)
                        data.naughty = false
                        data.restricted = false
                    } else {
                        this.is_public(true)
                        data.naughty = false
                        data.restricted = false
                    }
                }

                if (this.type() === "free_trial") {
                    if (this.duration_month() != 0 || this.duration_day() != 0) {
                        data.duration = {
                            month: parseInt(this.duration_month()),
                            day: parseInt(this.duration_day()),
                        };
                        if (data.duration.day == NaN) {
                            data.duration.day = 0;
                        }
                        if (data.duration.month == NaN) {
                            data.duration.month = 0;
                        }
                    }
                    data.lander_text_agegate1_ja = this.lander_text_agegate1_ja();
                    data.lander_text_button1_ja = this.lander_text_button1_ja();
                    data.lander_text_button2_ja = this.lander_text_button2_ja();
                    data.lander_text_appeal1_ja = this.lander_text_appeal1_ja();
                    data.register_text_appeal1_ja = this.register_text_appeal1_ja();
                    data.lander_text_agegate1_en = this.lander_text_agegate1_en();
                    data.lander_text_button1_en = this.lander_text_button1_en();
                    data.lander_text_button2_en = this.lander_text_button2_en();
                    data.lander_text_appeal1_en = this.lander_text_appeal1_en();
                    data.register_text_appeal1_en = this.register_text_appeal1_en();

                    data.lander_img_d = this.lander_img_d();
                    data.lander_img_d_size = this.lander_img_d_size();
                    data.lander_img_d_position = this.lander_img_d_position();
                    data.lander_img_m = this.lander_img_m();
                    data.lander_img_m_size = this.lander_img_m_size();
                    data.lander_img_m_position = this.lander_img_m_position();

                    data.register_anime1 = this.register_anime1();
                }

                // 1. POST to API
                createContent(data, this.slug(), this.type(), publish_date, expiry_date, this.body(), this.is_public(), this.premium()).then(
                    res => {
                        const postProcesses = [];

                        // 2. Set the parent ID
                        const parentId = this.content_id() || this.content();

                        // 3. If a parent ID is set...
                        if (parentId > 0) {
                            // 4. Store the ID for this new content
                            const contentId = res.content.id;

                            // 5. Fetch the parent to-be
                            postProcesses.push(getContent(parentId).then(
                                res => {
                                    // 6. Create an array of child_ids
                                    const child_ids = [];
                                    let newContentIsPushed = false;

                                    // 7. Remember the position
                                    const position = this.position();

                                    // 8. If this parent has children, then populate child_ids
                                    const children = res.content.children;
                                    if (children) {
                                        children.forEach(function(child) {
                                            child_ids.push(child.id);

                                            // 9. If the order of the new Content is after this child, .push() the new contentId here
                                            if (position == child.id) {
                                                child_ids.push(contentId);
                                                newContentIsPushed = true;
                                            }
                                        });
                                    }

                                    // 10. If the contentId wasn't yet pushed, prepend it
                                    if (!newContentIsPushed) {
                                        child_ids.unshift(contentId);
                                    }

                                    // 11. Patch the parent
                                    return patchContent(parentId, { child_ids: child_ids });
                                }),
                            );
                        }

                        if (this.attribute_ids.length > 0) {
                            // 12. If there are attributes to be set, set them
                            postProcesses.push(patchContent(res.content.id, { attribute_ids: this.attribute_ids }));
                        }

                        Promise.all(postProcesses)
                            .catch(err => { console.log(err); })
                            .finally(() => { m.route.set(`/content/${res.content.id}/edit`); });
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );

                return false;
            };

            return this;
        },

        view() {
            return m(
                "form",
                {
                    onsubmit: this.submit,
                },
                [
                    m(
                        "h1",
                        "New " +
                            (this.type()
                                ? this.type()
                                      .replace("volume", "Magazine")
                                      .replace("chapter", "Chapter")
                                      .replace("tankoubon", "Tankoubon")
                                      .replace("subscription", "Subscription")
                                      .replace("physical", "Physical")
                                      .replace("article", "Special content")
                                      .replace("asset", "Special content asset")
                                      .replace("faq", "FAQ article")
                                      .replace("volume_affiliate", "Affiliate")
                                      .replace("status", "System status")
                                : "Content"),
                    ),
                    m("div", [
                        m("label[for=type]", "Type"),
                        m(
                            "select[name=type]",
                            {
                                onchange: e => {
                                    this.type(e.target.value);
                                    if (e.target.value === "volume_affiliate") {
                                        this.public_scope("naughty")
                                    } else {
                                        this.public_scope("public")
                                    }
                                    if (e.target.value === "tankoubon") {
                                        this.premium(true)
                                    }
                                },
                                value: this.type(),
                            },
                            [
                                m("option"),
                                m("option[value=volume]", "Magazine"),
                                m("option[value=chapter]", "Chapter"),
                                m("option[value=sample]", "Sample Chapter"),
                                m("option[value=tankoubon]", "Tankoubon"),
                                m("option[value=physical]", "Physical"),
                                m("option[value=volume_affiliate]", "Affiliate"),
                                m("option[value=announcement]", "Announcement"),
                                m("option[value=subscription]", "Subscription"),
                                m("option[value=article]", "Special content"),
                                m("option[value=asset]", "Special content asset"),
                                m("option[value=faq]", "FAQ article"),
                                m("option[value=status]", "System status"),
                                m("option[value=tweet]", "Tweet template"),
                                m("option[value=free_trial]", "Free trial"),
                            ],
                        ),
                    ]),
                    this.type() != "volume_affiliate"
                        ? null
                        : m('div',[
                              m("div", [
                                  m("label", "Product ID / EAN"),
                                  m("input[type=text]", {
                                      onchange: m.withAttr("value", this.isbn),
                                      value: this.isbn(),
                                  }),
                              ]),
                              m("div", [
                                  m(
                                      "button",
                                      {
                                          onclick: this.isbnLookup,
                                      },
                                      "Auto-populate",
                                  ),
                              ]),
                              m("div", [
                                  m(
                                      "button",
                                      {
                                          onclick: this.isbnSkip,
                                      },
                                      "Manually populate",
                                  ),
                              ]),
                          ]),
                    this.type() != "article"
                        ? null
                        : m("div", [
                              m("label[for=category]", "Category"),
                              m(
                                  "select[name=category]",
                                  {
                                      onchange: m.withAttr("value", this.category),
                                      value: this.category(),
                                  },
                                  [
                                      m("option", ""),
                                      m("option", { value: "announcement" }, "Announcement"),
                                      m("option", { value: "download" }, "Download"),
                                      m("option", { value: "original" }, "Original content"),
                                  ],
                              ),
                          ]),
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine_titles().length < 1 ? m("div", "Loading magazines titles...") : null,
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine_titles().length > 0
                        ? m("div", [
                              m("label[for=magazine]", "Magazine title"),
                              m(
                                  "select[name=magazine][id=magazine]",
                                  {
                                      onchange: (e) => {
                                          this.magazine(e.target.value);
                                          if (e.target.value === "tankoubon_mode") {
                                              this.premium(true)
                                              this.censorship = "単行本修正"
                                          } else {
                                              this.censorship = ""
                                          }
                                      },
                                      value: this.magazine(),
                                  },
                                  [
                                      m("option"),
                                      this.magazine_titles().map(magazine_title => m("option", { value: magazine_title.id }, magazine_title.data.name)),
                                      m("option", { value: "tankoubon_mode" }, "TANKOBON MODE"),
                                  ],
                              ),
                          ])
                        : null,
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine() && this.magazine() !== "tankoubon_mode" && this.magazine_issues().length < 1
                        ? m("div", "Loading issues...")
                        : null,
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine() && this.magazine() !== "tankoubon_mode" && this.magazine_issues().length > 0
                        ? m("div", [
                              m("label[for=content]", "Issue"),
                              m(
                                  "select[name=content]",
                                  {
                                      onchange: m.withAttr("value", this.content),
                                      value: this.content(),
                                  },
                                  [m("option")].concat(
                                      this.magazine_issues().map(issue =>
                                          m(
                                              "option",
                                              {
                                                  value: issue.id,
                                              },
                                              issue.data.title,
                                          ),
                                      ),
                                  ),
                              ),
                          ])
                        : null,
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine() && this.magazine() === "tankoubon_mode"
                        ? m("div", [
                              m("label[for=content]", "Issue id"),
                              m("input[type=text][name=content]", {
                                  onchange: m.withAttr("value", this.content),
                                  value: this.content(),
                              }),
                          ])
                        : "",
                    (this.type() == "chapter" || this.type() == "sample") && this.magazine() && this.content()
                        ? m("div", [
                              m("label[for=position]", "Insert after..."),
                              m(
                                  "select[name=position]",
                                  {
                                      onchange: m.withAttr("value", this.position),
                                      value: this.position(),
                                  },
                                  [m("option", "(Insert as first chapter)")].concat(
                                      this.chapters() ? this.chapters().map(chapter => m("option", { value: chapter.id }, chapter.data.title)) : null,
                                  ),
                              ),
                          ])
                        : null,
                    m(AttrTitleCopier, {
                        visible: this.type() === "chapter" && this.magazine() === "tankoubon_mode",
                        contentID: this.content(),
                        attributeIDs : this.attribute_ids,
                        setTitle: (t, tk) => { this.title(t); this.title_kana = tk },
                        setAttributeIDs: (a) => { this.attribute_ids = a },
                        undo: (a) => { this.title(""); this.title_kana = ""; this.attribute_ids = a },
                    }),
                    m(PresetSelector, {
                        visible: this.type() === "chapter" && this.magazine() === "tankoubon_mode",
                        contentID: this.content(),
                        setTitle: (t, tk) => { this.title(t); this.title_kana = tk },
                        setAttributeIDs: (a) => { this.attribute_ids = a },
                    }),
                    this.type() == "asset" && this.articles().length < 1 ? m("div", "Loading articles...") : null,
                    this.type() == "asset" && this.articles().length > 0
                        ? m('div', [
                              m("div", [
                                  m("label[for=content]", "Parent article"),
                                  m(
                                      "select[name=content]",
                                      {
                                          onchange: m.withAttr("value", this.content),
                                          value: this.content(),
                                      },
                                      // Parent articles
                                      [m("option", "")].concat(this.articles().map(article => m("option", { value: article.id }, article.data.title))),
                                  ),
                              ]),
                              m("div", [
                                  m("label[for=content_id]", ""),
                                  m("input[type=text][name=content_id]", {
                                      onchange: m.withAttr("value", this.content_id),
                                      value: this.content_id(),
                                      placeholder: "OR parent ID",
                                  }),
                              ]),
                              m("div", [
                                  m("label[for=url]", "Asset URL"),
                                  m("input[type=text][name=url]", {
                                      onchange: m.withAttr("value", this.url),
                                      value: this.url(),
                                      placeholder: "https://cdn.komiflo.com/...",
                                  }),
                              ]),
                          ])
                        : null,
                    this.type() == "article" && this.category() == "download"
                        ? m("div", [
                            m("label[for=download_type]", "Download type"),
                            m("input[type=text][name=download_type]", {
                                onchange: m.withAttr("value", this.download_type),
                                value: this.download_type(),
                                placeholder: "ウォールペーパー",
                            }),
                        ])
                        : null,
                    this.isbnLoading() == true ? m("div.loading", "Loading, please wait...") : null,
                    (this.type() != "volume_affiliate" || this.isbnPopulated() == true) && this.type()
                        ? m('div', [
                              this.type() != "volume_affiliate" ? null : m("hr"),
                              this.type() == "announcement"
                                  ? null
                                  : m("div", [
                                        m("label[for=title]", "Title"),
                                        m("input[type=text][name=title]", {
                                            onchange: m.withAttr("value", this.title),
                                            value: this.title(),
                                        }),
                                    ]),
                              this.type() == "asset"
                                  ? null
                                  : m('div', [
                                        m("div", [
                                            m("label[for=published]", "Publish date"),
                                            m("input[type=text][name=published][autocomplete=off]", {
                                                onchange: m.withAttr("value", this.published),
                                                value: this.published(),
                                                oncreate: function(vnode) {
                                                    this.pikaday = new Pikaday({
                                                        field: vnode.dom,
                                                    });
                                                },
                                                onremove: function() {
                                                    this.pikaday.destroy();
                                                },
                                            }),
                                            "　",
                                            ...timeSelector(this.publishedTime, this.publishedTime()),
                                        ]),
                                        this.type() == "volume" || this.type() == "chapter" || this.type() == "tankoubon" || this.type() === "physical" || this.type() == "sample"
                                            ? m('div', [
                                                  m("div", [
                                                      m("label[for=expiry]", "Expiry date"),
                                                      m("input[type=text][name=expiry][autocomplete=off]", {
                                                          onchange: m.withAttr("value", this.expiry),
                                                          value: this.expiry(),
                                                          oncreate: function(vnode) {
                                                              this.pikaday = new Pikaday({
                                                                  field: vnode.dom,
                                                              });
                                                          },
                                                          onremove: function() {
                                                              this.pikaday.destroy();
                                                          },
                                                      }),
                                                      this.expiry() && this.published()
                                                          ? m('span', [
                                                                `　${dayjs(this.expiry()).diff(dayjs(this.published()), "day")}`,
                                                                m("span", { style: "color: #bbbbbb; font-size: .8em" }, "　(Number of days public)"),
                                                            ])
                                                          : "",
                                                  ]),
                                              ])
                                            : null,
                                    ]),
                              this.type() == "faq"
                                  ? null
                                  : m("div", [
                                        m("label[for=slug]", "Slug"),
                                        m("input[type=text][name=slug]", {
                                            onkeyup: m.withAttr("value", this.slug),
                                            value: this.slug(),
                                        }),
                                    ]),
                              m("div", { style: { display: this.slug() ? "block" : "none" } }, [
                                  m("label"),
                                  m("span", "https://komiflo.com/content/" + this.slug()),
                              ]),
                              this.type() == "article" || this.type() == "faq" || this.type() == "announcement" || this.type() == "tweet"
                                  ? m("div", [
                                        m("label[for=body]", "Body"),
                                        m("textarea[name=body]", {
                                            onkeyup: m.withAttr("value", this.body),
                                            value: this.body(),
                                        }),
                                    ])
                                  : null,
                              this.type() == "announcement"
                                  ? m("div", [
                                        m("label[for=body]", "URL"),
                                        m("input[type=text][name=url]", {
                                            onkeyup: m.withAttr("value", this.url),
                                            value: this.url(),
                                            placeholder: "/comics/123",
                                        }),
                                    ])
                                  : null,
                            this.type() === "chapter" || this.type() === 'volume_affiliate'   
                                  ? m("div", [   
                                        m("label[for=naughty]", "Public scope"),   
                                        m("span", {style: 'margin-top: -.2em;'}, [   
                                            m("p", {style: 'display: block;'}, [   
                                                m("input[type=radio][name=public_scope_public][id=public_scope_public]", {   
                                                    checked: this.public_scope() === "public",   
                                                    onclick: _ => { this.public_scope("public") },   
                                                    style: 'display: inline-block; float: left;'   
                                                }),   
                                                m("label[for=public_scope_public]", {style: 'margin: -.2em 1em .5em 1em;'}, [   
                                                    m("span", { style: 'margin-right: 1em;' }, '🟢 Public'),   
                                                    m("span", { style: "color: #888888; font-size: .8em" }, 'Thumbnail is visible to guests.')   
                                                ]),   
                                            ]),   
                                            m('br'),   
                                            m("p", {style: 'display: block;'}, [   
                                                m("input[type=radio][name=public_scope_naughty][id=public_scope_naughty]", {   
                                                    checked: this.public_scope() === "naughty",   
                                                    onclick: _ => { this.public_scope("naughty") },   
                                                    style: 'display: inline-block; float: left;'   
                                                }),   
                                                m("label[for=public_scope_naughty]", {style: 'margin: -.2em 1em .5em 1em;'}, [   
                                                    m("span", { style: 'margin-right: 1em;' }, '🔞 18+'),   
                                                    m("span", { style: "color: #888888; font-size: .8em" }, 'Title page exposes nipples, genitals etc. Thumbnail is hidden for guests.')   
                                                ]),   
                                            ]),   
                                            m('br'),   
                                            m("p", {style: 'display: block;'}, [   
                                                m("input[type=radio][name=public_scope_access][id=public_scope_access]", {   
                                                    checked: this.public_scope() === "access",   
                                                    onclick: _ => { this.public_scope("access") },   
                                                    style: 'display: inline-block; float: left;'   
                                                }),   
                                                m("label[for=public_scope_access]", {style: 'margin: -.2em 1em .5em 1em;'}, [   
                                                    m("span", { style: 'margin-right: 1em;' }, '⛔ Restricted'),   
                                                    m("span", { style: "color: #888888; font-size: .8em" }, 'Title page has loli, rapey, etc contents. Or just 1-2 pages. No thumbnail for guests.')   
                                                ]),   
                                            ]),   
                                            m('br'),   
                                            m("p", {style: 'display: block;'}, [   
                                                m("input[type=radio][name=public_scope_authenticated_only][id=public_scope_authenticated_only]", {   
                                                    checked: this.public_scope() === "authenticated_only",   
                                                    onclick: _ => { this.public_scope("authenticated_only") },   
                                                    style: 'display: inline-block; float: left;'   
                                                }),   
                                                m("label[for=public_scope_authenticated_only]", {style: 'margin: -.2em 1em .5em 1em;'}, [   
                                                    m("span", { style: 'margin-right: 1em;' }, '🔝 Redirect'),   
                                                    m("span", { style: "color: #888888; font-size: .8em" }, 'Title of comic is dangerous. Guests are redirected to login screen. Search engines cannot crawl.')   
                                                ]),   
                                            ]),   
                                        ])   
                                  ])  
                                  : null,
                              this.type() === "chapter" || this.type() === "volume" || this.type() === "tankoubon" || this.type() === "physical"
                                  ? m("div", [
                                        m("label", "Premium"),
                                        m("input#premium[type=checkbox][name=premium]", {
                                            checked: this.premium(),
                                            onchange: m.withAttr("checked", this.premium),
                                        }),
                                        m("label[for=premium]", "Is Komiflo Plus or Store contents"),
                                    ])
                                  : null,
                              this.type() == "volume_affiliate"
                                  ? m('div', [
                                        m("div", [
                                            m("label[for=size]", "Size"),
                                            m("input[type=text][name=size]", {
                                                onkeyup: m.withAttr("value", this.size),
                                                value: this.size(),
                                            }),
                                        ]),
                                        m("div", [
                                            m("label[for=release_date]", "Release date"),
                                            m("input[type=text][name=release_date][autocomplete=off]", {
                                                onchange: m.withAttr("value", this.release_date),
                                                value: this.release_date(),
                                                oncreate: function(vnode) {
                                                    this.pikaday = new Pikaday({
                                                        field: vnode.dom,
                                                    });
                                                },
                                                onremove: function() {
                                                    this.pikaday.destroy();
                                                },
                                            }),
                                        ]),
                                        this.link_amazon().length > 0 || this.link_fanzagames().length > 0 || this.link_wani().length > 0
                                            ? null
                                            : m("div", [
                                                m("label[for=link_store]", "Link1 (Store)"),
                                                m("input[type=text][name=link_store]", {
                                                    onkeyup: m.withAttr("value", this.link_store),
                                                    value: this.link_store(),
                                                }),
                                            ]),
                                        this.link_amazon().length > 0 || this.link_store().length > 0 
                                            ? null
                                            : m("div", [
                                                  m("label[for=link_wani]", "Link1 (Publisher)"),
                                                  m("input[type=text][name=link_wani]", {
                                                      onkeyup: m.withAttr("value", this.link_wani),
                                                      value: this.link_wani(),
                                                  }),
                                                  !this.artist() && !this.title()
                                                      ? null
                                                      : m(
                                                            "a",
                                                            {
                                                                href:
                                                                    "https://www.wani.com?post_type=product&adult=true&s=" +
                                                                    (this.artist() ||
                                                                        this.title()
                                                                            .split(" ")[0]
                                                                            .split("(")[0]),
                                                                target: "_blank",
                                                            },
                                                            "Search",
                                                        ),
                                              ]),
                                        this.link_amazon().length > 0 || this.link_store().length > 0 
                                            ? null
                                            : m("div", [
                                                  m("label[for=link_fanzagames]", "Link1 (Fanza Games)"),
                                                  m("input[type=text][name=link_fanzagames]", {
                                                      onkeyup: m.withAttr("value", this.link_fanzagames),
                                                      value: this.link_fanzagames(),
                                                  }),
                                              ]),
                                        this.link_fanzagames().length > 0 || this.link_wani().length > 0 || this.link_store().length > 0 
                                            ? null
                                            : m("div", [
                                                  m("label[for=link_amazon]", "Link1 (Amazon)"),
                                                  m("input[type=text][name=link_amazon]", {
                                                      onkeyup: m.withAttr("value", this.link_amazon),
                                                      value: this.link_amazon(),
                                                  }),
                                                  // !this.link_amazon_auto() ? null : m("a", { href: this.link_amazon_auto(), target: "_blank" }, "Affiliate link"),
                                                  !this.link_amazon_auto() ? null : m("a", { onclick: this.useLinkAmazonAuto }, "Use affiliate link"),
                                              ]),
                                        this.link_fanzagames().length > 0
                                            ? null
                                            : [
                                                  m("div", [
                                                      m("label[for=link_dmm]", "Link2 (DMM/Fanza)"),
                                                      m("input[type=text][name=link_dmm]", {
                                                          onkeyup: m.withAttr("value", this.link_dmm),
                                                          value: this.link_dmm(),
                                                      }),
                                                      !this.title()
                                                          ? null
                                                          : m(
                                                                "a",
                                                                {
                                                                    href:
                                                                        "http://www.dmm.co.jp/search/=/searchstr=" +
                                                                        this.title()
                                                                            .split(" ")[0]
                                                                            .split("(")[0],
                                                                    target: "_blank",
                                                                },
                                                                "Search",
                                                            ),
                                                  ]),
                                                  m("div", [
                                                      m("label[for=link_dlsite]", "Link3 (DLsite)"),
                                                      m("input[type=text][name=link_dlsite]", {
                                                          onkeyup: m.withAttr("value", this.link_dlsite),
                                                          value: this.link_dlsite(),
                                                      }),
                                                      !this.title()
                                                          ? null
                                                          : m(
                                                                "a",
                                                                {
                                                                    href:
                                                                        "http://www.dlsite.com/books/fsr/=/language/jp/sex_category%5B0%5D/male/work_category%5B0%5D/books/per_page/30/from/fsr.again/keyword/" +
                                                                        this.title()
                                                                            .split(" ")[0]
                                                                            .split("(")[0],
                                                                    target: "_blank",
                                                                },
                                                                "Search",
                                                            ),
                                                  ]),
                                              ],
                                    ])
                                  : null,
                              ["volume", "volume_affiliate", "tankoubon", "physical"].indexOf(this.type()) == -1
                                  ? null
                                  : m("div", [
                                        m("label", "MSRP"),
                                        m("input[type=text][name=msrp]", {
                                            value: this.msrp(),
                                            onkeyup: m.withAttr("value", this.msrp),
                                        }),
                                    ]),
                              this.type() != "volume_affiliate"
                                  ? null
                                  : m("div", [
                                        m("label", "Not products"),
                                        m("input[type=checkbox][name=notproducts]", {
                                            checked: this.notproducts() ? "checked" : "",
                                            onchange: m.withAttr("checked", this.notproducts),
                                        }),
                                        m("label[for=notproducts]", "Only displayed on the artist page."),
                                    ]),
                              this.type() == "free_trial"
                                  ? m('div', [
                                        m("div", [
                                            m("label", "DurationMonth"),
                                            m("input#duration_month[type=text][name=duration_month]", {
                                                value: this.duration_month(),
                                                onchange: m.withAttr("value", this.duration_month),
                                            }),
                                        ]),
                                        m("div", [
                                            m("label", "DurationDay"),
                                            m("input#duration_day[type=text][name=duration_day]", {
                                                value: this.duration_day(),
                                                onchange: m.withAttr("value", this.duration_day),
                                            }),
                                        ]),
                                        m("div", [m("label", "lander text"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                                        m("div", [
                                            m("label", "Age gate 1"),
                                            m("label", "JP:　"),
                                            m("input#lander_text_agegate1_ja[type=text][name=lander_text_agegate1_ja]", {
                                                value: this.lander_text_agegate1_ja(),
                                                onchange: m.withAttr("value", this.lander_text_agegate1_ja),
                                            }),
                                            m("label", "　default: あとでみます"),
                                        ]),
                                        m("div", [
                                            m("label", ""),
                                            m("label", "EN:　"),
                                            m("input#lander_text_agegate1_en[type=text][name=lander_text_agegate1_en]", {
                                                value: this.lander_text_agegate1_en(),
                                                onchange: m.withAttr("value", this.lander_text_agegate1_en),
                                            }),
                                            m("label", "　default: Remind me later"),
                                        ]),
                                        m("div", [
                                            m("label", "Button text 1"),
                                            m("label", "JP:　"),
                                            m("input#lander_text_button1_ja[type=text][name=lander_text_button1_ja]", {
                                                value: this.lander_text_button1_ja(),
                                                onchange: m.withAttr("value", this.lander_text_button1_ja),
                                            }),
                                            m("label", "　default: 今すぐ読み始める"),
                                        ]),
                                        m("div", [
                                            m("label", ""),
                                            m("label", "EN:　"),
                                            m("input#lander_text_button1_en[type=text][name=lander_text_button1_en]", {
                                                value: this.lander_text_button1_en(),
                                                onchange: m.withAttr("value", this.lander_text_button1_en),
                                            }),
                                            m("label", "　default: Start reading now"),
                                        ]),
                                        m("div", [
                                            m("label", "Button text 2"),
                                            m("label", "JP:　"),
                                            m("input#lander_text_button2_ja[type=text][name=lander_text_button2_ja]", {
                                                value: this.lander_text_button2_ja(),
                                                onchange: m.withAttr("value", this.lander_text_button2_ja),
                                            }),
                                            m("label", "　default: 無料体験を始める"),
                                        ]),
                                        m("div", [
                                            m("label", ""),
                                            m("label", "EN:　"),
                                            m("input#lander_text_button2_en[type=text][name=lander_text_button2_en]", {
                                                value: this.lander_text_button2_en(),
                                                onchange: m.withAttr("value", this.lander_text_button2_en),
                                            }),
                                            m("label", "　default: Start my free trial"),
                                        ]),
                                        m("div", [
                                            m("label", "Appeal text 1"),
                                            m("label", "JP:　"),
                                            m("input#lander_text_appeal1_ja[type=text][name=lander_text_appeal1_ja]", {
                                                value: this.lander_text_appeal1_ja(),
                                                onchange: m.withAttr("value", this.lander_text_appeal1_ja),
                                            }),
                                            m("label", "　default: 今すぐ無料でおためし"),
                                        ]),
                                        m("div", [
                                            m("label", ""),
                                            m("label", "EN:　"),
                                            m("input#lander_text_appeal1_en[type=text][name=lander_text_appeal1_en]", {
                                                value: this.lander_text_appeal1_en(),
                                                onchange: m.withAttr("value", this.lander_text_appeal1_en),
                                            }),
                                            m("label", "　default: Try Komiflo now, for free."),
                                        ]),
                                        m("div", [m("label", "lander PR img"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                                        m("div", [
                                            m("label", "img"),
                                            m("input#lander_img_d[type=text][name=lander_img_d]", {
                                                value: this.lander_img_d(),
                                                onchange: m.withAttr("value", this.lander_img_d),
                                            }),
                                            m("label", "*Relative path(e.g: /asset/eg.png)"),
                                        ]),
                                        m("div", [
                                            m("label", "size"),
                                            m("input#lander_img_d_size[type=text][name=lander_img_d_size]", {
                                                value: this.lander_img_d_size(),
                                                onchange: m.withAttr("value", this.lander_img_d_size),
                                            }),
                                            m("label", "*0～100"),
                                        ]),
                                        m("div", [
                                            m("label", "position"),
                                            m("input#lander_img_d_position[type=text][name=lander_img_d_position]", {
                                                value: this.lander_img_d_position(),
                                                onchange: m.withAttr("value", this.lander_img_d_position),
                                            }),
                                            m("label", "*0～100 (e.g: 30, 50)"),
                                        ]),
                                        m("div", [
                                            m("label", "img(mobile)"),
                                            m("input#lander_img_m[type=text][name=lander_img_m]", {
                                                value: this.lander_img_m(),
                                                onchange: m.withAttr("value", this.lander_img_m),
                                            }),
                                            m("label", "*Relative path(e.g: /asset/eg.png)"),
                                        ]),
                                        m("div", [
                                            m("label", "size(mobile)"),
                                            m("input#lander_img_m_size[type=text][name=lander_img_m_size]", {
                                                value: this.lander_img_m_size(),
                                                onchange: m.withAttr("value", this.lander_img_m_size),
                                            }),
                                            m("label", "*0～100"),
                                        ]),
                                        m("div", [
                                            m("label", "position(mobile)"),
                                            m("input#lander_img_m_position[type=text][name=lander_img_m_position]", {
                                                value: this.lander_img_m_position(),
                                                onchange: m.withAttr("value", this.lander_img_m_position),
                                            }),
                                            m("label", "*0～100 (e.g: 30, 50)"),
                                        ]),
                                        m("div", [m("label", "register"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                                        m("div", [
                                            m("label[for=register_anime1]", "anime1"),
                                            m(
                                                "select[name=register_anime1]",
                                                {
                                                    onchange: m.withAttr("value", this.register_anime1),
                                                    value: this.register_anime1(),
                                                },
                                                [m("option[value=0]", "Type1 (default)"), m("option[value=2]", "Type2 (Bonus free 1month)")],
                                            ),
                                        ]),
                                        m("div", [
                                            m("label", "Appeal text 1"),
                                            m("label", "JP:　"),
                                            m("input#register_text_appeal1_ja[type=text][name=register_text_appeal1_ja]", {
                                                value: this.register_text_appeal1_ja(),
                                                onchange: m.withAttr("value", this.register_text_appeal1_ja),
                                            }),
                                            m("label", "　default: ●●の無料期間"),
                                        ]),
                                        m("div", [
                                            m("label", ""),
                                            m("label", "EN:　"),
                                            m("input#register_text_appeal1_en[type=text][name=register_text_appeal1_en]", {
                                                value: this.register_text_appeal1_en(),
                                                onchange: m.withAttr("value", this.register_text_appeal1_en),
                                            }),
                                            m("label", "　default: ●● month free trial"),
                                        ]),
                                    ])
                                  : null,

                              m("div", [m("button[type=submit]", "Create")]),
                          ])
                        : null,
                ],
            );
        },
    },
};
