"use strict";

import m from "mithril";

export default {
    oninit(vnode) {
        this.vm = vnode.attrs.vm;
        this.params = vnode.attrs.params;

        this.change = (page) => {
            this.vm.load(page, this.params);
            return false;
        };
    },

    view() {
        let total = this.vm.totalPages();
        let current = this.vm.page();
        let list = [];

        let i;

        if (current > 5) {
            list.push(0);
        }

        for (i = current - 5; i < current + 5; i += 1) {
            if (i >= 0 && i < total) {
                list.push(i);
            }
        }

        if (i < total) {
            list.push(total - 1);
        }
        return [
            m(".pagination-spacer"),
            m(".pagination", list.map((page) =>
                m("button" + (page == current ? ".current" : ""), { onclick: this.change.bind(this, page) }, page == 0 ? "First" : page == (total - 1) ? "Last" : (page + 1))
            ))
        ];
    },
};
