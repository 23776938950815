"use strict";

import m from "mithril";
import { getTask, updateTask } from "../../api-client";

const DEFALT_TYPE = "billing";
const DEFALT_STATUS = "";

export default {
    $component: {
        oninit(vnode) {
            this.loading = m.prop(true);
            this.id = m.prop(vnode.attrs.id);
            this.date = m.prop("");
            this.time = m.prop("");
            this.load();
        },

        load() {
            this.loading(true);

            getTask(this.id()).then(res => {
                this.loading(false);
                const {DateJst, TimeJst} = res.task;
                this.date("20" + String(DateJst).substr(0, 2) + "-" + String(DateJst).substr(2, 2) + "-" + String(DateJst).substr(4, 2));
                this.time(TimeJst);
                m.redraw();
            });
        },

        submit(e) {
            e.preventDefault();

            const date = new Date(this.date);
            const params = {
                type: DEFALT_TYPE,
                status: DEFALT_STATUS,
                date_jst: parseInt( date.getFullYear().toString().substr(2,2) + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2)),
                time_jst: parseInt(this.time()),
            };
            console.log(params);

            updateTask(this.id(), params)
                .then(() => {
                    alert("Successfully Edited");
                    m.route.set("/task/list");
                })
                .catch(error => {
                    alert(error);
                });
        },

        view() {
            if (this.loading()) {
                return m("h1", "Loading");
            }

            return m("div", [
                m("form", { onsubmit: e => this.submit(e) }, [
                    m("h2", "Edit Schedule"),
                    m("div", [
                        m("label", "Date"),
                        m("input[type=text][placeholder=date][required]", {
                            onchange: m.withAttr("value", this.date),
                            value: this.date(),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Time"),
                        m("select[required]", {
                            onchange: m.withAttr("value", this.time),
                            value: this.time(),
                        },
                        Array.apply(null, {length: 24}).map(Number.call, Number).map(i => {
                            return m("option", { value: i, selected: i == this.time || null }, ("0" + i).slice(-2) + ":00")
                        })
                    )
                    ]),
                    m("div", [m("span"), m("input[type=submit][value=Save]")]),
                ]),
            ]);
        },
    },
};
