import m from "mithril";
import { getContent, getContentSearch, patchContent } from "../../api-client";

const model = {
    visible: false,
    contentID: null,
    attributeIDs: [],
    copiedAttributeIDs: [],
    copyCandidates: [],
    selectedCopyCandidateID: null,
    copyLoading: false,

    onSetTitle: () => { alert("usage: (title: string, title_kana: string) => {}") },
    onSetAttributeIDs: () => { alert("usage: (attribute_ids: []number) => {}") },
    onUndo: () => { alert("usage: (new_attribute_ids: []number) => {}") },

    init() {
        this.visible = false;
        this.contentID = null;
        this.attributeIDs = [];
        this.copiedAttributeIDs = [];
        this.copyCandidates = [];
        this.selectedCopyCandidateID = null;
        this.copyLoading = false;
    },

    set(attrs) {
        this.visible = attrs.visible;
        this.attributeIDs = attrs.attributeIDs;
        this.onSetTitle = attrs.setTitle;
        this.onSetAttributeIDs = attrs.setAttributeIDs;
        this.onUndo = attrs.undo;
        if (!attrs.contentID || attrs.contentID == this.contentID) {
            return;
        }
        this.contentID = attrs.contentID;
        getContent(this.contentID)
            .then((res) => {
                const artistAttrs = res.content.attributes.artists.children;
                if (artistAttrs.length === 0) {
                    return;
                }
                const artist = artistAttrs[Math.floor(Math.random()*artistAttrs.length)];
                if (artist) {
                    getContentSearch(0, 999, null, null, null, null, null, null, artist.slug, null)
                        .then((res) => {
                            this.copyCandidates = res.results;
                        })
                        .finally(() => {
                            m.redraw();
                        });
                }
            })
    },

    shouldDisplay() {
        return this.visible && this.contentID;
    },

    copyAttributes() {
        if (!this.selectedCopyCandidateID) {
            alert("Please select a source content to copy from.");
            return;
        }

        const ids = this.attributeIDs;
        this.copyLoading = true;
        getContent(this.selectedCopyCandidateID)
            .then((res) => {
                Object.entries(res.content.attributes).forEach(([parentSlug, parent]) => {
                    parent.children.forEach((child) => {
                        if (ids.some((a) => a === child.id)) {
                            return;
                        }
                        ids.push(child.id);
                        this.copiedAttributeIDs.push(child.id);
                    });
                });

                this.onSetAttributeIDs(ids);
                this.onSetTitle(res.content.data.title, res.content.data.title_kana);
            })
            .finally(() => {
                this.copyLoading = false;
                m.redraw();
            })
    },

    undoCopy() {
        this.onUndo(this.attributeIDs.filter((id) => !this.copiedAttributeIDs.includes(id)));
        this.copiedAttributeIDs = [];
        this.selectedCopyCandidateID = null;
    },
}

export const AttrTitleCopier = {
    oninit: ({ attrs }) => {
        model.init();
        model.set(attrs);
    },
    onupdate: ({ attrs }) => {
        model.set(attrs);
    },
    view: () => {
        if (!model.shouldDisplay()) {
            return null;
        }

        return m("div", [
            m("label", m("button", {
                disabled: !model.selectedCopyCandidateID || model.copyLoading,
                style: { margin: "0" },
                onclick: (e) => {
                    e.preventDefault();
                    if (model.copiedAttributeIDs.length > 0) {
                        model.undoCopy();
                    } else {
                        model.copyAttributes();
                    }
                },
            }, (() => { if(model.copyLoading) {
                return "Processing...";
            } else if (model.copiedAttributeIDs.length > 0) {
                return "Undo copy";
            } else {
                return "Copy From";
            } })())),
            m("select", { value: model.selectedCopyCandidateID || "", onchange: (e) => {
                const candidate = model.copyCandidates.find(c => c.id.toString() === e.target.value);
                model.selectedCopyCandidateID = candidate ? candidate.id : null;
            } }, model.copyCandidates.length > 0
                ? [
                    m("option", { value: "" }, "Select source content"),
                    model.copyCandidates.map((content) => m("option", { value: content.id }, `${content.id}: ${content.data.title}`)),
                ]
                : m("option", { value: "" }, "...")
            ),
        ]);
    },
}