"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import {
    getEmailSchedulesFuture,
    deleteEmailSchedule,
    deleteEmailSchedulesAll,
} from "../../api-client";
import css from "./list.css";

const dateFormat = function(date) {
    return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2) + ` ${date.getHours()}:00`;
}

class Model {
    constructor() {
        this.page = m.prop(0);
        this.perPage = m.prop(50);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getEmailSchedulesFuture(this.page(), this.perPage()).then(
            res => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }
}

export default {
    $navTitle: "Schedule",
    $component: {
        oninit(vnode) {
            this.vm = new Model();

            this.vm.load(0);
        },

        deleteSchedule(s) {
            if (!confirm("Are you sure?")) return false;

            deleteEmailSchedule(s.id).then(
                res => {
                    s.deleted = true;
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            )
        },

        deleteAllSchedules() {
            if (!confirm("This will unschedule all emails which are yet to be delivered. Are you sure?")) return false;

            if (!confirm("Are you SURE you're sure?")) return false;

            deleteEmailSchedulesAll().then(
                res => {
                    this.vm.elements([]);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            )
        },

        view() {
            return m("div", [
                m("h1", "Scheduled emails"),
                this.vm.elements().length === 0
                    ? m("p", "There are no scheduled emails.")
                    : [
                        m("table", [
                            m("tr", [
                                m("th", "Date"),
                                m("th", "Template"),
                                m("th", "To"),
                                m("th", "")
                            ]),
                            this.vm.elements().map(s => {
                                const scheduleDate = new Date(s.schedule_date);
                                return m("tr",
                                    {
                                        classList: s.deleted ? css.deleted : "",
                                    },
                                    [
                                        m("td", dateFormat(scheduleDate)),
                                        m("td", m("a", { href: `/email/template/${s.email_id}/edit`, oncreate: m.route.link }, `#${s.email_id}: ${s.email_subject}`)),
                                        m("td", s.user_id
                                            ? m("a", { href: `/user/id/${s.user_id}`, oncreate: m.route.link }, s.email_address)
                                            : s.email_address
                                        ),
                                        m("td", m("button", { onclick: () => { this.deleteSchedule(s) } }, "x")),
                                    ]);
                            }),
                        ]),
                        m("button", { onclick: () => { this.deleteAllSchedules() } }, "Unschedule all"),
                        m(pagination, { vm: this.vm }),
                    ],
            ]);
        }
    }
};
