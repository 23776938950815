"use strict";

import Create from "./create";
import Edit from "./edit";
import List from "./list";

export default {
    create: Create,
    ":id/edit": Edit,
    list: List,
};
