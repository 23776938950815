import m from "mithril";
import css from "../../global.css";
import tablecss from "../../table.css";
import {
    getCoupon,
    getCouponItems,
    getCouponUsers,
    getItem,
    updateCoupon,
    updateCouponItems,
    deleteCoupon,
    deleteCouponItems,
    deleteCouponUsers,
} from "../../api-client";
import { timeSelector } from "../../utils/input-component";
import { dateFormatRFC3339 } from '../../utils/helper';
import { CouponTypes, CouponCommissionTerms } from './const';
import link from '../../utils/link';
import { parseIntForInput } from '../../utils/type-cast';


const invalidName = 'No item found for the ID';

export default {
    $component: {
        id: 0,
        coupon: {},
        start: {},
        expiry: {},
        items: [],
        users: [],
        loading: false,
        verifying: false,

        oninit(vnode) {
            this.id = vnode.attrs.id;
            this.loadCoupon(this.id);
            this.loadItems(this.id);
            this.loadUsers(this.id);
        },

        loadCoupon(id) {
            this.loading = true;
            getCoupon(id)
                .then(res => {
                    this.loading = false;
                    this.id = res.coupon.id;
                    this.coupon = res.coupon;

                    if (this.coupon.start_date) {
                        this.start = {
                            day: new Date(this.coupon.start_date),
                            time: new Date(this.coupon.start_date).getHours() + ":00",
                        }
                    } else {
                        this.start = { time: '0:00' };
                    }
                    if (this.coupon.expiry_date) {
                        this.expiry = {
                            day: new Date(this.coupon.expiry_date),
                            time: new Date(this.coupon.expiry_date).getHours() + ":00",
                        }
                    } else {
                        this.expiry = { time: '0:00' };
                    }
                    m.redraw();
                })
                .catch(err => {
                    this.loading = false;
                    alert(JSON.stringify(err, null, 2));
                });
        },
        loadItems(id) {
            getCouponItems(id)
            .then(res => {
                this.items = res.items.map(item => {
                    return {
                        id: item.item_id,
                        name: item.item_name,
                        type: item.type,
                        value: item.value,
                        commission_terms: item.commission_terms,
                        new_face: false,
                    }
                });
                m.redraw();
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))});
        },
        loadUsers(id) {
            getCouponUsers(id)
            .then(res => {
                this.users = res.users;
                m.redraw();
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))});
        },

        submit(e) {
            e.preventDefault();

            this.coupon.start_date = dateFormatRFC3339(this.start.day, this.start.time);
            this.coupon.expiry_date = dateFormatRFC3339(this.expiry.day, this.expiry.time);

            updateCoupon(this.id, this.coupon)
                .then(() => {
                    alert("Successfully updated this coupon");
                    this.loadCoupon(this.id);
                })
                .catch(err => {alert(JSON.stringify(err, null, 2))});
        },
        submitItem(index) {
            this.coupon.individual_items = [this.items[index]];

            updateCouponItems(this.id, this.coupon)
                .then(() => {
                    alert("Successfully saved items");
                    this.loadItems(this.id);
                })
                .catch(err => {alert(JSON.stringify(err, null, 2))});
        },

        removeItem(index) {

            const target = this.items[index];

            if (target.new_face === true) {
                this.items.splice(index, 1);
            } else if (target.new_face === false) {
                deleteCouponItems(this.id, [target.id])
                    .then(() => {
                        alert("Successfully deleted this item");
                        this.loadItems(this.id);
                    })
                    .catch(err => {alert(JSON.stringify(err, null, 2))});
            }
        },
        removeUser(index) {
            deleteCouponUsers(this.id, [this.users[index].user_id])
                .then(() => {
                    alert("Successfully deprived this coupon from the user");
                    this.loadUsers(this.id);
                    m.redraw();
                })
                .catch(err => {alert(JSON.stringify(err, null, 2))});
        },
        delete() {
            if (this.verifying) {
                deleteCoupon(this.id)
                    .then(() => {
                        alert("Successfully deleted this coupon");
                        m.route.set("/coupon/list");
                    })
                    .catch(err => {alert(JSON.stringify(err, null, 2))});
            } else {
                this.verifying = true ;
            }
        },

        view() {

            if (this.loading) {
                return m('div', [
                    m('.loading', [
                        m('.load'),
                        m('p', 'Loading...'),
                    ])
                ])
            }

            return m("div", [
                this.formView(),
                m('hr'),
                this.applicableItemsView(),
                m('hr'),
                this.usersVuew(),
                m('hr'),
                this.dangerZoneView(),
            ])
        },

        formView() {
            return m('form', [
                m('h2', 'Edit Coupon'),
                m('div', [
                    m('label', 'Name'),
                    m('input[type=text][placeholder=Name][required]', {
                        oninput: e => {this.coupon.name = e.target.value},
                        value: this.coupon.name,
                    }),
                ]),
                m('div', [
                    m('label', 'Max per user'),
                    m('input[type=text][placeholder=Max per User]', {
                        oninput: e => {this.coupon.max_per_user = parseIntForInput(e.target.value)},
                        value: this.coupon.max_per_user,
                    }),
                ]),
                m('div', [
                    m('label', 'Start date'),
                    m('input[type=text]', {
                        onchange: e => {this.start.day = e.target.value},
                        value: this.start.day,
                        oncreate(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom,
                            });
                        },
                        onremove() {
                            this.pikaday.destroy();
                        },
                    }),
                    ...timeSelector(e => {this.start.time = e}, this.start.time),
                ]),
                m('div', [
                    m('label', 'Expiry date'),
                    m('input[type=text]', {
                        onchange: e => {this.expiry.day = e.target.value},
                        value: this.expiry.day,
                        oncreate(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom,
                            });
                        },
                        onremove() {
                            this.pikaday.destroy();
                        },
                    }),
                    ...timeSelector(e => {this.expiry.time = e}, this.expiry.time),
                ]),
                m('div', [
                    m('label', 'Auto add future items'),
                    m('input', {
                        type: 'checkbox',
                        oninput: e => {this.coupon.auto_add = e.target.checked},
                        checked: this.coupon.auto_add,
                    }),
                ]),
                m('div', m('button', { onclick: e => this.submit(e) }, 'Update main part')),
            ])
        },
        applicableItemsView() {
            return m('div', [
                m('h2', 'Applicable Items'),
                m('table', [
                    m('thead', [
                        m('th', 'ID'),
                        m('th', 'Name'),
                        m('th', 'Discount Value'),
                        m('th', 'Commission'),
                        m('th', 'Remove'),
                        m('th', 'Apply Changes'),
                    ]),
                    this.items.map((item, i) =>
                        m('tr', [
                            m('td',
                                item.new_face
                                ? m(`input[type=text].${css.short}`, {
                                    oninput: e => {
                                        if (e.target.value) {
                                            item.id = parseIntForInput(e.target.value);
                                            getItem(item.id)
                                                .then(res => { item.name = res.shop_item.name; m.redraw() })
                                                .catch(() => { item.name = invalidName; m.redraw() });
                                        } else {
                                            item.id = undefined;
                                        }
                                    },
                                    value: item.id,
                                })
                                : m('a', link(`/items/${item.id}/view`, item.id))
                            ),
                            m('td', item.name),
                            m('td', [
                                m(`input[type=text]${item.type ? '' : '[disabled]'}.${css.short}`, {
                                    oninput: e => { item.value = parseIntForInput(e.target.value) },
                                    value: item.value,
                                }),
                                m(`select.${css.short}`,
                                    { onchange: e => {item.type = e.target.value} },
                                    CouponTypes.map(couponType =>
                                        m('option',
                                            {
                                                selected: item.type === couponType.value,
                                                value: couponType.value,
                                            },
                                            couponType.name,
                                        ),
                                    ),
                                ),
                            ]),
                            m('td', [
                                m(`select.${css.short}`,
                                { onchange: e => {item.commission_terms =  e.target.value} },
                                CouponCommissionTerms.map(commissionTerms =>{
                                    return m('option',
                                        {
                                            selected: item.commission_terms === commissionTerms,
                                            value: commissionTerms,
                                        },
                                        commissionTerms,
                                    )}
                                )),
                            ]),
                            m('td', m('a', { onclick: () => this.removeItem(i) }, '✕')),
                            m('td', m('a', { onclick: () => this.submitItem(i) }, '✔')),
                        ])
                    ),
                    m('tr', m('button', { onclick: () => this.addItem() }, 'Add item')),
                ]),
            ])
        },
        usersVuew() {
            return m('div', [
                m('h2', 'Users Who Have This Coupon'),
                m('table', [
                    m('thead', [
                        m('th', 'ID'),
                        m('th', 'Email'),
                        m('th', '# Left'),
                        m('th', 'Deprive'),
                    ]),
                    this.users.map((user, i) =>
                        m('tr', [
                            m('td', m('a', link(`/user/${user.user_id}/view`, user.user_id))),
                            m('td', user.user_email),
                            m("td", m(`span.${tablecss.count}`, user.remaining)),
                            m('td', m('button', { onclick: () => this.removeUser(i) }, 'DEPRIVE THIS COUPON')),
                        ])
                    ),
                ]),
            ])
        },
        dangerZoneView() {
            return m('div', [
                m('h2', 'Danger Zone'),
                m('button', { onclick: () => this.delete() }, 'DELETE THIS COUPON')
            ])
        },

        addItem() {
            this.items.push({
                new_face: true,
                type: CouponTypes[0].value,
                value: 0,
                commission_terms: CouponCommissionTerms[0],
            })
        }
    },
};
