"use strict";

import Create from "./create";
import Mine from "./mine";
import Expired from "./expired";
import Comments from "./comments";
import Type from "./type";
import TypeList from "./type_list";
import View from "./view";
import Edit from "./edit";
import List from "./list";
import Ranking from "./ranking";
import Weekly from "./weekly";
import Download from "./download";
import Filter from "./filter"
import FilterList from "./list_filter_notifications"

export default {
    "create": Create,
    "type": TypeList,
    "mine": Mine,
    "expired": Expired,
    "comments": Comments,
    "type/:type": Type,
    ":id/view": View,
    ":id/edit": Edit,
    "ranking": Ranking,
    "weekly": Weekly,
    "download": Download,
    "list": List,
    "filter/notification": Filter,
    "filter/created_filter_notifications": FilterList
};
