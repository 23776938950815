"use strict";

import m from "mithril";
import link from "../../utils/link";

export default {
    $navTitle: "Published",
    $component: {
        view() {
            return m("div", [
                m("h1", "Content type"),
                m("ul", [
                    m("li", [ m("a", { href: "/content/type/volume", oncreate: m.route.link }, "Magazine") ]),
                    m("li", [ m("a", { href: "/content/type/chapter", oncreate: m.route.link }, "Chapter") ]),
                    m("li", [ m("a", { href: "/content/type/tankoubon", oncreate: m.route.link }, "Tankoubon") ]),
                    m("li", [ m("a", { href: "/content/type/sample", oncreate: m.route.link }, "Sample") ]),
                    m("li", [ m("a", { href: "/content/type/volume_affiliate", oncreate: m.route.link }, "Affiliate") ]),
                    m("li", [ m("a", { href: "/content/type/announcement", oncreate: m.route.link }, "Announcement") ]),
                    m("li", [ m("a", { href: "/content/type/subscription", oncreate: m.route.link }, "Subscription") ]),
                    m("li", [ m("a", { href: "/content/type/physical", oncreate: m.route.link }, "Physical") ]),
                    m("li", [ m("a", { href: "/content/type/article", oncreate: m.route.link }, "Special content") ]),
                    m("li", [ m("a", { href: "/content/type/asset", oncreate: m.route.link }, "Special content asset") ]),
                    m("li", [ m("a", { href: "/content/type/faq", oncreate: m.route.link }, "FAQ article") ]),
                    m("li", [ m("a", { href: "/content/type/status", oncreate: m.route.link }, "System status") ]),
                    m("li", [ m("a", { href: "/content/type/free_trial", oncreate: m.route.link }, "Free trial") ]),
                    m("li", [ m("a", { href: "/content/type/tweet", oncreate: m.route.link }, "Tweet") ]),
                ])
            ]);
        },
    },
};
