import m from "mithril";
import { getCoupon, getEmailTemplates, getAttribute, getPointMutiple, getMission, createMission, putMission, deleteMission } from "../../api-client";
import { dateFormatRFC3339 } from "../../utils/helper";
import { parseIntForInput } from "../../utils/type-cast";
import { timeSelector } from "../../utils/input-component";
import link from "../../utils/link";
import autocomplete from "../../components/autocomplete";
import dayjs from "dayjs";
import { model } from "./editor";

const reqSeed = [
    { title: "Visit Komiflo", slug: "visit" },
    { title: "Gain Premium Access", slug: "premium_access" },
    { title: "Purchase Shop Items", slug: "purchase" },
    { title: "Purchase Subscription", slug: "subscription" },
    { title: "Switch Provider", slug: "switch_provider" },
    { title: "Switch Provider with an active subscription", slug: "switch_provider_active" },
    { title: "Escape from Visa", slug: "escape_visa" },
    { title: "Escape from Mastercard", slug: "escape_master" },
    { title: "Invited user registers email", slug: "invite_register" },
    { title: "Invited user pays (by credit card)", slug: "invite_verified" },
    { title: "Invited", slug: "invited" },
    { title: "Answer Survey", slug: "answer_survey" },
    { title: "Post to Twitter", slug: "tweet" },
];

export const requirementsView = () => [
    m("h2", "Requirements"),
    model.mission.requirements.map((req, i) => [
        m(
            "h3",
            `Stage ${i + 1}  `,
            i > 0
                ? m(
                      "button",
                      {
                          onclick: (e) => {
                              e.preventDefault();
                              model.mission.requirements.splice(i, 1);
                          },
                      },
                      "✗",
                  )
                : "",
        ),
        m(
            "table",
            { style: "max-width: 1000px;" },
            m("thead", m("tr", m("th", "type"), m("th", "# actions"), m("th", ""), m("th", ""))),
            m(
                "tbody",
                req.map((r, j) =>
                    m(
                        "tr",
                        m(
                            "td",
                            m(
                                "select",
                                {
                                    onchange: (e) => {
                                        r.type = e.target.value;
                                    },
                                    value: r.type,
                                    style: "width: 100%;",
                                },
                                m("option", { value: "" }, "Select type..."),
                                reqSeed.map((rs) => m("option", { value: rs.slug }, rs.title)),
                            ),
                        ),
                        m(
                            "td",
                            m("input", {
                                type: "number",
                                style: "width: 3em",
                                disabled: r.type === "",
                                oninput: (e) => {
                                    const val = parseIntForInput(e.target.value);
                                    if (val > 0) {
                                        r.count = val;
                                    }
                                },
                                value: r.count,
                            }),
                        ),
                        m("td", m(paramsInput, { requirement: r })),
                        m(
                            "td",
                            m(
                                "button",
                                {
                                    onclick: (e) => {
                                        e.preventDefault();
                                        req.splice(j, 1);
                                    },
                                },
                                "✗",
                            ),
                        ),
                    ),
                ),
            ),
            m(
                "tfoot",
                m(
                    "button",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            req.push({ type: "", count: 1 });
                        },
                    },
                    "Add requirement",
                ),
            ),
        ),
    ]),
    m(
        "button",
        {
            onclick: (e) => {
                e.preventDefault();
                model.mission.requirements.push([]);
            },
        },
        "Add stage",
    ),
];

const paramsInput = {
    view: ({ attrs }) => {
        const { requirement } = attrs;

        switch (requirement.type) {
        case "tweet":
            return m("input", {
                type: "text",
                placeholder: "target,tweet,content,ids",
                onchange: (e) => {
                    requirement.target_content = e.target.value.split(",").map((v) => parseIntForInput(v));
                },
                value: (requirement.target_content || []).join(","),
            });
        case "answer_survey":
            return m("input", {
                type: "text",
                placeholder: "target,survey,ids",
                onchange: (e) => {
                    requirement.target_survey = e.target.value.split(",").map((v) => parseIntForInput(v));
                },
                value: (requirement.target_survey || []).join(","),
            });
        case "subscription":
            return m("input", {
                type: "text",
                placeholder: "target,next,providers",
                onchange: (e) => {
                    requirement.target_next_provider = e.target.value.split(",");
                },
                value: (requirement.target_next_provider || []).join(","),
            })
        case "switch_provider":
        case "switch_provider_active":
            return [
                m("input", {
                    type: "text",
                    placeholder: "target previous provider",
                    onchange: (e) => {
                        requirement.target_previous_provider = e.target.value;
                    },
                    value: requirement.target_previous_provider || "",
                }),
                m("input", {
                    type: "text",
                    placeholder: "target,next,providers",
                    onchange: (e) => {
                        requirement.target_next_provider = e.target.value.split(",");
                    },
                    value: (requirement.target_next_provider || []).join(","),
                }),
            ]
        case "purchase":
            return [
                m("input", {
                    type: "text",
                    placeholder: "target,item,ids",
                    onchange: (e) => {
                        requirement.target_item = e.target.value.split(",").map((v) => parseIntForInput(v));
                    },
                    value: (requirement.target_item || []).join(","),
                }),
                m("input", {
                    type: "text",
                    placeholder: "threshold value",
                    onchange: (e) => {
                        requirement.threshold = parseIntForInput(e.target.value);
                    },
                    value: requirement.threshold || "",
                }),
            ];
        default:
            return ""
        }
    }
}
