"use strict";

import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import css from "./editor.css";
import {
    baseUrl,
    createAttribute,
    patchAttribute,
    getAttribute,
    getAttributeChildren,
    deleteAttribute,
    getAttributeRoots,
    getPendingBannerByAttrId,
    postApproveBanner,
    setAttributeOriginalName,
    postBulkAssignAttribute,
    deleteBulkRemoveAttribute,
    postAttributeSibling,
    deleteAttributeSibling,
} from "../../api-client";
import { timeSelector } from "../../utils/input-component";
import dayjs from "dayjs";

function rootString(root) {
    return `${root.data.title || root.slug} (#${root.id})`;
}

class Model {
    constructor(id) {
        this.attr = m.prop({});
        this.pending = m.prop({});

        this.children = m.prop([]);
        this.siblings = [];
        this.content = m.prop([]);
        this.published = m.prop("");
        this.publishedTime = m.prop("");

        this.slug = m.prop("");
        this.name_read = m.prop("");
        this.name_en = m.prop("");
        this.ipRestrict = m.prop("");
        this.href = m.prop("");
        this.name = m.prop("");
        this.duration = "";
        this.percentage = m.prop(0);
        this.visibility = m.prop(true);
        this.searchTerms = m.prop("");
        this.tankoubonLabel = m.prop("");
        this.parentId = m.prop(0);
        this.createdFromParent = m.prop(false);
        this.naughtyBanner = m.prop(false);

        this.uploading = m.prop(false);
        this.progressValue = m.prop(0);
        this.progressMax = m.prop(1);
        this.loading = m.prop(true);

        this.loading = m.prop(false);

        this.onupdate(id);
    }

    onupdate(id) {
        const newID = parseInt(id, 10);

        if (isFinite(newID) && this.id !== newID) {
            this.id = newID;
            this.refresh();
        }
    }

    refresh() {
        this.loading(true);

        getAttribute(this.id).then(
            res => {
                this.loading(false);

                this.attr(res.attribute);
                this.slug(res.attribute.slug);
                this.name_read(res.attribute.name_read);
                this.name_en(res.attribute.data.name_en || "");
                this.published(res.attribute.published || "");
                this.publishedTime(new Date(res.attribute.published).getHours() + ":00" || "");
                this.ipRestrict(res.attribute.data.ipRestrict || "");
                this.visibility(Boolean(res.attribute.visibility));
                this.href(res.attribute.data.href || "");
                this.name(res.attribute.data.name || "");
                this.duration = res.attribute.data.duration || "";
                this.percentage(res.attribute.data.percentage || 0);
                this.searchTerms(res.attribute.data.searchTerms || "");
                this.tankoubonLabel(res.attribute.data.tankoubonLabel || "");
                this.parentId((res.attribute.parent && res.attribute.parent.id) || 0);
                this.children(res.children);
                this.content(res.content);
                this.naughtyBanner(res.attribute.data.naughtyBanner || false);

                // Populate this.siblings with actual (or empty) sibling ids
                let i = 0;
                let siblingsLength = 3;

                if (this.parentId() !== config.artists_attribute_id) {
                    siblingsLength = res.attribute.siblings.length;
                }

                while (i < siblingsLength) {
                    this.siblings.push(m.prop(res.attribute.siblings[i] ? res.attribute.siblings[i] : []));
                    i += 1;
                }

                this.loading(false);

                m.redraw();
            },
            err => {
                this.loading(false);

                console.error(err);
                alert(JSON.stringify(err));

                this.loading(false);
            },
        );

        getPendingBannerByAttrId(this.id).then(
            res => {
                this.pending(res.results);
            },
            err => {
                if (err.error !== "no_such_object") {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            },
        );
    }

    data() {
        const data = {
            name: this.name(),
        };

        if (this.name_en()) {
            data.name_en = this.name_en();
        }
        if (this.searchTerms()) {
            data.searchTerms = this.searchTerms();
        }
        if (this.tankoubonLabel()) {
            data.tankoubonLabel = this.tankoubonLabel();
        }
        if (this.ipRestrict()) {
            data.ipRestrict = this.ipRestrict();
        }
        if (this.href()) {
            data.href = this.href();
        }
        if (this.duration) {
            data.duration = this.duration;
        }
        if (this.percentage()) {
            data.percentage = this.percentage();
        }
        if (this.parentId() == config.artists_attribute_id) {
            data.naughtyBanner = this.naughtyBanner() || false;
        }

        return data;
    }

    save() {
        const publishDate = new Date(`${this.published()} ${this.publishedTime()}:00`).toJSON();

        if (this.attr().id) {
            patchAttribute(this.attr().id, {
                slug: this.slug(),
                name_read: this.name_read(),
                published: publishDate,
                visibility: this.visibility() ? 1 : 0,
                parent: parseInt(this.parentId(), 10) || null,
                data: this.data(),
            }).then(
                res => {
                    this.attr(res.attribute);
                    m.redraw();
                    alert("Save successful");
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        } else {
            createAttribute(this.slug(), this.name_read(), publishDate, this.parentId(), this.data(), this.visibility() ? 1 : 0).then(
                res => {
                    this.attr(res.attribute);
                    this.id = res.attribute.id;
                    m.route.set(`/attribute/${res.attribute.id}/edit`);
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        }

        return false;
    }

    submitBanner() {
        const form = document.querySelector("#files");
        const fd = new FormData(form);
        const xhr = new XMLHttpRequest();
        const attrs = this.attr();

        const bannerType = document.getElementById("banner_type");
        const bannerTypeSelected = bannerType.options[bannerType.selectedIndex].value || "banner";

        // Don't add the image to the list of images associated with the
        // attribute.
        fd.set("detached", "true");

        xhr.addEventListener("load", () => {
            const res = JSON.parse(xhr.responseText);
            const img = res.results[0];
            const origId = img.original_id;

            return setAttributeOriginalName(attrs.id, origId, bannerTypeSelected).then(
                () => {
                    attrs.named_imgs.banner = {
                        width: img.width,
                        height: img.height,
                    };
                    this.uploading(false);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        });

        xhr.addEventListener("error", () => {
            this.uploading(false);
        });

        this.progressValue(0);
        this.progressMax(0);
        this.uploading(true);

        xhr.open("POST", `${baseUrl}/attribute/id/${this.id}/files`);
        xhr.withCredentials = true;
        xhr.send(fd);

        return false;
    }

    banner() {
        const attrs = this.attr();

        return attrs && attrs.named_imgs && attrs.named_imgs.banner;
    }

    pendingBanner() {
        const attrs = this.pending();

        return attrs && attrs.named_imgs && attrs.named_imgs.pending;
    }

    bannerMobile() {
        const attrs = this.attr();

        return attrs && attrs.named_imgs && attrs.named_imgs.banner_mobile;
    }
}

const bulkAssigner = {
    id: 0,
    oninit(vnode) {
        this.id = vnode.attrs.attribute.id;
    },
    view() {
        return m("div", [
            m("hr"),
            m("h2", "Bulk Assign Extention"),
            m("div", [
                m("p", "You can assign this extension to all contents that satisfies at least one of following"),
                m("ul", [m("li", "The content has attribute you selected"), m("li", "The parent of the content has attribute you selected")]),
            ]),
            m("div", [
                m("span", "Select Attribute"),
                m(
                    autocomplete,
                    autocomplete.opts.adminAttrs({
                        onclick: target => {
                            this.bulkAssign(target);
                        },
                    }),
                ),
            ]),
            m("h2", "Bulk Remove Extention"),
            m(
                "button",
                {
                    onclick: () => {
                        this.bulkRemove();
                    },
                },
                "REMOVE THIS EXTENTION FROM ALL CONTENTS",
            ),
        ]);
    },

    bulkAssign: function(target) {
        console.log(target);
        postBulkAssignAttribute(this.id, target.id)
            .then(() => {
                alert("Successfully assigned.");
            })
            .catch(err => {
                alert(err);
            });
    },
    bulkRemove: function() {
        deleteBulkRemoveAttribute(this.id)
            .then(() => {
                alert("Successfully removed from all contents.");
            })
            .catch(err => {
                alert(err);
            });
    },
};

const siblingEditor = {
    id: 0,
    siblings: [],
    oninit(vnode) {
        const attribute = vnode.attrs.attribute;

        if (!attribute) {
            return console.error("Attribute sibling editor initialized without attribute");
        }

        this.id = attribute.id;
        this.siblings = attribute.siblings;

        this.autocompleteOpts = autocomplete.opts.adminAttrs({
            onclick: sibling => this.addSibling(sibling),
            filter: sibling => !this.siblings.some(s => s().id === sibling.id),
        });

        return this;
    },
    view() {
        return m("div", [
            m("hr"),
            m("h2", { style: "display: inline-block; padding-right: 1em;" }, "Siblings"),
            m("div.tag", m("span", "published sibling")),
            m("div.tag.unpublished", m("span", "unpublished sibling")),
            m("div", [m("span", "Add Siblings"), m(autocomplete, this.autocompleteOpts)]),
            this.siblings
                ? this.siblings.map(attr => {
                      const elem = m(`div.tag${dayjs(attr().published).isAfter(dayjs()) ? ".unpublished" : ""}`, [
                          m("span", attr().data.name),
                          m(
                              "button",
                              {
                                  onclick: () => {
                                      this.removeSibling(attr());
                                  },
                              },
                              m.trust("&nbsp;"),
                          ),
                      ]);

                      return elem;
                  })
                : "",
        ]);
    },
    addSibling(sibling) {
        postAttributeSibling(this.id, sibling.id)
            .then(res => {
                const temp = [m.prop(res.sibling)];
                this.siblings = temp.concat(this.siblings);
                // this.siblings temp.push(m.prop(res.sibling));
            })
            .catch(err => {
                console.error(err);
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },
    removeSibling(sibling) {
        deleteAttributeSibling(this.id, sibling.id)
            .then(() => {
                this.siblings.splice(this.siblings.findIndex(s => s().id === sibling.id), 1);
            })
            .catch(err => {
                console.error(err);
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },
};

const deleteAttributeButton = {
    oninit(vnode) {
        this.attribute = vnode.attrs.attribute;
        this.forceDeleteOption = m.prop(false);
        this.intendsToDelete = m.prop(this.attribute.parentId() === 0);

        this.buttonPressed = () => {
            if (this.intendsToDelete()) {
                const confirmPrompt = confirm("Are you sure this attribute should be deleted? This cannot be undone.");
                if (confirmPrompt) {
                    this.buttonPressConfirm();
                }
            } else {
                this.intendsToDelete(true);
            }
        };

        this.buttonPressConfirm = () => {
            deleteAttribute(this.attribute.id).then(
                () => {
                    alert("The attribute was deleted successfully");
                    m.route.set("/attribute/list");
                },
                err => {
                    console.error(err);
                },
            );
        };

        this.switchForceDeleteOption = attributeName => {
            this.forceDeleteOption(this.attribute.slug() === attributeName);
        };
    },
    view() {
        if (this.intendsToDelete()) {
            return [
                m("hr"),
                m("h2", "Delete attribute"),
                m(`.${css.danger}`, [m("strong", "Warning: This is a top level attribute. Please enter the attribute slug to confirm.")]),

                m("input[type=text][id=forceDeleteField]", { oninput: m.withAttr("value", this.switchForceDeleteOption), placeholder: "slug" }),
                m("button", { onclick: this.buttonPressed, disabled: !this.forceDeleteOption() }, "Delete"),
            ];
        }

        return [m("hr"), m("h2", "Delete attribute"), m("button", { onclick: this.buttonPressed }, "Delete")];
    },
};

export default {
    oninit(vnode) {
        this.roots = m.prop([]);
        this.tags = m.prop([]);
        this.vm = new Model(vnode.attrs.id);
        this.banner = type => this.image(type, this.vm.banner());
        this.pendingBanner = type => this.image(type, this.vm.pendingBanner());
        this.image = (type, ident) => {
            const img = type === "banner_mobile" ? this.vm.bannerMobile() : ident;

            if (img && img.filename) {
                img.url = "https://";
                if (config.baseUrl === "https://staging.api.komiflo.com") {
                    img.url += "staging.";
                }

                img.url += "cdn.komiflo.com/resized/1250_desktop_large/" + img.filename;
            }

            if (!img) {
                return "";
            } else if (img.url) {
                return m(`.${css.full}`, [
                    m("div", "Mobile"),
                    m(`.${css.after}`),
                    m("img", {
                        src: img.url,
                    }),
                ]);
            }

            return m(
                `div.${css.placeholder}`,
                {
                    style: {
                        width: `${img.width - 2}px`,
                        height: `${img.height - 2}px`,
                        lineHeight: `${img.height - 2}px`,
                    },
                },
                `${img.width}x${img.height}`,
            );
        };

        if (vnode.attrs.parentId) {
            this.vm.createdFromParent(Boolean(vnode.attrs.parentId));
            this.vm.parentId(Number(vnode.attrs.parentId));
        }

        if (!vnode.attrs.id) {
            // Populate this.siblings with empty sibling ids
            let i = 0;
            while (i < 3) {
                this.vm.siblings.push(m.prop([]));
                i += 1;
            }
        }

        // Get a list of tags for siblings list
        getAttributeChildren(config.tags_attribute_id).then(
            res => {
                this.tags(res.children);
                m.redraw();
            },
            err => {
                console.error(err);
            },
        );

        // Get a list of top level attributes for parent
        getAttributeRoots().then(
            res => {
                this.roots(res.results);
                m.redraw();
            },
            err => {
                console.error(err);
            },
        );
    },

    onupdate(vnode) {
        this.vm.onupdate(vnode.attrs.id);
    },

    view() {
        if (this.vm.loading()) {
            return m(".loading", [m(".load"), m("p", "Loading...")]);
        }

        const parentOption = root => {
            const value = (root && root.id) || 0;

            return {
                value,
                selected: value === this.vm.parentId() ? "selected" : "",
            };
        };
        const siblingOption = (tag, position) => {
            const selectedID = this.vm.siblings.length > position ? this.vm.siblings[position]().id : null;
            const value = (tag && tag.id) || 0;

            return {
                value,
                selected: value === selectedID ? "selected" : "",
            };
        };

        const parentShortNames = [];
        let i = 0;

        this.roots().forEach(root => {
            if (root.id === this.vm.attr().id) {
                Reflect.deleteProperty(this.roots(), Object.keys(this.roots())[i]);
            } else {
                parentShortNames[root.id] = root.slug;
            }
            i += 1;
        });

        // For assigning a count to siblings
        let siblingCounter = 0;

        // If the parent navigated to from a query param is not a top level parent, include it in the array of top level parents
        if (this.vm.parentId()) {
            const foundParent = this.roots().some(r => r.id === this.vm.parentId());
            if (foundParent === false) {
                // Not in the array. Add it. (We don't need the specifics, so don't bother with another API request)
                const roots = this.roots();
                roots.unshift({
                    id: this.vm.parentId(),
                    data: {},
                    slug: "{tag/" + this.vm.parentId() + "}",
                });
                this.roots(roots);
            }
        }

        return [
            m("form", { onsubmit: this.vm.save.bind(this.vm) }, [
                m("h1", "Edit Attribute"),
                m("div", [
                    m("label[for=name]", "Name"),
                    m("input[name=name]", {
                        onchange: m.withAttr("value", this.vm.name),
                        value: this.vm.name(),
                    }),
                ]),
                m("div", [
                    m("label[for=published]", "Publish date"),
                    m("input[type=text][name=published][autocomplete=off]", {
                        onchange: m.withAttr("value", this.vm.published),
                        value: this.vm.published(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom,
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                    " ",
                    ...timeSelector(this.vm.publishedTime, this.vm.publishedTime()),
                ]),
                m("div", [
                    m("label[for=parent]", "Parent"),
                    m(
                        "select[name=parent]",
                        {
                            onchange: e => {
                                e.preventDefault();
                                this.vm.parentId(Number(e.currentTarget.value));
                            },
                            value: this.vm.parentId(),
                        },
                        [m("option", parentOption(), "None")].concat(this.roots().map(root => m("option", parentOption(root), rootString(root)))),
                    ),
                    this.vm.parentId() ? [m.trust("&nbsp;"), m("a", { href: `/attribute/${this.vm.parentId()}/view`, oncreate: m.route.link }, "View")] : null,
                ]),
                m("div", [
                    m("label[for=slug]", "Slug"),
                    m("input[name=slug]", {
                        onkeyup: m.withAttr("value", this.vm.slug),
                        value: this.vm.slug(),
                    }),
                ]),
                m("div", { style: { display: this.vm.slug() ? "block" : "none" } }, [
                    m("label"),
                    m("span", "https://komiflo.com/" + (parentShortNames[this.vm.parentId()] || "attributes") + "/" + this.vm.slug()),
                ]),
                this.vm.parentId() && (this.vm.parentId() === config.tags_attribute_id || this.vm.parentId() === config.artists_attribute_id)
                    ? m("div", [
                          m("label[for=name_read]", "Hiragana name"),
                          m("input[name=name_read]", {
                              onkeyup: m.withAttr("value", this.vm.name_read),
                              value: this.vm.name_read(),
                          }),
                      ])
                    : null,
                this.vm.parentId() && (this.vm.parentId() === config.tags_attribute_id || this.vm.parentId() === config.artists_attribute_id || this.vm.parentId() === config.publisher_attribute_id)
                    ? m("div", [
                          m("label[for=name_en]", "English name"),
                          m("input[name=name_en]", {
                              onkeyup: m.withAttr("value", this.vm.name_en),
                              value: this.vm.name_en(),
                          }),
                      ])
                    : null,
                this.vm.parentId() && (this.vm.parentId() === config.banners_attribute_id || this.vm.parentId() === config.banners_plus_attribute_id)
                    ? m("div", [
                          m("label[for=href]", "Link"),
                          m("input[name=href]", {
                              onkeyup: m.withAttr("value", this.vm.href),
                              value: this.vm.href(),
                          }),
                      ])
                    : null,
                this.vm.parentId() === config.extension_id
                    ? m("div", [
                          m("label[for=duration]", "Duration"),
                          m("input[name=duration]", {
                              placeholder: "1 year 2 month 3 days ...",
                              onkeyup: e => {
                                  this.vm.duration = e.target.value;
                              },
                              value: this.vm.duration,
                          }),
                      ])
                    : "",
                this.vm.parentId() === config.points_id
                    ? m("div", [
                          m("label[for=percentage]", "Percentage"),
                          m("input[name=percentage]", {
                              placeholder: "1",
                              onchange: m.withAttr("value", this.vm.percentage),
                              value: this.vm.percentage(),
                          }),
                      ])
                    : "",
                m("div", [
                    m("label[for=ipRestrict]", "IP restrict"),
                    m("input[name=ipRestrict]", {
                        onkeyup: m.withAttr("value", this.vm.ipRestrict),
                        value: this.vm.ipRestrict(),
                    }),
                ]),
                m("div", [m("label"), m.trust("Separate multiple IP addresses with semicolons; wrap all IP addresses in 'apostrophes'.")]),
                // Tag siblings only listed for artists (for now)
                // Only if editing for now (due to API restraints)
                !this.vm.attr().id || this.vm.parentId() !== config.artists_attribute_id
                    ? ""
                    : this.vm.siblings.map(() => {
                          const elem = m("div", [
                              m("label", (this.vm.parentId() === config.artists_attribute_id ? "Tag" : "Sibling") + " " + (siblingCounter + 1)),
                              m(
                                  "select",
                                  {
                                      onchange: m.withAttr("value", this.vm.siblings[siblingCounter]),
                                      value: this.vm.siblings[siblingCounter]().id,
                                  },
                                  [m("option", siblingOption(), "")].concat(
                                      this.tags().map(tag => m("option", siblingOption(tag, siblingCounter), rootString(tag))),
                                  ),
                              ),
                          ]);

                          siblingCounter += 1;

                          return elem;
                      }),
                this.vm.parentId() &&
                (this.vm.parentId() === config.tags_attribute_id ||
                    this.vm.parentId() === config.artists_attribute_id ||
                    this.vm.parentId() === config.hidden_tags_attribute_id)
                    ? m("div", [
                          m("label[for=searchTerms]", "Search terms"),
                          m("textarea[name=searchTerms]", {
                              onchange: m.withAttr("value", this.vm.searchTerms),
                              value: this.vm.searchTerms(),
                          }),
                      ])
                    : null,
                this.vm.parentId() && (this.vm.parentId() === config.tags_attribute_id || this.vm.parentId() === config.artists_attribute_id)
                    ? m("div", [
                          m("label[for=tankoubonLabel]", "Affiliate label"),
                          m(
                              "select[name=tankoubonLabel]",
                              {
                                  onchange: m.withAttr("value", this.vm.tankoubonLabel),
                                  value: this.vm.tankoubonLabel(),
                              },
                              [
                                  m(
                                      "option",
                                      {
                                          value: "",
                                          selected: this.vm.tankoubonLabel() == "" ? "selected" : undefined,
                                      },
                                      "",
                                  ),
                                  m(
                                      "option",
                                      {
                                          value: "affiliate",
                                          selected: this.vm.tankoubonLabel() == "affiliate" ? "selected" : undefined,
                                      },
                                      "単行本",
                                  ),
                                  m(
                                      "option",
                                      {
                                          value: "artbook",
                                          selected: this.vm.tankoubonLabel() == "artbook" ? "selected" : undefined,
                                      },
                                      "画集",
                                  ),
                                  m(
                                      "option",
                                      {
                                          value: "collaboration",
                                          selected: this.vm.tankoubonLabel() == "collaboration" ? "selected" : undefined,
                                      },
                                      "コラボ",
                                  ),
                              ],
                          ),
                      ])
                    : null,
                this.vm.parentId() === config.artists_attribute_id
                    ? [
                          m("div", [
                              m("label[for=visibility]", "Visibility"),
                              m("input[name=visibility][type=checkbox]", {
                                  checked: this.vm.visibility() === true ? "checked" : "",
                                  onchange: m.withAttr("checked", this.vm.visibility),
                              }),
                              m("label[for=visibility]", "Show in search results"),
                          ]),
                          m("div", [
                              m("label[for=naughtyBanner]", "Naughty banner"),
                              m("input[name=naughtyBanner][type=checkbox]", {
                                  checked: this.vm.naughtyBanner() === true ? "checked" : "",
                                  onchange: m.withAttr("checked", this.vm.naughtyBanner),
                              }),
                              m("label[for=naughtyBanner]", "Hide on unauthenticated pages"),
                          ]),
                      ]
                    : null,
                m("div", [m("button[type=submit]", this.vm.attr().id ? "Save" : "Create")]),
                this.vm.attr().id
                    ? [
                          m("h2", "Banner", [
                              m(
                                  "form#files",
                                  {
                                      method: "POST",
                                      enctype: "multipart/form-data",
                                      onsubmit: this.vm.submitBanner.bind(this.vm),
                                  },
                                  [
                                      m("input[type=file][name=files][multiple=multiple]"),
                                      m("select[id=banner_type]", [
                                          m("option", { value: "banner" }, "Desktop"),
                                          m("option", { value: "banner_mobile" }, "Mobile"),
                                      ]),
                                      this.vm.uploading()
                                          ? [
                                                m("span", "Uploading..."),
                                                m("progress", {
                                                    value: this.vm.progressValue(),
                                                    max: this.vm.progressMax(),
                                                }),
                                            ]
                                          : m("button[type=submit]", "Upload"),
                                  ],
                              ),
                              this.banner(),
                          ]),
                      ]
                    : "",
                this.vm.pending().id
                    ? [
                          m("h2", "Pending Banner", [
                              this.pendingBanner(),
                              m(
                                  "button",
                                  {
                                      onclick: () => {
                                          postApproveBanner(this.vm.attr().id).then(
                                              () => {
                                                  this.vm.banner(this.vm.pending());
                                                  this.vm.pending("");
                                              },
                                              err => {
                                                  console.error(err);
                                                  alert(JSON.stringify(err));
                                              },
                                          );
                                      },
                                  },
                                  "Approve",
                              ),
                          ]),
                      ]
                    : "",
            ]),
            this.vm.attr().id && this.vm.parentId() === config.extension_id ? m(bulkAssigner, { attribute: this.vm }) : "",
            !this.vm.attr().id || this.vm.parentId() === config.artists_attribute_id
                ? ""
                : [
                      m(siblingEditor, {
                          attribute: this.vm,
                      }),
                      m(deleteAttributeButton, {
                          attribute: this.vm,
                      }),
                  ],
        ];
    },
};
