"use strict";

import m from "mithril";
import { logout } from "../../api-client";
import css from "./index.css";

export default {
    oninit : function(vnode) {
        this.logout = (e) => {
            e.preventDefault();
            logout().then(() => {
                m.route.set("/login")
                location.reload();
            });
        };
        //this.error = JSON.stringify(vnode.attrs);
    },
    view : function() {
        return m(`.${css.unadmin}`, [
            m("h1", "Logged in user is not an admin"),
            //m(`.${css.error}`, {}, this.error),
            m(`button`, { onclick: this.logout }, "Logout")
        ]);
    },
};
