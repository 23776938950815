"use strict";

import { getContentSearch } from "../../api-client";
import css from "./type.css";
import link from "../../utils/link";
import m from "mithril";
import pagination from "../../components/pagination";
import config from "../../config";

class Model {
    constructor(opts = {}) {
        this.type = opts.type;

        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getContentSearch(this.page(), this.perPage(), null, null, this.type, null, null, null, null).then(
            res => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    }
}

export default {
    // $navTitle: "Type",
    $component: {
        oninit(vnode) {
            this.content = m.prop([]);
            this.vm = new Model();
            this.vm.type = vnode.attrs.type;
            this.vm.load(0);
        },
        onbeforeupdate(vnode, old) {
            if (vnode.attrs.type != old.attrs.type) {
                this.content = m.prop([]);
                this.vm = new Model();
                this.vm.type = vnode.attrs.type;
                this.vm.load(0);
            }
        },
        view() {
            const convertTypeName = typeName => {
                switch (typeName) {
                    case "volume":
                        return "Magazines";
                    case "tankoubon":
                        return "Tankoubon";
                    case "physical":
                        return "Physical";
                    case "asset":
                        return "Special content assets";
                    case "article":
                        return "Special content assets";
                    case "status":
                        return "Special contents";
                    case "faq":
                        return "Special content assets";
                    case "volume_affiliate":
                        return "Affiliates";
                    case "Chapter":
                        return "Chapters";
                    case "free_trial":
                        return "Free trial";
                    default:
                        return typeName.charAt(0).toUpperCase() + typeName.slice(1) + "s";
                }
            };

            const typeHeading = m("h1", convertTypeName(this.vm.type));

            if (this.vm.loading()) {
                return [typeHeading, m(".loading", [m(".load"), m("p", "Retrieving content..."), m("p", "Please wait.")])];
            }

            const dateFormat = function(date) {
                return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
            };

            return m("div", [
                typeHeading,
                m(["volume", "tankoubon", "physical"].indexOf(this.vm.type) != -1 ? `.${css.volumes}` : "table", [
                    ["volume", "tankoubon", "physical"].indexOf(this.vm.type) == -1
                        ? m("thead", [
                              m("th", "Title"),
                              m("th", "Published"),
                              this.vm.type == "chapter" ? [m("th", "Artist"), m("th", "# tags")] : null,
                              m("th", "# likes"),
                              m("th", "# comments"),
                              (/chapter|article/).test(this.vm.type) ? m("th", "") : null,
                          ])
                        : null,
                    this.vm.elements().map(c => {
                        if (["volume", "tankoubon", "physical"].indexOf(this.vm.type) != -1) {
                            let img = m(`.${css.img_placeholder}`);

                            if (c.children.length > 0 && c.children[0].named_imgs && c.children[0].named_imgs.cover) {
                                const child = c.children[0];
                                const cover = child.named_imgs.cover;
                                img = m("img", {
                                    src: child.cdn_public + "/320_mobile_narrow_2x/" + cover.filename,
                                    width: 160,
                                    height: 226,
                                });
                            }

                            const expired = c.expiry;

                            return m(
                                `a.${css.volume}`,
                                {
                                    href: `/content/${c.id}/edit`,
                                    oncreate: m.route.link,
                                },
                                [img, m("p", c.data.title)],
                            );
                        }
                        const published = new Date(c.published);

                        return m("tr", [
                            m("td", [
                                link(
                                    `/content/${c.id}/edit`,
                                    `${c.id}: ` +
                                        (c.type == "announcement"
                                            ? c.body.substring(0, 14) + (c.body.length > 14 ? "..." : "")
                                            : c.data.title
                                            ? c.data.title
                                            : c.slug
                                            ? c.slug
                                            : "(Untitled)"),
                                ),
                            ]),
                            m("td", (/chapter|article/).test(this.vm.type) ? dateFormat(published) : null),
                            this.vm.type == "chapter"
                                ? m(
                                      "td",
                                      c.attributes && c.attributes.artists
                                          ? link("/attribute/" + c.attributes.artists.children[0].id + "/view", c.attributes.artists.children[0].data.name)
                                          : "-",
                                  )
                                : null,
                            this.vm.type == "chapter" ? m("td", c.attributes && c.attributes.tags ? c.attributes.tags.children.length : "-") : null,
                            m("td", c.likes_count > 0 ? c.likes_count : "-"),
                            m("td", c.comments_count > 0 ? c.comments_count : "-"),
                            (/chapter|article/).test(this.vm.type)
                                ? m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href:
                                                  `https://${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/` +
                                                  (this.vm.type == "chapter" ? "comics/" : "articles/") +
                                                  (c.slug || c.id),
                                              target: "_blank",
                                          },
                                          "➡",
                                      ),
                                  )
                                : "",
                        ]);
                    }),
                    m(`.${css.clear}`),
                ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
