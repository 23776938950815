"use strict";

import m from "mithril";
import { getMissionList } from "../../api-client";
import link from "../../utils/link";
import { dateFormat } from "../../utils/helper";

const model = {
    loading: false,
    missions: [],
};

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            getMissionList()
                .then(res => {
                    model.missions = res.missions;
                })
                .catch(err => {
                    alert(JSON.stringify(err));
                })
                .finally(() => {
                    model.loading = false;
                    m.redraw();
                });
        },

        view() {
            return m("div", [
                m("h1", "Missions"),
                model.loading
                    ? m(".loading", [m(".load"), m("p", "Loading...")])
                    : m("table", [
                          m("thead", [m("th", "ID"), m("th", "Title"), m("th", "Start Date"), m("th", "Deadline Date")]),
                          model.missions.map(mission =>
                              m("tr", [
                                  m("td", link(`/mission/${mission.id}/edit`, {}, mission.id)),
                                  m("td", mission.name),
                                  m("td", dateFormat(mission.start_date)),
                                  m("td", dateFormat(mission.deadline_date)),
                              ]),
                          ),
                      ]),
            ]);
        },
    },
};
