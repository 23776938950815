"use strict";

import m from "mithril";
import {dateFormat} from "../utils/helper";
import css from "./user.css";

// Component for rendering out a user in a tabular form or something.
export default {
    oninit(vnode) {
        this.user = vnode.attrs.user;
    },

    onupdate(vnode) {
        this.user = vnode.attrs.user;
    },

    view() {
        let user = this.user();
        let $field = `div.${css.field}`;
        let $heading = `span.${css.heading}`;
        let $value = `span.${css.value}`;

        if (!user) {
            return null;
        }

        return m(`div.${css.table}`, [
            m($field, [
                m($heading, "ID"),
                m($value, user.id),
            ]),
            m($field, [
                m($heading, "Created"),
                m($value, dateFormat(user.created)),
            ]),
            m($field, [
                m($heading, "Email"),
                m($value, user.email),
            ]),
            !user.password ? "" : m($field, [
                m($heading, "Password"),
                m($value, user.password),
            ]),
            m($field, [
                m($heading, "Notifications"),
                m($value, user.notifications),
            ]),
            !user.deleted ? "" : m($field, [
                m($heading, "Deleted"),
                m($value, user.deleted)
            ]),
            m('div', {style: ' margin: 15px 0;'}),
            !user.data.register_url ? "" : ($field, [
                m($heading, "Access URL at registration"),
                m($value, user.data.register_url),
            ]),
            !user.data.register_source_link_url ? "" : ($field, [
                m($heading, "Link source at registration"),
                m($value, user.data.register_url),
            ]),
        ]);
    },
};
