"use strict";

import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import {
    postEmailSchedulePrepare,
    getEmailTemplates,
    postEmailSchedule,
} from "../../api-client";
import {timeSelector} from "../../utils/input-component";

class Model {
    constructor(id) {
        this.work = m.prop({});

        this.fields = {
            users_include:   {
                value:  m.prop(""),
                type:   "textarea",
                title:  "group of users",
                output: ["integer", "array"],
                show:   m.prop(false),
            },
            email_addresses:   {
                value:  m.prop(""),
                type:   "textarea",
                output: ["array"],
                show:   m.prop(false),
            },
            can_view_contents:   {
                value:  m.prop(""),
                type:   "select",
                title:  "access",
                options: m.prop([
                    {
                        value: "",
                        label: "either",
                    },
                    {
                        value: "yes",
                        label: "can view contents",
                    },
                    {
                        value: "no",
                        label: "can not view contents",
                    },
                ]),
            },
            subscription_provider: {
                value: m.prop(""),
                type: "select",
                title: "available payment methods",
                options: m.prop([
                    {
                        value: "",
                        label: "any",
                    },
                    {
                        value: "anotherlane",
                        label: "AnotherLane (Visa/Mastercard)",
                    },
                    {
                        value: "anotherlane_exclusive",
                        label: "AnotherLane (V/M) exclusive (never added an alternative PM)",
                    },
                    {
                        value: "sbps",
                        label: "SBPS (JCB/AmEx/Diners)",
                    },
                    {
                        value: "sbps_exclusive",
                        label: "SBPS (J/A/D) exclusive (never added an alternative PM)",
                    },
                    {
                        value: "bitcash",
                        label: "bitcash",
                    },
                    {
                        value: "internal",
                        label: "prepaid card/access code",
                    },
                    {
                        value: "never",
                        label: "never added a payment method",
                    },
                ]),
            },
            with_user_attr:   {
                value:  m.prop(""),
                type:   "select",
                title:  "artists",
                options: m.prop([
                    {
                        value: "",
                        label: "include",
                    },
                    {
                        value: "no",
                        label: "exclude",
                    },
                    {
                        value: "yes",
                        label: "only",
                    },
                ]),
            },
            total_receipts_min:   {
                value:  m.prop(""),
                type:   "text",
                title: "minimum charges",
            },
            total_receipts_max:   {
                value:  m.prop(""),
                type:   "text",
                title: "maximum charges",
            },
            user_created:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created on",
            },
            user_created_before:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created before",
            },
            user_created_beyond:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created on or after",
            },
            user_cancelled_before:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user cancelled, and subscription finished before",
            },
            user_cancelled_beyond:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user cancelled, and subscription finished on or after",
            },
            cancel_reasons: {
                value: m.prop(""),
                type: "text",
                output: ["string", "array"],
            },
            obey_opt_out:   {
                value:  m.prop(false),
                type:   "checkbox",
                title:  "respect email preferences",
            },
            email_id:   {
                value:      m.prop(0),
                type:       "select",
                title:      "template",
                options:    m.prop([]),
                output:     ["integer"],
            },
            exclude_previous_recipients:   {
                value:  m.prop(false),
                type:   "checkbox",
                title:  "not received this email",
            },
            users_exclude:   {
                value:  m.prop(""),
                type:   "textarea",
                title:  "exclude users",
                output: ["integer", "array"],
            },
        };

        this.out = {
            users:  m.prop(""),
            emails: m.prop(""),
        }

        this.updateTemplates(this.fields.email_id.options);

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.schedule = {
            date:       m.prop(tomorrow),
            time:       m.prop(16),
            loading:    m.prop(false),
        }

        this.which_users = m.prop('all');
    }

    save() {
        let data = {};

        const fields = Object.keys(this.fields);

        for (let i = 0; i < fields.length; ++i) {
            const thisField = this.fields[fields[i]];
            data[fields[i]] = thisField.value();

            if (typeof thisField.output !== "undefined") {
                thisField.output.sort();

                for (let j = 0; j < thisField.output.length; ++j) {
                    if (thisField.output[j] === "integer") {
                        if (typeof data[fields[i]] === "object") {
                            for (let k = 0; k < data[fields[i]].length; ++k) {
                                data[fields[i]][k] = parseInt(data[fields[i]][k]);
                            }
                        } else {
                            data[fields[i]] = parseInt(data[fields[i]]);
                        }
                    } else if (thisField.output[j] === "array") {
                        if (data[fields[i]]) {
                            data[fields[i]] = data[fields[i]].replace(/\s/g, "").split(",");
                        } else {
                            data[fields[i]] = [];
                        }
                    }
                }
            }

            if (thisField.type === "date") {
                data[fields[i]] = data[fields[i]] ? new Date(data[fields[i]]).toJSON().split("T")[0] : "";
            }
        }

        // reset lists of users and emails
        this.out["users"]("");
        this.out["emails"]("");

        postEmailSchedulePrepare(data).then(
            (res) => {
                this.out["users"]("");
                this.out["emails"]("");
                if (this.which_users() !== "emails") {
                    if (!res.users || res.users.length < 1) {
                        alert("Could not find any users with those conditions.")
                    } else {
                        for (let i = 0; i < res.users.length; ++i) {
                            if (this.out["users"]() != "") {
                                this.out["users"](this.out["users"]() + ",")
                            }

                            this.out["users"](this.out["users"]() + res.users[i])
                        }
                    }
                }
                this.out["emails"](res.email_addresses ? res.email_addresses.join(",") : []);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );

        return false;
    }

    scheduleEmailsSubmit() {
        this.schedule.loading(true);

        let data = {
            users: this.out.users().length < 1 ? [] : this.out.users().split(","),
            email_addresses: this.out.emails().length < 1 ? [] : this.out.emails().split(","),
            schedule_date: this.schedule.date() ? new Date(this.schedule.date() + " " + this.schedule.time() + ":00").toJSON() : "",
            email_id: parseInt(this.fields.email_id.value()),
        }

        for (let i = 0; i < data.users.length; ++i) {
            data.users[i] = parseInt(data.users[i]);

            if (isNaN(parseFloat(data.users[i])) || !isFinite(data.users[i])) {
                data.users.splice(i, 1);
                --i;
            }
        }

        if (data.email_addresses.length === 1 && data.email_addresses[0].length < 1) {
            data.email_addresses = [];
        }

        postEmailSchedule(data).then(
            (res) => {
                this.schedule.loading(false);
                setTimeout(_ => {
                    alert(res['records_inserted'] + " emails scheduled successfully");
                    m.route.set("/email/schedules");
                }, 10);
            },
            (err) => {
                this.schedule.loading(false);
                setTimeout(_ => {
                    alert(JSON.stringify(err));
                }, 10);
                console.error(err);
                m.redraw();
            }
        )
    }

    updateTemplates(p) {
        getEmailTemplates(0, 1000).then(
            (res) => {
                for (let i = 0; i < res.results.length; ++i) {
                    const r = res.results[i];

                    p().push({
                        value: r.Id,
                        label: `${r.Id}: ${r.Subject}`,
                    })
                }
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    setUsers(which) {
        this.fields.users_include.value("");
        this.fields.email_addresses.value("");
        this.which_users(which);
        this.fields.users_include.show(which == "group");
        this.fields.email_addresses.show(which == "emails");
        if (which == "emails") {
            this.fields.users_include.value("1");
        }
        m.redraw();
    }
};

export default {
    oninit(vnode) {
        this.vm = new Model();
        this.vm = new Model(vnode.attrs.id);
    },

    view() {
        return !this.vm.work()
            ? null
            : this.vm.schedule.loading()
                ? [
                    m(".loading", [
                        m(".load"),
                        m("p", "Creating schedules..."),
                        m("p", "If you have selected many users, this may take a while.")
                    ])
                ]
                : [
                    m("form", { onsubmit : this.vm.save.bind(this.vm) }, [
                        m("h1", "Prepare email schedule"),
                        m("table", [
                            m("tr", [
                                m("th", "Conditions"),
                                m("th"),
                            ]),
                            m("tr", [
                                m("td", "selection pool"),
                                m("td", [
                                    m("p", [
                                        m("label", ""),
                                        m("input[type=radio][name=which_users][id=which_users_1]", {
                                            checked: this.vm.which_users() === "all",
                                            onclick: _ => { this.vm.setUsers("all") },
                                        }),
                                        m("label[for=which_users_1]", "all users"),
                                    ]),
                                    m("p", [
                                        m("label", ""),
                                        m("input[type=radio][name=which_users][id=which_users_2]", {
                                            checked: this.vm.which_users() === "group",
                                            onclick: _ => { this.vm.setUsers("group") },
                                        }),
                                        m("label[for=which_users_2]", "group of users"),
                                    ]),
                                    m("p", [
                                        m("label", ""),
                                        m("input[type=radio][name=which_users][id=which_users_3]", {
                                            checked: this.vm.which_users() === "emails",
                                            onclick: _ => { this.vm.setUsers("emails") },
                                        }),
                                        m("label[for=which_users_3]", "email addresses only"),
                                    ]),
                                ])
                            ]),
                            Object.keys(this.vm.fields).map(k => {
                                const field = this.vm.fields[k];
                                const label = m(`label[for=${k}]`, field.title || k.replace("_", " "));

                                if (typeof field.type == "undefined" || !field.type) return;

                                let input;

                                if (typeof field.show != 'undefined' && field.show() == false) return;

                                if (this.vm.which_users() === "emails" && k !== "email_addresses") return;

                                if (field.type === "text" || field.type === "date") {
                                    input = m("input[name=name]", {
                                        onchange: m.withAttr("value", field.value),
                                        value: field.value(),
                                        oncreate: (vnode) => {
                                            if (field.type == "date") {
                                                this.pikaday = new Pikaday({
                                                    field: vnode.dom
                                                });
                                            }
                                        },
                                        onremove: () => {
                                            if (field.type == "date") {
                                                this.pikaday.destroy();
                                            }
                                        },
                                    });
                                } else if (field.type === "textarea") {
                                    input = m("textarea[name=name]", {
                                        onkeyup: m.withAttr("value", field.value),
                                        value: field.value(),
                                    });
                                } else if (field.type === "checkbox") {
                                    input = m(`input[name=${k}][type=checkbox]`, {
                                        checked: field.value() == true ? "checked" : "",
                                        onchange: m.withAttr("checked", field.value),
                                    });
                                } else if (field.type === "select") {
                                    if (!field.value() && field.options().length > 0) {
                                        field.value(field.options()[0].value);
                                    }

                                    input = m(`select`,
                                    {
                                        onchange: m.withAttr("value", field.value),
                                        value: field.value(),
                                    },
                                    field.options().map(o => {
                                        return m(`option`, { value: o.value, selected: (o.value == field.value()) }, typeof o.label === "undefined" ? o.value : o.label)
                                    }));
                                }

                                if (!input) return;

                                return m("tr", [
                                    m("td", label),
                                    m("td", input),
                                ]);
                            }),
                            m("tr", [
                                m("td"),
                                m("td", [
                                    m("button[type=submit]", "Generate list"),
                                ])
                            ])
                        ]),
                    ]),
                    this.vm.out.users() != "" || this.vm.out.emails() != "" ?
                        [
                            m("hr"),
                            m("form", { onsubmit : this.vm.scheduleEmailsSubmit.bind(this.vm) }, [
                                m("h2", "Create schedules"),
                                m("table", [
                                    m("tr", [
                                        m("td", "user IDs"),
                                        m("td", m("textarea", {
                                            onkeyup: m.withAttr("value", this.vm.out.users),
                                            value: this.vm.out.users(),
                                        })),
                                    ]),
                                    m("tr", [
                                        m("td", "email addresses"),
                                        m("td", m("textarea", {
                                            onkeyup: m.withAttr("value", this.vm.out.emails),
                                            value: this.vm.out.emails(),
                                        })),
                                    ]),
                                    m("tr", [
                                        m("td", "date"),
                                        m("td", m("input[type=text]", {
                                            onchange: m.withAttr("value", this.vm.schedule.date),
                                            value: this.vm.schedule.date(),
                                            oncreate: function(vnode) {
                                                this.pikaday = new Pikaday({
                                                    field: vnode.dom
                                                });
                                            },
                                            onremove: function() {
                                                this.pikaday.destroy();
                                            },
                                        })),
                                    ]),
                                    m("tr", [
                                        m("td", "time"),
                                        m("td", ...timeSelector(this.vm.schedule.time, this.vm.schedule.time())),
                                    ]),
                                    m("tr", [
                                        m("td"),
                                        m("td", m("input[type=submit]", "Schedule emails")),
                                    ]),
                                ]),
                            ]),
                        ]
                        : null
                    ];
    }
};
