import m from "mithril";
import { getCoupon, getEmailTemplates, getAttribute, getPointMutiple, getMission, createMission, putMission, deleteMission } from "../../api-client";
import { dateFormatRFC3339 } from "../../utils/helper";
import { parseIntForInput } from "../../utils/type-cast";
import { timeSelector } from "../../utils/input-component";
import link from "../../utils/link";
import autocomplete from "../../components/autocomplete";
import dayjs from "dayjs";

const invalid = "couldn't find";

let dayPicker = {};

const isValidNum = (num) => !isNaN(num) && num > 0;

export const model = {
    loading: false,
    mission: {},
    emailTemplates: [],
    start: {
        day: "",
        time: "0:00",
    },
    deadline: {
        day: "",
        time: "0:00",
    },
    modalExpiry: {
        day: "",
        time: "0:00",
    },
    linkages: [],
    newLinkage: "",
    init: function () {
        this.loading = false;
        this.mission = {
            name: "",
            detail: { text: "", link: "" },
            data: {
                overview: "",
                color: "",
                color_dark: "",
                color_overlay: "",
            },
            secret: false,
            repeat: 1,
            requirements: [[]],
            rewards: {
                coupons: [],
                extensions: [],
                point_multiples: [],
                access_codes: [],
                email: null,
                komipoints: 0,
                storecredits: 0,
            },
            linkages: [],
        };
        this.emailTemplates = [];
        this.start = { day: "", time: "0:00" };
        this.deadline = { day: "", time: "0:00" };
        this.modalExpiry = { day: "", time: "0:00" };
        this.linkages = [];
        this.newLinkage = "";
        this.getTemplates();
    },
    get: async function (id) {
        this.loading = true;

        await this.getTemplates();

        getMission(id)
            .then((res) => {
                this.mission = Object.assign(
                    {
                        data: {
                            overview: "",
                            color: "",
                            color_dark: "",
                            color_overlay: "",
                        },
                    },
                    res.mission,
                );
                const start = dayjs(res.mission.start_date);
                if (start.isValid()) {
                    this.start = {
                        day: start.format("YYYY-MM-DD"),
                        time: start.format("H:00"),
                    };
                }
                const deadline = dayjs(res.mission.deadline_date);
                if (deadline.isValid()) {
                    this.deadline = {
                        day: deadline.format("YYYY-MM-DD"),
                        time: deadline.format("H:00"),
                    };
                }
                const modalExpiry = dayjs(res.mission.modal_expiry);
                if (modalExpiry.isValid()) {
                    this.modalExpiry = {
                        day: modalExpiry.format("YYYY-MM-DD"),
                        time: modalExpiry.format("H:00"),
                    };
                }
                this.mission.rewards.coupons = (this.mission.rewards.coupons || []).map((c) => ({ id: c }));
                this.mission.rewards.extensions = (this.mission.rewards.extensions || []).map((e) => ({
                    id: e,
                    slug: null,
                }));
                this.mission.rewards.point_multiples = (this.mission.rewards.point_multiples || []).map((p) => ({ id: p }));
                this.mission.rewards.access_codes = this.mission.rewards.access_codes || [];
                this.linkages = (this.mission.linkages || []).map((l) => ({
                    id: l,
                    name: null,
                }));
            })
            .catch((err) => {
                console.error(err);
                alert(JSON.stringify(err, null, 2));
            })
            .finally(() => {
                this.loading = false;
                m.redraw();
            });
    },
    getTemplates() {
        return getEmailTemplates(0, 100)
            .then((res) => {
                this.emailTemplates = res.results.map((r) => ({
                    value: r.Id,
                    label: `${r.Id}: ${r.Subject}`,
                }));
            })
            .finally(() => {
                m.redraw();
            });
    },
    create: function () {
        const missionToSend = this.assemble();

        createMission(missionToSend)
            .then((res) => {
                alert("Successfully created a mission");
                m.route.set(`/mission/${res.id}/edit`);
            })
            .catch((err) => {
                console.error(err);
                alert(JSON.stringify(err, null, 2));
            });
    },
    save: function () {
        const missionToSend = this.assemble();

        putMission(missionToSend.id, missionToSend)
            .then(() => {
                this.get(missionToSend.id);
                alert("Updated successfully");
            })
            .catch((err) => {
                console.error(err);
                alert(JSON.stringify(err, null, 2));
            })
            .finally(() => {
                m.redraw();
            });
    },
    delete: function () {
        if (confirm("Are you sure you want to delete this mission?\nAll user progress for this mission will be permanently destroyed.")) {
            deleteMission(this.mission.id)
                .then(() => {
                    alert("Successfully deleted this coupon");
                    m.route.set("/mission/list");
                })
                .catch((err) => {
                    console.error(err);
                    alert(JSON.stringify(err, null, 2));
                });
        }
    },
    assemble: function () {
        const mission = Object.assign({}, this.mission);

        mission.start_date = dateFormatRFC3339(this.start.day, this.start.time);
        mission.deadline_date = dateFormatRFC3339(this.deadline.day, this.deadline.time);
        mission.modal_expiry = dateFormatRFC3339(this.modalExpiry.day, this.modalExpiry.time);

        mission.rewards.coupons = mission.rewards.coupons.filter((c) => isValidNum(c.id)).map((c) => c.id);
        if (!mission.rewards.coupons.length === 0) {
            Reflect.deleteProperty(mission.rewards, "coupons");
        }
        mission.rewards.extensions = mission.rewards.extensions.filter((e) => isValidNum(e.id)).map((e) => e.id);
        if (!mission.rewards.extensions.length === 0) {
            Reflect.deleteProperty(mission.rewards, "extensions");
        }
        mission.rewards.point_multiples = mission.rewards.point_multiples.filter((p) => isValidNum(p.id)).map((p) => p.id);
        if (!mission.rewards.point_multiples.length === 0) {
            Reflect.deleteProperty(mission.rewards, "point_multiples");
        }
        mission.linkages = this.linkages.map((l) => l.id);

        mission.repeat = isValidNum(mission.repeat) ? mission.repeat : null;

        return mission;
    },
    toggleRequirement: function (stage, reqType) {
        const i = model.mission.requirements[stage].findIndex((e) => e.type === reqType);
        if (i >= 0) {
            model.mission.requirements[stage].splice(i, 1);
        } else {
            model.mission.requirements[stage].push({ type: reqType, count: 1 });
        }
    },
};

export const mainView = () => [
    m("div", [
        m("label", "Title"),
        m("input", {
            onchange: (e) => {
                model.mission.name = e.target.value;
            },
            name: "title",
            value: model.mission.name,
        }),
    ]),
    m("div", [
        m("label", "Detail"),
        m("textarea", {
            onchange: (e) => {
                model.mission.detail.text = e.target.value;
            },
            value: model.mission.detail.text,
        }),
    ]),
    m("div", [
        m("label", "Detail link"),
        m("input", {
            onchange: (e) => {
                model.mission.detail.link = e.target.value;
            },
            value: model.mission.detail.link,
            placeholder: "Leave blank for no link",
        }),
    ]),
    m("div", [
        m("label", "Overview"),
        m("textarea", {
            onchange: (e) => {
                model.mission.data.overview = e.target.value;
            },
            value: model.mission.data.overview,
        }),
    ]),
    m("div", [
        m("label", "Gradient colour"),
        m("label", "Light"),
        " ",
        m("input", {
            onchange: (e) => {
                model.mission.data.color = e.target.value;
            },
            style: "width: 1000px;",
            value: model.mission.data.color,
            placeholder: "Hex code (#000000) or CSS",
        }),
    ]),
    m("div", [
        m("label", ""),
        m("label", "Dark"),
        " ",
        m("input", {
            onchange: (e) => {
                model.mission.data.color_dark = e.target.value;
            },
            style: "width: 1000px;",
            value: model.mission.data.color_dark,
            placeholder: "Hex code (#000000) or CSS",
        }),
    ]),
    m("div", [
        m("label", ""),
        m("label", "Overlay"),
        " ",
        m("input", {
            type: "checkbox",
            oninput: (e) => {
                model.mission.data.color_overlay = e.target.checked;
            },
            checked: model.mission.data.color_overlay,
        }),
    ]),
    m("div", [
        m("label", "Secret"),
        m("input", {
            onchange: (e) => {
                model.mission.secret = e.target.checked;
            },
            checked: model.mission.secret,
            type: "checkbox",
        }),
    ]),
    m("div", [
        m("label", "Repeat"),
        m("input", {
            onchange: (e) => {
                model.mission.repeat = parseIntForInput(e.target.value);
            },
            value: model.mission.repeat,
            placeholder: "blank = infinite",
            type: "text",
        }),
    ]),
    m("div", [
        m("label", "Start date"),
        m("input", {
            onchange: (e) => {
                model.start.day = e.target.value;
            },
            value: model.start.day,
            oncreate: (vnode) => {
                dayPicker = new Pikaday({ field: vnode.dom });
            },
            onremove: () => {
                dayPicker.destroy();
            },
        }),
        "　",
        ...timeSelector((e) => {
            model.start.time = e;
        }, model.start.time),
    ]),
    m("div", [
        m("label", "Deadline date"),
        m("input", {
            onchange: (e) => {
                model.deadline.day = e.target.value;
            },
            value: model.deadline.day,
            oncreate: (vnode) => {
                dayPicker = new Pikaday({ field: vnode.dom });
            },
            onremove: () => {
                dayPicker.destroy();
            },
        }),
        "　",
        ...timeSelector((e) => {
            model.deadline.time = e;
        }, model.deadline.time),
    ]),
    m("div", [
        m("label", "Modal expiry"),
        m("input", {
            onchange: (e) => {
                model.modalExpiry.day = e.target.value;
            },
            value: model.modalExpiry.day,
            oncreate: (vnode) => {
                dayPicker = new Pikaday({ field: vnode.dom });
            },
            onremove: () => {
                dayPicker.destroy();
            },
            placeholder: "Blank = match deadline",
        }),
        "　",
        ...timeSelector((e) => {
            model.modalExpiry.time = e;
        }, model.modalExpiry.time),
    ]),
];

export const rewardsView = () => [
    m("h2", "Rewards"),
    m("h3", "Coupons"),
    m("table", { style: "max-width: 1000px;" }, [
        m("thead", [m("th", "ID"), m("th", "Name"), m("th")]),
        model.mission.rewards.coupons.map((coupon, i) =>
            m("tr", [
                m(
                    "td",
                    m("input[type=text]", {
                        oninput: (e) => {
                            coupon.id = parseIntForInput(e.target.value);
                            if (coupon.id) {
                                getCoupon(coupon.id)
                                    .then((res) => {
                                        coupon.name = res.coupon.name;
                                    })
                                    .catch(() => {
                                        coupon.name = invalid;
                                    })
                                    .finally(() => m.redraw());
                            } else {
                                coupon.name = invalid;
                            }
                        },
                        value: coupon.id,
                    }),
                ),
                m("td", link(`/coupon/${coupon.id}/view`, {}, coupon.name ? coupon.name : "")),
                m(
                    "td",
                    m(
                        "button",
                        {
                            onclick: (e) => {
                                e.preventDefault();
                                model.mission.rewards.coupons.splice(i, 1);
                            },
                        },
                        "✕",
                    ),
                ),
            ]),
        ),
        m(
            "tr",
            m(
                "td",
                { colspan: 3 },
                m(
                    "button",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            model.mission.rewards.coupons.push({ id: 0 });
                        },
                    },
                    "Add coupon",
                ),
            ),
        ),
    ]),
    m("h3", "Expiry Extentions"),
    m("table", { style: "max-width: 1000px;" }, [
        m("thead", m("tr", [m("th", "Slug"), m("th", "ID"), m("th")])),
        model.mission.rewards.extensions.map((extension, i) =>
            m("tr", [
                extension.slug === null
                    ? m(
                          "button",
                          {
                              onclick: (e) => {
                                  e.preventDefault();
                                  getAttribute(extension.id)
                                      .then((res) => {
                                          extension.slug = res.attribute.slug;
                                      })
                                      .catch(() => {
                                          alert("Something went wrong. Please try again.");
                                      })
                                      .finally(() => {
                                          m.redraw();
                                      });
                              },
                          },
                          "Show name",
                      )
                    : m("td", extension.slug),
                m("td", link(`/attribute/${extension.id}/view`, extension.id ? extension.id : "")),
                m(
                    "td",
                    m(
                        "button",
                        {
                            style: "cursor: pointer",
                            onclick: (e) => {
                                e.preventDefault();
                                model.mission.rewards.extensions.splice(i, 1);
                            },
                        },
                        "✕",
                    ),
                ),
            ]),
        ),
        m(
            "tr",
            m("td", [
                "Enter slug: ",
                m(
                    autocomplete,
                    autocomplete.opts.adminAttrs({
                        onclick: (target) => {
                            model.mission.rewards.extensions.push({
                                id: target.id,
                                slug: target.slug,
                            });
                        },
                    }),
                ),
            ]),
        ),
    ]),
    m("h3", "Point Mutipliers"),
    m("table", { style: "max-width: 1000px;" }, [
        m("thead", [m("th", "ID"), m("th", "Multiple"), m("th")]),
        model.mission.rewards.point_multiples.map((pm, i) =>
            m("tr", [
                m(
                    "td",
                    m("input[type=text]", {
                        oninput: (e) => {
                            pm.id = parseIntForInput(e.target.value);
                            if (pm.id) {
                                getPointMutiple(pm.id)
                                    .then((res) => {
                                        pm.multiple = res.point_multiple.multiple;
                                    })
                                    .catch(() => {
                                        pm.multiple = invalid;
                                    })
                                    .finally(() => m.redraw());
                            } else {
                                pm.multiple = invalid;
                            }
                        },
                        value: pm.id,
                    }),
                ),
                m("td", link(`/point_multiply/${pm.id}/view`, {}, pm.multiple || "")),
                m(
                    "td",
                    m(
                        "button",
                        {
                            onclick: (e) => {
                                e.preventDefault();
                                model.mission.rewards.point_multiples.splice(i, 1);
                            },
                        },
                        "✕",
                    ),
                ),
            ]),
        ),
        m(
            "tr",
            m(
                "td",
                { colspan: 4 },
                m(
                    "button",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            model.mission.rewards.point_multiples.push({ id: 0 });
                        },
                    },
                    "Add Point Multiple",
                ),
            ),
        ),
    ]),
    m("h3", "Email"),
    m(
        "select",
        {
            onchange: (e) => {
                model.mission.rewards.email = parseInt(e.target.value, 10);
            },
            value: model.mission.rewards.email,
        },
        [
            m("option", {
                value: null,
                label: "Select to send an email as reward...",
            }),
            model.emailTemplates.map((t) => m("option", t)),
        ],
    ),
    m("h3", "Points"),
    m("table", { style: "max-width: 1000px;" }, [
        m("thead", m("tr", [m("th", "KomiPoints"), m("th", "StoreCredits"), m("th")])),
        m(
            "tr",
            m("td", [
                "KomiPoints: ",
                    m("input[type=text]", {
                        oninput: (e) => {
                                model.mission.rewards.komipoints = parseIntForInput(e.target.value);
                        },
                        value: model.mission.rewards.komipoints,
                    }),
            ]),
            m("td", [
                "StoreCredits: ",
                    m("input[type=text]", {
                        oninput: (e) => {
                            model.mission.rewards.storecredits = parseIntForInput(e.target.value);
                        },
                        value: model.mission.rewards.storecredits,
                    }),
            ]),
        ),
    ]),
];

export const linkagesView = () => [
    m("h2", "Auto-complete linked missions"),
    m("p", "Automatically mark other missions as complete when a user completes this mission."),
    m(
        "p",
        "You may also wish to apply auto-complete to the missions linked below, so that this mission is marked as completed when other missions are completed.",
    ),
    m("table", { style: "max-width: 1000px;" }, [
        m("thead", m("tr", m("th", "ID"), m("th", "Name"), m("th"))),
        model.linkages.map((l, i) =>
            m("tr", { style: "width: 8em;" }, [
                m("td", link(`/mission/${l.id}/edit`, {}, l.id)),
                m(
                    "td",
                    l.name === null
                        ? m(
                              "button",
                              {
                                  onclick: (e) => {
                                      e.preventDefault();
                                      getMission(l.id)
                                          .then((res) => {
                                              l.name = res.mission.name;
                                          })
                                          .catch(() => {
                                              alert("Something went wrong. Please try again.");
                                          })
                                          .finally(() => {
                                              m.redraw();
                                          });
                                  },
                              },
                              "Show name",
                          )
                        : m("p", l.name),
                ),
                m(
                    "td",
                    m(
                        "a",
                        {
                            style: "cursor: pointer",
                            onclick: () => model.linkages.splice(i, 1),
                        },
                        "✕",
                    ),
                ),
            ]),
        ),
        m(
            "tr",
            m(
                "td",
                m("input", {
                    oninput: (e) => {
                        model.newLinkage = e.target.value;
                    },
                    value: model.newLinkage,
                    placeholder: "Mission ID",
                }),
                m(
                    "button",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            if (!model.newLinkage) {
                                return;
                            }

                            getMission(model.newLinkage)
                                .then((res) => {
                                    model.linkages.push({
                                        id: res.mission.id,
                                        name: res.mission.name,
                                    });
                                })
                                .catch((err) => {
                                    alert(err);
                                })
                                .finally(() => {
                                    m.redraw();
                                });
                        },
                    },
                    "Add",
                ),
            ),
        ),
    ]),
];
