import m from "mithril";
import { getArtists, getAttributeContents, getContent } from "../../api-client";
import dayjs from "dayjs";
import link from "../../utils/link";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const complementExpiry = chapters =>
    chapters.reduce(async (promise, chapter) => {
        const accumulator = await promise;

        if (!chapter.expiry) {
            await delay(1000);
            const { content } = await getContent(chapter.id);

            return [...accumulator, content];
        }

        return [...accumulator, chapter];
    }, Promise.resolve([]));

const load = async state => {
    if (state.loading()) {
        return;
    }

    state.artists([]);
    state.loading(true);

    const { attribute } = await getArtists(9999);

    const list = attribute.children;
    state.artistsCount(list.length);
    state.completeArtistsCount(0);

    const tasks = list.reduce(async (promise, artist) => {
        const init = await promise;

        await delay(1000);

        const res = await getAttributeContents(artist.id);

        const allChapters = res.results.filter(c => c.type === "chapter");
        const hasTankobons = res.results.some(c => c.type === "volume_affiliate");

        // Complement expiry date
        const chapters = await complementExpiry(allChapters);

        const activeContents = chapters.filter(c => dayjs(c.expiry).isAfter(dayjs()));

        const result = {
            id: artist.id,
            name: artist.data.name,
            contentsCount: activeContents.length,
            hasTankobons,
        };

        state.completeArtistsCount(state.completeArtistsCount() + 1);
        m.redraw();

        return [...init, result];
    }, Promise.resolve([]));

    const result = await tasks;

    state.artists(result);
    state.loading(false);
    m.redraw();
};

const oninit = ({ state }) => {
    state.artists = m.prop([]);
    state.artistsCount = m.prop(0);
    state.completeArtistsCount = m.prop(0);
    state.loading = m.prop(false);
    state.filter = m.prop(true);
    state.load = () => load(state);
};

const table = artists =>
    m("table", [
        m("thead", [m("th", "ID"), m("th", "Name"), m("th", "Contents count"), m("th", "Has tankobons")]),
        ...artists.map(artist =>
            m("tr", [
                m("td", link(`/attribute/${artist.id}/edit`, {}, artist.id)),
                m("td", artist.name),
                m("td", artist.contentsCount),
                m("td", artist.hasTankobons),
            ]),
        ),
    ]);

const generateCsv = artists => {
    const keys = Object.keys(artists[0]);
    const head = keys.join(",");
    const tail = artists.map(artist => keys.map(k => artist[k]).join(","));
    const csv = [head, ...tail].join("\n");

    return encodeURI(csv);
};

const view = ({ state }) => {
    const artists = state.filter()
        ? state.artists().filter(artist => !artist.hasTankobons && artist.contentsCount === 0)
        : state.artists();

    return m("div", [
        m("h1", "Validate artists"),
        m("label", [
            m("input[type=checkbox]", { checked: state.filter(), onchange: m.withAttr("checked", state.filter) }),
            "Filter artist with no content and no tankobons",
        ]),
        m("br"),
        m(
            "button",
            {
                onclick() {
                    state.load();
                },
                disabled: state.loading(),
            },
            "Load",
        ),
        state.loading() ? m("p", `${state.completeArtistsCount}/${state.artistsCount}`) : table(artists),
        !state.loading() && artists.length > 0
            ? m(
                "a",
                {
                    href: "data:text/csv;charset=UTF-8," + generateCsv(artists),
                    target: "_blank",
                    download: "artists.csv",
                },
                "Download CSV",
            )
            : null,
    ]);
};

export default {
    $component: {
        oninit,
        view,
    },
};
