"use strict";

import {getAttribute, getAttributeContent, getAttributeChildren} from "../../api-client";
import css from "../../global.css";
import link from "../../utils/link";
import m from "mithril";
import pagination from "../../components/pagination";

class Model {
    constructor(opts = {}) {
        this.id = opts.id;

        this.attr = m.prop();
        this.children = [];
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.content = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getAttribute(this.id, this.page(), this.perPage()).then(
            (res) => {
                this.attr(res.attribute);

                if (res.attribute.parent && res.attribute.parent.slug === "artists") {
                    this.loadContents()
                } else {
                    this.loading(false);
                    m.redraw();
                }
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );

        getAttributeChildren(this.id, this.page(), this.perPage())
            .then(res => {
                this.totalPages(res.total_pages);
                this.children = res.children;
                m.redraw();
            })
            .catch(err => {
                alert(JSON.stringify(err));
            });
    }

    loadContents() {
        getAttributeContent(this.id, this.page(), 20).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.content(res.results);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                alert(JSON.stringify(err));
                throw new Error(err);
            }
        );
    }
}

export function attributeView(vm) {
    if (typeof vm.loading === "function" && vm.loading()) {
        return m(".loading", [
            m(".load"),
            m("p", "Preparing attribute..."),
            m("p", "Please wait."),
        ]);
    }

    const attr = vm.attr ? vm.attr() : vm;

    const content = vm.content && vm.content().length > 0
        ? vm.content()
        : attr.content || [];

    const type = vm.attr && (vm.children.length || attr.siblings) ? "div" : "a";

    return m(".attribute-preview", [
        type != "div" ? link(`/attribute/${attr.id}/edit`, { classList: "edit" }, "edit") : "",
        link(type != "div" ? `/attribute/${attr.id}/view` : "", { classList: "container" }, [
            m("h2", attr.data && attr.data.name ? attr.data.name : `Attribute #${attr.id}`),
            type == "div"
                ? [
                      attr.content ? [] : link(`/attribute/${attr.id}/view`, { classList: css.button }, "view"),
                      link(`/attribute/${attr.id}/edit`, { classList: css.button }, "edit")
                  ]
                : "",
            m("div", [m("span", "id"), m("b", attr.id)]),
            m("div", [m("span", "Slug"), m("span", attr.slug)]),
            m("div", [
                attr.content ? m("span", "Parent") : "",
                !attr.parent
                    ? attr.content
                        ? link("/attribute/list", "(Top)")
                        : ""
                    : link(
                          `/attribute/${attr.parent.id}/view`,
                          `${attr.parent.id}: ${attr.parent.data.name || attr.parent.slug}`
                      )
            ]),
            isFinite(attr.all_followers) ? m("div", [m("span", "# Followers (All)"), m("span", attr.all_followers)]) : '',
            isFinite(attr.all_followers) ? m("div", [m("span", "# Followers (Active)"), m("span", attr.active_followers)]) : '',
            // attr.siblings is only included at the top level
            !attr.siblings || type == "a"
                ? ""
                : m("div", [
                      m("hr"),
                      m("h2", "Child Attributes"),
                      m("p", [
                          link(
                              "/attribute/create?parentId=" + attr.id,
                              { classList: css.button },
                              "Add a new child attribute"
                          )
                      ]),
                      !vm.children.length
                          ? ""
                          : m("ul", vm.children.map(attr => attributeView(attr)))
                  ]),
            !content.length
                ? ""
                : m("div", [
                      m("h2", "Content"),
                      m("div", content.map(c => m("p", link(`/content/${c.id}/edit`, `${c.data.title}`))))
                  ]),
            type == "div" ? m(pagination, { vm: vm }) : "",
        ]),
    ]);
}

export default {
    $component: {
        handle(vnode) {
            this.vm = new Model();
            this.vm.id = vnode.attrs.id;
            this.vm.load(0);
        },
        oninit(vnode) {
            return this.handle(vnode);
        },
        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id != old.attrs.id) {
                return this.handle(vnode);
            }
        },
        view() {
            return attributeView(this.vm);
        }
    }
};
