"use strict";

import m from "mithril";
import {
    getMetricsSubscriptionsSuccessful,
    getMetricsSubscriptionsFailed
} from "../../api-client";

export default {
    $navTitle: "Subscribers",
    $component: {
        oninit(vnode) {
            let self = this;

            self.formatDate = function(date) {
                return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
            }

            self.date = m.prop();

            self.endDate = m.prop(new Date());
            self.endDate().setDate(self.endDate().getDate() + 1);
            self.endDate(self.formatDate(self.endDate()));

            self.startDate = m.prop(new Date());
            // self.startDate().setDate(self.startDate().getDate());
            self.startDate(self.formatDate(self.startDate()));

            self.changeDate = function(e) {
                let selectedDate = new Date(e.target.value);

                self.startDate(self.formatDate(selectedDate));
                self.endDate(selectedDate);
                self.endDate().setDate(self.endDate().getDate() + 1);
                self.endDate(self.formatDate(self.endDate()));

                self.setup();
            }

            self.future = m.prop(false);

            self.setup = function() {
                if (parseFloat(self.formatDate(new Date()).replace(/-/g,"")) <= (parseFloat(self.startDate().replace(/-/g,"")) - 1)) {
                    self.future(true);
                } else {
                    self.future(false);

                    self.subscriptionSuccessful = m.prop([]);

                    self.tally = {
                        "first": {
                            "success": m.prop(0),
                            "fail": m.prop({}),
                        },
                        "multiple": {
                            "success": m.prop(0),
                            "fail": m.prop({}),
                        },
                        "new": {
                            "success": m.prop(0),
                            "cancel": m.prop(0),
                            "error": m.prop(0),
                        }
                    }

                    getMetricsSubscriptionsSuccessful(self.startDate(),self.endDate()).then(
                        (res) => {
                            for (let tally in res.subscriptions) {
                                self.tally[res.subscriptions[tally]["subscription_count"]].success(self.tally[res.subscriptions[tally]["subscription_count"]].success() + res.subscriptions[tally]["count"]);

                                if (res.subscriptions[tally]["billing_count"] == "first") {
                                    self.tally.new.success(self.tally.new.success() + res.subscriptions[tally]["count"]);
                                }
                            }

                            self.subscriptionSuccessful(res.subscriptions);
                            m.redraw();
                        },
                        (err) => {
                            console.error(err);
                            self.error(err);
                        }
                    );

                    self.subscriptionFailed = m.prop([]);

                    getMetricsSubscriptionsFailed(self.startDate(),self.endDate()).then(
                        (res) => {
                            for (let tally in res.subscriptions) {
                                let plus = self.tally[res.subscriptions[tally]["subscription_count"]]["fail"]()[res.subscriptions[tally]["error_code"]] || 0;

                                if (res.subscriptions[tally]["subscription_count"] == "multiple" && res.subscriptions[tally]["error_code"] == "999") {
                                    // User was actually billed
                                    self.tally.multiple.success(self.tally.multiple.success() + res.subscriptions[tally]["count"]);
                                } else {
                                    self.tally[res.subscriptions[tally]["subscription_count"]]["fail"]()[res.subscriptions[tally]["error_code"]] = res.subscriptions[tally]["count"] + plus;

                                    if (res.subscriptions[tally]["billing_count"] == "first") {
                                        if (res.subscriptions[tally]["error_code"] == "999" || res.subscriptions[tally]["error_code"] == "899") {
                                            self.tally.new.cancel(self.tally.new.cancel() + res.subscriptions[tally]["count"]);
                                        } else if (res.subscriptions[tally]["error_code"]) {
                                            self.tally.new.error(self.tally.new.error() + res.subscriptions[tally]["count"]);
                                        }
                                    }
                                }
                            }
                            self.subscriptionFailed(res.subscriptions);
                            m.redraw();
                        },
                        (err) => {
                            console.error(err);
                            self.error(err);
                        }
                    );

                }
            }

            this.setup();
        },

        view() {
            let fails = {
                first: [],
                multiple: [],
            };

            for (let type in fails) {
                for (let tally in this.tally[type].fail()) {
                    if (tally) {
                        fails[type].push(m("tr", [
                            m("td", tally),
                            m("td", this.tally[type].fail()[tally]),
                        ]))
                    }
                }
            }

            return m("form", [
                m("h1", "Subscribers report"),
                m("div", [
                    m("label", "Date"),
                    m("input[type=text][autocomplete=off]", {
                        onchange: this.changeDate,
                        value: this.date(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    })
                ]),
                this.future()
                ? m("p", "These metrics will be available in the future.")
                : [
                    m("p", "Cycle executed on " + this.startDate() + " at 03:00 JST."),
                    m("hr"),
                    m("h2", "First subscription"),
                    m("p", m("i", "Users who have retained their first subscription since registration")),
                    m("table", [
                        m("thead", [
                            m("th", "Status / fail code"),
                            m("th", "Count"),
                        ]),
                        m("tr", [
                            m("td", "Successfully charged"),
                            m("td", this.tally.first.success()),
                        ]),
                        fails.first
                    ]),
                    m("h2", "Repeat subscription"),
                    m("p", m("i", "Users who have re-subscribed after cancellation or failure")),
                    m("table", [
                        m("thead", [
                            m("th", "Status / fail code"),
                            m("th", "Count"),
                        ]),
                        m("tr", [
                            m("td", "Successfully charged"),
                            m("td", this.tally.multiple.success()),
                        ]),
                        fails.multiple
                    ]),
                    m("hr"),
                    m("h3", "...of which new subscribers"),
                    m("p", m("i", "Users who subscribed in the last one month")),
                    m("table", [
                        m("thead", [
                            m("th", "Status"),
                            m("th", "Count"),
                        ]),
                        m("tr", [
                            m("td", "Successfully charged"),
                            m("td", this.tally.new.success()),
                        ]),
                        m("tr", [
                            m("td", "Cancel"),
                            m("td", this.tally.new.cancel()),
                        ]),
                        m("tr", [
                            m("td", "Error"),
                            m("td", this.tally.new.error()),
                        ]),
                    ]),
                ]
            ]);
        }
    }
}
