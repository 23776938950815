'use strict';

import m from 'mithril';
import css from "../../global.css";
import { createCoupon, getItem } from '../../api-client';
import { timeSelector } from '../../utils/input-component';
import { dateFormatRFC3339 } from '../../utils/helper';
import { CouponTypes, CouponCommissionTerms } from './const';
import { parseIntForInput } from '../../utils/type-cast';

const invalidName = 'No item found for the ID';
const useDefaultLabel = 'default';

let dayPicker = {};

export const model = {
    coupon: {},
    start: {
        day: '',
        time: '0:00',
    },
    expiry: {
        day: '',
        time: '0:00',
    },
    items: [],

    reset() {
        this.coupon = {
            name: '',
            max_per_user: 1,
            start_date: undefined,
            expiry_date: undefined,
            auto_add: false,
            bulk_items: {
                plus: false,
                type: CouponTypes[0].value,
                value: 0,
                commission_terms: CouponCommissionTerms[0],
            },
            individual_items: [],
        };
        this.start = {
            day: '',
            time: '0:00',
        };
        this. expiry = {
            day: '',
            time: '0:00',
        };
        this.items = [];
    },

    create() {
        this.coupon.start_date = dateFormatRFC3339(this.start.day, this.start.time);
        this.coupon.expiry_date = dateFormatRFC3339(this.expiry.day, this.expiry.time);
        this.coupon.individual_items = this.items
            .filter(i => i.name && i.name != invalidName)
            .map(i => {
                return {
                    id: i.id,
                    value: i.type ? i.value : undefined,
                    type: i.type,
                    commission_terms: i.commission_terms,
                }
            });

        createCoupon(this.coupon)
            .then(res => {
                m.route.set(`/coupon/${res.coupon_id}/edit`);
            })
            .catch(err => alert(JSON.stringify(err, null, 2)));
    },
}

export const mainView = () => [
    m('div', [
        m('label', 'Name'),
        m('input[type=text][placeholder=Name][required][name=name]', {
            oninput: e => {model.coupon.name = e.target.value},
            value: model.coupon.name,
        }),
    ]),
    m('div', [
        m('label', 'Max per user'),
        m('input[type=text][placeholder=Max per User]', {
            oninput: e => {model.coupon.max_per_user = parseIntForInput(e.target.value)},
            value: model.coupon.max_per_user,
        }),
    ]),
    m('div', [
        m('label', 'Start date'),
        m('input[type=text]', {
            onchange: e => {model.start.day = e.target.value},
            value: model.start.day,
            oncreate(vnode) { dayPicker = new Pikaday({ field: vnode.dom }) },
            onremove() { dayPicker.destroy() },
            style: 'margin-right: 1em',
        }),
        ...timeSelector(e => {model.start.time = e}, model.start.time),
    ]),
    m('div', [
        m('label', 'Expiry date'),
        m('input[type=text]', {
            onchange: e => {model.expiry.day = e.target.value},
            value: model.expiry.day,
            oncreate(vnode) { dayPicker = new Pikaday({ field: vnode.dom }) },
            onremove() { dayPicker.destroy() },
            style: 'margin-right: 1em',
        }),
        ...timeSelector(e => {model.expiry.time = e}, model.expiry.time),
    ]),
];

export const bulkItemView = () => [
    m('h3', 'Bulk Register'),
    m('table', [
        m('tr', [
            m('td', 'Default Discount Value'),
            m('td', [
                m(`input[type=text][placeholder=value > 0][required].${css.short}`, {
                    oninput: e => {model.coupon.bulk_items.value = parseIntForInput(e.target.value)},
                    value: model.coupon.bulk_items.value,
                }),
                m('select',
                    { onchange: e => {model.coupon.bulk_items.type = e.target.value} },
                    CouponTypes.map(couponType =>
                        m('option',
                            {
                                selected: model.coupon.bulk_items.type === couponType.value,
                                value: couponType.value,
                            },
                            couponType.name,
                        ),
                    ),
                ),
            ]),
        ]),
        m('tr', [
            m('td', 'Default Commission'),
            m('td', [
                m('select',
                    { onchange: e => {model.coupon.bulk_items.commission_terms = e.target.value} },
                    CouponCommissionTerms.map(commissionTerms =>
                        m('option',
                            {
                                selected: model.coupon.bulk_items.commission_terms === commissionTerms,
                                value: commissionTerms,
                            },
                            commissionTerms,
                        ),
                    ),
                ),

            ]),
        ]),
    ]),
    m('div', [
        m('label', 'Komiplus'),
        m('input', {
            type: 'checkbox',
            checked: model.coupon.bulk_items.plus === true,
            onclick: _ => {
                if (model.coupon.bulk_items.plus === true) {
                    model.coupon.bulk_items.plus = false;
                } else {
                    model.coupon.bulk_items.plus = true;
                }
            },
        }),
    ]),
    m('div', [
        m('label', 'Auto add future items'),
        m('input', {
            type: 'checkbox',
            oninput: e => {model.coupon.auto_add = e.target.checked},
            checked: model.coupon.auto_add,
        }),
    ]),
];

export const individuallyItemView = () => [
    m('h3', 'Individual Register'),
    m('table', [
        m('thead', [
            m('th', 'Item ID'),
            m('th', 'Name'),
            m('th', 'Discount Value'),
            m('th', 'Commission'),
            m('th', 'Remove'),
        ]),
        model.items.map((item, i) =>
            m('tr', [
                m('td',
                    m(`input[type=text].${css.short}`, {
                        oninput: e => {
                            if (e.target.value) {
                                item.id = parseInt(e.target.value);
                                getItem(item.id)
                                    .then(res => { item.name = res.shop_item.name; m.redraw() })
                                    .catch(() => { item.name = invalidName; m.redraw() });
                            } else {
                                item.id = undefined;
                            }
                        },
                        value: item.id,
                    }),
                ),
                m('td', item.name),
                m('td', [
                    m(`input[type=text]${item.type ? '' : '[disabled]'}.${css.short}`, {
                        oninput: e => { item.value = parseIntForInput(e.target.value) },
                        value: item.value,
                    }),
                    m(`select.${css.short}`,
                        { onchange: e => {item.type = (e.target.value === useDefaultLabel) ? undefined : e.target.value} },
                        m('option', {
                            selected: typeof(item.type) === 'undefined',
                            value: undefined,
                        }, useDefaultLabel),
                        CouponTypes.map(couponType =>
                            m('option',
                                {
                                    selected: item.type === couponType.value,
                                    value: couponType.value,
                                },
                                couponType.name,
                            ),
                        ),
                    ),
                ]),
                m('td', [
                    m(`select.${css.short}`,
                    { onchange: e => {item.commission_terms = (e.target.vaue === useDefaultLabel) ? undefined : e.target.value} },
                    m('option', {
                        selected: typeof(item.commission_terms) === 'undefined',
                        value: undefined,
                    }, useDefaultLabel),
                    CouponCommissionTerms.map(commissionTerms =>{
                        return m('option',
                            {
                                selected: item.commission_terms === commissionTerms,
                                value: commissionTerms,
                            },
                            commissionTerms,
                        )}
                    )),
                ]),
                m('td', m('a', { onclick: () => model.items.splice(i, 1) }, '✕')),
            ])
        ),
        m('tr', m('button', { onclick: () => addItem() }, 'Add item')),
    ]),
];

function addItem() {
    console.log(model.items);
    model.items.push({ id: 0 });
}
