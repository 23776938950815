import m from "mithril";
import config from "../../config";
import {
    getAdminAutoAttributes,
    linkAttributesToItem,
    getItemAttributes,
} from "../../api-client";
import dayjs from "dayjs";
import css from "./edit.css";

const model = {
    attributes: [],
    id: null,

    loadAttributes() {
        getItemAttributes(this.id)
            .then((res) => {
                this.attributes = res.attributes.map((attribute) => ({
                    weight: attribute.weight,
                    id: attribute.attr.id,
                    slug: attribute.attr.slug,
                    is_artist: attribute.attr.parent_id === config.artists_attribute_id,
                    candidates: [],
                }));
            }).finally(() => {
                m.redraw();
            });
    },

    updateAttribute(index, attribute) {
        const attributes = [...this.attributes];
        attributes[index] = attribute;
        this.attributes = attributes;
    },

    updateCandidates(index, input) {
        if (!input) {
            return;
        }

        getAdminAutoAttributes(input)
            .then((res) => {
                const attr = this.attributes[index];

                const candidates = res.results || [];
                this.updateAttribute(index, { ...attr, candidates });
            })
            .finally(() => {
                m.redraw();
            });
    },

    removeAttribute(index) {
        this.attributes = this.attributes.filter((_, i) => i !== index);
    },

    appendAttribute() {
        this.attributes = [
            ...this.attributes,
            {
                id: null,
                slug: "",
                weight: "",
                candidates: [],
                is_artist: false,
            },
        ];
    },

    submitAttributes() {
        // Ignore if id is empty
        const attributes = this.attributes.filter((attr) => attr.id);
        const attributeIDs = attributes.map((attr) => attr.id);
        const attributeWeights = attributes.map((attr) => parseInt(attr.weight, 10));

        linkAttributesToItem(this.id, attributeIDs, attributeWeights)
            .then(() => {
                alert("Successfully updated attributes");
                this.loadAttributes();
            })
            .catch((err) => alert(err));
    },
}

export const AttributeComponent = {
    oninit: ({ attrs }) => {
        model.id = attrs.id;
        model.loadAttributes();
    },
    view: () => {

        const weightSum = model.attributes.reduce((a, b) => a + parseInt(b.weight || 0, 10), 0);
        const weightOver = weightSum > 100;
        return m("div", 
            m("h2", "Edit attributes"),
            m("form", [
                m(`.${css.attributeSection}`, [
                    m("p", "Attribute"),
                    // m("p", "Type"),
                    m("p", "Share (%) of earnings"),
                    m("div"),
                    model.attributes.map((attr, i) =>
                        m.fragment({}, [
                            m("div", [
                                m("input[type=text]", {
                                    placeholder: "New Attribute",
                                    value: attr.slug,
                                    autocomplete: "on",
                                    list: `attributes${i}`,
                                    oninput: (e) => {
                                        const input = e.target.value;
        
                                        model.updateAttribute(i, {
                                            ...attr,
                                            slug: input,
                                        });
        
                                        model.updateCandidates(i, input);
                                    },
                                    onchange: (e) => {
                                        // This event will be fired when the datalist selected
                                        const result = attr.candidates.find((c) => c.slug === e.target.value);
                                        if (result) {
                                            model.updateAttribute(i, {
                                                ...attr,
                                                id: result.id,
                                                is_artist: result.parent && result.parent.id === config.artists_attribute_id,
                                            });
                                        }
                                    },
                                }),
                                m(
                                    `datalist#attributes${i}`,
                                    attr.candidates.map((canditate) =>
                                        m("option", {
                                            value: canditate.slug,
                                        }),
                                    ),
                                ),
                            ]),
                            m(
                                "div",
                                m("input[type='text']", {
                                    // placeholder: "0",
                                    value: attr.is_artist ? attr.weight : "",
                                    disabled: !attr.is_artist,
                                    oninput: (e) =>
                                        model.updateAttribute(i, {
                                            ...attr,
                                            weight: e.target.value,
                                        }),
                                }),
                            ),
                            m(
                                "div",
                                m(
                                    `button.${css.delete}`,
                                    { onclick: (e) => {
                                            e.preventDefault();
                                            model.removeAttribute(i)
                                    } },
                                    "X",
                                ),
                            ),
                        ]),
                    ),
                ]),
                weightOver ? m("p", "The sum of attribute weights must be 100 or under.") : null,
                m("button", { onclick: (e) => {
                    e.preventDefault();
                    model.appendAttribute();
                } }, "Add an attribute"),
                m(
                    "button[type='submit']",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            model.submitAttributes()
                        },
                        disabled: weightOver || isNaN(weightSum),
                    },
                    "Save",
                ),
            ]),
        )
    }
}
