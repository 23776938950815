"use strict";

import m from "mithril";
import { createTask } from "../../api-client";

const DEFALT_TIME = "5";
const DEFALT_TYPE = "billing";
const DEFALT_STATUS = "";

export default {
    $component: {
        oninit(vnode) {
            const dateinit = vnode.attrs.date;
            this.date = m.prop(typeof dateinit === 'undefined' ? "" : "20" + dateinit.substr(0, 2) + "-" + dateinit.substr(2, 2) + "-" + dateinit.substr(4, 2));
            this.time = m.prop(DEFALT_TIME);
        },

        submit(e) {
            e.preventDefault();

            const date = new Date(this.date);
            const params = {
                type: DEFALT_TYPE,
                status: DEFALT_STATUS,
                date_jst: parseInt(date.getFullYear().toString().substr(2,2) + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2)),
                time_jst: parseInt(this.time()),
            };

            createTask(params)
                .then(() => {
                    alert("Successfully created");
                    m.route.set("/task/list");
                })
                .catch(error => {
                    alert(error);
                });
        },

        view() {
            return m("div", [
                m("form", { onsubmit: e => this.submit(e) }, [
                    m("h2", "Create New Schedule"),
                    m("div", [
                        m("label", "Date"),
                        m("input[type=text][placeholder=date][required]", {
                            onchange: m.withAttr("value", this.date),
                            value: this.date(),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Time"),
                        m("select[required]", {
                            onchange: m.withAttr("value", this.time),
                            value: this.time(),
                        },
                        Array.apply(null, {length: 24}).map(Number.call, Number).map(i => {
                            return m("option", { value: i, selected: i == this.time || null }, ("0" + i).slice(-2) + ":00")
                        })
                    )
                    ]),
                    m("div", [m("span"), m("input[type=submit][value=Save]")]),
                ]),
            ]);
        },
    },
};
