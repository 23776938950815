"use strict";

import css from "./layout.css";
import m from "mithril";
import { Subscription, Store } from "./routes";
import config from "./config";
import {
    getCurrentUser,
    postAdminTimestamp,
    adminTimestampStatus,
} from "./api-client";

function renderNav(hash, work) {
    work = work || "";

    return Object.keys(hash)
        .map((k) => {
            let entry = hash[k];

            if (entry == undefined) {
                return;
            }

            let path = `${work}/${k}`;
            let title = entry.$navTitle || k;

            if (entry.$component) {
                if (!entry.$navTitle) {
                    // XXX: Need a better way to hide routes.
                    return;
                }

                return m(`div.${css.naventry}`, [
                    m(`a[href='${path}']`, {
                        oncreate: m.route.link,
                    }, title),
                ]);

            } else {
                return m(`div.${css.navheading}`, [
                    Object.keys(hash[k]).includes("list")
                        ? m(`a[href='${path}/list']`, {
                                oncreate: m.route.link,
                            }, m("h2", `${title} →`))
                        : m("h2", title),
                    renderNav(Object.assign({}, entry, {list: undefined}), path),
                ]);
            }
        })
        .filter(Boolean);
}

function updateStatus(e) {
    if (e.target.selectedOptions.length > 0) {
        const value = e.target.selectedOptions[0].value;

        const confirmStatus = confirm(`Set your status to "${adminTimestampStatus[value]}"?`);

        if (confirmStatus) {
            postAdminTimestamp(value).then(
                (res) => {
                    alert("Status set successfully")
                },
                (err) => {
                    console.error(err);
                    alert("ERROR: Status could not be set. Please try again.")
                    alert(JSON.stringify(err));
                }
            );
        }
    }
}

export default {
    oninit(vnode) {
        getCurrentUser()
            .then(
                (user) => {
                    this.user = user;
                    m.redraw();
                    if(user.access_level < 15) {
                        m.route.set("/unadmin");
                    }
                },
                (err) => {
                    m.route.set("/login");
                }
            );

        this.attrs = vnode.attrs;
    },

    onbeforeupdate(vnode) {
        this.attrs = vnode.attrs;
    },

    view() {
        return m(`div`, [
            m(`nav.${css.nav}`, [
                m(`div.${css.logo}${config.baseUrl.includes('staging') ? `.${css.sta}` : ''}`),
                m(`div.${css.switch}`, [
                    m(`label.${css.switch__label}`, [
                      m(`input[type=checkbox].${css.switch__input}`, {
                        onchange: () => {
                            const Mode = localStorage.getItem('Mode') === 'store';
                            localStorage.setItem('Mode',  Mode ? '' : 'store');
                            location.reload()
                        },
                        checked: localStorage.getItem('Mode') === 'store'
                      }),
                      m(`span.${css.switch__content}`),
                      m(`span.${css.switch__circle}`),
                      m(`span.${css.switch__text}`,'Store')
                    ])
                ]),
                renderNav(localStorage.getItem('Mode') === 'store' ? Store : Subscription),
                m(`div.${css.naventry}`, [
                    m("h2", ""),
                    m("a[href=/logout]", { oncreate: m.route.link }, "Log out")
                ]),
            ]),
            this.user
                ? m(`div.${css.userbox}`, [
                    m("p", m("select", { onchange: updateStatus }, [
                        m("option", ""),
                        Object.keys(adminTimestampStatus).map(k => {
                            return m("option", { value: k }, adminTimestampStatus[k]);
                        }),
                    ])),
                    m("p", this.user.email)
                ])
                : null,
            m(`div.${css.content}`, [
                m(this.attrs.content, this.attrs)
            ])
        ]);
    }
};

