"use strict";

import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import css from "./list.css";
import {
    getEmailSources,
    getEmailTemplate,
    createEmailTemplate,
    putEmailTemplate,
    postEmail,
    deleteEmailTemplate,
} from "../../api-client";

class Model {
    constructor(id) {
        this.email_template = m.prop({});
        this.user_ids = m.prop("");
        this.addresses = m.prop("");

        this.fields = {
            subject:     {
                value:  m.prop(""),
                type:   "text",
            },
            slug:     {
                value:  m.prop(""),
                type:   "text",
            },
            source_id:   {
                value:  m.prop(""),
                type:   "select",
                title:  "sender",
                options: m.prop([]),
            },
            body_html:   {
                value:  m.prop(""),
                type:   "textarea",
            },
            body_text:   {
                value:  m.prop(""),
                type:   "textarea",
            },
            sent_count:  {
                value:  m.prop(""),
            },
            lock_edit:   {
                value:  m.prop(""),
                // type:   "checkbox",
                title:  "prevent edits",
                title:  "prevent edits",
            },
            lock_delete: {
                value:  m.prop(""),
                // type:   "checkbox",
                title:  "prevent deletion",
            },
        };

        this.updateSources(this.fields.source_id.options);

        this.onupdate(id);
    }

    onupdate(id) {
        id = parseInt(id, 10);

        if (isFinite(id) && this.id != id) {
            this.id = id;
            this.refresh();
        }
    }

    refresh() {
        getEmailTemplate(this.id).then(
            (res) => {
                this.email_template(res.email);

                for (let k in res.email) {
                    if (this.fields[k]) {
                        this.fields[k].value(res.email[k])
                    }
                }

                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    updateSources(p) {
        getEmailSources().then(
            (res) => {
                p(res.email_sources);

                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    save() {
        const data = {
            subject:        this.fields.subject.value(),
            source_id:      parseInt(this.fields.source_id.value()),
            body_html:      this.fields.body_html.value(),
            body_text:      this.fields.body_text.value(),
            lock_edit:      this.fields.lock_edit.value(),
            lock_delete:    this.fields.lock_delete.value(),
            slug:           this.fields.slug.value()
        }

        if (this.email_template().id) {
            putEmailTemplate(this.email_template().id, data).then(
                (res) => {
                    m.redraw();
                    alert("Save successful");
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        } else {
            createEmailTemplate(data).then(
                (res) => {
                    const id = res.email.Id;
                    m.route.set(`/email/template/${id}/edit`);
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        }

        return false;
    }
};

export default {
    oninit(vnode) {
        this.vm = new Model();
        this.vm = new Model(vnode.attrs.id);
    },

    onupdate(vnode) {
        this.vm.onupdate(vnode.attrs.id);
    },

    deleteButton() {
        if (!this.vm.email_template().id) return;

        const buttonPressed = _ => {
            const confirmPrompt = confirm("Are you sure? Deleting this template may break parts of the application which depend upon it.");
            if (!confirmPrompt) return false;

            deleteEmailTemplate(this.vm.email_template().id).then(
                res => {
                    alert("Email template deleted successfully.");
                    m.route.set(`/email/templates`);
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        }

        if (this.vm.email_template().lock_delete) {
            return m("div", [
                m("hr"),
                m("p", "This email template cannot be deleted. Please contact an administrator if necessary.")
            ]);
        } else {
            return m("div", [
                m("hr"),
                m("button", { onclick: buttonPressed }, "Delete")
            ]);
        }
    },

    view() {
        return !this.vm.email_template()
            ? null
            : [
                m("form", { onsubmit : this.vm.save.bind(this.vm) }, [
                    m("h1", this.vm.email_template().id ? "Edit email template" : "New email template"),
                    Object.keys(this.vm.fields).map(k => {
                        const field = this.vm.fields[k];
                        const label = m(`label[for=${k}]`, field.title || k.replace("_", " "));

                        if (typeof field.type == "undefined" || !field.type) return;

                        let input;

                        if (field.type === "text") {
                            input = m("input[name=name]", {
                                disabled: this.vm.email_template().lock_edit || (this.vm.email_template().id && k == "slug"),
                                onchange: m.withAttr("value", field.value),
                                value: field.value(),
                            });
                        } else if (field.type === "textarea") {
                            input = m("textarea[name=name]", {
                                disabled: this.vm.email_template().lock_edit,
                                onkeyup: m.withAttr("value", field.value),
                                value: field.value(),
                                classList: css.large + (k === "body_html" ? " " + css.code : ""),
                            });
                        } else if (field.type === "checkbox") {
                            input = m(`input[name=${k}][type=checkbox]`, {
                                disabled: this.vm.email_template().lock_edit,
                                checked: field.value() == true ? "checked" : "",
                                onchange: m.withAttr("checked", field.value),
                            });
                        } else if (field.type === "select") {
                            if (!field.value() && field.options().length > 0) {
                                field.value(field.options()[0].id);
                            }

                            input = m(`select`,
                            {
                                onchange: m.withAttr("value", field.value),
                                value: field.value(),
                            },
                            field.options().map(o => {
                                return m(`option`, { value: o.id, selected: (o.id == field.value()) }, `${o.from_name} <${o.from_address}>`)
                            }));
                        }

                        if (!input) return;

                        return m("div", [
                            label,
                            input,
                        ]);
                    }),
                    this.vm.email_template().lock_edit
                        ? m("div", [
                            m("hr"),
                            m("p", "This email template cannot be modified. Please contact an administrator if necessary.")
                        ])
                        : m("div", [
                            m("button[type=submit]", this.vm.email_template().id ? "Save" : "Create"),
                        ]),
                    m("div", [
                    m("span", "UserIDs"),
                        m("input[name=user_ids]", {
                            onchange: m.withAttr("value", this.vm.user_ids),
                            value: this.vm.user_ids(),
                        }),
                        m("button",{ onclick: (e) => {
                            e.preventDefault();
                            postEmail({
                                user_ids:this.vm.user_ids(),
                                template_id: this.vm.email_template().id
                            }).then(
                            (res) => {
                                alert("Send successful");
                            },
                            (err) => {
                                console.error(err);
                                alert(JSON.stringify(err));
                            }
                        )
                        }},"Send Test")
                    ]),
                    m("div", [
                    m("span", "Addresses"),
                        m("input[name=addresses]", {
                            onchange: m.withAttr("value", this.vm.addresses),
                            value: this.vm.addresses(),
                        }),
                        m("button",{ onclick: (e) => {
                            e.preventDefault()
                            postEmail({
                                addresses: this.vm.addresses(),
                                template_id: this.vm.email_template().id
                            }).then(
                            (res) => {
                                alert("Send successful");
                            },
                            (err) => {
                                console.error(err);
                                alert(JSON.stringify(err));
                            })
                        }},"Send Test")
                    ])
                ]),
                m("iframe", { srcdoc: this.vm.fields.body_html.value() }),
                this.deleteButton(),
            ];
    }
};
