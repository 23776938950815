"use strict";

import m from "mithril";

export default {
    $component: {
        oninit(vnode) {
        },

        view() {
            return m("h1", "TODO: VIEW");
        },
    }
};
