"use strict";

import m from "mithril";
import {
    getFilterNotifications
} from "../../api-client";
import config from '../../config';

export default {
    $navTitle: "ListFilter",
    $component: {
        oninit(vnode) {
            this.filter_notifications = m.prop([]);
            this.loading = m.prop(true);

            getFilterNotifications().then(
                res => {
                    this.loading(false);
                    this.filter_notifications(res.filter_notifications);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        },

        view() {
            return m("div", [
                m("h1", "FilterNotificatons"),
                this.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("table", [
                        m('thead', [
                            m('th', 'ID'),
                            m('th', 'Filter'),
                            m('th', 'Created'),
                            m('th', 'CreatedBy'),
                            m('th'),
                        ]),
                        this.filter_notifications().map(fn => {
                            return m('tr', [
                                m("td", fn.id),
                                m("td", JSON.stringify(fn.data)),
                                m("td", fn.created),
                                m("td", fn.created_by),
                            ]);
                    })
                ]),
            ]);
        }
    }
};
