"use strict";

import m from "mithril";
import config from "../../config";
import {
    postShopItemList,
} from "../../api-client";

export default {
    $navTitle: "Create new",
    $component: {
        oninit(vnode) {
            this.name = m.prop("");
            this.explanation = m.prop("");
            this.expiry = m.prop(null);
            this.submit = () => {
                const name = this.name()
                const explanation = this.explanation()
                const expiry = this.expiry() ? new Date(this.expiry()).toJSON() : null;
                postShopItemList(name, explanation, expiry, "public").then(
                    (res) => {
                        // Nothing else to do
                        m.route.set(`/item_list/${res.shop_item_list.id}/edit`);
                    },
                    (err) => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    }
                );

                return false;
            };

            return this;
        },

        view() {
            return m("form", {
                onsubmit: this.submit,
            }, [
                m("h1", "New Item List"),
                m("div", [
                    m("label[for=name]", "Title"),
                    m("input[type=text][name=name]", {
                        onchange: m.withAttr("value", this.name),
                        value: this.name(),
                    })
                ]),
                m("div", [
                    m("label[for=explanation]", "Subtitle"),
                    m("input[type=text][name=explanation]", {
                        onchange: m.withAttr("value", this.explanation),
                        value: this.explanation(),
                    })
                ]),
                m("div", [
                    m("label[for=expiry]", "Expiry date"),
                    m("input[type=text][name=expiry][autocomplete=off]", {
                        onchange: m.withAttr("value", this.expiry),
                        value: this.expiry(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom,
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                ]),
                m("div", [
                    m("button[type=submit]", "Create")
                ])
            ]);
        },
    },
};
