"use strict";

import m from "mithril";
import { login, logout } from "../../api-client";
import css from "./index.css";
import { getCurrentUser } from "../../api-client";

export default {
    oninit : function(vnode) {
        this.email = m.prop();
        this.password = m.prop();
        this.error = m.prop("");
        this.busy = m.prop(false);

        getCurrentUser()
            .then(
                (user) => {
                    m.route.set("/");
                    if(user.access_level < 15) {
                        m.route.set("/unadmin");
                    }
                },
                (err) => {}
            );

        this.login = (e) => {
            e.preventDefault();

            this.error("");
            this.busy(true);

            login(this.email(), this.password()).then(
                (res) => {
                    if (res.user.access_level < 3) {
                        this.error("Account does not have access.");
                        logout();
                        return;
                    }
    
                    m.route.set("/");
                },
                (err) => {
                    logout();
                    if (err.error === "no_such_object") {
                        this.error("Account doesn't exist");
                    } else if (err.error === "invalid_password") {
                        this.error("Invalid password");
                    } else {
                        console.error(err);
                        this.error(err.error);
                        alert("uhh, " + JSON.stringify(err));
                    }
                }
            ).finally( _ => {
                this.busy(false);
            });
        };
    },

    view : function() {
        return m(`form.${css.login}`, {
            onsubmit: this.login,
        }, [
            m("h1", "Log in"),
            m("input[placeholder=email]", {
                autocomplete: "email",
                required: true,
                oninput: (e) => { this.email(e.target.value) },
                value: this.email()
            }),
            m("input[type=password][placeholder=password]", {
                name: "password",
                required: true,
                oninput: (e) => { this.password(e.target.value) },
                value: this.password()
            }),
            m(`input[type=submit]`, {
                disabled: this.busy() ? "disabled" : '',
                classList: this.busy() ? css.disabled : ''
            }, "Login"),
            !this.error() ? "" : m(`.${css.errors}`, [
                m("span", this.error())
            ])
        ]);
    },
};
