exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".X7uErcGUpDskzkf_uGlha {\n    font-family: \"Comic Sans MS\", cursive, sans-serif;\n    animation: _7FPS4SZ3qT2TmmcTCjIvG 1s linear infinite;\n}\n\n@keyframes _7FPS4SZ3qT2TmmcTCjIvG {\n    50% { opacity: 0.0; }\n}\n\nbutton {\n    transition: all .4s ease-out;\n}\n\n.Wy4xxh5-WGf-1ShBpsFLu {\n    background: transparent !important;\n    padding: 0;\n}\n\n.bUDtpu3JOPk0d3qrOkNXs {\n    /* width: 2em; */\n}\n\n.bUDtpu3JOPk0d3qrOkNXs ._21_IR4odjWdfOCSeSeLs2d,\n    .bUDtpu3JOPk0d3qrOkNXs ._2g0rcE57_jEVFrqDjQRwfn {\n    background: lightgreen;\n}\n\n.bUDtpu3JOPk0d3qrOkNXs ._2lh7UEImq1t06xGv95zDcA,\n    .bUDtpu3JOPk0d3qrOkNXs ._1n3-c1e4zMp4PD6ggkrY6x {\n    background: red;\n}\n\n.bUDtpu3JOPk0d3qrOkNXs ._38C165mmUFvKpZXg-7Xv9J,\n    .bUDtpu3JOPk0d3qrOkNXs ._2X9SkMIsRoHTs34ydCqNi6 {\n    background: yellow;\n}\n\n.bUDtpu3JOPk0d3qrOkNXs ._25PmZAzRv8AolOFG0jEm-F,\n    .bUDtpu3JOPk0d3qrOkNXs ._1F2iOWUnzZhR4s4eHVKkUq {\n    background: #ccc;\n}\n", ""]);

// exports
exports.locals = {
	"i_am_funny": "X7uErcGUpDskzkf_uGlha",
	"blink": "_7FPS4SZ3qT2TmmcTCjIvG",
	"begone": "Wy4xxh5-WGf-1ShBpsFLu",
	"status": "bUDtpu3JOPk0d3qrOkNXs",
	"shinjuku1565_in": "_21_IR4odjWdfOCSeSeLs2d",
	"remote_in": "_2g0rcE57_jEVFrqDjQRwfn",
	"shinjuku1565_out": "_2lh7UEImq1t06xGv95zDcA",
	"remote_out": "_1n3-c1e4zMp4PD6ggkrY6x",
	"shinjuku1565_lunch": "_38C165mmUFvKpZXg-7Xv9J",
	"remote_lunch": "_2X9SkMIsRoHTs34ydCqNi6",
	"shinjuku1565_out_of_office": "_25PmZAzRv8AolOFG0jEm-F",
	"remote_out_of_office": "_1F2iOWUnzZhR4s4eHVKkUq"
};