"use strict";

import m from "mithril";
import css from "./autocomplete.css";
import {
    getAdminAutoAttributes,
    getAdminAutoContent
} from "../api-client";

let Autocomplete = {
    oninit(vnode) {
        this.error = [];
        this.results = [];
        this.emptyResults = false;

        this.hasFocus = false;

        this.minLength = vnode.attrs.minLength || 1;
        this.placeholder = vnode.attrs.placeholder || "";

        let renderResult = vnode.attrs.render || ((res) => {
            return m("span", "" + res);
        });

        let renderError = vnode.attrs.renderError || ((err) => {
            return m("div", JSON.stringify(err));
        });

        this.render = (res) => {
            let elem = renderResult(res);
            return Array.isArray(elem) ? elem : [elem];
        };

        let fetchResults = (term) => {

            if (!term || term.length < this.minLength) {
                this.error = [];
                this.results = [];
                this.emptyResults = false;
                return;
            }

            vnode.attrs.loadFn(term).then(
                (res) => {
                    this.error = [];
                    if (Array.isArray(res) && res.length >= 1) {
                        this.results = res;
                        this.emptyResults = true;
                    } else {
                        this.results = [];
                        this.emptyResults = false;
                    }
                    m.redraw();
                },
                (err) => {
                    console.error(err);
                    this.error = [renderError(err)];
                    this.results = [];
                    this.emptyResults = false;
                    m.redraw();
                }
            );
        }

        this.updateResults = (term) => {
            if (!vnode.attrs.loadFn) {
                return;
            }
            fetchResults(term);
        };

        this.focus = (term) => {
            this.hasFocus = true;
            this.results = [];
            fetchResults(term);
        };

        this.blur = () => {
            this.hasFocus = false;
        };

        return this;
    },

    view() {
        return m(`div.${css.component}`, [
            m(`input`, {
                placeholder: this.placeholder,
                onkeyup: m.withAttr("value", this.updateResults),
                onfocus: m.withAttr("value", this.focus),
                onblur: this.blur
            }),
            this.hasFocus
                ? m(`div.${css.results}`, this.error,
                    this.emptyResults
                        ? this.results.map((res) => { return m(`div.${css.result}`, this.render(res)); }) 
                        : m(`div.${css.result}`, "No results")
                )
                : "",
        ]);
    }
};

Autocomplete.opts = {};

function makeAutoOpts(endpoint) {
    return function(opts) {
        opts = opts || {};

        let filter = opts.filter || function() { return true };
        let onclick = opts.onclick || function() { return false };

        return Object.assign({}, opts, {
            loadFn: (term) => {
                return endpoint(term).then(
                    (res) => (res.results || []).filter(filter) || [],
                    Promise.reject
                );
            },

            render: opts.render || function(res) {
                return m("span", {
                        onmousedown: function(e) {
                            if (e.which == 1) {
                                onclick(res);
                                document.activeElement.blur();
                            }
                        }
                    }, res.data.name + " (" + res.slug + ")");
            }
        });
    }
}

Autocomplete.opts = {
    adminAttrs: makeAutoOpts(getAdminAutoAttributes),
    adminContent: makeAutoOpts(getAdminAutoContent)
};

export default Autocomplete;
