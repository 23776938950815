"use strict";

import TemplateNew from "./template_new";
import TemplateEdit from "./template_edit";
import TemplateList from "./template_list";
import ScheduleList from "./schedule_list";
import ScheduleNew  from "./schedule_new";

export default {
    "templates": TemplateList,
    "template/create": TemplateNew,
    "template/:id/edit": TemplateEdit,
    "schedules": ScheduleList,
    "schedule/prepare": ScheduleNew,
};
