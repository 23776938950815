"use strict";

import Results from "./list";
import SurveysResults from "./list";
import SurveysView from "./results";
import Create from "./create";
import Edit from "./edit";
// import View from "./view";
// import SubscriptionView from "../subscriptions/view";

export default {
    "create": Create,
    "results": SurveysResults,
    "id/:id/results": SurveysView,
    "id/:id/edit": Edit,
    // "email/:email": View,
    // "email/:email/edit": Edit,
    // "subscription/:id": SubscriptionView,
};
