'use strict';

import businessCsv from '../../utils/business-csv';
import config from '../../config';
import css from "../../global.css";
import dayjs from 'dayjs';
import { getBusinessReports } from '../../api-client';
import m from 'mithril';
import pagination from '../../components/pagination';

class Model {
    constructor() {
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.sort = m.prop("email_asc");
        this.totalPages = m.prop(0);
        this.totalViews = m.prop(0);

        const { start, end } = periods()[0]

        this.startDate = m.prop(start);
        this.endDate = m.prop(end);
        this.email = m.prop('')
        this.elements = m.prop([]);
    }

    load(page = 0) {
        this.page(page)
        this.loading = m.prop(true);

        getBusinessReports(dayjs(this.startDate()).format('YYYY-MM-DD'), dayjs(this.endDate()).format('YYYY-MM-DD'), this.email(), page, this.perPage(), this.sort()).then(
            (res) => {
                this.elements(res.results);
                this.totalPages(res.total_pages);
                this.totalViews(res.total_views);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
                this.loading(false);
            }
        );
    }
}

const periods = () => {
    const today = dayjs().add(1, 'month');
    const firstDay = dayjs('2019-3-1').add(1, 'month');

    const thisMonth = today.month() + 1;
    const thisYear = today.year();

    const p = []

    for (let year = firstDay.year(); year <= thisYear; year++) {
        for (let month = 1; month <= 12; month++) {
            if (month >= thisMonth && year === thisYear) continue;

            const start = dayjs(`${year}-${month}-1`);
            const end = start.add(1, 'month').add(-1, 'day');

            p.unshift({start: start, end: end});
        }
    }

    return p
}

const selectorPeriod = () =>
    periods().map(period =>
        m('option',
            { start: period.start, end: period.end },
            `${period.start.format('YYYY-MM-DD')} ~ ${period.end.format('MM-DD')}`,
        )
    );

const selectorCompany = () =>
    [
        ['',        'All'],
        ['kclub',   '快活CLUB'],
        ['jiyu',    '自遊空間'],
        ['mland',   'まんがランド'],
        ['gera',    'ゲラゲラ'],
        ['attime',  'アットタイム'],
        ['kaji',    'カジ'],
        ['joy',     'ジョイカフェ'],
        ['apre',    'アプレシオ'],
        ['busta',   'バスター'],
        ['new2',    'NEWNEW'],
        ['clich',   'CLICH'],
    ].map(c =>
        m('option',
            { value: c[0] },
            c[1],
        )
    );

export default {
    $navTitle: 'Partners',
    $component: {
        oninit() {
            this.vm = new Model();
            this.vm.load();
        },

        view() {
            const setPeriod = (dom) => {
                this.vm.startDate(dom[0].getAttribute('start'));
                this.vm.endDate(dom[0].getAttribute('end'));
                this.vm.load();
            }

            const setCompany = (dom) => {
                console.log(dom);
                this.vm.email(dom[0].getAttribute('value'));
                this.vm.load();
            }

            const updateFilter = () => {
                this.vm.load();
            }

            const sort = (sort) => {
                this.vm.sort(sort);
                this.vm.load();
            }

            const sortDisplayUserId = (this.vm.sort() == 'user_id_asc' ? ' ↑' : (this.vm.sort() == 'user_id_desc' ? ' ↓' : ''));
            const sortDisplayEmail = (this.vm.sort() == 'email_asc' ? ' ↑' : (this.vm.sort() == 'email_desc' ? ' ↓' : ''));
            const sortDisplayViews = (this.vm.sort() == 'views_asc' ? ' ↑' : (this.vm.sort() == 'views_desc' ? ' ↓' : ''));

            return m('div', [
                m('h1', 'Partner pageview reports'),
                m('form', [
                    m('div', [
                        m('label', 'Period'),
                        m('select', { onchange: m.withAttr('selectedOptions', setPeriod) }, selectorPeriod())
                    ]),
                    m('div', [
                        m('label', 'Company'),
                        m('select', { onchange: m.withAttr('selectedOptions', setCompany) }, selectorCompany())
                    ]),
                ]),
                this.vm.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Generating report..."),
                        m("p", "Please wait up to 30 seconds for this report to be generated."),
                    ])
                    : !this.vm.elements() || this.vm.elements().length < 1
                        ? m('p', 'No records for this filter.')
                        : [
                            m('p', [
                                m('strong', this.vm.totalViews().toLocaleString()),
                                ' total views for this filter.'
                            ]),
                            m('table', [
                                m('thead', [
                                    m(`th.${css.sortable}`, {
                                        onclick: _ => { sort(this.vm.sort() == 'user_id_desc' ? 'user_id_asc' : 'user_id_desc'); }
                                    }, `User ID${sortDisplayUserId}`),
                                    m(`th.${css.sortable}`, {
                                        onclick: _ => { sort(this.vm.sort() == 'email_desc' ? 'email_asc' : 'email_desc'); }
                                    }, `Email${sortDisplayEmail}`),
                                    m('th', 'Store name'),
                                    m(`th.${css.sortable}`, {
                                        onclick: _ => { sort(this.vm.sort() == 'views_desc' ? 'views_asc' : 'views_desc'); }
                                    }, `Views${sortDisplayViews}`),
                                ]),
                                this.vm.elements().map(r => m('tr', [
                                    m('td',
                                        m('a', {
                                            href: `/user/id/${r.Id}`,
                                            oncreate: m.route.link
                                        }, r.Id)
                                    ),
                                    m('td', r.Email.replace('@komiflo.com', '')),
                                    m('td', r.StoreName),
                                    m('td', r.Views),
                                ]))
                            ]),
                            m('div', [
                                m('button',
                                    { onclick: () => businessCsv(dayjs(this.vm.startDate()).format('YYYY-MM-DD'), dayjs(this.vm.endDate()).format('YYYY-MM-DD'), this.vm.email()) },
                                    'Download CSV'
                                ),
                            ]),
                            m(pagination, { vm: this.vm }),
                        ]
            ]);
        },
    },
};
