import dayjs from "dayjs";
import m from "mithril";
import { prepareDownloadContent, getItemStocks, patchItemStock, downloadItem } from "../../api-client";
import { parseFloatForInput, parseIntForInput } from "../../utils/type-cast";

const resolutionMap = {
    100: "100p",
    1200: "1200p",
    1447: "1447p",
    1500: "1500p",
    1536: "1536p",
    1578: "1578p",
    1600: "1600p",
    1910: "1910p",
    1920: "1920p",
    0: "original",
};

const model = {
    id: 0,
    name: "",
    started: dayjs(),
    content_invalid: false,
    resolution: 0.316,
    amount: 0,
    interval: 0,
    ratio: 0,

    stocks: [],

    submitePrepare() {
        prepareDownloadContent(this.id, this.resolution, this.amount, this.interval, this.ratio)
            .then(() => {
                alert("successfully started generating process. you may want to reload this page after server finished preparing pdf.");
            })
            .catch((err) => {
                alert(err);
            });
    },

    download(resolution) {
        downloadItem(this.id, this.name, resolution);
    },

    patchStock(id) {
        const target = this.stocks.find((s) => s.id === id);

        patchItemStock(this.id, id, {
            shrink_interval: target.new_shrink_interval,
            shrink_ratio: target.new_shrink_ratio,
            amount: target.new_length,
        })
            .then(() => {
                alert("Success!");
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {});
    },

    drawChart(targetStock) {
        let interval = this.interval || 7;
        let ratio = this.ratio || 0.5;
        let amount = this.amount || 100;

        if (targetStock) {
            interval = targetStock.new_shrink_interval || targetStock.shrink_interval;
            ratio = targetStock.new_shrink_ratio || targetStock.shrink_ratio;
            amount = targetStock.new_length || targetStock.length;
        }

        const target = document.getElementById("shrink-graph");
        const ctx = target.getContext("2d");
        if (!target || !target.getContext) {
            return;
        }

        let started = dayjs(this.started);
        if (!started.isValid() || started.isBefore(dayjs())) {
            started = dayjs();
        }
        const daysMax = 60;
        const minStock = 5;
        const endL = 30;
        const endT = 0;
        const endR = target.width;
        const endB = target.height - endT - 30;
        const pichX = (endR - endL) / (daysMax / interval);
        const cnstH = (endB - endT - 30) / Math.max(amount, minStock);
        const pichY = Math.max(amount, minStock) / 4;
        ctx.clearRect(0, 0, target.width, target.height);
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.beginPath();

        ctx.moveTo(endL, endB);
        ctx.lineTo(endR, endB);
        for (let i = 0; i < endR; i += pichX) {
            ctx.moveTo(endL + i, endB);
            ctx.lineTo(endL + i, endB - 5);
            ctx.fillText(started.add((interval * i) / pichX + 1, "day").format("MM/DD"), endL + i, endB + 8);
        }
        ctx.moveTo(endL, endB);
        ctx.lineTo(endL, endT);
        for (let i = 0; i < endB / (cnstH - 1); i += pichY) {
            ctx.moveTo(endL, endB - i * cnstH);
            ctx.lineTo(endL + 5, endB - i * cnstH);
            ctx.fillText(i, endL - 10, endB - i * cnstH);
        }

        let prev = amount;
        for (let i = 0; interval * (i - 1) < daysMax; i += 1) {
            var val = Math.max(amount * Math.pow(1 - ratio, i), minStock);
            if (i === 0 && amount < minStock) {
                val = amount;
            }

            ctx.moveTo(endL + i * pichX, endB - cnstH * val);
            if (i > 0) {
                ctx.lineTo(endL + (i - 1) * pichX, endB - cnstH * prev);
            }
            ctx.fillRect(endL + i * pichX - 2, endB - cnstH * val - 2, 4, 4);
            prev = val;
        }
        ctx.stroke();
    },
};

export const DownloadContentComponent = {
    oninit({ attrs }) {
        model.id = attrs.id;
        model.name = attrs.name;
        getItemStocks(attrs.id)
            .then((res) => {
                model.stocks = res.stocks;
                model.drawChart(model.stocks[0]);
            })
            .catch((err) => {
                if (err.error === "no_such_object") {
                    model.content_invalid = true;
                } else {
                    alert(err.error);
                }
                model.stocks = [];
            })
            .finally(() => {
                m.redraw();
            });
    },

    onupdate({ attrs }) {
        if (attrs.started !== model.started) {
            model.started = attrs.started;
            model.drawChart();
        }
    },

    view() {
        if (model.content_invalid) {
            return [m("h2", "Manage Download PDF"), m("p", "this item doesn't exist, or doesn't have content attached")];
        }

        return [
            m("h2", "Manage Download PDF"),
            m("ul", [
                m("li", "Shrink interval: How long after last shrink in days"),
                m("li", "Shrink ratio: How many stocks after shrink, ratio to the current value"),
            ]),
            m("table", [
                m("thead", [
                    m("th", "resolution"),
                    m("th", "shrink interval (days)"),
                    m("th", "shrink ratio"),
                    m("th", "updated"),
                    m("th", "amount"),
                    m("th"),
                    m("th"),
                ]),
                model.stocks.map((s) =>
                    m("tr", [
                        m("td", resolutionMap[s.resolution]),
                        m(
                            "td",
                            m("input", {
                                style: "width: 4em;",
                                value: s.new_shrink_interval || s.shrink_interval,
                                oninput: (e) => {
                                    s.new_shrink_interval = parseIntForInput(e.target.value);
                                    model.drawChart(s);
                                },
                            }),
                        ),
                        m(
                            "td",
                            m("input", {
                                style: "width: 4em;",
                                value: s.new_shrink_ratio || s.shrink_ratio,
                                oninput: (e) => {
                                    s.new_shrink_ratio = parseFloatForInput(e.target.value);
                                    model.drawChart(s);
                                },
                            }),
                        ),
                        m("td", s.updated),
                        m(
                            "td",
                            m("input", {
                                style: "width: 4em;",
                                value: isFinite(s.new_length) ? s.new_length : s.length,
                                oninput: (e) => {
                                    s.new_length = parseIntForInput(e.target.value);
                                    model.drawChart(s);
                                },
                            }),
                        ),
                        m(
                            "td",
                            m(
                                "button",
                                {
                                    onclick: (e) => {
                                        model.patchStock(s.id);
                                        e.preventDefault();
                                    },
                                },
                                "Update",
                            ),
                        ),
                        m(
                            "td",
                            m(
                                "button",
                                {
                                    onclick: (e) => {
                                        model.download(s.resolution);
                                        e.preventDefault();
                                    },
                                },
                                "Download",
                            ),
                        ),
                    ]),
                ),
                m("tr", [
                    m(
                        "td",
                        m(
                            "select",
                            {
                                onchange: (e) => {
                                    model.resolution = parseIntForInput(e.target.value);
                                },
                                value: model.resolution,
                            },
                            Object.entries(resolutionMap).map(([k, v]) => m("option", { value: k }, v)),
                        ),
                    ),
                    m(
                        "td",
                        m("input", {
                            style: "width: 4em;",
                            placeholder: "7",
                            oninput: (e) => {
                                model.interval = parseIntForInput(e.target.value);
                                model.drawChart();
                            },
                        }),
                    ),
                    m(
                        "td",
                        m("input", {
                            style: "width: 4em;",
                            placeholder: "0.5",
                            oninput: (e) => {
                                model.ratio = parseFloatForInput(e.target.value);
                                model.drawChart();
                            },
                        }),
                    ),
                    m("td"),
                    m(
                        "td",
                        m("input", {
                            style: "width: 4em;",
                            placeholder: "100",
                            oninput: (e) => {
                                model.amount = parseIntForInput(e.target.value);
                                model.drawChart();
                            },
                        }),
                    ),
                    m(
                        "td",
                        m(
                            "button",
                            {
                                onclick: (e) => {
                                    model.submitePrepare();
                                    e.preventDefault();
                                },
                            },
                            "Generate",
                        ),
                    ),
                ]),
            ]),
            m("canvas#shrink-graph", {
                width: 360,
                height: 300,
            }),
        ];
    },
};
