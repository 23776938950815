"use strict";

import m from "mithril";
import { getItems } from "../../api-client";
import pagination from "../../components/pagination";
import { isStoreMode } from "../../utils/helper";

const ItemTypes = ["komiplus", "store", "bolton", "subscription"];
const ContentTypes = ["chapter", "volume", "tankoubon", "physical"];

class Model {
    constructor() {
        this.itemType = m.prop(isStoreMode() ? "store" : "komiplus");
        this.keyword = m.prop("");
        this.contentType = m.prop("");
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(1);
        this.elements = m.prop([]);
        this.load();
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getItems(page, this.perPage(), this.itemType(), this.keyword(), this.contentType()).then((res) => {
            this.loading(false);

            this.totalPages(res.total_pages);
            this.page(res.page);
            this.elements(res.results);
            m.redraw();
        });
    }
}

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            this.content = m.prop([]);
            this.vm = new Model();
        },

        view() {
            return m("div", [
                m("h1", "Items"),
                this.vm
                    ? m("form", [
                          m("div", [
                              m("span", "Item type"),
                              m(
                                  "select",
                                  {
                                      onchange: m.withAttr("value", this.vm.itemType),
                                  },
                                  ItemTypes.map((i) =>
                                      m(
                                          "option",
                                          {
                                              selected: this.vm.itemType() === i,
                                              value: i,
                                          },
                                          i,
                                      ),
                                  ),
                              ),
                          ]),
                          m("div", [
                              m("span", "Keyword"),
                              m("input[type=text]", {
                                  oninput: m.withAttr("value", this.vm.keyword),
                                  value: this.vm.keyword(),
                              }),
                          ]),
                          m("div", [
                              m("span", "Content type"),
                              m(
                                  "select",
                                  {
                                      onchange: m.withAttr("value", this.vm.contentType),
                                  },
                                  m("option", "All"),
                                  ContentTypes.map((c) =>
                                      m(
                                          "option",
                                          {
                                              selected: this.vm.contentType() === c,
                                              value: c,
                                          },
                                          c,
                                      ),
                                  ),
                              ),
                          ]),
                          m("div", [
                              m(
                                  "button",
                                  {
                                      onclick: (e) => {
                                          e.preventDefault();
                                          this.vm.load(0);
                                      },
                                  },
                                  "search",
                              ),
                          ]),
                      ])
                    : "",
                m("hr"),
                this.vm.loading()
                    ? m(".loading", [m(".load"), m("p", "Loading...")])
                    : m("table", [
                          m("thead", [m("th", "ID"), m("th"), m("th", "Name"), m("th", "Type"), m("th", "Content"), m("th", "Value pre tax")]),
                          this.vm.elements().map((item) =>
                              m("tr", [
                                  m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href: `/items/${item.id}/view`,
                                              oncreate: m.route.link,
                                              onupdate: m.route.link,
                                          },
                                          item.id,
                                      ),
                                  ),
                                  m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href: `/items/${item.id}/edit`,
                                              oncreate: m.route.link,
                                              onupdate: m.route.link,
                                          },
                                          "Edit",
                                      ),
                                  ),
                                  m("td", item.name),
                                  m("td", item.type),
                                  m("td", { style: "text-align: end;" }, item.content && item.content.length),
                                  m("td", { style: "text-align: end;" }, 0),
                                  //   m("td", { style: 'text-align: end;'}, item.price.value_pre_tax),
                              ]),
                          ),
                      ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
