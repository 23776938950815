"use strict";

import Create from "./create";
import List from "./list";
import View from "./view";
import Edit from "./edit";
import Mine from "./mine";

export default {
    "create": Create,
    "list": List,
    "mine": Mine,

    ":id/view": View,
    ":id/edit": Edit,
};
