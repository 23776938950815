import m from "mithril";
import { getAccessCodeUsers } from "../../api-client";
import dayjs from "dayjs";
import link from "../../utils/link";

export default {
    $component: {
        oninit(vnode) {
            this.id = m.prop(vnode.attrs.id);
            this.loading = m.prop(true);
            this.useduser = m.prop(null);
            this.accesscode = m.prop(null);
            this.initialLoad();
        },

        initialLoad() {
            getAccessCodeUsers(this.id).then(res => {
                this.accesscode(res.access_code);
                this.useduser(res.used_users);
                this.loading(false);
            }).catch(err => {
            }).finally(() => {
                m.redraw();
            })
        },

        onupdate(vnode) {
            if (vnode.attrs.id !== this.id()) {
                this.id(vnode.attrs.id);
                this.loading(true);
                m.redraw();
                this.initialLoad();
            }
        },

        dateFormat(d) {
            const date = new dayjs(d);
            return date.format("YYYY-MM-DD HH:mm:ss");
        },

        view() {
            if (this.accesscode() === null) {
                return m("div", [
                    m(".loading", [m(".load"), m("p", "Loading...")]),
                ]);
            }

            let code = this.accesscode().access_code.match(
                new RegExp(".{1,4}", "g"),
            );
            code = code ? code.join("-") : "";
            code = code.toUpperCase();

            return m("div", [
                m("h1", "Access Code View"),

                m("div", [
                    m("span", "Code"),
                    m("span", code),
                ]),

                m("div", [
                    m("span", "Available"),
                    m("span", this.accesscode().available),
                ]),

                m("div", [
                    m("span", "Consumed"),
                    m("span", this.accesscode().consumed),
                ]),

                m("div", [
                    m("span", "Duration"),
                    m("span", this.accesscode().duration),
                ]),

                m("div", [
                    m("span", "Type"),
                    m("span", [
                        (() => {
                            if (this.accesscode().applicable_condition == "NotHasSubscription") return "Not have a subscription now (including expired user)"
                            if (this.accesscode().applicable_condition == "FirstSubscription") return "Never had a subscription (only user)"

                            return ""
                        })()
                    ]),
                ]),

                m("div", [
                    m("span", "Created Date"),
                    m("span", this.dateFormat(this.accesscode().Created)),
                ]),

                m("hr"),
                m("h2", "Used Users"),
                this.useduser() === null || this.useduser().length === 0
                    ? 'Not used'
                    : m("table", [
                        m("tr", [
                            m("th", "ID"),
                            m("th", "Email"),
                            m("th", "Used date"),
                        ]),
                        this.useduser().map(user => {
                            return m("tr", [
                                m("td", [
                                    m(
                                        "a",
                                        {
                                            href: `/user/id/${user.Id}`,
                                            oncreate: m.route.link,
                                            onupdate: m.route.link,
                                        },
                                        user.Id,
                                    ),
                                ]),
                                m("td", user.Email),
                                m("td", this.dateFormat(user.Created)),
                            ]);
                        }),
                    ]),
            ]);
        },
    },
};
