"use strict";

import surveyCsv from "../../utils/survey-csv";
import m from "mithril";
import pagination from "../../components/pagination";
import link from "../../utils/link";
import {
    getSurveyResults,
    getSurvey
} from "../../api-client";

class Model {
    constructor(opts = {}) {
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loadingSurvey = m.prop(true);
        this.loadingResults = m.prop(true);
        this.questions = m.prop({});
        this.totalPages = m.prop(0);
        this.survey = m.prop([]);
        this.results = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loadingSurvey(true);
        this.loadingResults(true);

        getSurvey(this.id).then(
            res => {
                this.survey(res.survey);
                for (let i = 0; i < res.survey.questions.length; ++i) {
                    this.questions()[res.survey.questions[i].id] = res.survey.questions[i];
                }
                this.loadingSurvey(false);
                m.redraw();
            },
            err => {
                console.error(err);
                this.loadingSurvey(false);
                alert(JSON.stringify(err));
            }
        )

        getSurveyResults(this.id, this.page(), this.perPage()).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.results(res.results);
                this.loadingResults(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                this.loadingResults(false);
                alert(JSON.stringify(err));
            }
        );
    }
}

export default {
    $component: {
        handle(vnode) {
            this.content = m.prop([]);
            this.vm = new Model();

            this.vm.id = vnode.attrs.id;

            this.vm.load(0);
        },

        oninit(vnode) {
            return this.handle(vnode);
        },

        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id != old.attrs.id) {
                return this.handle(vnode);
            }
        },

        view() {
            let dateFormat = function (date) {
                return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
            }

            if (this.vm.loadingSurvey() || this.vm.loadingResults()) {
                return m("h1", "Loading");
            }

            let self = this;

            const surveyId = this.vm.survey().id;

            return m("div", [
                m("h1", "Survey Results"),
                m("h2", this.vm.survey().data.title),
                m("div", this.vm.results().map((c) => {
                    let published = new Date(c.Created);

                    return m("table.comment", [
                        m("tr", [
                            m("td", "Date"),
                            m("td", dateFormat(published)),
                        ]),
                        m("tr", [
                            m("td", "User"),
                            m("td", link(`/user/id/${c.UserId}`, c.UserId)),
                        ]),
                        m("tr", [
                            m("td", "Question"),
                            m("td", self.vm.questions()[c.SurveyQuestionId].data.title),
                        ]),
                        typeof c.data.answer == 'string'
                            ? m("tr", [
                                m("td", "Answer"),
                                m("td.comment-body", c.data.answer),
                            ])
                            : c.data.answer.map((a, i) => {
                                return m("tr", [
                                    m("td", "Answer " + (i + 1)),
                                    m("td.comment-body", a),
                                ]);
                            }),
                    ]);
                })),
                m("button", {onclick: () => surveyCsv(surveyId)}, "Download CSV"),
                m(pagination, {vm: this.vm}),
            ]);
        },
    },
};
