"use strict";

import m from "mithril";
import Editor from "./editor";

export default {
    $component: {
        oninit(vnode) {
            this.attrs = vnode.attrs;
        },

        onupdate(vnode) {
            this.attrs = vnode.attrs;
        },

        view() {
            return m(Editor, this.attrs);
        },
    },
};
