"use strict";

import m from "mithril";

import { model, mainView, rewardsView, linkagesView } from "./editor";
import { requirementsView } from "./editor_requirements";

export default {
    $component: {
        oninit({ attrs }) {
            model.get(attrs.id);
        },
        view() {
            if (model.loading) {
                return m("div", "div", m(".loading", m(".load"), m("p", "Loading...")));
            }

            return m(
                "form",
                m("h1", "Edit mission"),
                mainView(),
                m("hr"),
                requirementsView(),
                m("hr"),
                rewardsView(),
                m("hr"),
                linkagesView(),
                m("hr"),
                m(
                    "div",
                    m(
                        "button",
                        {
                            onclick: e => {
                                e.preventDefault();
                                model.save();
                            },
                        },
                        "Update Mission",
                    ),
                ),
                m("hr"),
                m(
                    "div",
                    m("h2", "Danger Zone"),
                    m(
                        "button",
                        {
                            onclick: e => {
                                e.preventDefault();
                                model.delete();
                            },
                        },
                        "DELETE THIS MISSION",
                    ),
                ),
            );
        },
    },
};
