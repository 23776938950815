import m from "mithril";
import { getReplyTemplates } from "../../api-client";
import link from "../../utils/link";

const model = {
    loading: false,
    templates: [],
    filter: "",

    load: () => {
        model.loading = true;
        getReplyTemplates(model.page)
            .then((res) => {
                model.templates = res.templates;
            })
            .finally(() => {
                model.loading = false;
                m.redraw();
            });
    },
};

export const List = {
    $component: {
        oninit() {
            model.load();
        },
        view() {
            if (model.loading) {
                return m(".loading", [m(".load"), m("p", "Loading...")]);
            }

            return m(
                "div",
                m("h2", "Support reply templates"),
                m("input", {
                    placeholder: "filter...",
                    oninput: (e) => {
                        e.preventDefault();
                        model.filter = e.currentTarget.value;
                    },
                }),
                m("hr"),
                m("table", [
                    m("thead", [m("tr", [m("th", "User Intent"), m("th", "Text"), m("th", "Link")])]),
                    model.templates
                        .filter((t) => {
                            if (!model.filter) {
                                return true;
                            }

                            return t.text_ja.includes(model.filter) || t.text_en.includes(model.filter);
                        })
                        .map((t) =>
                            m("tr", [
                                m("td", t.user_intent),
                                m("td", t.text_ja.slice(0, 20) + "..."),
                                m("td", link(`/support/template/id/${t.id}/edit`, "Edit")),
                            ]),
                        ),
                ]),
            );
        },
    },
};
