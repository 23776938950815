"use strict";

import m from "mithril";

import { model, mainView, rewardsView, linkagesView } from "./editor";
import { requirementsView } from "./editor_requirements";

export default {
    $navTitle: "Create new",
    $component: {
        oninit() {
            model.init();
        },
        view() {
            return m(
                "form",
                m("h1", "New mission"),
                mainView(),
                m("hr"),
                requirementsView(),
                m("hr"),
                rewardsView(),
                m("hr"),
                linkagesView(),
                m("hr"),
                m(
                    "div",
                    m(
                        "button",
                        {
                            onclick: e => {
                                e.preventDefault();
                                model.create();
                            },
                        },
                        "Create",
                    ),
                ),
            );
        },
    },
};
