var config = {};

config.local = {
    // Don't edit here; if you want to run with staging backend,
    // $ yarn start:staging
    baseUrl: "http://localhost:3000",
    originalsBucket: "komiflo-staging-originals",
    magazines_attribute_id: 4,
    weekly_kairakuten_attribute_id: 328,
    artists_attribute_id: 2,
    tags_attribute_id: 1,
    hidden_tags_attribute_id: 783,
    banners_attribute_id: 438,
    banners_plus_attribute_id: 1036,
    publisher_attribute_id: 363,
    extension_id: 379,
    points_id: 12,
};

config.production = {
    baseUrl: "https://api.komiflo.com",
    originalsBucket: "komiflo-production-originals",
    magazines_attribute_id: 4,
    weekly_kairakuten_attribute_id: 1732,
    artists_attribute_id: 2,
    tags_attribute_id: 1,
    hidden_tags_attribute_id: 783,
    banners_attribute_id: 438,
    banners_plus_attribute_id: 1036,
    publisher_attribute_id: 3378,
    extension_id: 2179,
    points_id: 12,
};

config.staging = {
    baseUrl: "https://staging.api.komiflo.com",
    originalsBucket: "komiflo-staging-originals",
    magazines_attribute_id: 4,
    weekly_kairakuten_attribute_id: 328,
    artists_attribute_id: 2,
    tags_attribute_id: 1,
    hidden_tags_attribute_id: 783,
    banners_attribute_id: 20,
    banners_plus_attribute_id: 270,
    publisher_attribute_id: 363,
    extension_id: 379,
    points_id: 12,
};

export default config[ENVIRONMENT];
