"use strict";

import Landing from "./pages/landing";
import User from "./pages/user";
import Attribute from "./pages/attribute";
import Collection from "./pages/collection";
import Content from "./pages/content";
import Email from "./pages/email";
import Metrics from "./pages/metrics";
import Surveys from "./pages/surveys";
import Items from "./pages/item";
import ItemList from "./pages/item_list";
import Coupon from "./pages/coupon";
import Beta from "./pages/beta";
import AccessCodes from "./pages/access_codes";
import Mission from "./pages/mission";
import Task from "./pages/task";
import Receipt from "./pages/receipt";
// import Billing from "./pages/billing";
import validateArtist from "./pages/utils/validate-artist";
import Support from "./pages/support";

import StoreContent from "./pages/content/index_store";
import StoreItems from "./pages/item/index_store";

export const Store = {
    "": Landing,

    // content admin
    attribute: Attribute,
    content: StoreContent,
    items: StoreItems,
    item_list: ItemList,
    user: User,

    // shop/promo
    coupon: Coupon,
    receipt: Receipt,
    beta: Beta,
};

export const Subscription = {
    "": Landing,

    // content admin
    attribute: Attribute,
    content: Content,
    items: Items,
    user: User,

    // marketing
    email: Email,
    metrics: Metrics,

    collection: Collection,

    // shop/promo
    coupon: Coupon,
    receipt: Receipt,
    access_codes: AccessCodes,
    mission: Mission,
    task: Task,

    surveys: Surveys,
    validateArtist: validateArtist,
    // billing: Billing

    support: Support,
};
