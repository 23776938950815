import Edit from "./edit";
import View from "./view";
import Create from "./create";
import List from "./list";

export default {
    ":id/edit": Edit,
    ":id/view": View,
    create: Create,
    list: List,
};
