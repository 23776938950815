"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
// import PaginatedModel from "../../utils/paginated-model";
import link from "../../utils/link";
import {
    getReview,
    putReviewStatus,
} from "../../api-client";
import config from "../../config";

class Model {
    constructor(opts = {}) {
        this.loading = m.prop(true);
        this.keyword = m.prop("");
        this.status = m.prop();
        this.itemid = m.prop();
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    get() {
        getReview(this.page(), this.perPage()).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    load(page) {
        this.page(page);
        this.loading(true);

        this.reviewCheck = (id, status) => {
            const params = {
                status: status,
            }
            putReviewStatus(id, params).then(
                res => {
                    // Delete was successful
                    this.filter_submit();
                },
                err => {
                    console.error(err);
                }
            );
        };

        this.filter_submit = () => {
            getReview(
                this.page(),
                this.perPage(),
                this.itemid(),
                this.status(),
                this.keyword(),
            ).then(
                (res) => {
                    this.totalPages(res.total_pages);
                    this.elements(res.results);
                    this.loading(false);
                    m.redraw();
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        };

        if (this.itemid() || this.status()) {
            this.filter_submit();
        } else {
            this.get();
        }
    }
}

export default {
    $navTitle: "Reviews",
    $component: {
        oninit(vnode) {
            this.vm = new Model();

            this.vm.load(0);
        },

        view() {
            let dateFormat = function(date) {
                return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
            }

            let i = -1; // because i is incremented before first i is used

            return m("div", [
                m("h1", "Reviews"),
                this.vm.loading()
                    ? m(".loading", [m(".load"), m("p", "Loading...")])
                    : m("div", {style: 'margin-bottom: 1em;'},

                        m("div", [
                            m("span", "Keyword"),
                            m("input[type=text]",{
                                onchange: m.withAttr("value", this.vm.keyword),
                                value: this.vm.keyword(),
                            }),
                        ]),
                        m("div", [
                            m("span", "ItemId"),
                            m("input[type=text]",{
                                onchange: m.withAttr("value", this.vm.itemid),
                                value: this.vm.itemid(),
                            }),
                        ]),
                        m("div", {style: 'margin-bottom: 1em;'}, [
                            m("label[for=status]", "Status"),
                            m("select[name=status]",
                            {
                                onchange: m.withAttr("value", this.vm.status),
                                value: this.vm.status(),
                            },
                            [
                                m("option[value='']", "ALL"),
                                m("option[value=1]", "AwaitingApproval"),
                                m("option[value=2]", "Check OK(viewed)"),
                                m("option[value=3]", "Check NG"),
                            ])
                        ]),
                        m("button", {onclick: e => { e.preventDefault(); this.vm.filter_submit(); }}, 'Submit filter'),
                    ),
                    m("div",

                        this.vm.elements().map(c => {
                        let published = new Date(c.created);

                        ++i;
                        c.index = i;

                        return c.loading
                            ? m("p", "Loading...")
                            : m("table.comment", [
                                m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                    m("td", "ID"),
                                    m("td", c.id),
                                ]),
                                m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                    m("td", "User ID"),
                                    m("td", link(`/user/id/${c.user_id}`, `${ c.user_id}`),),
                                ]),
                                c.item.name ?
                                    m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                        m("td", "Items"),
                                        m("td", [
                                            link(`/items/${c.item.id}/edit`, `${c.item.id} ${c.item.name}`),
                                            m.trust("&nbsp;"),
                                            m("a", { href:  `https://store.${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/item/` + + c.item.id, target: "_blank" }, "Ⓚ")
                                        ]),
                                    ])
                                : "",
                                m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                    m("td", "Posted"),
                                    m("td", dateFormat(published)),
                                ]),
                                m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                    m("td", "Rate"),
                                    m("td", 
                                        [
                                            c.rate,
                                            ' ',
                                            m('span', {style:'color:#ffd700;'}, [...Array(+c.rate)].map(() => '★'))
                                        ]),
                                ]),
                                m("tr", c.status == 3 ? {style: "opacity:.3;"} : {}, [
                                    m("td", "Body"),
                                    m("td.comment-body", c.body),
                                ]),
                                m("tr", [
                                    m("td", "Status"),
                                    m("td", 
                                        function() {
                                            if (c.status == 1) return 'AwaitingApproval'
                                            if (c.status == 2) return 'OK'
                                            if (c.status == 3) return 'NG'
                                            return ''
                                        }()
                                    ),
                                ]),
                                m("tr", [
                                    m("td", ""),
                                    m("td", [
                                        m("button", { href: "", onclick: e => { e.preventDefault(); this.vm.reviewCheck(c.id, 2) } }, "OK"),
                                        m("button", { href: "", onclick: e => { e.preventDefault(); this.vm.reviewCheck(c.id, 3) } }, "NG")
                                    ]),
                                ]),
                            ]);
                    })),
                m(pagination, { vm: this.vm ,params: this.term}),
            ]);
        },
    },
};
