"use strict";

import m from "mithril";
import {
    getCollections
} from "../../api-client";
import config from '../../config';

export default {
    $navTitle: "Browse",
    $component: {
        oninit(vnode) {
            this.collections = m.prop([]);
            this.loading = m.prop(true);

            getCollections().then(
                res => {
                    this.loading(false);
                    this.collections(res.reading_lists);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        },

        view() {
            return m("div", [
                m("h1", "Collections"),
                this.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("table", [
                        m('thead', [
                            m('th', 'ID'),
                            m('th', 'Title'),
                            m('th', 'Publisher'),
                            m('th', '# likes'),
                            m('th'),
                        ]),
                        this.collections().map(c => {
                            return m('tr', [
                                m("td", m("a", { href: `/collection/${c.id}/edit`, oncreate: m.route.link }, c.id)),
                                m("td", c.name.split('|')[0] || ''),
                                m("td", c.name.split('|')[2] || ''),
                                m("td", c.likes_count),
                                m('td', [
                                    m(
                                        "a",
                                        {
                                            href: `https://${config.baseUrl.includes('staging') ? 'staging.' : ''}komiflo.com/collection/${c.id}`,
                                            target: "_blank"
                                        },
                                        "➡"
                                    )
                                ])
                            ]);
                    })
                ]),
            ]);
        }
    }
};
