exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1aBrbJNVoLZS9BU1UJysCm {\n    background: #fff;\n    padding: 2em 2em 1em;\n    margin: 2em 0;\n    border-radius: .5em;\n    box-shadow: 0 .2em 1em rgba(0,0,0,.15);\n}\n\n._1aBrbJNVoLZS9BU1UJysCm h3 {\n    margin-top: 0;\n}\n\n._1aBrbJNVoLZS9BU1UJysCm div:first-of-type button {\n    float: right;\n    margin-right: 0;\n    margin-left: 1em;\n}\n\n._1aBrbJNVoLZS9BU1UJysCm > div:nth-of-type(2) input {\n    width: 100%;\n    box-sizing: border-box;\n}\n\n._2KRJm5tPqpxeVJdTRJMb-S {\n    border-radius: 50%;\n    color: transparent;\n    width: 1.4em;\n    height: 1.4em;\n    padding: 0;\n    margin-right: 0;\n    background: #aaa;\n}\n\n._1bKHzVRu5kTdNIx3gNWcCH {\n    opacity: .5;\n    text-align: center;\n    padding-top: 1em;\n}\n\n._2KRJm5tPqpxeVJdTRJMb-S:before, ._2KRJm5tPqpxeVJdTRJMb-S:after {\n    content: \"\";\n    display: block;\n    width: .8em;\n    height: .2em;\n    background: #fff;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    margin-top: -.1em;\n    margin-left: -.4em;\n    transform: rotate(-45deg);\n}\n\n._2KRJm5tPqpxeVJdTRJMb-S:before {\n    transform: rotate(45deg);\n}\n\n._34NbJCPKRwxS_t5zSzDODy > :not(button) {\n    margin-right: 1em;\n}\n\n._34NbJCPKRwxS_t5zSzDODy input {\n    width: 16em;\n}\n\n._34NbJCPKRwxS_t5zSzDODy > :first-child {\n    width: 8em;\n}\n\n._1aBrbJNVoLZS9BU1UJysCm button {\n    margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"question": "_1aBrbJNVoLZS9BU1UJysCm",
	"delete": "_2KRJm5tPqpxeVJdTRJMb-S",
	"deleted": "_1bKHzVRu5kTdNIx3gNWcCH",
	"option": "_34NbJCPKRwxS_t5zSzDODy"
};