"use strict";

export default function csv(filename, data) {
    let csvHeader = "";
    let csvBody = "";

    data.forEach((d, i) => {
        Object.entries(d).forEach(([k, v]) => {
            if (i === 0) {
                csvHeader += k + ",";
            }

            if (v) {
                csvBody += v;
            }
            csvBody += ",";
        });

        csvBody = csvBody.substring(0, csvBody.length - 1) + "\n";
    });

    const csvStr = csvHeader.substring(0, csvHeader.length - 1) + "\n" + csvBody;

    const dl = document.createElement("a");
    dl.href = "data:text/csv;charset=UTF-8," + encodeURIComponent(csvStr);
    dl.target = "_blank";
    dl.download = filename + ".csv";
    dl.click();
}
