"use strict";

import m from "mithril";
import link from "../../utils/link";
import css from "./view.css";
import { getSubscription, getSubscriptionPaymentMethods, getSubscriptionReceipts, getSubscriptionEdits } from "../../api-client";
import { dateFormat, deletez } from "../../utils/helper";
import dayjs from "dayjs";

function pagenate(url, now, pageMax) {
    let count = 0;
    let start = -4;

    return [
        m(`div.${css.paginate}`, [
            Number(now) > 0 ? link(`${url.replace("___", 0)}`, {}, "<<") : m(`a.${css.disabled}`, "<<"),
            Number(now) > 0 ? link(`${url.replace("___", Number(now) - 1)}`, {}, "<") : m(`a.${css.disabled}`, "<"),
            [...Array(10)].map(() => {
                start += 1;
                if (Number(now) + start >= 0 && Number(now) + start <= Number(pageMax) && count < 7) {
                    count += 1;

                    return m(`${start === 0 ? `.${css.now}` : ""}`, link(`${url.replace("___", Number(now) + start)}`, {}, Number(now) + start + 1));
                }

                return "";
            }),
            Number(now) < Number(pageMax) ? link(`${url.replace("___", Number(now) + 1)}`, {}, ">") : m(`a.${css.disabled}`, ">"),
            Number(now) < Number(pageMax) ? link(`${url.replace("___", Number(pageMax))}`, {}, ">>") : m(`a.${css.disabled}`, ">>"),
        ]),
    ];
}

class Model {
    constructor() {
        this.loading = true;
        this.receiptPage = m.prop(0);
        this.totalReceiptPages = m.prop(0);
        this.historiesPage = m.prop(0);
        this.totalHistoriesPages = m.prop(0);
        this.subscription = {};
        this.paymentMethods = [];
        this.receipts = [];
        this.subscriptionPatches = [];
    }

    load() {
        getSubscription(this.id)
            .then(res => {
                this.subscription = res.subscription;

                return Promise.all([
                    getSubscriptionPaymentMethods(this.id).then(r => {
                        this.paymentMethods = r.payment_methods;
                    }),
                    getSubscriptionReceipts(this.id, 0, 99999).then(r => {
                        this.totalReceiptPages(r.results.length / 20 || 0);
                        this.receipts = r.results;
                    }),
                    getSubscriptionEdits(this.id).then(r => {
                        this.totalHistoriesPages(r.subscription_patches.length / 20 || 0);
                        this.subscriptionPatches = r.subscription_patches;
                    }),
                ]);
            })
            .catch(err => {
                console.error(err);
                alert(JSON.stringify(err));
            })
            .finally(() => {
                this.loading = false;
                m.redraw();
            });
    }
}

export default {
    $component: {
        oninit(vnode) {
            this.vm = new Model();
            this.vm.id = vnode.attrs.id;
            this.vm.load();
        },
        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id !== old.attrs.id) {
                this.oninit(vnode);
                m.redraw();
            }
            if (vnode.attrs.receiptpage !== old.attrs.receiptpage || vnode.attrs.historiespage !== old.attrs.historiespage) {
                this.vm.receiptPage(vnode.attrs.receiptpage || 0);
                this.vm.historiesPage(vnode.attrs.historiespage || 0);
                m.redraw();
            }
        },
        view() {
            if (this.vm.loading) {
                return m(".loading", [m(".load"), m("p", "Loading subscription..."), m("p", "Please wait.")]);
            }

            const tableColumnMap = c =>
                m(
                    "td",
                    Object.keys(c).map(k => {
                        let v = c[k];

                        if (["Finished", "NextBilled", "TrialTermEnded"].includes(k)) {
                            v = dateFormat(deletez(v));
                        }

                        return m("p", [`${k}: ${v || "-"}`]);
                    }),
                );

            return m("div", [
                m("h1", "Subscription"),
                m("h2", "#" + this.vm.subscription.id),
                m("p", link(`/user/id/${this.vm.subscription.user_id}`, "User #" + this.vm.subscription.user_id)),
                m("table", [
                    m("tr", [m("td", "Created"), m("td", dateFormat(deletez(this.vm.subscription.created)))]),
                    m("tr", [m("td", "Next billed"), m("td", dateFormat(deletez(this.vm.subscription.next_billed)))]),
                    m("tr", [m("td", "Finished"), m("td", this.vm.subscription.finished ? dateFormat(deletez(this.vm.subscription.finished)) : "-")]),
                    m("tr", [m("td", "Cancel reason code"), m("td", this.vm.subscription.cancel_reason_code || "-")]),
                    m("tr", [m("td", "Force billing"), m("td", this.vm.subscription.force_billing || "false")]),
                    m("tr", [m("td", "RPIK (for old subscriptions)"), m("td", this.vm.subscription.res_payinfo_key || "-")]),
                ]),
                m("hr"),
                m("h2", "Payment Methods"),
                (() => {
                    if (this.vm.paymentMethods && this.vm.paymentMethods.length > 0) {
                        return m("table", [
                            m("thead", [
                                m("th", "ID"),
                                m("th", "Provider"),
                                m("th", "Card identifier"),
                                m("th", "Provider identifier"),
                                m("th.right", "Created"),
                                m("th.right", "Updated"),
                                m("th.right", "Deleted"),
                                m("th.right", "Priority"),
                            ]),
                            this.vm.paymentMethods.map(pm => {
                                return m("tr", [
                                    m("td", pm.id),
                                    m("td", pm.provider),
                                    m("td", pm.unique_identifier || "-"),
                                    m("td", pm.provider_identifier || m("p.warn", "None")),
                                    m("td.right", dateFormat(pm.created)),
                                    m("td.right", dateFormat(pm.updated)),
                                    m("td.right", dateFormat(pm.deleted)),
                                    m("td.right", pm.priority),
                                ]);
                            }),
                        ]);
                    } else if (this.vm.subscription.cancel_reason_code === "988") {
                        return m("p", "This subscription doesn't have payment method other than the prepaid card");
                    }

                    return m("p.warn", "This subscription doesn't have any payment method");
                })(),
                m("hr"),
                m("h2", "Receipts"),
                this.vm.receipts && this.vm.receipts.length > 0
                    ? [
                          m("table", [
                              m("thead", [m("th", "ID"), m("th", "Created"), m("th", "Status"), m("th", "Provider"), m("th", "Data"), m("th", "Price w/ tax")]),
                              this.vm.receipts
                                  .slice(this.vm.receiptPage() * 20, this.vm.receiptPage() * 20 + 20)
                                  .map(s =>
                                      m(`tr${s.status === "complete" ? ".highlight" : ""}${s.status === "refund" ? ".closed" : ""}`, [
                                          m("td", s.id ? s.id : ""),
                                          m("td", dateFormat(s.created)),
                                          m("td", s.status),
                                          m("td", [s.provider, s.brand ? ` / ${s.brand}` : ""]),
                                          m("td", (()=>{
                                            if (s.status == 'error' && !s.errordataview) return m('a', {style: 'cursor: pointer;', onclick: ()=>{s.errordataview = true}}, 'open')
                                            if (s.status == 'error' && s.errordataview) return [`${s.error_data ? Object.keys(s.error_data).map(key => `${key}: ${JSON.stringify(s.error_data[key])}`).join(' ') : ''}`, ' ', m('a', {style: 'cursor: pointer;', onclick: ()=>{s.errordataview = false}}, 'close')]
                                            if (s.provider == 'access_code') return `${s.duration} days / ${s.access_code}`
                                            if (s.status != 'provisional' && (s.provider == 'atone' || s.provider == 'atone_tsudo')) return `consoleID: ${this.vm.subscription.user_id}_subscription_${s.id}`
                                            if (s.provider == 'paidy') return `${s.transaction_id}`
                                            if (s.partial_paid) return `partial paid value: ${s.partial_paid}`
                                            if (s.provider == 'anotherlane' || s.provider == 'sbps') return `ProviderIdentifier: ${s.provider_card_identifier}`
                                            return ""
                                          })()),
                                          m("td", s.value_pre_tax + s.tax),
                                      ]),
                                  ),
                          ]),
                          pagenate(
                              `/user/subscription/${this.vm.id}?receiptpage=___&historiespage=${this.vm.historiesPage()}`,
                              this.vm.receiptPage(),
                              this.vm.totalReceiptPages(),
                          ),
                      ]
                    : m("p", "This subscription has not yet been billed."),
                m("hr"),
                m("h2", "Edit histories"),
                m("p", "Only displaying changed part of the subscription data."),
                this.vm.subscriptionPatches.length > 0
                    ? [
                          m("table", [
                              m("thead", [m("th", "Old"), m("th", "New"), m("th", "Changed Date")]),
                              this.vm.subscriptionPatches
                                  .slice(this.vm.historiesPage() * 20, this.vm.historiesPage() * 20 + 20)
                                  .map(eh =>
                                      m("tr", [m("td", tableColumnMap(eh.reverse)), m("td", tableColumnMap(eh.patch)), m("td.right", dateFormat(eh.created))]),
                                  ),
                          ]),
                          pagenate(
                              `/user/subscription/${this.vm.id}?receiptpage=${this.vm.receiptPage()}&historiespage=___`,
                              this.vm.historiesPage(),
                              this.vm.totalHistoriesPages(),
                          ),
                      ]
                    : m("p", "(No changes for this subscription yet)"),
            ]);
        },
    },
};
