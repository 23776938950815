"use strict";

import {getSurveys} from "../../api-client";
import surveyCsv from "../../utils/survey-csv";
import link from "../../utils/link";
import m from "mithril";
import pagination from "../../components/pagination";

class Model {
    constructor(opts = {}) {
        this.id = opts.id

        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.surveys = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getSurveys().then(
            res => {
                this.loading(false);
                this.surveys(res.results);
                m.redraw();
            },
            err => {
                console.error(err);
                this.loading(false);
                alert(JSON.stringify(err));
            }
        )
    }
}

export default {
    $navTitle: "Browse",
    $component: {
        handle(vnode) {
            this.content = m.prop([]);
            this.vm = new Model();

            this.vm.load(0);
        },

        oninit(vnode) {
            return this.handle(vnode)
        },

        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id != old.attrs.id) {
                return this.handle(vnode);
            }
        },

        view() {
            let dateFormat = function(date) {
                if (date.getFullYear() >= (new Date().getFullYear() + 10)) {
                    return '';
                } else {
                    return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
                }
            }

            let self = this;

            return m("div", [
                m("h1", "Survey results"),
                this.vm.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Retrieving content..."),
                        m("p", "Please wait.")
                    ])
                    : m(
                        "table",
                        m(
                            "tbody",
                            m("tr", [
                                m("th", "edit"),
                                m("th", "results"),
                                m("th", "title"),
                                m("th", "start date"),
                                m("th", "end date"),
                                m("th", "download")
                            ]),
                            this.vm.surveys().map((survey) => {
                                const startDate = dateFormat(new Date(survey.start_date));
                                const endDate = dateFormat(new Date(survey.end_date));

                                return m("tr", [
                                    m("td", link(`/surveys/id/${survey.id}/edit`, "edit")),
                                    m("td", link(`/surveys/id/${survey.id}/results`, "results")),
                                    m("td", survey.data.title),
                                    m("td", startDate),
                                    m("td", endDate),
                                    m("td", m("button", {onclick: () => surveyCsv(survey.id)}, "Download CSV"))
                                ]);
                            })
                        )
                ),
                m(pagination, {vm: this.vm})
            ]);
        },
    },
};
