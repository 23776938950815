"use strict";
// For some reason, the date format is broken when this is declared.
// import Pikaday from "pikaday";
import { getAccessCodes, expireAccessCode } from "../../api-client";
import css from "../../global.css";
import m from "mithril";
import pagination from "../../components/pagination";
import { AccessCodeType } from "./utils";
import dayjs from "dayjs";

class Model {
    constructor() {
        this.loading = true;
        this.error = "";
        this.params = {
            code: "",
            created: "",
            used: "",
            type: 2,
            user_id: "",
        };
        this.page = m.prop(0);
        this.perPage = 25;
        this.totalPages = m.prop(0);
        this.accessCodes = [];
    }

    get() {
        this.loading = true;

        getAccessCodes(this.params, this.page(), this.perPage)
            .then(res => {
                this.totalPages(res.total_pages);
                this.accessCodes = res.results || [];
            })
            .catch(err => {
                this.error = err;
            })
            .finally(() => {
                this.loading = false;
                m.redraw();
            });
    }

    expire(accessCode) {
        if (confirm("Are you sure?")) {
            var promise = new Promise(resolve => {
                expireAccessCode(accessCode.id).then(
                    () => {
                        accessCode.deleted = new Date();
                        m.redraw();

                        return resolve(accessCode);
                    },
                    err => {
                        alert(err);

                        return resolve(accessCode);
                    },
                );
            });

            return promise;
        }

        return accessCode;
    }

    load(page) {
        this.page(page);

        this.get();
    }
}

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            this.vm = new Model();

            this.vm.load(0);
        },

        dateFormat(d) {
            const date = new dayjs(d);
            return date.format("YYYY-MM-DD HH:mm:ss");
        },

        view() {
            if (this.vm.error !== "") {
                return m("div", [m("h1", "Error"), m("p", this.vm.error)]);
            }

            if (this.vm.loading) {
                return m("div", [
                    m("h1", "Access Codes"),
                    m(".loading", [m(".load"), m("p", "Loading...")]),
                ]);
            }

            return m("div", [
                m("h1", "Access Codes"),
                m("form", [
                    m("div", [
                        m("label", "Type"),
                        m(
                            "select",
                            {
                                oninput: e => {
                                    this.vm.params.type = e.target.value;
                                },
                                value: this.vm.params.type,
                            },
                            Object.entries(AccessCodeType).map(([k, v]) => m("option", {value: k}, v)),
                        ),
                    ]),
                    m("div", [
                        m("label", "Code"),
                        m("input", {
                            oninput: e => {
                                this.vm.params.code = e.target.value;
                            },
                            value: this.vm.params.code,
                            type: "text",
                            placeholder: "Partial match",
                        }),
                    ]),
                    m("div", [
                        m("label", "Created Date"),
                        m("input", {
                            onchange: e => {
                                this.vm.params.created = e.target.value;
                            },
                            value: this.vm.params.created,
                            type: "text",
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Used"),
                        m(
                            "select",
                            {
                                oninput: e => {
                                    this.vm.params.used = e.target.value;
                                },
                                value: this.vm.params.used,
                            },
                            m("option", { value: "" }, "Don't care"),
                            m("option", { value: "true" }, "Yes"),
                            m("option", { value: "false" }, "No"),
                        ),
                    ]),
                    m("div", [
                        m("label", "User ID"),
                        m("input", {
                            oninput: e => {
                                this.vm.params.user_id = e.target.value;
                            },
                            value: this.vm.params.user_id,
                            type: "text",
                            placeholder: "",
                        }),
                    ]),
                    m(
                        "button",
                        {
                            onclick: () => {
                                this.vm.load(0);
                            },
                        },
                        "Apply Filter",
                    ),
                ]),
                m("hr"),
                m("table", [
                    m("thead", [
                        m("th", "Code"),
                        m("th", ["Duration (days)", m('br'), "or Balance (JPY)"]),
                        m("th", ["Qty", m('br'), "available"]),
                        m("th", ["Qty", m('br'), "consumed"]),
                        m("th", ["Qty", m('br'), "remaining"]),
                        m("th", "Created date"),
                        m("th", "Expiry date"),
                        m("th", "User status"),
                        m("th", ""),
                        m("th", ""),
                    ]),
                    this.vm.accessCodes.map(ac => {
                        const codeFormat = ac.access_code.match(
                            new RegExp(".{1,4}", "g"),
                        );

                        const accessCode = codeFormat
                            ? codeFormat.join("-")
                            : "";

                        return m(`tr${ac.deleted ? `.${css.deleted}` : ""}`, [
                            m("td", accessCode.toUpperCase()),
                            m("td", {style: "text-align: right;"}, ac.duration),
                            m("td", {style: "text-align: right;"}, ac.available),
                            m("td", {style: "text-align: right;"}, ac.consumed),
                            m("td", {style: "text-align: right;"}, ac.available - ac.consumed),
                            m("td", this.dateFormat(ac.Created)),
                            m("td", this.dateFormat(ac.expiry_date)),
                            m("td", (
                                () => {
                                    if (ac.applicable_condition == "NotHasSubscription") return "Not have a subscription now (including expired user)"
                                    if (ac.applicable_condition == "FirstSubscription") return "Never had a subscription (only user)"

                                    return ""
                                })()
                            ),
                            m("td", [
                                m("a", { 
                                    href: `/access_codes/id/${ac.id}`,
                                    oncreate: m.route.link,
                                    onupdate: m.route.link,
                                 }, "View"),
                            ]),
                            m(
                                "td",
                                m(
                                    "button",
                                    {
                                        onclick: () => {
                                            this.vm.expire(ac);
                                        },
                                    },
                                    "x",
                                ),
                            ),
                        ]);
                    }),
                ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
