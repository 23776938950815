"use strict";

import Create from "./create";
import Edit from "./edit";
import View from "./view";
import List from "./list";
import Banners from "./banners"

export default {
    create: Create,
    list: List,
    PendingBanners: Banners,
    ":id/view": View,
    ":id/edit": Edit,
};
