exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1vawERdWEaxiSSoHjSVmys {\n    position: relative;\n    width: 50em;\n    height: 25em;\n    font-size: 15px;\n}\n\n._1b_JVCPNtnm6UwOaOlNfWO {\n    font-family: courier;\n    font-weight: 300;\n    background: #313440;\n    color: #DEE2F7;\n    line-height: 1.5em;\n}\n\niframe {\n    position: relative;\n    width: 100%;\n    height: 40em;\n    background: #fff;\n    border: 1px solid #bbb;\n    box-shadow: 0 .5em 1em rgba(0,0,0,.15);\n    margin: 2em 0;\n    border-radius: .15em;\n}\n", ""]);

// exports
exports.locals = {
	"large": "_1vawERdWEaxiSSoHjSVmys",
	"code": "_1b_JVCPNtnm6UwOaOlNfWO"
};