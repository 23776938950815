"use strict";

import m from "mithril";
import {
    getPreRegistrations,
    postPreRegistrations
} from "../../api-client";
import config from '../../config';
import csv from "../../utils/csv";
import dayjs from 'dayjs'

export default {
    $navTitle: "Browse",
    $component: {
        oninit(vnode) {
            this.type = m.prop("store");
        },
        csvDownload() {
            let output = []
            getPreRegistrations(this.type(), false).then(res=>{
                res.pre_registration.forEach((rp) => {
                    output.push({
                        "user_id": rp.UserId,
                        "email": rp.Email,
                        "added": dayjs(rp.Added).format("YYYY/M/D HH:mm"),
                        "sent": rp.Sent ? dayjs(rp.Sent).format("YYYY/M/D HH:mm") : "",
                        "type": rp.Type,
                    })
                })

                csv("beta_request_list", output)
                postPreRegistrations(this.type(), null).then(res=>{}).catch(()=>{alert('Update error')})
            })
        },
        view() {
            return m("div", [
                m("h1", "Beta request list"),
                m("div", [
                    m("label[for=type]", "Type"),
                    m(
                        "select[name=type]",
                        {
                            onchange: m.withAttr("value", this.type),
                            value: this.type(),
                        },
                        [
                            m("option", ""),
                            m("option", { value: "store" }, "Store"),
                        ],
                    ),
                ]),
                m("h1", ""),
                m("button", { onclick: () => {this.csvDownload()} }, "List Download"),
            ]);
        }
    }
};
