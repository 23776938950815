"use strict";

import m from "mithril";
import {
    getShopItemLists
} from "../../api-client";
import config from '../../config';

export default {
    $navTitle: "Browse",
    $component: {
        oninit(vnode) {
            this.list = m.prop([]);
            this.loading = m.prop(true);

            getShopItemLists().then(
                res => {
                    this.loading(false);
                    this.list(res.shop_item_lists);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        },

        view() {
            return m("div", [
                m("h1", "Item List"),
                this.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("table", [
                        m('thead', [
                            m('th', 'ID'),
                            m('th', 'Title'),
                            m('th'),
                        ]),
                        this.list().map(c => {
                            return m('tr', [
                                m("td", m("a", { href: `/item_list/${c.id}/edit`, oncreate: m.route.link }, c.id)),
                                m("td", c.name || ''),
                                m('td', [
                                    // m(
                                    //     "a",
                                    //     {
                                    //         href: `https://${config.baseUrl.includes('staging') ? 'staging.' : ''}komiflo.com/item_list/${c.id}`,
                                    //         target: "_blank"
                                    //     },
                                    //     "➡"
                                    // )
                                ])
                            ]);
                    })
                ]),
            ]);
        }
    }
};
