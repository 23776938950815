"use strict";

import m from "mithril";
import css from "../../global.css";
import csv from "../../utils/csv";
import dayjs from 'dayjs';
import pagination from "../../components/pagination";
import PaginatedModel from "../../utils/paginated-model";
import link from "../../utils/link";
import { isCreditCardProvider, isSubscriptionProvider, isUsingWebcoin } from "../../utils/billing";
import { getUsers } from "../../api-client";
import { now } from "moment";

export default {
    $navTitle: "Browse",
    $component: {
        oninit(vnode) {
            let page = parseInt(vnode.attrs.page, 10) || 0;

            this.access_levels = [
                {
                    value: 1,
                    label: "User with no active subscription"
                },
                {
                    value: 2,
                    label: "User with active subscription"
                },
                {
                    value: 3,
                    label: "Business"
                },
                {
                    value: 8,
                    label: "Publisher"
                },
                {
                    value: 10,
                    label: "Editor"
                },
                {
                    value: 15,
                    label: "Admin"
                },
                {
                    value: 20,
                    label: "Super"
                },
            ];

            this.sort = m.prop('id_desc');
            this.filter = m.prop('');

            this.params = m.prop({
                email: m.prop(""),
                email_look_before: m.prop(""),
                email_look_after: m.prop(""),
                cancel_reason_code: m.prop(""),
                created_after: m.prop(""),
                created_before: m.prop(""),
                created_beyond: m.prop(""),
                access_level: m.prop(""),
                status: m.prop(""),
                searching: m.prop(false),
                ip_addresses: m.prop(""),
                user_ids: m.prop(""),
                payment_methods_search_type: m.prop("only"),
                payment_methods: m.prop([]),
                with_payment_method: m.prop(""),
                softbank: m.prop(false),
                anotherlane: m.prop(false),
                crypto: m.prop(false),
                atone: m.prop(false),
                atone_tsudo: m.prop(false),
                bitcash: m.prop(false),
                paidy: m.prop(false),
                webcoin: m.prop(false),
                maxconnect: m.prop(false),
                referral: m.prop(""),
                referral_exact: m.prop(""),
                access_code_use: m.prop(""),
                unique_identifiers: m.prop(""),
                register_url: m.prop(""),
            });



            // Store access_level separately for future reference
            this.access_level = m.prop(null);

            this.filter_submit = _ => {
                // Status can only be "open", "closed", or nothing
                if (this.params().status() != "open" && this.params().status() != "closed") {
                    this.params().status("");
                }

                // Make sure this access level exists in the array
                this.params().access_level("");
                for (let i = 0; i < this.access_levels.length; ++i) {
                    if (this.access_levels[i].value == this.access_level()) {
                        this.params().access_level(this.access_levels[i].value)
                    }
                }
                let createdAfter = dayjs(this.params().created_after());
                let createdBefore = dayjs(this.params().created_before());

                this.params().created_after(this.params().created_after() != "" ? createdAfter.format('YYYY-MM-DD') : "");
                this.params().created_before(this.params().created_before() != "" ? createdBefore.format('YYYY-MM-DD') : "");

                this.params().email_look_before(this.params().email_look_before() ? true : "");
                this.params().email_look_after(this.params().email_look_after() ? true : "");

                this.params().payment_methods([])
                if (this.params().softbank())
                    this.params().payment_methods().push("sbps")
                if (this.params().anotherlane())
                    this.params().payment_methods().push("anotherlane")
                if (this.params().atone())
                    this.params().payment_methods().push("atone")
                if (this.params().atone_tsudo())
                    this.params().payment_methods().push("atone_tsudo")
                if (this.params().crypto())
                    this.params().payment_methods().push("crypto")
                if (this.params().bitcash())
                    this.params().payment_methods().push("bitcash")
                if (this.params().paidy())
                    this.params().payment_methods().push("paidy")
                if (this.params().webcoin())
                    this.params().payment_methods().push("webcoin")
                if (this.params().maxconnect())
                    this.params().payment_methods().push("maxconnect")


                this.params().access_code_use(this.params().access_code_use() ? true : "");
                this.params().with_payment_method(this.params().with_payment_method() ? true : "");
                this.params().payment_methods_search_type(this.params().payment_methods_search_type() || "only");
                this.params().referral_exact(this.params().referral_exact() ? true : "");

                this.params().searching(true);

                // Rebuild the users list
                this.vm.load(page, this.params, this.sort(), this.filter());

                return false;
            }

            this.changeSortMethod = (sort) => {
                this.sort(sort);
                this.vm.load(page, this.params, this.sort(), this.filter());
            }

            this.vm = new PaginatedModel(getUsers);
            this.vm.load(page, this.params, this.sort(), this.filter());
        },

        csvDownload() {
            let output = []
            getUsers(0, 999999, this.sort(), this.filter(), this.params).then(res=>{
            res.results.forEach((user) => {
                const payment_methods = user.payment_methods && user.payment_methods.map(pm => pm.Provider).join(",")
                    output.push({
                        "user_id": user.id,
                        "email": user.email,
                        "cancel_reason_code": user.subscription && user.subscription.CancelReasonCode,
                        "created": dayjs(user.created).format("YYYY/M/D HH:mm"),
                        "cancelled": user.subscription && user.subscription.Finished != null ? dayjs(user.subscription.Finished).format('YYYY-MM-DD HH:mm') : null,
                        "status": this.userStateView(user).text == null ? this.userStateView(user) : this.userStateView(user).text,
                        "affiliate": user.referral && user.referral.Referral,
                        "payment_methods": `"${payment_methods}"`,
                        "store_payment": user.store_payment,
                        "subscription_payment": user.subscription_payment,
                        "access_code_count": user.access_codes && user.access_codes.length,
                        "first_access_url": user.data.register_url ? user.data.register_url.replace(/(https:\/\/|http:\/\/)/, '') : '',
                    })
                })

                csv("users", output)
            })
        },

    userStateView(user) {
        let access_level = "";
        let statusCheck = false;
        let status = "";
        if (user.deleted) {
            access_level = "Deleted";
        } else if (user.access_level == 20){
            access_level = "Super";
        } else if(user.access_level >= 15){
            access_level = "Admin";
        } else if(user.access_level == 3){
            access_level = "Business";
        } else if(user.access_level == 8){
            access_level = "Publisher";
        } else if(user.access_level == 2 || user.access_level == 1){
            if (!user.has_password) {
                access_level = "No Password";
            } else if(user.subscription && (isSubscriptionProvider(user.subscription.Provider) || isUsingWebcoin(user))) {
                access_level = user.subscription.ChargesCount === 0 ? "Trial" : "Subscriber";
                statusCheck = true;
            } else if(user.subscription) {
                access_level = "Prepaid / Exception";
                statusCheck = true;
            } else {
                access_level = user.access_level == 2 ? "Complimentary" : "User";
            }
        } else {
            access_level = "<UNKNOWN>";
        }
        if (statusCheck) {
            if (user.subscription.Finished !== null && new Date(user.subscription.Finished).getTime() < now()) {
                status = '(expired)';
            } else if (user.subscription && (isSubscriptionProvider(user.subscription.Provider) || isUsingWebcoin(user))) {
                if (user.subscription.CancelReasonCode == '999' ||
                    user.subscription.CancelReasonCode == '989' ||
                    user.subscription.CancelReasonCode == '988' ||
                    user.subscription.CancelReasonCode == '899' )
                {
                    status = '(canceled)';
                } else if (user.subscription.CancelReasonCode == '898') {
                    status = '(force billing)';
                }
            }
        }
        // add status link
        if (statusCheck) {
            return link(`/user/subscription/${user.subscription.ID}`, access_level + status);
        } else {
            return access_level + status;
        }
    },

        view() {
            let filters = m(`form.${css.filters}`, {
                onsubmit: this.filter_submit
            }, [
                m("div", [
                    m("span", "Email"),
                    m("label", [
                        m("input", {
                            type: 'checkbox',
                            name: 'email_look_before',
                            checked: (this.params().email_look_before() ? "checked" : ""),
                            onchange: m.withAttr("checked", this.params().email_look_before),
                        }),
                        m("span", " ← ..."),
                    ]),
                    m("input", {
                        type: 'text',
                        name: 'email',
                        onchange: m.withAttr("value", this.params().email),
                        value: this.params().email(),
                    }),
                    m("label", [
                        m("span", "... → "),
                        m("input", {
                            type: 'checkbox',
                            name: 'email_look_after',
                            checked: (this.params().email_look_after() ? "checked" : ""),
                            onchange: m.withAttr("checked", this.params().email_look_after),
                        }),
                    ]),
                ]),
                m("div", [
                    m("span", "Access"),
                    m("select[name=access_level]", {
                        onchange: m.withAttr("value", this.access_level),
                    }, [
                        m("option", { selected: !this.access_level() }, "Any"),
                        this.access_levels.map(level => {
                            return m(`option[value=${level.value}]`, { selected: this.access_level() == level.value }, level.label);
                        }),
                    ]),
                ]),
                m("div", [
                    m("span", "Status"),
                    m("label", [
                        m("input[type=radio][name=status]", {
                            checked: this.params().status() != "open" && this.params().status() != "closed" ? "checked" : "",
                            onchange: m.withAttr("value", this.params().status),
                        }),
                        m("span", "All"),
                    ]),
                    m("label", [
                        m("input[type=radio][name=status][value=open]", {
                            checked: this.params().status() == "open" ? "checked" : "",
                            onchange: m.withAttr("value", this.params().status),
                        }),
                        m("span", "Open"),
                    ]),
                    m("label", [
                        m("input[type=radio][name=status][value=closed]", {
                            checked: this.params().status() == "closed" ? "checked" : "",
                            onchange: m.withAttr("value", this.params().status),
                        }),
                        m("span", "Closed"),
                    ]),
                ]),
                m("div", [
                    m("span", "Cancel reason code"),
                    m("input[type=text][name=cancel_reason_code]", {
                        onchange: m.withAttr("value", this.params().cancel_reason_code),
                        value: this.params().cancel_reason_code(),
                    }),
                ]),
                m("div", [
                    m("span", "Created after"),
                    m("input[type=text][name=created_after]", {
                        onchange: m.withAttr("value", this.params().created_after),
                        value: this.params().created_after(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                ]),
                m("div", [
                    m("span", "Created before"),
                    m("input[type=text][name=created_before]", {
                        onchange: m.withAttr("value", this.params().created_before),
                        value: this.params().created_before(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                ]),
                m("div", [
                    m("span", "Unique identifier"),
                    m("input[type=text][name=unique_identifiers]", {
                        onchange: m.withAttr("value", this.params().unique_identifiers),
                        value: this.params().unique_identifiers(),
                    }),
                    m.trust("Separate,with,commas"),
                ]),
                m("div", [
                    m("span", "IP address"),
                    m("input[type=text][name=ip_addresses]", {
                        onchange: m.withAttr("value", this.params().ip_addresses),
                        value: this.params().ip_addresses(),
                    }),
                    m.trust("Separate,with,commas"),
                ]),
                m("div", [
                    m("span", "Payment methods"),
                    m("input[type=checkbox][id=with_payment_method]", {
                        checked: this.params().with_payment_method() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().with_payment_method),
                    }),
                    m("label[for=with_payment_method]", "Any"),
                    m("input[type=checkbox][id=softbank]", {
                        checked: this.params().softbank() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().softbank),
                    }),
                    m("label[for=softbank]", "SBPS"),
                    m("input[type=checkbox][id=anotherlane]", {
                        checked: this.params().anotherlane() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().anotherlane),
                    }),
                    m("label[for=anotherlane]", "AnotherLane"),
                    m("input[type=checkbox][id=webcoin]", {
                        checked: this.params().webcoin() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().webcoin),
                    }),
                    m("label[for=webcoin]", "Webcoin"),
                    m("input[type=checkbox][id=crypto]", {
                        checked: this.params().crypto() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().crypto),
                    }),
                    m("label[for=crypto]", "Crypto"),
                    m("input[type=checkbox][id=atone]", {
                        checked: this.params().atone() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().atone),
                    }),
                    m("label[for=atone]", "Atone"),
                    m("input[type=checkbox][id=atone_tsudo]", {
                        checked: this.params().atone_tsudo() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().atone_tsudo),
                    }),
                    m("label[for=atone_tsudo]", "Atone Tsudo"),
                    m("br"),
                    m("input[type=checkbox][id=bitcash]", {
                        checked: this.params().bitcash() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().bitcash),
                    }),
                    m("label[for=bitcash]", "BitCash"),
                    m("input[type=checkbox][id=paidy]", {
                        checked: this.params().paidy() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().paidy),
                    }),
                    m("label[for=paidy]", "Paidy"),
                    m("input[type=checkbox][id=maxconnect]", {
                        checked: this.params().maxconnect() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().maxconnect),
                    }),
                    m("label[for=maxconnect]", "Maxconnect"),
                    m("input[type=checkbox][id=access_code_use]", {
                        checked: this.params().access_code_use() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().access_code_use),
                    }),
                    m("label[for=access_code_use]", "AccessCode"),
                    (()=>{
                        if (this.params().payment_methods_search_type() === "or") {
                            return m("button", {
                                onclick: _ => { this.params().payment_methods_search_type("and") },
                                style: "margin: 0 10px 0 0; padding: 4px 10px; background-color: transparent; border: 1px solid #ef4274; color: #ef4274;",
                            }, "OR")
                        }
                        if (this.params().payment_methods_search_type() === "and") {
                            return m("button", {
                                onclick: _ => { this.params().payment_methods_search_type("only") },
                                style: "margin: 0 10px 0 0; padding: 4px 10px; background-color: transparent; border: 1px solid #ef4274; color: #ef4274;",
                            }, "AND")
                        }
                        return m("button", {
                            onclick: _ => { this.params().payment_methods_search_type("or") },
                            style: "margin: 0 10px 0 0; padding: 4px 10px; background-color: transparent; border: 1px solid #ef4274; color: #ef4274;",
                        }, "ONLY")
                    })(),
                ]),
                m("div", [
                    m("span", "Referral"),
                    m("input[type=text][name=referral]", {
                        onchange: m.withAttr("value", this.params().referral),
                        value: this.params().referral(),
                    }),
                    m("input[type=checkbox][id=referral_exact]", {
                        checked: this.params().referral_exact() === true ? "checked" : "",
                        onchange: m.withAttr("checked", this.params().referral_exact),
                    }),
                    m("label[for=referral_exact]", "Exact"),
                ]),
                m("div", [
                    m("span", "First access URL"),
                    m("input[type=text][name=register_url]", {
                        onchange: m.withAttr("value", this.params().register_url),
                        value: this.params().register_url(),
                    }),
                ]),
                m("div", [
                    m("span", "User ID"),
                    m("input[type=text][name=user_ids]", {
                        onchange: m.withAttr("value", this.params().user_ids),
                        value: this.params().user_ids(),
                    }),
                    m.trust("Separate,with,commas"),
                ]),
                m("div", [
                    m("span", m.trust("&nbsp;")),
                    m("input[type=submit][value=Apply filter]")
                ]),
            ]);

            const sortDisplayId = (this.vm.sort() == 'id_asc' ? ' ↑' : (this.vm.sort() == 'id_desc' ? ' ↓' : ''));
            const sortDisplayEmail = (this.vm.sort() == 'email_asc' ? ' ↑' : (this.vm.sort() == 'email_desc' ? ' ↓' : ''));
            const sortDisplayCreated = (this.vm.sort() == 'created_asc' ? ' ↑' : (this.vm.sort() == 'created_desc' ? ' ↓' : ''));
            const sortDisplayCancelled = (this.vm.sort() == 'cancelled_asc' ? ' ↑' : (this.vm.sort() == 'cancelled_desc' ? ' ↓' : ''));

            let header = m("thead", [
                m(`th.${css.sortable}`, {
                    onclick: _ => { this.changeSortMethod(this.vm.sort() == 'id_desc' ? 'id_asc' : 'id_desc'); }
                }, `ID${sortDisplayId}`),
                m("th", ""),
                m(`th.${css.sortable}`, {
                    onclick: _ => { this.changeSortMethod(this.vm.sort() == 'email_desc' ? 'email_asc' : 'email_desc'); }
                }, `Email${sortDisplayEmail}`),
                m(`th.${css.sortable}`, {
                    onclick: _ => { this.changeSortMethod(this.vm.sort() == 'created_desc' ? 'created_asc' : 'created_desc'); }
                }, `Created${sortDisplayCreated}`),
                m(`th.${css.sortable}`, {
                    onclick: _ => { this.changeSortMethod(this.vm.sort() == 'cancelled_desc' ? 'cancelled_asc' : 'cancelled_desc'); }
                }, `Cancelled${sortDisplayCancelled}`),
                m("th", "Status"),
                m("th", "CRC"),
                m("th", "Affiliate"),
                this.params().register_url() ? m("th", 'Register URL') : '',
                m("th", "PMs"),
                m("th", ["Store", m('br'), "Payment"]),
                m("th", ["Sub", m('br'), "Payment"]),
                m("th", ["AccessCode", m('br'), "Count"]),
                m("th", "Plus"),
            ]);
            const body = m("tbody", [
                this.vm.elements().map((user) => {
                    const userCategory = (() => {
                        if (user.access_level >= 10) {
                            return '.admin';
                        } else if (user.access_level === 8) {
                            return '.publisher';
                        } else if (user.access_level === 3) {
                            return '.business';
                        } else if (user.subscription) {
                            // XXX: when we have internal credit card provider,
                            // this won't work
                            if (isSubscriptionProvider(user.subscription.Provider) || isUsingWebcoin(user)) return '.credit';
                            if (!user.deleted && user.access == "registered") return '.prepaid';
                            return '.unknown';
                        }
                        return '';
                    })();

                    const userState = (() => {
                        if (user.deleted) {
                            return '.closed';
                        } else if (user.subscription) {
                            if (dayjs(user.subscription.Finished).subtract(9, 'hour').isBefore(dayjs())) {
                                return '.expired';
                            // FIXME: I wanted to set this condition to user.subscription.CancelReasonCode === '999'
                            // but I'm not very sure if I can.
                            } if (user.subscription && (isSubscriptionProvider(user.subscription.Provider) || isUsingWebcoin(user))) {
                                if (user.subscription.CancelReasonCode == '999' ||
                                    user.subscription.CancelReasonCode == '989' ||
                                    user.subscription.CancelReasonCode == '988' ||
                                    user.subscription.CancelReasonCode == '899' )
                                {
                                        return '.canceled';
                                }
                            }
                        }
                        return '';
                    })();

                    return m(`tr${userCategory}${userState}`, [
                        m("td", link(`/user/id/${user.id}`, user.id)),
                        m("td", m(`a`, { href: `/user/id/${user.id}/edit`, oncreate: m.route.link, onupdate: m.route.link }, "Edit")),
                        m("td", user.email),
                        m("td", dayjs(user.created).format('YYYY-MM-DD HH:mm')),
                        m("td", user.subscription && user.subscription.Finished != null ? dayjs(user.subscription.Finished).format('YYYY-MM-DD HH:mm') : null),
                        m("td", this.userStateView(user)),
                        m("td", user.subscription && user.subscription.CancelReasonCode),
                        m("td", user.referral && user.referral.Referral),
                        this.params().register_url() ? m("td", user.data.register_url ? user.data.register_url.replace(/(https:\/\/|http:\/\/)/, '') : '') : '',
                        m("td", user.payment_methods && user.payment_methods.map(pm => pm.Provider).join(",")),
                        m("td", user.store_payment),
                        m("td", user.subscription_payment),
                        m("td", user.access_codes && user.access_codes.length),
                        m("td", user.subscription && user.subscription.ChargesCount > 0 ? "Plus" : ""),
                    ])
                })
            ]);

            return m("div", [
                m("h1", "Users"),
                filters,
                this.vm.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m(`table`, [
                        header,
                        body
                    ]),
                // this.params().searching() ? null : m(pagination, { vm: this.vm, params: this.params }),
                m(pagination, { vm: this.vm, params: this.params }),
                m("p",`約${(this.vm.totalPages() + 1)  * this.vm.perPage()}件`),
                m("button", { onclick: () => {this.csvDownload()} }, "List Download"),
            ]);
        },
    }
};
