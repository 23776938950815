"use strict";

import m from "mithril";
import { getUserPoints, postUserPoints } from "../../api-client";
import { parseIntForInput } from "../../utils/type-cast";

const model = {
    user_id: 0,
    points: 0,
    points_to_add: 0,
    loading: false,

    load(id) {
        this.user_id = id;
        this.loading = true;
        getUserPoints(id)
            .then((res) => {
                this.points = res.store_credits;
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                this.loading = false;
                m.redraw();
            });
    },

    give() {
        postUserPoints(this.user_id, this.points_to_add)
            .then(() => {
                this.load(this.user_id);
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },
};

export const pointEditor = {
    oninit({ attrs }) {
        model.load(attrs.id);
    },

    view() {
        if (model.loading) {
            return [m("hr"), m("h2", "Store Credits"), m("div", "Loading...")];
        }

        return [
            m("hr"),
            m("h2", "Store Credits"),
            m("div", [m("label", "Current Store Credits"), m("span", model.points)]),
            m(
                "div",
                m("input", {
                    onchange: (e) => {
                        model.points_to_add = parseIntForInput(e.target.value);
                    },
                    value: model.points_to_add || "",
                }),
                m(
                    "button",
                    {
                        onclick: () => {
                            model.give();
                        },
                    },
                    "Give Store Credits",
                ),
            ),
        ];
    },
};
