"use strict";

import m from "mithril";
import { getTasks } from "../../api-client";

const MAX_VIEW_DATE = 100;

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            this.loading = m.prop(true);
            this.tasks = m.prop([]);
            this.error = m.prop("");
            this.load();
        },

        load() {
            getTasks(MAX_VIEW_DATE)
                .then(res => {
                    this.loading(false);
                    const tasks = res.results || [];
                    this.tasks(tasks);
                    m.redraw();
                    console.log(res.results);
                })
                .catch(error => this.error(error));
        },

        view() {
            if (this.error() !== "") {
                return m("div", [m("h1", "Error"), m("p", this.error())]);
            }

            if (this.loading()) {
                return m("h1", "Loading");
            }

            const scheduleList = [];
            const today = new Date();
            let date = "";
            let task;
            for (let i = 0; i < MAX_VIEW_DATE; i += 1) {
                today.setDate(today.getDate() + 1);
                date = today.getFullYear().toString().substr(2,2) + ("0" + (today.getMonth() + 1)).slice(-2) + ("0" + today.getDate()).slice(-2);
                task = this.tasks().filter(function(e) { return e.DateJst == date; }).pop();
                if (typeof task === "undefined") {
                    scheduleList.push({
                        date: date,
                        id: "",
                        time: "",
                        type: "billing",
                        status: "auto",
                    });
                } else {
                    scheduleList.push({
                        date: date,
                        id: task.Id,
                        time: ("0" + task.TimeJst).slice(-2),
                        type: task.Type,
                        status: task.Status,
                    });
                }
            }

            return m("div", [
                m("h1", "Tasks"),
                m("table", [
                    m("tr", [
                        m("th", "DATE"),
                        m("th", "TIME"),
                        m("th", "TYPE"),
                        m("th", "STATUS"),
                        m("th", ""),
                    ]),
                    scheduleList.map(schedule =>
                        m("tr", [
                            m("td", schedule.date.substr(0, 2) + "." + schedule.date.substr(2, 2) + "." + schedule.date.substr(4, 2)),
                            m("td", schedule.time === "" ? "05:00" : schedule.time + ":00"),
                            m("td", schedule.type),
                            m("td", schedule.status),
                            m("td", [
                                m(schedule.id === "" ? `a[href=/task/create?date=${schedule.date}]` : `a[href=/task/${schedule.id}/edit]`, {oncreate: m.route.link, onupdate: m.route.link}, "Edit"),
                            ]),
                        ]),
                    ),
                ]),
            ]);
        },
    },
};
