"use strict";

import List from "./list";
import Create from "./create";
import Edit from "./edit";
import View from "./view";
import SubscriptionView from "../subscriptions/view";

export default {
    "create": Create,
    "list": List,
    "id/:id": View,
    "id/:id/edit": Edit,
    "email/:email": View,
    "email/:email/edit": Edit,
    "subscription/:id": SubscriptionView,
};
