exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2HGwKd1yq-rXSOBSZ9fCoY {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 0% 3%;\n}\n\n.cowz0dWWAcQqZ0RChcUYQ {\n    display: inline-block;\n    position: relative;\n    margin: 0 1em;\n    box-shadow: 0 1px 10px rgba(0,0,0,.25);\n}\n\n._3GCrGK0WjfNW6EM0LVen3H {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    opacity: 0;\n    bottom: 0;\n}\n._3GCrGK0WjfNW6EM0LVen3H:hover {\n    opacity: 100%;\n}\n\n._1len_nyIIoXSRlIoSkSBj1 {\n    margin-left: 0em;\n}\n\n._1MqrdxlZf96TZSUD2uWDms {\n    color: #ff0000\n}\n._2cemcnhd9S_G3511iGU_yt {\n    display: inline-block;\n    position: relative;\n    margin-right: 0.5em;\n    padding: 0.5em 1em;\n    border-radius: 0.25em;\n    color: #fff;\n    background: #ef4274;\n    margin-left: 8em;\n    margin-top: 1em;\n}", ""]);

// exports
exports.locals = {
	"attributeSection": "_2HGwKd1yq-rXSOBSZ9fCoY",
	"imgContainer": "cowz0dWWAcQqZ0RChcUYQ",
	"imgAddButton": "_3GCrGK0WjfNW6EM0LVen3H",
	"delete": "_1len_nyIIoXSRlIoSkSBj1",
	"warn": "_1MqrdxlZf96TZSUD2uWDms",
	"gotostorepage": "_2cemcnhd9S_G3511iGU_yt"
};