import m from "mithril";
import { getAttribute, getContent, getContentSearch, patchContent } from "../../api-client";

const model = {
    visible: false,
    contentID: null,

    onSetTitle: () => { alert("usage: (title: string, title_kana: string) => {}") },
    onSetAttributeIDs: () => { alert("usage: (attribute_ids: []number) => {}") },

    init() {
        this.visible = false;
        this.contentID = null;
    },

    set(attrs) {
        this.visible = attrs.visible;
        this.contentID = attrs.contentID;
        this.onSetTitle = attrs.setTitle;
        this.onSetAttributeIDs = attrs.setAttributeIDs;
    },

    selectPreset(preset) {
        switch (preset) {
        case "cover":
            this.onSetTitle("表紙", "ひょうし");
        break;
        case "editorial":
            this.onSetTitle("あとがき", "あとがき");
        break;
        case "firstpublished":
            this.onSetTitle("初出一覧", "しょしゅついちらん");
        break;
        case "backcover":
            this.onSetTitle("裏表紙", "うらびょうし");
        break;
        default:
            this.onSetTitle("", "");
        }

        switch (preset) {
        case "cover":
            Promise.all([
                getAttribute("hyoshi")
                    .then((res) => {
                        return [res.attribute.id];
                    }),
                this.getContentArtist(),
            ]).then((res) => {
                this.onSetAttributeIDs(res.flat());
            }).catch((err) => {
                console.error(err);
            });
        break;
        case "editorial":
        case "firstpublished":
        case "backcover":
            Promise.all([
                getAttribute("hidden_table_of_content")
                    .then((res) => {
                        return [res.attribute.id];
                    }),
                this.getContentArtist(),
            ]).then((res) => {
                this.onSetAttributeIDs(res.flat());
            }).catch((err) => {
                console.error(err);
            });
        break;
        default:
            this.onSetAttributeIDs([]);
        }
    },

    getContentArtist() {
        if (!this.contentID) {
            return Promise.resolve([]);
        }

        return getContent(this.contentID)
            .then((res) => {
                return res.content.attributes.artists.children.map(a => a.id);
            })
            .catch((err) => {
                console.error(err);
                return [];
            })
    },

    shouldDisplay() {
        return this.visible && this.contentID;
    },
}

export const PresetSelector = {
    oninit: ({ attrs }) => {
        model.init();
        model.set(attrs);
    },
    onupdate: ({ attrs }) => {
        model.set(attrs);
    },
    view: () => {
        if (!model.shouldDisplay()) {
            return null;
        }

        return m("div", 
            m("label", { for: "preset" }, "Preset"),
            m("select", {
                name: "preset",
                onchange: (e) => { model.selectPreset(e.currentTarget.value) },
            }, [
                m("option", { value: "" }, ""),
                m("option", { value: "cover" }, "表紙"),
                m("option", { value: "editorial" }, "あとがき"),
                m("option", { value: "firstpublished" }, "初出一覧"),
                m("option", { value: "backcover" }, "裏表紙"),
            ]),
        );
    },
}