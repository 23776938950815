"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import {
    getEmailTemplates
} from "../../api-client";

class Model {
    constructor() {
        this.page = m.prop(0);
        this.perPage = m.prop(20);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getEmailTemplates(this.page(), this.perPage()).then(
            res => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }
}

export default {
    $navTitle: "Templates",
    $component: {
        oninit(vnode) {
            this.vm = new Model();

            this.vm.load(0);
        },

        view() {
            return m("div", [
                m("h1", "Email templates"),
                this.vm.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("table", [
                        m("thead", [
                            m("th", "ID"),
                            m("th", ""),
                            m("th", "Edit"),
                            m("th", "Del"),
                            m("th", "Subject"),
                            m("th", "Deliveries"),
                        ]),
                        this.vm.elements().map(e => {
                            return m("tr", [
                                m("td", e.Id),
                                m("td", m("a", { href: `/email/template/${e.Id}/edit`, oncreate: m.route.link }, "Edit")),
                                m("td", e.LockEdit ? "🔒" : ""),
                                m("td", e.LockDelete ? "🔒" : ""),
                                m("td", e.Subject),
                                m("td", e.SentCount.toLocaleString()),
                            ]);
                        }),
                    ]),
                m(pagination, { vm: this.vm }),
            ]);
        }
    }
};
