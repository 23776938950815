"use strict";

import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import css from "./editor.css";
import {
    createSurvey,
    patchSurvey,
    getSurvey,
    deleteSurvey,
    createSurveyQuestion,
    patchSurveyQuestion,
    deleteSurveyQuestion,
} from "../../api-client";

class Model {
    constructor(id) {
        this.survey = m.prop({});

        this.title      = m.prop("");
        this.start_date = m.prop("");
        this.end_date   = m.prop("");
        this.listed     = m.prop(false);
        this.questions  = m.prop([])

        this.onupdate(id);
    }

    onupdate(id) {
        id = parseInt(id, 10);

        if (isFinite(id) && this.id != id) {
            this.id = id;
            this.refresh();
        }
    }

    refresh() {
        getSurvey(this.id).then(
            (res) => {
                this.survey(res.survey);
                this.title(res.survey.data.title);
                this.start_date(res.survey.start_date);
                this.end_date(res.survey.end_date);
                this.listed(res.survey.listed);
                this.questions([]);

                res.survey.questions.forEach(q => {
                    let options = [];

                    if (q.options.list) {
                        for (let i = 0; i < q.options.list.length; ++i) {
                            const o = q.options.list[i];

                            options.push({
                                key: m.prop(o.key),
                                value: m.prop(o.value),
                            });
                        }
                    }

                    this.questions().push({
                        id: m.prop(q.id),
                        answer: m.prop(q.answer),
                        title: m.prop(q.data.title),
                        type: m.prop(q.data.type),
                        options: m.prop(options),
                        deleted: m.prop(0),
                    })
                })

                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    data() {
        return {
            title: this.title(),
        };
    }

    save() {
        const start_date = new Date(this.start_date()).toJSON();
        const end_date = new Date(this.end_date()).toJSON();

        if (this.survey().id) {
            patchSurvey(this.survey().id, {
                start_date: start_date,
                end_date: end_date,
                listed: !!this.listed(),
                data: this.data()
            }).then(
                (res) => {
                    m.redraw();
                    alert("Save successful");
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        } else {
            createSurvey(this.data(), start_date, end_date, !!this.listed()).then(
                (res) => {
                    const id = res.survey.Id;
                    m.route.set(`/surveys/id/${id}/edit`);
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        }

        return false;
    }
};

export default {
    oninit(vnode) {
        this.vm = new Model();
        this.vm = new Model(vnode.attrs.id);
    },

    onupdate(vnode) {
        this.vm.onupdate(vnode.attrs.id);
    },

    view() {
        let i = -1;

        return !this.vm.survey()
            ? null
            : [
                m("form", { onsubmit : this.vm.save.bind(this.vm) }, [
                    m("h1", this.vm.survey().id ? "Edit Survey" : "New survey"),
                    m("div", [
                        m("label[for=name]", "Name"),
                        m("input[name=name]", {
                            onchange: m.withAttr("value", this.vm.title),
                            value: this.vm.title(),
                        })
                    ]),
                    m("div", [
                        m("label[for=start_date]", "Start date"),
                        m("input[type=text][name=start_date][autocomplete=off]", {
                            onchange: m.withAttr("value", this.vm.start_date),
                            value: this.vm.start_date(),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        })
                    ]),
                    m("div", [
                        m("label[for=end_date]", "End date"),
                        m("input[type=text][name=end_date][autocomplete=off]", {
                            onchange: m.withAttr("value", this.vm.end_date),
                            value: this.vm.end_date(),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        })
                    ]),
                    m("div", [
                        m("label[for=listed]", "Visibility"),
                        m("input[name=listed][type=checkbox]", {
                            checked: this.vm.listed() == true ? "checked" : "",
                            onchange: m.withAttr("checked", this.vm.listed),
                        }),
                        m("label[for=listed]", "Show in survey listings"),
                    ]),
                    m("div", [
                        m("button[type=submit]", this.vm.survey().id ? "Save" : "Create"),
                    ]),
                ]),
                !this.vm.survey().id
                    ? null
                    : m(allQuestions, {
                        survey: this.vm
                    })
            ];
    }
};

const allQuestions = {
    handle(vnode) {
        this.survey = vnode.attrs.survey;

        if (!this.survey) {
            return console.error("Survey question editor initialized without survey");
        }

        this.questions = m.prop(this.survey.questions());

        this.i = 0;
    },

    oninit(vnode) {
        this.handle(vnode);
    },

    addQuestion() {
        this.questions().push({
            id: m.prop(`_${this.i}`),
            answer: m.prop(),
            title: m.prop(""),
            type: m.prop(""),
            options: m.prop([]),
            deleted: m.prop(0),
        });

        ++this.i;

        m.redraw();

        return false;
    },

    save() {
        const totalQuestions = this.questions().length;
        let questionsRemaining = totalQuestions;

        for (let i = 0; i < totalQuestions; ++i) {
            const q = this.questions()[i];

            // XXX: For ordinality, we don't need to care about deleted questions

            if (q.deleted() > 0) {
                if (q.id() && q.deleted() == 1) {
                    deleteSurveyQuestion(
                            this.survey.id,
                            q.id()
                        )
                        .then(
                            res => {
                                q.deleted(2);
                            },
                            err => {
                                console.error(err);
                                alert(JSON.stringify(err));
                            }
                        )
                        .finally(_ => {
                            questionsRemaining--;

                            if (questionsRemaining == 0) {
                                alert("Save successful")
                            }
                        });
                } else {
                    questionsRemaining--;

                    if (questionsRemaining == 0) {
                        alert("Save successful")
                    }
                }
            } else if ((q.id() + "").substring(0,1) == '_') {
                createSurveyQuestion(
                        this.survey.id,
                        i + 1,
                        {
                            title: q.title(),
                            type: q.type(),
                        },
                        q.options().length > 0
                            ? {
                                list: q.options(),
                            }
                            : {}
                    )
                    .then(
                        res => {
                            q.id(res.survey_question.Id);
                        },
                        err => {
                            console.error(err);
                            alert(JSON.stringify(err));
                        }
                    )
                    .finally(_ => {
                        questionsRemaining--;

                        if (questionsRemaining == 0) {
                            alert("Save successful")
                        }
                    });
            } else {
                // TODO: only PUT if question has actually changed
                patchSurveyQuestion(
                    this.survey.id,
                    q.id(),
                    i + 1,
                    {
                        title: q.title(),
                        type: q.type(),
                    },
                    q.options().length > 0
                        ? {
                            list: q.options(),
                        }
                        : {}
                )
                .then(
                    res => {},
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    }
                )
                .finally(_ => {
                    questionsRemaining--;

                    if (questionsRemaining == 0) {
                        alert("Save successful");
                    }
                })
            }
        }

        return false;
    },

    reorderQuestions(question, direction) {
        const questions = [];

        for (let i = 0; i < this.questions().length; ++i) {
            if (direction == 'down' && i == question || direction == 'up' && i+1 == question) {
                ++i;
                questions.push(this.questions()[i]);
                questions.push(this.questions()[i-1]);
            } else {
                questions.push(this.questions()[i]);
            }
        }

        this.questions(questions);

        m.redraw();
    },

    view() {
        let i = 0;

        return m("form", { onsubmit : this.save.bind(this) }, [
            m('hr'),
            m("h2", "Questions"),
            m("p", [
                m("input[type=submit][value='Save changes to questions']")
            ]),
            this.questions().length < 1
                ? m("p", "(None)")
                : this.questions().map(q => {
                    ++i;

                    return m(questionEditor, {
                        question: q,
                        i: i,
                        questions: this.questions,
                        reorderQuestions: this.reorderQuestions,
                    })
                }),
            m("p", [
                m("button", { onclick: _ => { this.addQuestion(); return false; } }, "Add new question")
            ]),
        ]);
    }
}

let questionEditor = {
    handle(vnode) {
        this.question = vnode.attrs.question;
        this.i = vnode.attrs.i;

        this.questions = vnode.attrs.questions;
        this.total_questions = this.questions().length;
        this.reorderQuestions = vnode.attrs.reorderQuestions;
    },

    oninit(vnode) {
        this.handle(vnode);
    },

    onupdate(vnode) {
        this.handle(vnode);
    },

    deleteOption(e) {
        const k = e.target.getAttribute("optionkey");

        const options = [];

        for (let i = 0; i < this.question.options().length; ++i) {
            if (this.question.options()[i].key != k) {
                options.push(this.question.options()[i])
            }
        }

        this.question.options(options);
        m.redraw();

        return false;
    },

    addOption() {
        this.question.options().push({
            key: m.prop(""),
            value: m.prop(""),
        });

        return false;
    },

    moveQuestion(e) {
        const question = parseInt(e.target.getAttribute("question")) - 1;
        const direction = e.target.getAttribute("direction");

        this.reorderQuestions(question, direction);

        return false;
    },

    deleteQuestion() {
        this.question.deleted(1);

        return false;
    },

    view() {
        let i = 0;

        if (this.question.deleted() == 2) {
            return null;
        }

        return m(`.${css.question}` + (this.question.deleted() > 0 ? `.${css.deleted}` : ''), [
            this.question.deleted() > 0
                ? m("div", [
                    m("p", "Question deleted. Save changes to confirm.")
                ])
                : [
                    m("div", [
                        m(`button[question=${this.i}]`, { onclick: this.deleteQuestion.bind(this) }, "x"),
                        this.i > 1 ? m(`button[direction=up][question=${this.i}]`, { onclick: this.moveQuestion.bind(this) }, "▲") : null,
                        this.i != this.total_questions ? m(`button[direction=down][question=${this.i}]`, { onclick: this.moveQuestion.bind(this) }, "▼") : null,
                        m("h3", `Question ${this.i}`),
                    ]),
                    m("div", [
                        m(`input[name=question_title_${this.question.id()}][placeholder='Title']`, {
                            onchange: m.withAttr("value", this.question.title),
                            value: this.question.title(),
                        }),
                    ]),
                    m("div", [
                        m("label", "Type:"),
                        m(`select[name=question_type_${this.question.id()}]`,
                            {
                                onchange: m.withAttr("value", this.question.type),
                                value: this.question.type(),
                            },
                            [
                                m("option", {
                                    value: "",
                                    selected: this.question.type() == "" ? "selected" : undefined,
                                }, "Free text"),
                                m("option", {
                                    value: "single-choice",
                                    selected: this.question.type() == "single-choice" ? "selected" : undefined,
                                }, "Single choice"),
                                m("option", {
                                    value: "multiple-choice",
                                    selected: this.question.type() == "multiple-choice" ? "selected" : undefined,
                                }, "Multiple choice"),
                            ]
                        ),
                    ]),
                    !this.question.type() || this.question.type() == ""
                        ? null
                        : [
                            // m("p", "Options:"),
                            this.question.options().map(o => {
                                ++i;

                                return m(`.${css.option}`, [
                                    m("strong", i),
                                    m(`input`, {
                                        placeholder: "Reference key",
                                        onchange: m.withAttr("value", o.key),
                                        value: o.key,
                                    }),
                                    m(`input`, {
                                        placeholder: "User-friendly description",
                                        onchange: m.withAttr("value", o.value),
                                        value: o.value,
                                    }),
                                    m(`button[optionkey='${o.key}'].${css.delete}`, { onclick: this.deleteOption.bind(this) }, "x")
                                ]);
                            }),
                            m("p", [
                                m("button", { onclick: this.addOption.bind(this) }, `Add option ${i+1}`)
                            ]),
                        ],
                ]
        ]);
    }
}
