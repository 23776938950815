import m from "mithril";
// For some reason, the date format is broken when this is declared.
// import Pikaday from "pikaday";
import config from "../../config";
import {
    getItem,
    updateItem,
    linkContentsToItem,
    deleteItem,
    getAdminAutoAttributes,
    linkAttributesToItem,
    getItemAttributes,
    getItemContents,
    getContent,
} from "../../api-client";
import dayjs from "dayjs";
import css from "./edit.css";
import { timeSelector } from "../../utils/input-component";
import { DownloadContentComponent } from "./edit_downloads";

import { objExistence } from "../../utils/helper";
import { parseIntForInput } from "../../utils/type-cast";
import { AttributeComponent } from "./edit_attributes";

const ItemTypes = ["komiplus", "store", "bolton", "subscription"];

/**
 * @typedef {object} Attribute
 * @prop {string} id Attribute ID
 * @prop {string} slug Attribute slug
 * @prop {string} weight Attribute weight
 * @prop {Object[]} candidates
 * @prop {bool} is_artist
 */

export default {
    $component: {
        oninit(vnode) {
            this.id = m.prop(vnode.attrs.id);
            this.error = m.prop("");

            // Edit Item field
            this.name = m.prop("");
            this.prePublished = m.prop(null);
            this.published = m.prop(null);
            this.publishedTime = m.prop("0:00");
            this.type = m.prop("");
            this.quantity = m.prop("");
            this.expiry = m.prop(null);
            this.quantity_total = m.prop("");
            this.unlimited = m.prop(false);
            this.max_per_user = 1;
            this.public = m.prop(false);
            this.total_value_pre_tax = "";
            this.commission_value_pre_tax = "";
            this.prtext = m.prop("");

            // Child contents field
            this.contentId = m.prop("");
            this.contents = m.prop([]);

            // Danger Zone
            this.verifying = m.prop(false);
            this.confirmationName = m.prop("");

            this.loading = m.prop(true);

            this.initialLoad();
        },

        initialLoad() {
            this.load();
            this.loadContents();
        },

        load() {
            getItem(this.id())
                .then((res) => {
                    const item = res.shop_item;

                    this.name(item.name);
                    this.prePublished(item.pre_published ? new Date(item.pre_published) : null);
                    this.published(item.published ? new Date(item.published) : null);
                    this.publishedTime(item.published ? new Date(item.published).getHours() + ":00" : "0:00");
                    this.quantity_total(String(item.quantity_total || ""));
                    this.unlimited(!item.quantity_total);
                    this.type(item.type);
                    this.expiry(item.expiry_date ? new Date(item.expiry_date) : null);
                    this.max_per_user = item.user_max_qty;
                    this.public(item.public);
                    this.total_value_pre_tax = item.total_value_pre_tax;
                    this.commission_value_pre_tax = item.commission_value_pre_tax;
                    this.prtext(item.data && item.data.prtext ? item.data.prtext : "");
                    this.error("");
                    m.redraw();
                    this.loading(false);
                })
                .catch((err) => {
                    this.error(err.error);
                    m.redraw();
                    this.loading(false);
                });
        },

        loadContents() {
            this.loading(true);
            this.contents([]);
            getItemContents(this.id())
                .then((res) => {
                    console.log(res);
                    // Get their contents information to get Artist information
                    const tasks = res.contents.reduce(
                        (acc, content) =>
                            acc.then(() =>
                                getContent(content.id).then((renc) => {
                                    this.contents([...this.contents(), renc.content]);
                                }),
                            ),
                        Promise.resolve(),
                    );

                    tasks.then(() => m.redraw());
                })
                .catch((err) => {
                    if (err.error === "no_such_object") {
                        return;
                    }
                    alert(err);
                });
        },

        onupdate(vnode) {
            if (vnode.attrs.id !== this.id()) {
                this.id(vnode.attrs.id);
                this.loading(true);
                m.redraw();
                this.initialLoad();
            }
        },

        submit(e) {
            e.preventDefault();

            this.quantity_total(this.unlimited() ? "" : this.quantity_total());

            const published = this.published() ? dayjs(this.published() + " " + this.publishedTime()).toISOString() : "";

            const expiry = this.expiry() ? dayjs(this.expiry()).toISOString() : "";

            const prepublished = this.prePublished() ? dayjs(this.prePublished()).toISOString() : "";

            const params = {
                expiry,
                pre_published: prepublished,
                published,
                name: this.name(),
                type: this.type(),
                public: this.public(),
                quantity_total: parseInt(this.quantity_total(), 10),
                max_per_user: this.max_per_user,
                total_value_pre_tax: this.total_value_pre_tax,
                commission_value_pre_tax: this.commission_value_pre_tax,
                data: {
                    prtext: this.prtext(),
                },
            };

            updateItem(this.id(), params)
                .then(() => {
                    alert("Successfully updated this item");
                    this.initialLoad();
                })
                .catch((err) => {
                    alert(err);
                });
        },

        submitChildContent(e) {
            e.preventDefault();

            const contentIds = [...this.contents().map((content) => content.id), parseInt(this.contentId(), 10)];

            linkContentsToItem(this.id(), contentIds)
                .then(() => {
                    this.initialLoad();
                })
                .catch((err) => {
                    alert(err);
                });
        },

        deleteContent(index) {
            // Remove target content
            const contents = this.contents().filter((_, i) => index !== i);

            const contentIds = contents.map((content) => content.id);
            linkContentsToItem(this.id(), contentIds)
                .then(() => {
                    this.initialLoad();
                })
                .catch((err) => {
                    alert(err);
                });
        },

        delete() {
            if (this.verifying()) {
                deleteItem(this.id())
                    .then(() => {
                        alert("Successfully deleted this item");
                        m.route.set("/items/list");
                    })
                    .catch((err) => {
                        alert(err);
                    });
            } else {
                this.verifying(true);
            }
        },

        view() {

            // If the item has contents that have premium: false, Display warning
            const warnIds = this.contents()
                .filter((content) => !content.premium)
                .map((content) => content.id);
            const shouldDisplayWarn = warnIds.length !== 0 && this.type === "komiplus";

            if (this.error() !== "") {
                return m("h2", "Error", m("div", this.error()));
            }

            if (this.loading()) {
                return m("div", m(".loading", [m(".load"), m("p", "Loading...")]));
            }

            return m("div", [
                shouldDisplayWarn
                    ? m("div", { class: css.warn }, [
                          m("h2", "WARNING"),
                          m(
                              "ul",
                              warnIds.map((id) => m("li", `The content that has id ${id} does not have premium: true flag`)),
                          ),
                      ])
                    : null,
                m("form", { onsubmit: (e) => this.submit(e) }, [
                    m("h2", "Edit Item"),

                    m("div", [
                        m("label", "Title"),
                        m("input[type=text][required][name=title]", {
                            oninput: m.withAttr("value", this.name),
                            value: this.name(),
                        }),
                    ]),

                    m("div", [
                        m("label", "Type"),
                        m(
                            "select",
                            {
                                onchange: m.withAttr("value", this.type),
                            },
                            ItemTypes.map((itemType) =>
                                m(
                                    "option",
                                    {
                                        selected: this.type() === itemType,
                                        value: itemType,
                                    },
                                    itemType,
                                ),
                            ),
                        ),
                    ]),
                    m("div", [
                        m("label", "Pre Release date"),
                        m("input[type=text]", {
                            onchange: m.withAttr("value", this.prePublished),
                            value: this.prePublished(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Release date"),
                        m("input[type=text]", {
                            onchange: m.withAttr("value", this.published),
                            value: this.published(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                        "　",
                        ...timeSelector(this.publishedTime, this.publishedTime()),
                    ]),

                    m("div", [
                        m("label", "Expiry date"),
                        m("input[type=text]", {
                            onchange: m.withAttr("value", this.expiry),
                            value: this.expiry(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Public"),
                        m("input[type=checkbox]", {
                            checked: this.public(),
                            onchange: m.withAttr("checked", this.public),
                        }),
                    ]),
                    m("div", [
                        m("label", "Price"),
                        m("label", { style: "display:inline-block; width: 200px;" }, "Total value pre tax"),
                        m("input[type=text]", {
                            oninput: (e) => {
                                this.total_value_pre_tax = parseIntForInput(e.target.value);
                            },
                            value: this.total_value_pre_tax,
                        }),
                    ]),
                    m("div", [
                        m("label", ""),
                        m("label", { style: "display:inline-block; width: 200px;" }, "Commission value pre tax"),
                        m("input[type=text]", {
                            oninput: (e) => {
                                this.commission_value_pre_tax = parseIntForInput(e.target.value);
                            },
                            value: this.commission_value_pre_tax,
                        }),
                    ]),
                    m("div", [
                        m("label", "Quantity"),
                        m("input[type=text]", {
                            oninput: m.withAttr("value", this.quantity_total),
                            value: this.quantity_total(),
                            disabled: this.unlimited(),
                        }),
                        m("label", [
                            m("input[type=checkbox]", {
                                style: { "margin-left": "1em" },
                                checked: this.unlimited(),
                                onchange: m.withAttr("checked", this.unlimited),
                            }),
                            "Unlimited",
                        ]),
                    ]),
                    m("div", [
                        m("label", "Max per user"),
                        m("input[type=text]", {
                            oninput: (e) => {
                                this.max_per_user = parseIntForInput(e.target.value) || 1;
                            },
                            value: this.max_per_user,
                        }),
                    ]),
                    m("div", [
                        m("label", "PR text"),
                        m("textarea", {
                            oninput: m.withAttr("value", this.prtext),
                            value: this.prtext(),
                        }),
                    ]),
                    m("div", [m("button[type=submit]", "Save")]),
                ]),
                this.type() === "store"
                    ? m("div", [
                        m(
                            `a.${css.gotostorepage}`,
                            {
                                href: `https://store.${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/item/${this.id()}/`,
                                  target: "_blank",
                              },
                            "View in store page",
                        ),
                      ])
                    : "",
                m("div", [
                    m("hr"),

                    m("h2", "Child contents"),

                    m("form", { onsubmit: (e) => this.submitChildContent(e) }, [
                        m("h3", "Add new child by content ID"),
                        m("div", [
                            m("label[for='new_content']", "Content ID"),
                            m("input[name='new_content'][type='text']", {
                                value: this.contentId(),
                                oninput: m.withAttr("value", this.contentId),
                            }),
                        ]),
                        m("div", m("button[type='submit']", "Save")),
                    ]),

                    m("table", [
                        m("tr", [m("th", "Name"), m("th", "Artist"), m("th", "Type"), m("th", "Remove")]),
                        this.contents().map((content, i) =>
                            m("tr", [
                                m(
                                    "td",
                                    m(
                                        "a",
                                        {
                                            href: `/content/${content.id}/edit`,
                                            oncreate: m.route.link,
                                            onupdate: m.route.link,
                                        },
                                        `#${content.id} ${content.data.title}`,
                                    ),
                                ),
                                m("td", objExistence(content, "attributes.artists.children.0.data.name")),
                                m("td", content.type),
                                m(
                                    "td",
                                    m(
                                        "button",
                                        {
                                            onclick: () => this.deleteContent(i),
                                            style: { "margin-left": "0.5em" },
                                        },
                                        "x",
                                    ),
                                ),
                            ]),
                        ),
                    ]),
                ]),
                m("hr"),
                m(AttributeComponent, {
                    id: this.id(),
                }),
                m("hr"),
                m(DownloadContentComponent, {
                    id: this.id(),
                    name: this.name(),
                }),
                m("div", [
                    m("hr"),
                    m("h2", "Danger Zone"),
                    this.verifying()
                        ? m("div", [
                              m("storong", "Are you absolutely sure? This action cannot be undone."),
                              m("p", "Please type in the name of the item to confirm."),
                              m("input[type=text]", {
                                  value: this.confirmationName(),
                                  oninput: m.withAttr("value", this.confirmationName),
                              }),
                          ])
                        : null,
                    m("button", { onclick: () => this.delete() }, "Delete This Item"),
                ]),
            ]);
        },
    },
};
