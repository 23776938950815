"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import css from "../../global.css";
import {
    getItem,
    getShopItemList,
    putShopItemList,
    putShopItemListItems,
    delShopItemListItem,
    delShopItemListItemAll,
    patchShopItemListOrder,
} from "../../api-client";
import config from "../../config";

const metaEditor = {
    oninit(vnode) {
        const c = vnode.attrs.itemList;
        if (!c()) {
            return console.error("Collection metadata editor initialized without collection");
        }

        this.id = c().id;
        this.name = m.prop(c().name || "");
        this.attr_id = m.prop(c().attr_id || "");
        this.explanation = m.prop(c().explanation || "");
        this.expiry = m.prop(c().expiry ? new Date(c().expiry) : null);

        this.list_type = m.prop(c().list_type);

        return this;
    },
    submit(e) {
        e.preventDefault();
        const expiry = this.expiry() ? new Date(this.expiry()).toJSON() : null;
        putShopItemList(this.id, this.name(), this.explanation(), expiry, isNaN(+this.attr_id()) ? 0 : +this.attr_id()).then(
            res => {
                window.location.reload();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
        return false;
    },
    view() {
        return m("form", { onsubmit: (e) => { this.submit(e) }}, [
            m("h1", "Edit Item List"),
            m("div", [m("label", "ID"), m("span", this.id)]),
            m("div", [
                m("label[for=name]", "Title"),
                m("input[type=text][name=name]", {
                    value: this.name(),
                    onchange: m.withAttr("value", this.name),
                }),
            ]),
            m("div", [
                m("label[for=explanation]", "Subtitle"),
                m("input[type=text][name=explanation]", {
                    onchange: m.withAttr("value", this.explanation),
                    value: this.explanation(),
                })
            ]),
            m("div", [
                m("label[for=expiry]", "Expiry date"),
                m("input[type=text][name=expiry][autocomplete=off]", {
                    onchange: m.withAttr("value", this.expiry),
                    value: this.expiry(),
                    oncreate: function(vnode) {
                        this.pikaday = new Pikaday({
                            field: vnode.dom,
                        });
                    },
                    onremove: function() {
                        this.pikaday.destroy();
                    },
                }),
            ]),
            m("div", [
                m("label[for=attr_id]", "Attribute ID"),
                m("input[type=text][name=attr_id]", {
                    value: this.attr_id(),
                    onchange: m.withAttr("value", this.attr_id),
                }),
                this.attr_id()
                    ? m(
                        "button",
                        {
                            onclick: e => {
                                e.preventDefault();
                                window.open(`#!/attribute/${this.attr_id()}/edit`);
                            },
                            style: "margin-left: 1em;",
                        },
                        "attribute page",
                    )
                    : '',
            ]),
            m("div", [m("button[type=submit]", { style: "margin-left: 10em;" }, "Save")]),
        ]);
    },
};

const contentEditor = {
    oninit(vnode) {
        const c = vnode.attrs.itemList;
        this.reload = vnode.attrs.reload;
        if (!c()) {
            return console.error("Collection content editor initialized without collection");
        }

        this.ordinalitiesUpdated = m.prop(false);

        this.id = c().id;
        this.items = c().items;
        this.newItems = m.prop();

        return this;
    },
    onbeforeupdate(vnode) {
        this.items = vnode.attrs.itemList().items;
    },
    shift (item, direction) {
        const pos = this.items.findIndex(i => i.id === item.id);
        this.items.forEach((item, i) => {
            switch (direction) {
                case "up":
                    if (i === pos || i === pos - 1) {
                        item.loading = true;
                    }
                    break;
                case "down":
                    if (i === pos || i === pos + 1) {
                        item.loading = true;
                    }
                    break;
                case "top":
                    if (i <= pos) {
                        item.loading = true;
                    }
                    break;
                case "bottom":
                    if (i >= pos) {
                        item.loading = true;
                    }
                    break;
                default:
                // do nothing
            }
        });
        m.redraw();

        patchShopItemListOrder(this.id, item.id, direction)
            .then(() => {
                this.reload();
            })
            .finally(() => {
                this.items.forEach(c => {
                    c.loading = false;
                });
                m.redraw();
            });
    },
    addItems (listid, shopids) {
        const array = shopids.split(",").map(s => +(s.trim())).filter(s => !isNaN(s) && s > 0);
        putShopItemListItems(listid, array).then(
            res => {
                window.location.reload();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    },
    removeItem (id) {
        delShopItemListItem(this.id, id).then(
            res => {
                for (let i = 0; i < this.items.length; i += 1) {
                    if (this.items[i].id === id) {
                        this.items.splice(i, 1);
                    }
                }
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    },
    view ({ attrs }) {
        return [
            m("h2", "Child items"),
            m(
                "form",
                { onsubmit: () => { this.addItems(this.id, this.newItems()); return false; }},
                [
                    m("h3", "Add new child by item ID"),
                    m("div", [
                        m("label[for=newItems]", "Item ID"),
                        m("textarea[name=newItems]", {
                            value: this.newItems(),
                            onchange: m.withAttr("value", this.newItems),
                        }),
                    ]),
                    m("div", [m("button[type=submit]", { style: "margin-left: 10em;" }, "Add")]),
                    m("table", [
                        m("tr", [
                            m("th", "ID"),
                            m("th", "NAME"),
                            m("th", ""),
                            m("th", ""),
                            m("th", ""),
                            m("th", ""),
                            m("th", ""),
                        ]),
                        this.items.map((item, i) =>
                            m("tr", [
                                m("td", m("a", { href: `/items/${item.id}/edit`, oncreate: m.route.link }, item.id)),
                                m("td", item.name),
                                item.loading
                                    ? m(`td.${css.hover}`, i >= 1 ? "⤒" : "")
                                    : m(
                                          `td.${css.hover}`,
                                          i >= 1
                                              ? m(
                                                    "a",
                                                    {
                                                        href: "#",
                                                        onclick: e => {
                                                            e.preventDefault();
                                                            this.shift(item, "top");
                                                        },
                                                    },
                                                    "⤒",
                                                )
                                              : null,
                                      ),
                                item.loading
                                    ? m(`td.${css.hover}`, i >= 1 ? "↑" : "")
                                    : m(
                                          `td.${css.hover}`,
                                          i >= 1
                                              ? m(
                                                    "a",
                                                    {
                                                        href: "#",
                                                        onclick: e => {
                                                            e.preventDefault();
                                                            this.shift(item, "up");
                                                        },
                                                    },
                                                    "↑",
                                                )
                                              : null,
                                      ),
                                item.loading
                                    ? m(
                                          `td.${css.hover}`,
                                          i < this.items.length ? "↓" : "",
                                      )
                                    : m(
                                          `td.${css.hover}`,
                                          i < this.items.length
                                              ? m(
                                                    "a",
                                                    {
                                                        href: "#",
                                                        onclick: e => {
                                                            e.preventDefault();
                                                            this.shift(item, "down");
                                                        },
                                                    },
                                                    "↓",
                                                )
                                              : null,
                                      ),
                                item.loading
                                    ? m(
                                          `td.${css.hover}`,
                                          i < this.items.length ? "⤓" : "",
                                      )
                                    : m(
                                          `td.${css.hover}`,
                                          i < this.items.length
                                              ? m(
                                                    "a",
                                                    {
                                                        href: "#",
                                                        onclick: e => {
                                                            e.preventDefault();
                                                            this.shift(item, "bottom");
                                                        },
                                                    },
                                                    "⤓",
                                                )
                                              : null,
                                      ),
                                item.loading
                                    ? m(`td.${css.hover}`, "x")
                                    : m(
                                          `td.${css.hover}`,
                                          m(
                                              "a",
                                              {
                                                  href: "#",
                                                  onclick: e => {
                                                      e.preventDefault();
                                                      item.loading = true;
                                                      item.loading = this.removeItem(item.id);
                                                  },
                                              },
                                              "x",
                                          ),
                                      ),
                            ]),
                        ),
                    ]),
                ],
            ),
        ];
    },
};

export default {
    $component: {
        handle(vnode) {
            this.page = m.prop(0);
            this.perPage = m.prop(20);
            this.totalPages = m.prop(1);

            this.itemList = m.prop(null);
            this.id = vnode.attrs.id;

            this.load();
            m.redraw();
        },
        load(page = 0) {
            getShopItemList(this.id, page).then(
                res => {
                    this.page(res.page);
                    this.totalPages(res.total_pages);
                    this.itemList(res.results);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        },
        oninit(vnode) {
            this.handle(vnode);
        },
        onupdate(vnode) {
            if (vnode.attrs.id !== this.id) {
                this.id = vnode.attrs.id;
                this.itemList(null);
                this.load(this.page());
            }
        },
        removeItemAll() {
            if (!confirm("Are you sure you want to remove all items from this list?")) {
                return;
            }

            delShopItemListItemAll(this.id).then(
                res => {
                    window.location.reload();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        },
        view() {
            const c = this.itemList();

            if (!c) {
                return m("div", [m("h1", "Edit Item List"), m(".loading", [m(".load"), m("p", "Loading...")])]);
            }

            const linkURL = `https://store.${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/item/list/${c.id}`;

            return m("div", [
                m(metaEditor, {
                    itemList: this.itemList,
                }),
                m("hr", { style: "margin-bottom: 1em;" }),
                m("form", [
                    m("div", [
                        m("label", "Link"),
                        m("input[type=text]", {
                            value: linkURL,
                            onclick: () => {
                                if (
                                    window.clipboardData &&
                                    window.clipboardData.setData
                                ) {
                                    // IE specific code path to prevent textarea being shown while dialog is visible.
                                    alert("Copied link to clipboard.");
                                    window.clipboardData.setData(
                                        "Text",
                                        linkURL,
                                    );

                                    return false;
                                } else if (
                                    document.queryCommandSupported &&
                                    document.queryCommandSupported("copy")
                                ) {
                                    var textarea = document.createElement(
                                        "textarea",
                                    );
                                    textarea.textContent = linkURL;
                                    // Prevent scrolling to bottom of page in MS Edge.
                                    textarea.style.position = "fixed";
                                    document.body.appendChild(textarea);
                                    textarea.select();
                                    try {
                                        // Security exception may be thrown by some browsers.
                                        document.execCommand("copy");

                                        return false;
                                    } catch (ex) {
                                        alert(
                                            "Copy to clipboard failed. Please try again.",
                                        );

                                        return false;
                                    } finally {
                                        alert("Copied link to clipboard.");
                                        document.body.removeChild(textarea);
                                    }
                                }

                                return false;
                            },
                        }),
                        m(
                            "button",
                            {
                                onclick: e => {
                                    e.preventDefault();
                                    window.open(linkURL);
                                },
                                style: "margin-left: 1em;",
                            },
                            "Go to the collection page",
                        ),
                    ]),
                ]),
                m("hr"),
                m(contentEditor, {
                    itemList: this.itemList,
                    reload: () => {
                        this.load(this.page);
                    },
                }),
                m("hr"),
                m("div", [m("h2", "Danger Zone"), m("button", { onclick: () => this.removeItemAll() }, "REMOVE ALL ITEMS")]),
            ]);
        },
    },
};
