"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import link from "../../utils/link";
import {
    getFeedback
} from "../../api-client";

class Model {
    constructor(opts = {}) {
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getFeedback(this.page(), this.perPage()).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }
}

export default {
    $navTitle: "Feedback",
    $component: {
        oninit(vnode) {
            this.content = m.prop([]);
            this.vm = new Model();

            this.vm.load(0);
        },

        view() {
            let dateFormat = function(date) {
                return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
            }

            return m("div", [
                m("h1", "Feedback"),
                this.vm.loading()
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("div", this.vm.elements().map((c) => {
                        let published = new Date(c.Created);

                        return m("table.comment", [
                            m("tr", [
                                m("td", "ID"),
                                m("td", c.Id),
                            ]),
                            m("tr", [
                                m("td", "User"),
                                m("td", link(`/user/id/${c.UserId}`, c.UserId)),
                            ]),
                            m("tr", [
                                m("td", "Submitted"),
                                m("td", dateFormat(published)),
                            ]),
                            m("tr", [
                                m("td", "Body"),
                                m("td.comment-body", c.Body),
                            ]),
                        ]);
                    })),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
