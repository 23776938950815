"use strict";

import {getSurvey, getSurveyResults} from "../api-client";
import csv from "../utils/csv";

/**
 * @description Download Survey CSV
 * @param {number} id The ID of the survey
 * @param {number} [page=0]
 * @param {number} [perPage=1000]
 */

const surveyCsv = (id, page = 0, perPage = 1000) => {
    getSurvey(id).then((surveyResponse) => {
        const surveyTitle = surveyResponse.survey.data.title;
        const questions = surveyResponse.survey.questions;

        getSurveyResults(id, page, perPage).then((res) => {
            const results = res.results.map((survey) => {
                let answer = survey.data.answer;

                const question = questions.find((q) => q.id === survey.SurveyQuestionId);

                const title = question
                    ? question.data.title
                    : "";

                if (Array.isArray(answer)) {
                    answer = answer.join("・");
                }

                return {
                    date: survey.Created,
                    user_id: survey.UserId,
                    question: title,
                    answer: answer
                };
            });

            csv(surveyTitle, results);
        });
    });
};

export default surveyCsv;
