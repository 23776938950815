"use strict";

import m from "mithril";
import dayjs from "dayjs";
import csv from "../../utils/csv";
import { getMetricsRoyalty } from "../../api-client";

export default {
    $navTitle: "Royalty",
    $component: {
        oninit() {
            const self = this;

            self.formatDate = function(date) {
                return date.getFullYear() + (`0${(date.getMonth() + 1)}`).slice(-2);
            }

            self.date = m.prop(new Date());

            self.startDate = m.prop(new Date());
            self.startDate(self.formatDate(self.startDate()));

            self.endDate = self.startDate;

            self.changeDate = function(e) {
                self.date(new Date(e.target.value));

                self.endDate(self.date());
                self.startDate(self.date());

                self.startDate(self.formatDate(self.startDate()));

                self.endDate = self.startDate;

                self.date().setMonth(self.date().getMonth());

                self.setup();
            }

            self.future = m.prop(false);
            self.loading = m.prop(false);
            self.loadCompleted = m.prop(false);

            self.metricsMagazines = m.prop([]);
            self.metricsPublishers = m.prop([]);

            self.totalViews = m.prop(0);
            self.calculator = m.prop(0);
            self.summedPayout = m.prop(0);
            self.max_payout = 1;

            self.magazines = {
                dascomi: {
                    publisher: 2,
                    title: 'ダスコミ'
                },
                bavel: {
                    publisher: 2,
                    title: 'BAVEL'
                },
                beast: {
                    publisher: 0,
                    title: '快楽天BEAST'
                },
                comichappining: {
                    publisher: 0,
                    title: 'はぴにんぐ'
                },
                gaira: {
                    publisher: 1,
                    title: 'コミック外楽'
                },
                hotmilk: {
                    publisher: 1,
                    title: 'HOTMILK'
                },
                'hotmilk-koime': {
                    publisher: 1,
                    title: 'HOTMILK濃いめ'
                },
                kairakuten: {
                    publisher: 0,
                    title: '快楽天'
                },
                'kairakuten-25-anniv': {
                    publisher: 0,
                    title: '快楽天25記念'
                },
                'komiflo-originals': {
                    publisher: 0,
                    title: 'Komifloオリジナル'
                },
                megastorealpha: {
                    publisher: 1,
                    title: 'メガストアアルファ'
                },
                'megastore': {
                    publisher: 1,
                    title: 'メガストア'
                },
                'other-bunendo': {
                    publisher: 2,
                    title: 'Bunendo promotional'
                },
                'other-core': {
                    publisher: 1,
                    title: 'Core promotional'
                },
                'other-komiflo': {
                    publisher: 3,
                    title: 'Komiflo self-published'
                },
                'other-wani': {
                    publisher: 0,
                    title: 'Wani promotional'
                },
                shitsurakuten: {
                    publisher: 0,
                    title: '失楽天'
                },
                'wani-anthology': {
                    publisher: 0,
                    title: 'Wani Anthology'
                },
                'weekly-kairakuten': {
                    publisher: 0,
                    title: 'WEEKLY快楽天'
                },
                'x-eros': {
                    publisher: 0,
                    title: 'X-EROS'
                },
                xtc: {
                    publisher: 0,
                    title: 'XTC'
                },
                isekairakuten: {
                    publisher: 0,
                    title: '異世快楽天'
                },
                isekairakuten: {
                    publisher: 0,
                    title: '異世快楽天'
                },
                'wani-special-issue': {
                    publisher: 0,
                    title: 'ワニマガジン特別号'
                },
                'core-special-issue': {
                    publisher: 1,
                    title: 'コアマガジン特別号'
                },
                'bunendo-special-issue': {
                    publisher: 2,
                    title: '文苑堂特別号'
                },
            };

            self.publishers = [
                {
                    minimum_cut: 0,
                    title: '株式会社ワニマガジン社'
                },
                {
                    // minimum_cut: 0.1,
                    minimum_cut: 0,
                    title: '株式会社コアマガジン'
                },
                {
                    minimum_cut: 0,
                    title: '株式会社文苑堂'
                },
                {
                    minimum_cut: 0,
                    title: '株式会社Komiflo'
                },
            ];

            self.setup = function() {
                if (dayjs(self.startDate()).isAfter(dayjs())) {
                    self.future(true);
                } else {
                    self.future(false);
                    self.loading(true);
                    self.max_payout = 1;

                    getMetricsRoyalty(self.startDate(), self.endDate()).then(
                        (res) => {
                            self.loading(false);
                            self.loadCompleted(true);
                            self.metricsMagazines(res.brands);
                            self.totalViews(0);
                            self.metricsPublishers([]);

                            res.brands.forEach((brand) => {
                                self.totalViews(brand.views_count + self.totalViews());
                                if(self.magazines[brand.brand]){
                                    const pub = self.magazines[brand.brand].publisher;
                                    if (self.metricsPublishers()[pub]) {
                                        self.metricsPublishers()[pub] += brand.views_count;
                                    } else {
                                        self.metricsPublishers()[pub] = brand.views_count;

                                        self.max_payout -= self.publishers[pub].minimum_cut;
                                    }
                            }
                            });

                            m.redraw();
                        },
                        (err) => {
                            console.error(err);
                            self.error(err);
                            self.loading(false);
                            self.loadCompleted(true);
                        }
                    );
                }
            }
        },

        view() {
            const self = this;

            const now = new Date();
            const dates = [];

            for (let year = now.getFullYear(); year >= 2017; year--) {
                for (let month = year == now.getFullYear() ? now.getMonth() : 11; month >= 0; month--) {
                    dates.push(new Date(year, month, 1, 0, 0, 0));
                }
            }

            let pubI = -1;

            self.summedPayout(0);

            const lastDayOfMonth = new Date(2017, this.date().getMonth() + 1, 0, 0, 0, 0);

            const royaltyResults = (loading, future, loadCompleted) => {
                if (loading) {
                    return [
                        m(".loading", [
                            m(".load"),
                            m("p", "Generating report..."),
                            m("p", "Please wait up to 30 seconds for this report to be generated.")
                        ])
                    ]
                } else if (future) {
                    return m("p", "These metrics will be available in the future.")
                } else if (loadCompleted) {
                    return [
                        m("h2", this.date().getFullYear() + "年" + (this.date().getMonth() + 1) + "月"),
                        m("p", this.date().getFullYear() + "." + ("0" + (this.date().getMonth() + 1)).slice(-2) + ".01 - " + this.date().getFullYear() + "." + ("0" + (this.date().getMonth() + 1)).slice(-2) + "." + lastDayOfMonth.getDate()),
                        this.date().getFullYear() + "-" + this.date().getMonth() == now.getFullYear() + "-" + now.getMonth()
                            ? m("p.warn", "Report incomplete. Use data cautiously.")
                            : null,
                        m("table", [
                            m("thead", [
                                m("th", "Brand"),
                                m("th.right", "Views"),
                                m("th.right", "Share"),
                            ]),
                            this.metricsMagazines().map((metric) => {
                                return self.magazines[metric.brand] ?
                                    m("tr", [
                                        m("td", self.magazines[metric.brand].title),
                                        m("td.right", metric.views_count.toLocaleString()),
                                        m("td.right", (metric.views_count / self.totalViews() * 100).toFixed(2) + "%"),
                                        // m("td", Math.floor(self.calculator() * metric.views_count / self.totalViews())),
                                    ])
                                :
                                    ""
                            }),
                            m("tr", [
                                m("td", "Total"),
                                m("td.right", self.totalViews().toLocaleString()),
                                m("td", ""),
                            ])
                        ]),
                        self.metricsMagazines().length
                            ? m("p", m("button", {onclick: function(e) {
                            e.preventDefault(); csv(self.date().getFullYear() + "-" + self.date().getMonth(), self.metricsMagazines());
                            }}, "Download CSV"))
                            : null,
                        m("hr"),
                        m("h3", "Publisher breakdown"),
                        m("div", [
                            m("label", "Calculator"),
                            m("input[type=text][autocomplete=off]", {
                                onkeyup: m.withAttr("value", this.calculator),
                                value: this.calculator(),
                            })
                        ]),
                        m("table", [
                            m("thead", [
                                m("th", "Publisher"),
                                m("th.right", "Views"),
                                m("th.right", "Share"),
                                m("th.right", "Min. payout"),
                                this.calculator() > 0 ? m("th.right", "Payout") : null,
                            ]),
                            this.metricsPublishers().map((metric) => {
                                pubI++;

                                const percent = metric / self.totalViews();
                                const min_cut = self.publishers[pubI].minimum_cut;

                                const payout = Math.round(percent >= self.max_payout ? self.calculator() * self.max_payout : percent >= min_cut ? self.calculator() * metric / self.totalViews() : self.calculator() * min_cut);

                                self.summedPayout(self.summedPayout() + payout);

                                return m("tr", [
                                    m("td", self.publishers[pubI].title),
                                    m("td.right", metric.toLocaleString()),
                                    m("td.right", (percent * 100).toFixed(2) + "%"),
                                    m("td.right", min_cut * 100 + "%"),
                                    this.calculator() > 0 ? m("td.right", payout.toLocaleString() + "円") : null,
                                ])
                            }),
                            m("tr", [
                                m("td", "Total"),
                                m("td.right", self.totalViews().toLocaleString()),
                                m("td", ""),
                                m("td", ""),
                                this.calculator() > 0 ? m("td.right", self.summedPayout().toLocaleString() + "円") : null,
                            ])
                        ]),
                    ]
                }

                return ""

            }

            return m("form", [
                m("h1", "Royalty report"),
                m("div", [
                    m("label", "Date"),
                    m("select", {
                            onchange: this.changeDate,
                            value: this.date(),
                            disabled: this.loading() ? "disabled" : "",
                        },
                        dates.map((date) => m("option", {
                                value: date,
                                selected: date.getFullYear() + "." + date.getMonth() == self.date().getFullYear() + "." + self.date().getMonth() ? "selected" : "",
                            }, date.getFullYear() + "年" + (date.getMonth() + 1) + "月"))),
                ]),

                royaltyResults(this.loading(), this.future(), this.loadCompleted())
            ]);
        }
    }
}
