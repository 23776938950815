'use strict';

import config from '../config';
import csv from '../utils/csv';
import { getBusinessReports } from '../api-client';

/**
 * @description Download Business Report CSV
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} email
 */

const businessCsv = (startDate, endDate, email) => {
    getBusinessReports(startDate, endDate, email, 0, 99999, '').then(
        (res) => {
            const results = res.results.map(r => {
                return {
                    user_id: r.Id,
                    email: r.Email,
                    store_name: r.StoreName,
                    views: r.Views,
                }
            });

            csv(`business-${(email ? `${email}-` : '')}${startDate}~${endDate}`, results);
        },
        (err) => {
            throw err
        }
    );
};

export default businessCsv;
