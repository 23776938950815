'use strict';

import Affiliate from './affiliate';
import Business from './business';
import Feedback from './feedback';
import Royalty from './royalty';
import Subscribers from './subscribers';

export default {
    'subscribers': Subscribers,
    'royalty': Royalty,
    'feedback': Feedback,
    'affiliate': Affiliate,
    'business': Business,
};
