"use strict";

import m from "mithril";
import { getContentSearch } from "../../api-client";
import pagination from "../../components/pagination";
import { objExistence, isStoreMode } from "../../utils/helper";
import autocomplete from "../../components/autocomplete";


const ItemTypes = ["komiplus", "store", "bolton", "subscription"];
const ContentTypes = ["chapter", "volume", "tankoubon", "sample"];

class Model {
    constructor() {
        this.contentType = m.prop("");
        this.contentName = m.prop("");
        this.hasItem = m.prop("");
        this.itemType = m.prop(isStoreMode() ? 'store' : '');
        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(1);
        this.elements = m.prop([]);
        this.artist = m.prop("");
        this.magazine = m.prop("");
        this.load();
    }

    load(page) {
        this.page(page);
        this.loading(true);
        // page, perPage, start, end, type, name, hasItem, itemType
        getContentSearch(
            page,
            this.perPage(),
            '',
            '2036-01-01',
            this.contentType(),
            this.contentName(),
            this.hasItem(),
            this.itemType(),
            this.artist(),
            this.magazine(),
        ).then(res => {
            this.loading(false);
            this.totalPages(res.total_pages);
            this.page(res.page);
            this.elements(res.results);
            m.redraw();
        });
    }
}

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            this.content = m.prop([]);
            this.vm = new Model();

            this.removeArtist = () => {
                this.vm.artist('');
            }

            this.removeMagazine = () => {
                this.vm.magazine('');
            }

            this.artistAutoOpts = autocomplete.opts.adminAttrs({
                filter: e => e.parent && e.parent.slug === 'artists',
                onclick: (new_artist) => {
                    this.vm.artist(new_artist.slug);
                },
            });

            this.magazineAutoOpts = autocomplete.opts.adminAttrs({
                filter: e => e.parent && e.parent.slug === 'magazine',
                onclick: (new_magazine) => {
                    this.vm.magazine(new_magazine.slug);
                },
            });
        },

        view() {
            
            return m("div", [
                m("h1", "Chapter / Volume search"),
                this.vm
                    ? [
                        m("form", [
                            m("div", [
                                m("span", "Type"),
                                m(
                                    "select",
                                    {
                                        onchange: m.withAttr("value", this.vm.contentType),
                                    },
                                    m("option", {value: ''}),
                                    ContentTypes.map(c =>
                                        m(
                                            "option",
                                            {
                                                selected: this.vm.contentType() === c,
                                                value: c,
                                            },
                                            c,
                                        ),
                                    ),
                                ),
                            ]),
                            m("div", [
                                m("span", "Name"),
                                m("input[type=text]", {
                                    oninput: m.withAttr("value", this.vm.contentName),
                                    value: this.vm.contentName(),
                                }),
                            ]),
                            m("div", [
                                m("span", "Item Type"),
                                m(
                                    "select",
                                    {
                                        onchange: m.withAttr("value", this.vm.itemType),
                                    },
                                    m("option", {value: ''}),
                                    ItemTypes.map(i =>
                                        m(
                                            "option",
                                            {
                                                selected: this.vm.itemType() === i,
                                                value: i,
                                            },
                                            i,
                                        ),
                                    ),
                                ),
                            ]),
                        ]),
                        m("div", [
                            m('hr'),
                            m("h3", "Filter by artist"),
                            m(autocomplete, this.artistAutoOpts),
                            " ",
                            this.vm.artist() !== ''
                                ? m(`div.tag`, [
                                    m("span", this.vm.artist()),
                                    m("button", { onclick: this.removeArtist }, m.trust("&nbsp;"))
                                ])
                                : ''
                        ]),
                        m("div", [
                            m('hr'),
                            m("h3", "Filter by magazine"),
                            m(autocomplete, this.magazineAutoOpts),
                            " ",
                            this.vm.magazine() !== ''
                                ? m(`div.tag`, [
                                    m("span", this.vm.magazine()),
                                    m("button", { onclick: this.removeMagazine }, m.trust("&nbsp;"))
                                ])
                                : '',
                            m('hr'),
                        ]),
                        m("div", [
                            m(
                                "button",
                                { onclick: () => this.vm.load(0)},
                                "search",
                            ),
                        ])
                    ] : '',
                m("hr"),
                this.vm.loading()
                    ? m(".loading", [m(".load"), m("p", "Loading...")])
                    : m("table", [
                          m("thead", [
                              m("th", ""),
                              m("th", "ID"),
                              m("th", "Name"),
                              m("th", "Type"),
                              m("th", "Published"),
                          ]),
                          this.vm.elements().map(c =>
                              m("tr", [
                                  m("td", m('img', {height: '70px', src: c.cdn_public + '/148_desktop_small/' + objExistence(c, 'named_imgs.cover.filename')})),
                                  m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href: `/content/${c.id}/edit`,
                                              oncreate: m.route.link,
                                              onupdate: m.route.link,
                                          },
                                          c.id,
                                      ),
                                  ),
                                  m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href: `/content/${c.id}/edit`,
                                              oncreate: m.route.link,
                                              onupdate: m.route.link,
                                          },
                                          c.data.title,
                                      ),
                                  ),
                                  m("td", c.type),
                                  m("td", c.published),
                              ]),
                          ),
                      ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
