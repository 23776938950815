import m from "mithril";
import { getReceipt, getReceiptOwner, postRefund, postRepeatReceipt, postShipToReceipt } from "../../api-client";
import link from "../../utils/link";
import { dateFormat } from "../../utils/helper";
import { receiptStat } from "./utils";

const model = {
    receipt: null,
    owner: null,
    loading: false,
    shipStatus: '',
    shipTrackingNo: '',
    targetItems: [],

    load(id) {
        this.loading = true;
        Promise.all([
            getReceipt(id)
                .then((res) => {
                    this.receipt = res.receipt;
                    if (res.receipt.ship_to.status) {
                        this.shipStatus = res.receipt.ship_to.status;
                    } else {
                        this.shipStatus = '';
                    }
                    if (res.receipt.ship_to.tracking_number) {
                        this.shipTrackingNo = res.receipt.ship_to.tracking_number;
                    } else {
                        this.shipTrackingNo = '';
                    }
                })
                .catch((err) => {
                    alert(err);
                }),
            getReceiptOwner(id)
                .then((res) => {
                    this.user = res.user;
                })
                .catch((err) => {
                    alert(err);
                }),
        ]).finally(() => {
            this.loading = false;
            m.redraw();
        });
    },
    refund() {
        postRefund(this.receipt.id)
            .then(() => {
                this.load(this.receipt.id);
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },
    repeat() {
        if (
            confirm(
                `Repeat charge will include following items:\n\n${this.receipt.items
                    .filter((i) => this.targetItems.includes(i.id))
                    .map((item) => item.shop_item.name)
                    .join("\n")}`,
            )
        ) {
            postRepeatReceipt(this.receipt.id, this.targetItems)
                .then(() => {
                    this.load(this.receipt.id);
                })
                .catch((err) => {
                    alert(err);
                })
                .finally(() => {
                    m.redraw();
                });
        }
    },
    changeShipStatus() {
        postShipToReceipt(this.receipt.id, this.shipStatus, this.shipTrackingNo)
        .then(() => {
            this.load(this.receipt.id);
        })
        .catch((err) => {
            alert(err);
        })
        .finally(() => {
            m.redraw();
        });
    },
};

export const ReceiptView = {
    $component: {
        oninit({ attrs }) {
            model.load(attrs.id);
        },
        view() {
            if (model.loading) {
                return m(".loading", [m(".load"), m("p", "Retrieving receipt..."), m("p", "Please wait.")]);
            }

            return m("div", [
                m("h1", "Receipt"),
                m("div", [m("span", "status"), m("span", receiptStat(model.receipt))]),
                m("div", [m("span", "user id"), m("span", model.user.id)]),
                m("div", [m("span", "email"), m("span", model.user.email)]),
                m("div", [m("span", "value pre tax"), m("span", model.receipt.value_pre_tax)]),
                m("div", [m("span", "created"), m("span", dateFormat(model.receipt.created))]),
                model.receipt.provider == 'atone' ||  model.receipt.provider == 'atone_tsudo'
                    ? m("div", [m("span", "atore transaction ID"), m("span", `${user.id}_store_${model.receipt.id}`)])
                    : '',
                m("hr"),
                model.receipt.user_address_id
                    ? [
                        m("h2", "Address / Ship status"),
                        m("div", [m("span", "zip code"), m("span", '〒 ' + model.receipt.user_address.ZipCode)]),
                        m("div", [m("span", "address"), m("span", [
                            model.receipt.user_address.State,
                            model.receipt.user_address.AddressLine3,
                            model.receipt.user_address.AddressLine2,
                            model.receipt.user_address.AddressLine1
                        ])]),
                        m("div", [m("span", "name"), m("span", [
                            model.receipt.user_address.Name,
                        ])]),
                        m("div", [m("span", "tel"), m("span", [
                            model.receipt.user_address.Telephone,
                        ])]),
                        m("div", [m("span", "ship stasus"), m(
                                "select",
                                {
                                    oninput: e => {
                                        model.shipStatus = e.target.value;
                                    },
                                    value: model.shipStatus,
                                },
                                m("option", { value: "" }, "Before shipping"),
                                m("option", { value: "shipped" }, "Shipped"),
                        )]),
                        m("div", [m("span", "tracking_number"), m("input[type=text][name=tracking_number]", {
                            onchange: e => {
                                model.shipTrackingNo = e.target.value;
                                if (e.target.value && e.target.value.length > 4) {
                                    model.shipStatus = 'shipped';
                                }
                            },
                            value: model.shipTrackingNo,
                        }),]),
                        m(
                            "button",
                            {
                                onclick: () => {
                                    model.changeShipStatus();
                                },
                                style: 'margin-top: 10px;'
                            },
                            "Charge ship status",
                        ),
                        m("hr"),
                    ]
                    : '',
                m("h2", "Items"),
                m("table", [
                    m("thead", [m("th", "ID"), m("th", "Name"), m("th", "Quantity"), m("th", "Refunded"), m("th", "Include Charge")]),
                    model.receipt.items.map((item) => {
                        const refunded = model.receipt.shop_receipt_events.reduce((prev, sre) => {
                            if (sre.status !== "complete") {
                                return prev;
                            }

                            switch (sre.event_type) {
                                case "refund":
                                    return sre.target_items.some((ti) => ti === item.id) || prev;
                                case "repeat":
                                    return !sre.target_items.some((ti) => ti === item.id) && prev;
                                default:
                                    return prev;
                            }
                        }, false);

                        return m("tr", [
                            m("td", link(`/items/${item.id}/edit`, item.id)),
                            m("td", item.shop_item.name),
                            m("td", item.quantity),
                            m("td", { style: "text-align:center;" }, refunded ? "✔" : ""),
                            m(
                                "td",
                                m(
                                    "label",
                                    { style: "text-align:center; display:inline-block; width:100%; height:100%;" },
                                    m("input", {
                                        type: "checkbox",
                                        disabled: receiptStat(model.receipt) !== "refunded",
                                        checked: model.targetItems.includes(item.id),
                                        onchange: (e) => {
                                            e.target.checked
                                                ? model.targetItems.push(item.id)
                                                : model.targetItems.splice(model.targetItems.indexOf(item.id), 1);
                                        },
                                    }),
                                ),
                            ),
                        ]);
                    }),
                ]),
                m("hr"),
                m("h2", "Events"),
                model.receipt.shop_receipt_events.length
                    ? m("table", [
                          m("thead", [m("th", "Type"), m("th", "Created"), m("th", "Status")]),
                          model.receipt.shop_receipt_events.map((event) =>
                              m("tr", [m("td", event.event_type), m("td", dateFormat(event.created)), m("td", event.status)]),
                          ),
                      ])
                    : m("p", "No events yet"),
                m("hr"),
                ReceiptView.inputArea(),
            ]);
        },
    },
    inputArea: () => {
        const hasPendingEvent = model.receipt.shop_receipt_events.some((event) => event.status === "pending");
        const allChecked = model.receipt.items.every((item) => model.targetItems.includes(item.id));

        const pendingText = hasPendingEvent ? m("p", "Please wait 1 minute and then refresh your browser.") : "";

        switch (receiptStat(model.receipt)) {
            case "partial refunded":
                return "";
            case "refunded":
                return m("div", [
                    m(
                        "button",
                        {
                            disabled: hasPendingEvent || allChecked || model.targetItems.length === 0,
                            onclick: () => {
                                model.repeat();
                            },
                        },
                        "Charge for checked items",
                    ),
                    pendingText,
                ]);
            default:
                return m("div", [
                    m(
                        "button",
                        {
                            disabled: hasPendingEvent,
                            onclick: () => {
                                model.refund();
                            },
                        },
                        "Refund total receipt",
                    ),
                    pendingText,
                ]);
        }
    },
};
