"use strict";

import m from "mithril";
import csv from "../../utils/csv";
import link from "../../utils/link";
import {
    getMetricsRanking
} from "../../api-client";

export default {
    $navTitle: "Rankings",
    $component: {
        oninit(vnode) {
            let self = this;

            self.formatDate = function(date) {
                return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-01";
            }

            self.ranking_type = m.prop('volume');

            self.date = m.prop(new Date());

            self.endDate = m.prop(new Date());
            self.endDate().setMonth(self.endDate().getMonth() + 1);
            self.endDate(self.formatDate(self.endDate()));

            self.startDate = m.prop(new Date());
            self.startDate(self.formatDate(self.startDate()));

            self.changeDate = function(e) {
                self.date(new Date(e.target.value));

                self.endDate(self.date());
                self.startDate(self.date());

                self.startDate(self.formatDate(self.startDate()));

                self.endDate().setMonth(self.endDate().getMonth() + 1);
                self.endDate(self.formatDate(self.endDate()));

                self.date().setMonth(self.date().getMonth() - 1);

                self.setup();
            }

            self.future = m.prop(false);
            self.loading = m.prop(false);

            self.totalViews = m.prop(0);

            self.rankings = m.prop([]);
            self.rankingsCSV = m.prop([]);

            self.setup = function() {
                if (new Date(self.startDate()) > new Date()) {
                    self.future(true);
                } else {
                    self.future(false);
                    self.loading(true);

                    getMetricsRanking(self.startDate(), self.endDate(), self.ranking_type()).then(
                        (res) => {
                            self.loading(false);
                            self.rankings(res.rankings);
                            self.totalViews(0);
                            self.rankingsCSV([]);
                            let work;
                            res.rankings.forEach(metric => {
                                self.totalViews(metric.views_count + self.totalViews());
                            });
                            res.rankings.forEach((metric, index) => {
                                if (this.ranking_type() == 'chapter') {
                                    work = {
                                        "rank"           : index + 1,
                                        "id"             : metric.id,
                                        "volume_title"   : metric.volume_title,
                                        "order"          : metric.ordinality + 1,
                                        "title"          : metric.title,
                                        "artist_id"      : metric.artist_id,
                                        "artist_name"    : metric.artist_name,
                                        "published"      : new Date(metric.published).toLocaleString("ja"),
                                        "views_count"    : metric.views_count,
                                        "likes_count"    : metric.likes_count,
                                        "bookmark_count" : metric.reading_list_count,
                                    }
                                } else {
                                    work = {
                                        "rank"        : index + 1,
                                        "id"          : metric.id,
                                        "title"       : metric.title,
                                        "published"   : new Date(metric.published).toLocaleString("ja"),
                                        "views_count" : metric.views_count,
                                        "shere"       : ((metric.views_count / self.totalViews()) * 100).toFixed(2) + "%",
                                    }
                                }             
                                self.rankingsCSV().push(work);
                            });
                            m.redraw();
                        },
                        (err) => {
                            console.error(err);
                            self.error(err);
                            self.loading(false);
                        }
                    );
                }
            }

            this.setup();
        },

        view() {
            let self = this;

            let now = new Date();
            let dates = [];

            for (let year = now.getFullYear(); year >= 2017; year--) {
                for (let month = (year == now.getFullYear() ? now.getMonth() : 11); month >= 0; month--) {
                    dates.push(new Date(year, month, 1, 0, 0, 0));
                }
            }

            let lastDayOfMonth = new Date(2017, this.date().getMonth() + 1, 0, 0, 0, 0);

            let rankI = 0;

            return m("form", [
                m("h1", "Magazine + chapter performance"),
                m("div", [
                    m("label", "Date"),
                    m("select", {
                            onchange: this.changeDate,
                            value: this.date(),
                            disabled: this.loading() ? "disabled" : "",
                        },
                        dates.map(date => {
                            return m("option", {
                                value: date,
                                selected: (date.getFullYear() + "." + date.getMonth()) == (self.date().getFullYear() + "." + self.date().getMonth()) ? "selected" : "",
                            }, date.getFullYear() + "年" + (date.getMonth() + 1) + "月")
                        })),
                ]),
                m("div", [
                    m("label", "Type"),
                    m("select", {
                            onchange: function(e) {
                                self.ranking_type(e.target.value);
                                self.setup();
                            },
                            value: this.ranking_type(),
                            disabled: this.loading() ? "disabled" : ""
                        },
                        [
                            m("option", { value: "volume", selected: this.ranking_type() == "volume" }, "Magazines"),
                            m("option", { value: "chapter", selected: this.ranking_type() == "chapter" }, "Chapters")
                        ])
                ]),
                this.loading()
                    ? [
                        m(".loading", [
                            m(".load"),
                            m("p", "Generating report..."),
                            m("p", "Please wait up to 1 minute for this report to be generated.")
                        ])
                    ]
                    : this.future()
                        ? m("p", "These metrics will be available in the future.")
                        : [
                            m("h2", this.date().getFullYear() + "年" + (this.date().getMonth() + 1) + "月"),
                            m("p", this.date().getFullYear() + "." + ("0" + (this.date().getMonth() + 1)).slice(-2) + ".01 - " + this.date().getFullYear() + "." + ("0" + (this.date().getMonth() + 1)).slice(-2) + "." + lastDayOfMonth.getDate()),
                            (this.date().getFullYear() + "-" + this.date().getMonth()) == (now.getFullYear() + "-" + now.getMonth())
                                ? m("p.warn", "Report incomplete. Use data cautiously.")
                                : m("p", ""),
                            m("table", [
                                m("thead", [
                                    m("th", "Rank"),
                                    this.ranking_type() == "chapter" ? m("th", "Volume") : null,
                                    this.ranking_type() == "chapter" ? m("th", "Order") : null,
                                    m("th", "Title"),
                                    this.ranking_type() == "chapter" ? m("th", "Artist") : null,
                                    m("th.right", "Views"),
                                    this.ranking_type() == "volume" ? m("th.right", "Share") : null,
                                    this.ranking_type() == "chapter" ? m("th.right", "Likes") : null,
                                    this.ranking_type() == "chapter" ? m("th.right", "Bookmark") : null,
                                ]),
                                this.rankings().map(metric => {
                                    ++rankI;

                                    return m("tr", [
                                        m("td", rankI),
                                        this.ranking_type() == "chapter" ? m("td", metric.volume_title) : null,
                                        this.ranking_type() == "chapter" ? m("td", metric.ordinality + 1) : null,
                                        m("td", metric.title),
                                        this.ranking_type() == "chapter" ? m("td", link(`/attribute/${metric.artist_id}/view`, metric.artist_name)) : null,
                                        m("td.right", metric.views_count.toLocaleString()),
                                        this.ranking_type() == "volume" ? m("td.right", ((metric.views_count / self.totalViews()) * 100).toFixed(2) + "%") : null,
                                        this.ranking_type() == "chapter" ? m("td.right", metric.likes_count.toLocaleString()) : null,
                                        this.ranking_type() == "chapter" ? m("td.right", metric.reading_list_count.toLocaleString()) : null,
                                    ])
                                }),
                                m("tr", [
                                    m("td", "Total"),
                                    this.ranking_type() == "chapter"
                                        ? [
                                            m("td", ""),
                                            m("td", ""),
                                            m("td", ""),
                                        ]
                                        : null,
                                    m("td", ""),
                                    m("td.right", self.totalViews().toLocaleString()),
                                    m("td", ""),
                                ])
                            ]),
                            self.rankings().length
                                ? m("p", m("button", { onclick: function(e) { e.preventDefault(); csv(self.date().getFullYear() + "-" + self.date().getMonth(), self.rankingsCSV()); } }, "Download CSV"))
                                : null,
                        ]
            ]);
        }
    }
}
