import m from "mithril";

const userIntentList = [
    "User requested to cancel their subscription",
    "Spouce died, so wants to delete account",
    "User requested to delete their account",
    "User cannot login",
    "User was charged unexpectedly",
];

const model = {
    onsubmit: null,
    template: null,
};

export const Editor = {
    oninit({ attrs }) {
        model.onsubmit = attrs.onsubmit;
        model.template = attrs.template;
    },
    view() {
        return m(
            "form",
            {
                onsubmit: (e) => {
                    e.preventDefault();
                    model.onsubmit();
                },
            },
            m("div", [
                m("label", { for: "user_intent" }, "User Intent"),
                m(
                    "select",
                    {
                        name: "user_intent",
                        style: "width: 24em;",
                        onchange: (e) => {
                            e.preventDefault();
                            model.template.user_intent = e.currentTarget.value;
                        },
                        value: model.template.user_intent,
                    },
                    [m("option", { value: "" }, "Select..."), ...userIntentList.map((i) => m("option", { value: i }, i))],
                ),
            ]),
            m("div", [
                m("label", { for: "text_ja" }, "Text JA"),
                m("textarea", {
                    name: "text_ja",
                    style: "width: 48em; height: 8em;",
                    oninput: (e) => {
                        e.preventDefault();
                        model.template.text_ja = e.currentTarget.value;
                    },
                    value: model.template.text_ja,
                }),
            ]),
            m("div", [
                m("label", { for: "text_en" }, "Text EN"),
                m("textarea", {
                    name: "text_en",
                    style: "width: 48em; height: 8em;",
                    oninput: (e) => {
                        e.preventDefault();
                        model.template.text_en = e.currentTarget.value;
                    },
                    value: model.template.text_en,
                }),
            ]),
            m("button", "Save"),
        );
    },
};
