import dayjs from "dayjs";
import { dateFormat } from "../../utils/helper";
import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import css from "./edit.css";
import {
    getContent,
    patchContent,
    postContentAttrPublished,
    getContentSearch,
} from "../../api-client";
import { timeSelector } from "../../utils/input-component";
import { AttrTitleCopier } from "./edit_copy";

export function recomputeAttrs(content) {
    // Need to coerce the attributes object into a list, which is a bit
    // annoying but alas. hue hue hue.
    return Object.keys(content.attributes).reduce((a, k) => {
        const result = a.concat(
            content.attributes[k].children.map(attr => {
                attr.parent = content.attributes[k].meta;

                return attr;
            }),
        );

        return result;
    }, []);
}

const model = {
    content: null,
    attributes: [],
    attrTypeReference: {
        [config.magazines_attribute_id] : "Magazines",
        [config.artists_attribute_id] : "Artists",
        [config.tags_attribute_id] : "Tags",
        [config.hidden_tags_attribute_id] : "Hidden tags",
    },
    modal: {
        display: m.prop(false),
        mode: m.prop(""),
        id: m.prop(null),
        attr: null,
        published: m.prop(null),
        publishedTime: m.prop(""),
        artistDisplayPriorityHigh: m.prop(null),
    },

    init(content) {
        this.content = content;
        this.attributes = recomputeAttrs(content);

        const artist = this.attributes.find((attr) => attr.parent_id === config.artists_attribute_id);
        if (artist) {
            getContentSearch(0, 999, null, null, null, null, null, null, artist.slug, null)
                .then((res) => {
                    this.copyCandidates = res.results.filter(c => c.id !== this.content.id);
                })
                .finally(() => {
                    m.redraw();
                });
        }
    },

    attributesGroupedByParent() {
        if (!this.attributes) {
            return [];
        }

        const collection = [];
        this.attributes.forEach(attr => {
            if (!collection[attr.parent_id]) {
                collection[attr.parent_id] = [];
            }

            collection[attr.parent_id].push(attr);
        });

        return collection;
    },

    patch(newAttrIDs) {
        return patchContent(this.content.id, { attribute_ids: newAttrIDs })
            .then(res => {
                this.init(res.content);
            })
            .catch((err) => {
                console.error(err);
                alert(JSON.stringify(err));
            })
            .finally(() => {
                m.redraw();
            });
    },

    patchTitle(title, title_kana) {
        if (!confirm("Do you want to copy title too?\nタイトルもコピーしますか？")) {
            return;
        }
        return patchContent(this.content.id, { data: { title, title_kana } })
            .then(res => {
                window.location.reload();
            })
            .catch((err) => {
                alert(JSON.stringify(err));
            })
            .finally(() => {
                m.redraw();
            });
    },

    addAttr(attr) {
        this.patch(this.attributes.map(a => a.id).concat([attr.id]));
    },

    removeAttr(attr) {
        this.patch(this.attributes.map(a => a.id).filter(id => id != attr.id));
    },

    openmodal(attr, mode) {
        this.modal.display(true);
        this.modal.id(attr.id);
        this.modal.attr = attr;
        if (mode == "artist") {
            this.modal.mode("artist");
            this.modal.artistDisplayPriorityHigh(Boolean(attr.other && attr.other.display_priority_high));
        } else {
            this.modal.mode("published");
            this.modal.published(attr.link_published ? dayjs(attr.link_published).format("ddd MMM DD YYYY") : "");
            this.modal.publishedTime(attr.link_published ? new Date(attr.link_published).getHours() + ":00" : "");
        }
    },
    closemodal() {
        this.modal.display(false);
        this.modal.mode("");
        this.modal.artistDisplayPriorityHigh(false);
        this.modal.published(null);
        this.modal.publishedTime("");
    },
    savemodal() {
        if (this.modal.mode() == "artist") {
            const data = { display_priority_high: this.modal.artistDisplayPriorityHigh() };
            postContentAttrPublished(this.content.id, this.modal.id(), data).then(
                res => {
                    if (!this.modal.attr.other) {
                        this.modal.attr.other = {};
                    }
                    this.modal.attr.other.display_priority_high = this.modal.artistDisplayPriorityHigh();
                    this.closemodal();
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        } else {
            const publish_date = this.modal.published() != "" ? new Date(this.modal.published() + " " + this.modal.publishedTime()).toJSON() : null;
            const data = { published: publish_date };
            postContentAttrPublished(this.content.id, this.modal.id(), data).then(
                res => {
                    this.modal.attr.link_published = new Date(this.modal.published() + " " + this.modal.publishedTime()).toJSON();
                    this.closemodal();
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        }
    },

    shouldShowWarning(attr) {
        const warning_attr = {
            chapter: ["Magazines"],
        };

        return warning_attr[model.content.type] && warning_attr[model.content.type].includes(model.attrTypeReference[attr.parent_id]);
    },
}

export const attrEditor = {
    oninit({ attrs }) {
        const { content } = attrs;
        if (!content()) {
            return console.error("Content attribute editor initialized without content");
        }

        model.init(content());
    },

    view() {
        return [
            m("div", [
                m("hr"),
                m("h2", "Attributes"),
                m("p", '* For announcements, the "announcement" attribute must be added to appear in users\' notification centers.'),
                m("div", [m("span", "Add Attributes"), m(autocomplete, autocomplete.opts.adminAttrs({
                    onclick: attr => { model.addAttr(attr) },
                    filter: attr => attr.parent && !model.attributes.some(a => a.id === attr.id),
                }))]),
                m(AttrTitleCopier, {
                    visible: model.content.type === "chapter",
                    contentID: model.content.id,
                    attributeIDs: model.attributes.map(a => a.id),
                    setTitle: (t, tk) => { model.patchTitle(t, tk) },
                    setAttributeIDs: (v) => { model.patch(v) },
                    undo: (v) => { model.patch(v) },
                }),
                model.attributesGroupedByParent().map(collection => {
                    // Don't show labels
                    if (collection[0].slug.split("label-").length > 1) {
                        return null;
                    }

                    let parent = false;

                    return m(
                        `.${css.attributecollection}`,
                        collection.map(attr => {
                            const elem = [
                                parent
                                    ? ""
                                    : [
                                          m(`.${css.heading}`, [
                                              m("span", collection.length),
                                              m(
                                                  "h3",
                                                  model.attrTypeReference[attr.parent_id]
                                                      ? model.attrTypeReference[attr.parent_id]
                                                      : "Other attributes",
                                              ),
                                              model.shouldShowWarning(attr)
                                                  ? m(`p.${css.warn_attr}`, "Warning: This tag should not be attached to this content type.")
                                                  : "",
                                          ]),
                                      ],
                                m(`div.tag.${attr.data.name == '表紙' ? css.hyoshi : ''}`, [
                                    [
                                        attr.data.duration
                                            ? m(
                                                  `span.${css.modal_link}`,
                                                  {
                                                      onclick: () => {
                                                          model.openmodal(attr, "published");
                                                      },
                                                  },
                                                  attr.data.name,
                                              )
                                            : "",
                                        attr.parent_id == 2
                                            ? m(
                                                  `span.${css.modal_link}`,
                                                  {
                                                      onclick: () => {
                                                        model.openmodal(attr, "artist");
                                                      },
                                                  },
                                                  attr.data.name,
                                              )
                                            : "",
                                        !(attr.data.duration || attr.parent_id == 2) ? m("span", attr.data.name) : "",
                                    ],
                                    m("button", { onclick: () => { model.removeAttr(attr) } }, m.trust("&nbsp;")),
                                    [
                                        attr.link_published ? m(`p.${css.attributes_link_time}`, dayjs(attr.link_published).format("YYYY/M/D HH:mm")) : "",
                                        attr.other && attr.other.display_priority_high ? m(`p.${css.attributes_link_time}`, "priority high") : "",
                                    ],
                                ]),
                            ];
                            parent = true;

                            return elem;
                        }),
                    );
                }),
            ]),
            m(`.${css.modal_bg}` + (model.modal.display() ? "" : `.${css.hidden}`), { onclick: () => { model.closemodal() } }, [
                m(
                    `.${css.modal}.${css.modal_height_min}`,
                    {
                        onclick: e => {
                            e.stopPropagation();
                        },
                        style: "width: 500px;",
                    },
                    [
                        model.modal.mode() == "artist"
                            ? m("div.artist", [
                                  m("label", "Display high priority"),
                                  m("input[type=checkbox][name=display_priority_high]", {
                                      checked: model.modal.artistDisplayPriorityHigh() ? "checked" : "",
                                      onchange: () => {
                                        model.modal.artistDisplayPriorityHigh(!model.modal.artistDisplayPriorityHigh());
                                      },
                                  }),
                              ])
                            : "",
                            model.modal.mode() == "published"
                            ? m("div.published", [
                                  m("p", "Link publish date"),
                                  m("input[type=text][name=published][autocomplete=off]", {
                                      onchange: m.withAttr("value", model.modal.published),
                                      value: model.modal.published(),
                                      oncreate: function(vnode) {
                                          this.pikaday = new Pikaday({
                                              field: vnode.dom,
                                          });
                                      },
                                      onremove: function() {
                                          this.pikaday.destroy();
                                      },
                                  }),
                                  "　",
                                  ...timeSelector(model.modal.publishedTime, model.modal.publishedTime()),
                              ])
                            : "",
                        m(`.${css.modal_save}`, m("button", { onclick: () => { model.savemodal() } }, "Save")),
                    ],
                ),
            ]),
        ];
    },
};