"use strict";

import dayjs from "dayjs";
import AWS from "aws-sdk";
import { dateFormat } from "../../utils/helper";
import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import css from "./edit.css";
import {
    baseUrl,
    deleteContent,
    getAdminContent,
    getAttribute,
    getAttributeChildren,
    getContent,
    patchContent,
    reorderContentChildren,
    deleteContentChild,
    deleteContentOriginal,
    deleteContentOriginalAll,
    reorderContentOriginals,
    setContentOriginalName,
    getAWSToken,
    setContentOriginalData,
    setContentOriginalDatas,
} from "../../api-client";
import link from "../../utils/link";
import { timeSelector } from "../../utils/input-component";
import { contentVariants } from "@komiflo/common";
import { attrEditor, recomputeAttrs } from "./edit_attr";

const metaEditor = {
    oninit(vnode) {
        const c = vnode.attrs.content;
        if (!c()) {
            return console.error("Content metadata editor initialized without content");
        }
        this.contentId = c().id;
        this.slug = m.prop(c().slug);
        this.type = m.prop(c().type);
        this.parents = m.prop(c().parents);
        this.fqtype = m.prop(c().data.fqtype || "all");
        this.body = m.prop(c().body);
        this.public = m.prop(c().public);
        this.published = m.prop(c().published);
        this.publishedTime = m.prop(new Date(c().published).getHours() + ":00");
        this.expiry = m.prop(c().expiry);
        this.expiry_time = m.prop(c().expiry != null ? dayjs(c().expiry).format("HH:mm") : "");
        this.premium = m.prop(c().premium);

        this.title = m.prop(c().data.title || "");
        this.title_kana = m.prop(c().data.title_kana || "");
        this.censorship = m.prop(c().data.censorship || "");
        this.isbn = m.prop(c().data.isbn || "");
        this.msrp = m.prop(c().data.msrp || "");
        this.notproducts = m.prop(c().data.notproducts || "");
        this.duration_month = m.prop("");
        this.duration_day = m.prop("");
        const duration = c().data.duration;
        if (duration) {
            this.duration_month(duration.month);
            this.duration_day(duration.day);
        }
        this.category = m.prop(c().data.category || "");
        this.url = m.prop(c().data.url || "");
        this.download_type = m.prop(c().data.download_type || "");
        this.image_position = m.prop(c().data.image_position || "none");

        this.public_scope = m.prop("public")
        if (this.type() === "chapter" || this.type() === 'volume_affiliate') {
            if (c().public && !c().data.naughty & !c().data.restricted) {
                this.public_scope("public")
            } else if (c().public && c().data.naughty & !c().data.restricted) {
                this.public_scope("naughty")
            } else if (!c().public && !c().data.naughty & c().data.restricted) {
                this.public_scope("access")
            } else if (!c().public && !c().data.naughty & !c().data.restricted) {
                this.public_scope ("authenticated_only")
            } else {
                alert(`The public scope is not the content of the new format. Reconfigure it. (Current settings Public = ${c().public ? 'true' : 'false'} Naughty = ${c().data.naughty ? 'true' : 'false'} Access = ${c().data.restricted ? 'true' : 'false'})`)
            }
        }

        this.lander_text_agegate1_ja = m.prop(c().data.lander_text_agegate1_ja || "");
        this.lander_text_button1_ja = m.prop(c().data.lander_text_button1_ja || "");
        this.lander_text_button2_ja = m.prop(c().data.lander_text_button2_ja || "");
        this.lander_text_appeal1_ja = m.prop(c().data.lander_text_appeal1_ja || "");
        this.lander_text_agegate1_en = m.prop(c().data.lander_text_agegate1_en || "");
        this.lander_text_button1_en = m.prop(c().data.lander_text_button1_en || "");
        this.lander_text_button2_en = m.prop(c().data.lander_text_button2_en || "");
        this.lander_text_appeal1_en = m.prop(c().data.lander_text_appeal1_en || "");
        this.lander_img_link = m.prop(c().data.lander_img_link || "");
        this.lander_img_d = m.prop(c().data.lander_img_d || "");
        this.lander_img_d_size = m.prop(c().data.lander_img_d_size || "50");
        this.lander_img_d_position = m.prop(c().data.lander_img_d_position || "50, 50");
        this.lander_img_m = m.prop(c().data.lander_img_m || "");
        this.lander_img_m_size = m.prop(c().data.lander_img_m_size || "50");
        this.lander_img_m_position = m.prop(c().data.lander_img_m_position || "50, 50");
        this.register_anime1 = m.prop(c().data.register_anime1 || "0");
        this.register_text_appeal1_ja = m.prop(c().data.register_text_appeal1_ja || "");
        this.register_text_appeal1_en = m.prop(c().data.register_text_appeal1_en || "");

        this.volume_affiliate_size = m.prop(c().data.size || "");
        this.volume_affiliate_release_date = m.prop(c().data.release_date || "");
        this.volume_affiliate_link_store = m.prop(c().data.link_store || "");
        this.volume_affiliate_link_wani = m.prop(c().data.link_wani || "");
        this.volume_affiliate_link_amazon = m.prop(c().data.link_amazon || "");
        this.volume_affiliate_link_fanzagames = m.prop(c().data.link_fanzagames || "");
        this.volume_affiliate_link_dmm = m.prop(c().data.link_dmm || "");
        this.volume_affiliate_link_dlsite = m.prop(c().data.link_dlsite || "");

        this.magazine_titles = m.prop([]);
        this.magazine_issues = m.prop([]);
        this.position = m.prop("");
        this.chapters = m.prop([]);
        this.magazine = m.prop("");
        this.content = m.prop("");
        this.child_ids = m.prop([]);

        this.status_begin  = m.prop(c().data.begin || '');
        this.status_begin_time = m.prop(c().data.begin!= null ? dayjs(c().data.begin).format("HH:mm") : "");
        this.status_end  = m.prop(c().data.end || '');
        this.status_end_time = m.prop(c().data.end != null ? dayjs(c().data.end).format("HH:mm") : "");
        this.status_update  = m.prop(c().data.update || '');
        this.status_update_time = m.prop(c().data.update != null ? dayjs(c().data.update).format("HH:mm") : "");
        this.status_priority  = m.prop(c().data.priority || "中");
        this.status_services  = m.prop(Array.isArray(c().data.services) ? c().data.services.join(',') : (c().data.services || ''));
        this.status_scheduled = m.prop(c().data.scheduled);

        this.attributes = m.prop(c().attributes);
        
        this.current_content_chapter_ids = m.prop(vnode.attrs.current_content_chapter_ids() || []);

        if ((this.type() == "chapter" || this.type == "sample") && this.magazine_titles().length < 1) {
            // Get the list of magazine titles
            getAttributeChildren(config.magazines_attribute_id).then(
                res => {
                    this.magazine_titles(res.children);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        }

        this.magazine.run(() => {
            this.magazine_issues([]);
            this.chapters([]);

            // Get the issues/volumes for this magazine title
            if (this.magazine()) {
                getAttribute(this.magazine()).then(
                    res => {
                        this.magazine_issues(res.attribute.content);
                        m.redraw();
                    },
                    err => {
                        console.error(err);
                    },
                );
            }
        });

        this.content.run(() => {
            this.chapters([]);
            if (this.content()) {
                getContent(this.content()).then(
                    res => {
                        this.chapters(res.content.children);
                        m.redraw();
                    },
                    err => {
                        console.error(err);
                    },
                );
            }
        });

        this.position.run(() => {
            getContent(this.content()).then(
                res => {
                    const child_ids = [];
                    const children = res.content.children;
                    let newContentIsPushed = false;
                    const position = this.position();

                    if (children) {
                        children.forEach(function(child) {
                            child_ids.push(child.id);

                            if (position == child.id) {
                                child_ids.push(c().id);
                                newContentIsPushed = true;
                            }
                        });
                    }

                    if (!newContentIsPushed) {
                        child_ids.unshift(c().id);
                    }

                    this.child_ids(child_ids);
                },
                err => {
                    console.error(err);
                },
            );
        });

        this.submit = () => {
            var publish_date = new Date(this.published() != "" ? this.published() + " " + this.publishedTime() : "").toJSON();
            var expiry_date = this.expiry() && !this.premium() ? new Date(this.expiry()).toJSON() : null;
            var release_date = new Date(this.volume_affiliate_release_date());

            const fields = {
                slug: this.slug(),
                body: this.body(),
                published: publish_date,
                expiry: expiry_date,
                public: this.public(),
                premium: this.premium(),
                data: {
                    title: this.title(),
                    title_kana: this.title_kana(),
                    censorship: this.censorship(),
                    isbn: this.isbn(),
                    msrp: this.msrp(),
                    notproducts: this.notproducts(),
                    category: this.category(),
                    url: this.url(),
                    download_type: this.download_type(),
                    size: this.volume_affiliate_size(),
                    release_date: release_date,
                    link_store: this.volume_affiliate_link_store(),
                    link_wani: this.volume_affiliate_link_wani(),
                    link_amazon: this.volume_affiliate_link_amazon(),
                    link_fanzagames: this.volume_affiliate_link_fanzagames(),
                    link_dmm: this.volume_affiliate_link_dmm(),
                    link_dlsite: this.volume_affiliate_link_dlsite(),
                    image_position: this.image_position(),
                    fqtype: this.fqtype(),
                },
            };

            if (this.type() === "sample") {
                fields.public = true
            } else if (this.type() === "chapter" || this.type() === 'volume_affiliate') {
                if (this.public_scope == 'naughty') {
                    fields.public = true
                    fields.data.naughty = true
                    fields.data.restricted = false
                } else if (this.public_scope == 'access') {
                    fields.public = false
                    fields.data.naughty = false
                    fields.data.restricted = true
                } else if (this.public_scope == 'authenticated_only') {
                    fields.public = false
                    fields.data.naughty = false
                    fields.data.restricted = false
                } else {
                    fields.public = true
                    fields.data.naughty = false
                    fields.data.restricted = false
                }
            }

            if (this.type() === "free_trial") {
                if (this.duration_month() != "" || this.duration_day() != "") {
                    fields.data.duration = {
                        month: parseInt(this.duration_month()),
                        day: parseInt(this.duration_day()),
                    };
                    if (fields.data.duration.day == NaN) {
                        fields.data.duration.day = 0;
                    }
                    if (fields.data.duration.month == NaN) {
                        fields.data.duration.month = 0;
                    }
                }
                fields.data.lander_text_agegate1_ja = this.lander_text_agegate1_ja();
                fields.data.lander_text_button1_ja = this.lander_text_button1_ja();
                fields.data.lander_text_button2_ja = this.lander_text_button2_ja();
                fields.data.lander_text_appeal1_ja = this.lander_text_appeal1_ja();
                fields.data.register_text_appeal1_ja = this.register_text_appeal1_ja();
                fields.data.lander_text_agegate1_en = this.lander_text_agegate1_en();
                fields.data.lander_text_button1_en = this.lander_text_button1_en();
                fields.data.lander_text_button2_en = this.lander_text_button2_en();
                fields.data.lander_text_appeal1_en = this.lander_text_appeal1_en();
                fields.data.register_text_appeal1_en = this.register_text_appeal1_en();

                fields.data.lander_img_link = this.lander_img_link();
                fields.data.lander_img_d = this.lander_img_d();
                fields.data.lander_img_d_size = this.lander_img_d_size();
                fields.data.lander_img_d_position = this.lander_img_d_position();
                fields.data.lander_img_m = this.lander_img_m();
                fields.data.lander_img_m_size = this.lander_img_m_size();
                fields.data.lander_img_m_position = this.lander_img_m_position();

                fields.data.register_anime1 = this.register_anime1();
            }

            if (this.type() === "status") {
                const status_begin   = new Date(this.status_begin() != '' ? (this.status_begin() + " " + this.status_begin_time()) : '').toJSON();
                const status_end   = new Date(this.status_end() != '' ? (this.status_end() + " " + this.status_end_time()) : '').toJSON();
                const status_update   = new Date(this.status_update() != '' ? (this.status_update() + " " + this.status_update_time()) : '').toJSON();
                const status_expiry   = new Date(this.expiry() != '' ? (this.expiry() + " " + this.expiry_time()) : '').toJSON();
                fields.data.begin     = status_begin;
                fields.data.end       = status_end;
                fields.data.update    = status_update;
                fields.expiry         = status_expiry;
                fields.data.priority  = this.status_priority();
                fields.data.services  = this.status_services().split(',');
                fields.data.scheduled = this.status_scheduled();
            }

            const contentId = c().id;

            patchContent(contentId, fields).then(
                res => {
                    res.content.children = vnode.attrs.content().children;
                    vnode.attrs.content(res.content);
                    if (this.child_ids().length > 1) {
                        patchContent(this.content(), { child_ids: this.child_ids() }).then(
                            res => {
                                alert("Save successful");
                                location.reload();
                            },
                            err => {
                                console.error(err);
                                alert(JSON.stringify(err));
                            },
                        );
                    } else {
                        alert("Save successful");
                        m.redraw();
                    }
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );

            return false;
        };

        return this;
    },

    view() {
        const articleCategories = [
            {
                value: "announcement",
                label: "Announcement",
            },
            {
                value: "download",
                label: "Download",
            },
            {
                value: "original",
                label: "Original content",
            },
        ];
        const hyoshiIsSet = this.attributes().tags && this.attributes().tags.children && this.attributes().tags.children.find(e => e.slug == "hyoshi");

        return m("form", { onsubmit: this.submit }, [
            m("h2", "Metadata"),
            m("div", [
                m("label[for=category]", "Type"),
                this.type() == "tankoubon" || this.type() == "sample"
                    ? [
                        m(
                            `span.${css.typestore}`,
                            this.type()
                        )
                    ]
                    : [
                        m(
                            `span.${css.capitalise}`,
                            this.type()
                                .replace("volume_affiliate", "affiliate")
                                .replace("volume", "magazine issue"),
                        )
                    ]
            ]),
            this.type() != "article"
                ? null
                : m("div", [
                      m("label[for=category]", "Category"),
                      m(
                          "select[name=category]",
                          {
                              onchange: m.withAttr("value", this.category),
                              value: this.category(),
                          },
                          articleCategories.map(c => m("option", { value: c.value, selected: c.value == this.category() ? "selected" : "" }, c.label)),
                      ),
                  ]),
            this.type() != "chapter" && this.type() != "sample"
                ? null
                : m("div", [
                      m("label", "Volume"),
                      this.parents() && this.parents().length > 0
                          ? [
                                m("span", m("a", { href: `/content/${this.parents()[0].id}/edit`, oncreate: m.route.link }, this.parents()[0].data.title)),
                                this.current_content_chapter_ids()
                                    ? [
                                        this.current_content_chapter_ids().indexOf(this.contentId) !== -1 && this.current_content_chapter_ids().indexOf(this.contentId) - 1 >= 0
                                            ? m("a", {href: `/content/${this.current_content_chapter_ids()[this.current_content_chapter_ids().indexOf(this.contentId) - 1]}/edit`, style: 'margin: 0 1em;', oncreate: m.route.link}, "←")
                                            : m("span", {style: 'margin: 0 1em;'}, "　"),
                                        this.current_content_chapter_ids().indexOf(this.contentId) !== -1 && this.current_content_chapter_ids().indexOf(this.contentId) + 1 < this.current_content_chapter_ids().length
                                            ? m("a", {href: `/content/${this.current_content_chapter_ids()[this.current_content_chapter_ids().indexOf(this.contentId) + 1]}/edit`, style: 'margin: 0 1em;', oncreate: m.route.link}, "→")
                                            : "",
                                    ]
                                    : ''
                            ]
                          : m("span", "(None)"),
                  ]),
            this.parents() && this.parents().length > 0
                ? ""
                : [
                      this.type() != "chapter" && this.type() != "sample"
                          ? ""
                          : m("div", [
                                m("label[for=magazine]", "Magazine title"),
                                m(
                                    "select[name=magazine][id=magazine]",
                                    {
                                        onchange: m.withAttr("value", this.magazine),
                                        value: this.magazine(),
                                    },
                                    [m("option")].concat(
                                        this.magazine_titles().map(magazine_title =>
                                            m(
                                                "option",
                                                {
                                                    value: magazine_title.id,
                                                },
                                                magazine_title.data.name,
                                            ),
                                        ),
                                    ),
                                ),
                            ]),
                      this.type() != "chapter" && this.type() != "sample" || this.magazine_issues().length < 1
                          ? ""
                          : m("div", [
                                m("label[for=content]", "Issue"),
                                m(
                                    "select[name=content]",
                                    {
                                        onchange: m.withAttr("value", this.content),
                                        value: this.content(),
                                    },
                                    [m("option")].concat(
                                        this.magazine_issues().map(issue =>
                                            m(
                                                "option",
                                                {
                                                    value: issue.id,
                                                },
                                                issue.data.title,
                                            ),
                                        ),
                                    ),
                                ),
                            ]),
                      this.type() != "chapter" && this.type() != "sample" || this.chapters().length < 1
                          ? ""
                          : m("div", [
                                m("label[for=position]", "Insert after..."),
                                m(
                                    "select[name=position]",
                                    {
                                        onchange: m.withAttr("value", this.position),
                                        value: this.position(),
                                    },
                                    [m("option", "(Insert as first chapter)")].concat(
                                        this.chapters() ? this.chapters().map(chapter => m("option", { value: chapter.id }, chapter.data.title)) : null,
                                    ),
                                ),
                            ]),
                  ],
            this.type() == "asset"
                ? [
                      m("div", [
                          m("label[for=url]", "Asset URL"),
                          m("input[type=text][name=url]", {
                              onchange: m.withAttr("value", this.url),
                              value: this.url(),
                          }),
                      ]),
                  ]
                : null,
            this.type() == "article" && this.category() == "download"
                ? [
                      m("div", [
                          m("label[for=download_type]", "Download type"),
                          m("input[type=text][name=download_type]", {
                              onchange: m.withAttr("value", this.download_type),
                              value: this.download_type(),
                          }),
                      ]),
                  ]
                : null,
            this.type() != "volume_affiliate"
                ? null
                : m("div", [
                      m("label", "Product ID / EAN"),
                      m("input[type=text][name=isbn]", {
                          value: this.isbn(),
                          onkeyup: m.withAttr("value", this.isbn),
                      }),
                  ]),
            this.type() == "announcement"
                ? null
                : [m("div", [
                      m("label[for=title]", "Title"),
                      m("input[type=text][name=title]", {
                          value: this.title(),
                          onchange: m.withAttr("value", this.title),
                      }),
                  ]),
                m("div", [
                      m("label[for=title_kana]", "Title Kana"),
                      m("input[type=text][name=title_kana]", {
                          value: this.title_kana(),
                          onchange: m.withAttr("value", this.title_kana),
                      }),
                  ]),
                ],
            this.type() == "asset"
                ? null
                : [
                      m("div", [
                          m("label[for=published]", "Publish date"),
                          m("input[type=text][name=published][autocomplete=off]", {
                              onchange: m.withAttr("value", this.published),
                              value: this.published(),
                              oncreate: function(vnode) {
                                  this.pikaday = new Pikaday({
                                      field: vnode.dom,
                                  });
                              },
                              onremove: function() {
                                  this.pikaday.destroy();
                              },
                          }),
                          "　",
                          ...timeSelector(this.publishedTime, this.publishedTime()),
                          this.parents() &&
                          this.parents().length > 0 &&
                          (dayjs(this.parents()[0].published).format("YYMMDD") != dayjs(this.published()).format("YYMMDD") ||
                              this.publishedTime() != new Date(this.parents()[0].published).getHours() + ":00")
                              ? m(
                                    "button",
                                    {
                                        onclick: _ => {
                                            this.published(dayjs(this.parents()[0].published).format("ddd MMM DD YYYY"));
                                            this.publishedTime(new Date(this.parents()[0].published).getHours() + ":00");

                                            return false;
                                        },
                                        style: "margin-left: 1em;",
                                    },
                                    "Match parent",
                                )
                              : this.parents() && this.parents().length > 0
                              ? m("label", "　Matches parent")
                              : null,
                      ]),
                      ["chapter", "volume", "tankoubon", "free_trial", "volume_affiliate", "sample"].indexOf(this.type()) != -1
                          ? m("div", [
                                m("label[for=expiry]", "Expiry date"),
                                this.premium()
                                    ? m("input[type=text][disabled=disabled][value=\" \"]")
                                    : m("input[type=text][name=expiry][autocomplete=off]", {
                                          onchange: m.withAttr("value", this.expiry),
                                          value: this.expiry(),
                                          oncreate: function(vnode) {
                                              this.pikaday = new Pikaday({
                                                  field: vnode.dom,
                                              });
                                          },
                                          onremove: function() {
                                              this.pikaday.destroy();
                                          },
                                      }),
                                this.parents() && this.parents().length > 0 && dateFormat(this.expiry()) != dateFormat(this.parents()[0].expiry)
                                    ? m(
                                          "button",
                                          {
                                              onclick: _ => {
                                                  // For future reference:
                                                  // console.log(this.expiry())
                                                  // console.log(this.parents()[0].expiry)
                                                  // console.log(dateFormat(this.expiry()))
                                                  // console.log(dateFormat(this.parents()[0].expiry))
                                                  // console.log(dayjs(this.expiry()).format('ddd MMM DD YYYY'))
                                                  // console.log(dayjs(this.parents()[0].expiry).format('ddd MMM DD YYYY'))
                                                  this.expiry(dayjs(this.parents()[0].expiry).format("ddd MMM DD YYYY"));

                                                  return false;
                                              },
                                              style: "margin-left: 1em;",
                                          },
                                          "Match parent",
                                      )
                                    : this.parents() && this.parents().length > 0

                                    ? m("label", "　Matches parent")
                                    : null,
                                this.expiry() && this.published()
                                    ? [
                                          `　/　${dayjs(this.expiry()).diff(dayjs(this.published()), "day")}`,
                                          m("span", { style: "color: #bbbbbb; font-size: .8em" }, "　(Number of days public)"),
                                      ]
                                    : "",
                            ])
                          : null,
                  ],
            this.type() == "faq" || this.type() == "free_trial"
                ? null
                : m("div", [
                      m("label[for=slug]", "Slug"),
                      m("input[type=text][name=slug]", {
                          value: this.slug(),
                          onkeyup: m.withAttr("value", this.slug),
                      }),
                  ]),
            m("div", { style: { display: this.slug() ? "block" : "none" } }, [m("label"), m("span", "https://komiflo.com/content/" + this.slug())]),
            this.type() == "volume_affiliate"
                ? [
                      m("div", [
                          m("label[for=volume_affiliate_size]", "Size"),
                          m("input[type=text][name=volume_affiliate_size]", {
                              onkeyup: m.withAttr("value", this.volume_affiliate_size),
                              value: this.volume_affiliate_size(),
                          }),
                      ]),
                      m("div", [
                          m("label[for=volume_affiliate_release_date]", "Release date"),
                          m("input[type=text][name=volume_affiliate_release_date][autocomplete=off]", {
                              onchange: m.withAttr("value", this.volume_affiliate_release_date),
                              value: this.volume_affiliate_release_date(),
                              oncreate: function(vnode) {
                                  this.pikaday = new Pikaday({
                                      field: vnode.dom,
                                  });
                              },
                              onremove: function() {
                                  this.pikaday.destroy();
                              },
                          }),
                      ]),
                      this.volume_affiliate_link_amazon().length > 0 || this.volume_affiliate_link_wani().length > 0 || this.volume_affiliate_link_fanzagames().length > 0
                        ? null
                        : m("div", [
                            m("label[for=volume_affiliate_link_store]", "Link1 (Store)"),
                            m("input[type=text][name=volume_affiliate_link_store]", {
                                onkeyup: m.withAttr("value", this.volume_affiliate_link_store),
                                value: this.volume_affiliate_link_store(),
                            }),
                        ]),
                      this.volume_affiliate_link_amazon().length > 0 || this.volume_affiliate_link_store().length > 0
                          ? null
                          : m("div", [
                                m("label[for=volume_affiliate_link_wani]", "Link1 (Publisher)"),
                                m("input[type=text][name=volume_affiliate_link_wani]", {
                                    onkeyup: m.withAttr("value", this.volume_affiliate_link_wani),
                                    value: this.volume_affiliate_link_wani(),
                                }),
                            ]),
                      this.volume_affiliate_link_amazon().length > 0 || this.volume_affiliate_link_store().length > 0
                          ? null
                          : m("div", [
                                m("label[for=volume_affiliate_link_fanzagames]", "Link1 (Fanza Games)"),
                                m("input[type=text][name=volume_affiliate_link_fanzagames]", {
                                    onkeyup: m.withAttr("value", this.volume_affiliate_link_fanzagames),
                                    value: this.volume_affiliate_link_fanzagames(),
                                }),
                            ]),
                      this.volume_affiliate_link_fanzagames().length > 0 || this.volume_affiliate_link_wani().length > 0 || this.volume_affiliate_link_store().length > 0
                          ? null
                          : m("div", [
                                m("label[for=volume_affiliate_link_amazon]", "Link1 (Amazon)"),
                                m("input[type=text][name=volume_affiliate_link_amazon]", {
                                    onkeyup: m.withAttr("value", this.volume_affiliate_link_amazon),
                                    value: this.volume_affiliate_link_amazon(),
                                }),
                            ]),
                      this.volume_affiliate_link_fanzagames().length > 0
                          ? null
                          : [
                                m("div", [
                                    m("label[for=volume_affiliate_link_dmm]", "Link2 (DMM/Fanza)"),
                                    m("input[type=text][name=volume_affiliate_link_dmm]", {
                                        onkeyup: m.withAttr("value", this.volume_affiliate_link_dmm),
                                        value: this.volume_affiliate_link_dmm(),
                                    }),
                                ]),
                                m("div", [
                                    m("label[for=volume_affiliate_link_dlsite]", "Link3 (DLsite)"),
                                    m("input[type=text][name=volume_affiliate_link_dlsite]", {
                                        onkeyup: m.withAttr("value", this.volume_affiliate_link_dlsite),
                                        value: this.volume_affiliate_link_dlsite(),
                                    }),
                                ]),
                            ],
                  ]
                : null,
            this.type() == "faq"
                ? m("div", [
                      m("label[for=fqtype]", "Type"),
                      m(
                          "select",
                          {
                              onchange: m.withAttr("value", this.fqtype),
                              value: this.fqtype(),
                          },
                          [
                              m("option", { value: "all", selected: this.fqtype() == "all" || !this.fqtype() }, "all"),
                              m("option", { value: "subscription", selected: this.fqtype() == "subscription" }, "subscription"),
                              m("option", { value: "store", selected: this.fqtype() == "store" }, "store"),
                          ],
                      ),
                  ])
                : null,
            this.type() === "status"
            ? [
                m("div", [
                    m("label[for=status_priority]", "priority"),
                    m("select[name=status_priority]",
                    {
                        onchange: m.withAttr("value", this.status_priority),
                        value: this.status_priority(),
                    },
                    [
                        m("option[value=高]", "High"),
                        m("option[value=中]", "Middle"),
                        m("option[value=低]", "Low"),
                    ])
                ]),
                m("div", [
                    m("label[for=status_begin]", "begin"),
                    m("input[type=text][name=status_begin][autocomplete=off]", {
                        onchange: m.withAttr("value", this.status_begin),
                        value: this.status_begin(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                    "　",
                    m("input[type=text][name=status_begin_time]", {
                        value: this.status_begin_time(),
                        onchange: m.withAttr("value", this.status_begin_time)
                    }),
                ]),
                m("div", [
                    m("label[for=status_update]", "last update"),
                    m("input[type=text][name=status_begin][autocomplete=off]", {
                        onchange: m.withAttr("value", this.status_update),
                        value: this.status_update(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                    "　",
                    m("input[type=text][name=status_update_time]", {
                        value: this.status_update_time(),
                        onchange: m.withAttr("value", this.status_update_time)
                    }),
                ]),
                m("div", [
                    m("label[for=status_end]", "end"),
                    m("input[type=text][name=status_begin][autocomplete=off]", {
                        onchange: m.withAttr("value", this.status_end),
                        value: this.status_end(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                    "　",
                    m("input[type=text][name=status_end_time]", {
                        value: this.status_end_time(),
                        onchange: m.withAttr("value", this.status_end_time)
                    }),
                ]),
                m("div", [
                    m("label[for=expiry]", "expiry"),
                    m("input[type=text][name=expiry][autocomplete=off]", {
                        onchange: m.withAttr("value", this.expiry),
                        value: this.expiry(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                    "　",
                    m("input[type=text][name=expiry_time]", {
                        value: this.expiry_time(),
                        onchange: m.withAttr("value", this.expiry_time)
                    }),
                ]),
                m("div", [
                    m("label", "services"),
                    m("input[type=text][name=status_services]", {
                        value: this.status_services(),
                        onchange: m.withAttr("value", this.status_services)
                    }),
                ]),
                m("div", [
                    m("label", "Scheduled"),
                    m("input[type=checkbox][name=status_scheduled]", {
                        checked: (this.status_scheduled() ? "checked" : ""),
                        onchange: m.withAttr("checked", this.status_scheduled)
                    }),
                ]),
            ]
            : null,
            this.type() == "article" || this.type() == "faq" || this.type() == "announcement" || this.type() == "tweet" || this.type() == "status"
                ? m("div", [
                      m("label[for=body]", "Body"),
                      m("textarea[name=body]", {
                          onkeyup: m.withAttr("value", this.body),
                          value: this.body(),
                      }),
                  ])
                : null,
            ["volume", "tankoubon", "volume_affiliate"].indexOf(this.type()) == -1
                ? null
                : m("div", [
                      m("label", "MSRP"),
                      m("input[type=text][name=msrp]", {
                          value: this.msrp(),
                          onkeyup: m.withAttr("value", this.msrp),
                      }),
                  ]),
            this.type() != "volume_affiliate"
                ? null
                : m("div", [
                      m("label", "Not products"),
                      m("input[type=checkbox][name=notproducts]", {
                          checked: this.notproducts() ? "checked" : "",
                          onchange: m.withAttr("checked", this.notproducts),
                      }),
                      m("label[for=notproducts]", "Only displayed on the artist page."),
                  ]),
            this.type() != "chapter"  && this.type() != "sample"
                ? null
                : m("div", [
                      m("label[for=censorship]", "Censorship"),
                      m(
                          "select[name=censorship]",
                          {
                              onchange: m.withAttr("value", this.censorship),
                              value: this.censorship(),
                          },
                          ["", "白抜き修正", "黒棒による修正", "モザイク修正", "単行本修正"].map((value, i) =>
                              m("option", { value: value, selected: value == this.censorship() ? "selected" : "" }, value),
                          ),
                      ),
                  ]),
             this.type() === "chapter" || this.type() === 'volume_affiliate'
                ? [
                    m("div", [
                      m("label[for=naughty]", "Public scope"),
                      m("span", {style: 'margin-top: -.2em;'}, [
                        m("p", {style: 'display: block;'}, [
                            m("input[type=radio][name=public_scope_public][id=public_scope_public]", {
                                checked: this.public_scope() === "public",
                                onclick: _ => { this.public_scope("public") },
                                style: 'display: inline-block; float: left;'
                            }),
                            m("label[for=public_scope_public]", {style: 'margin: -.2em 1em .5em 1em;'}, [
                                m("span", { style: 'margin-right: 1em;' }, '🟢 Public'),
                                m("span", { style: "color: #888888; font-size: .8em" }, 'Thumbnail is visible to guests.')
                            ]),
                        ]),
                        m('br'),
                        m("p", {style: 'display: block;'}, [
                            m("input[type=radio][name=public_scope_naughty][id=public_scope_naughty]", {
                                checked: this.public_scope() === "naughty",
                                onclick: _ => { this.public_scope("naughty") },
                                style: 'display: inline-block; float: left;'
                            }),
                            m("label[for=public_scope_naughty]", {style: 'margin: -.2em 1em .5em 1em;'}, [
                                m("span", { style: 'margin-right: 1em;' }, '🔞 18+'),
                                m("span", { style: "color: #888888; font-size: .8em" }, 'Title page exposes nipples, genitals etc. Thumbnail is hidden for guests.')
                            ]),
                        ]),
                        m('br'),
                        m("p", {style: 'display: block;'}, [
                            m("input[type=radio][name=public_scope_access][id=public_scope_access]", {
                                checked: this.public_scope() === "access",
                                onclick: _ => { this.public_scope("access") },
                                style: 'display: inline-block; float: left;'
                            }),
                            m("label[for=public_scope_access]", {style: 'margin: -.2em 1em .5em 1em;'}, [
                                m("span", { style: 'margin-right: 1em;' }, '⛔ Restricted'),
                                m("span", { style: "color: #888888; font-size: .8em" }, 'Title page has loli, rapey, etc contents. Or just 1-2 pages. No thumbnail for guests.')
                            ]),
                        ]),
                        m('br'),
                        m("p", {style: 'display: block;'}, [
                            m("input[type=radio][name=public_scope_authenticated_only][id=public_scope_authenticated_only]", {
                                checked: this.public_scope() === "authenticated_only",
                                onclick: _ => { this.public_scope("authenticated_only") },
                                style: 'display: inline-block; float: left;'
                            }),
                            m("label[for=public_scope_authenticated_only]", {style: 'margin: -.2em 1em .5em 1em;'}, [
                                m("span", { style: 'margin-right: 1em;' }, '🔝 Redirect'),
                                m("span", { style: "color: #888888; font-size: .8em" }, 'Title of comic is dangerous. Guests are redirected to login screen. Search engines cannot crawl.')
                            ]),
                        ]),
                      ])
                    ]),
                    hyoshiIsSet && this.public_scope() !== 'public' && this.public_scope() !== 'naughty'
                        ? m(`p.${css.warn_metadata}`, m.trust("<strong>Warning:</strong> this can't be viewed by unregistered guests"))
                        : "",
                ]
                : null,
            this.type() === "tankoubon" || this.type() === "chapter" || this.type() === "volume" || this.type() === "physical"
                ? [
                    m("div", [
                        m("label", "Premium"),
                        m("input#premium[type=checkbox][name=premium]", {
                            checked: this.premium(),
                            onchange: m.withAttr("checked", this.premium),
                        }),
                        m("label[for=premium]", "Is Komiflo Plus or Store contents"),
                    ])
                ]
                : null,       
            this.type() == "announcement"
                ? m("div", [
                      m("label[for=body]", "URL"),
                      m("input[type=text][name=url]", {
                          onkeyup: m.withAttr("value", this.url),
                          value: this.url(),
                          placeholder: "/comics/123",
                      }),
                  ])
                : null,
            this.type() == "announcement"
                ? m("div", [
                      m("label[for=body]", "Image Position"),
                      m(
                          "select",
                          {
                              onchange: m.withAttr("value", this.image_position),
                              value: this.image_position(),
                          },
                          [
                              m("option", { value: "none", selected: this.image_position() == "none" || !this.image_position() }, "none"),
                              m("option", { value: "left", selected: this.image_position() == "left" }, "left (text right)"),
                              m("option", { value: "above_full", selected: this.image_position() == "above_full" }, "above full width (text below)"),
                              m("option", { value: "above_half", selected: this.image_position() == "above_half" }, "above half width (text below)"),
                          ],
                      ),
                  ])
                : null,
            this.type() === "free_trial"
                ? [
                      m("div", [
                          m("label", "Length (months)"),
                          m("input#duration_month[type=text][name=duration_month]", {
                              value: this.duration_month(),
                              onchange: m.withAttr("value", this.duration_month),
                          }),
                      ]),
                      m("div", [
                          m("label", "Length (days)"),
                          m("input#duration_day[type=text][name=duration_day]", {
                              value: this.duration_day(),
                              onchange: m.withAttr("value", this.duration_day),
                          }),
                      ]),
                      m("div", [m("label", "Lander text"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                      m("div", [
                          m("label", "Age gate 1"),
                          m("span", "JA:　"),
                          m("input#lander_text_agegate1_ja[type=text][name=lander_text_agegate1_ja]", {
                              value: this.lander_text_agegate1_ja(),
                              onchange: m.withAttr("value", this.lander_text_agegate1_ja),
                          }),
                          m("label", "　default: あとでみます"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "EN:　"),
                          m("input#lander_text_agegate1_en[type=text][name=lander_text_agegate1_en]", {
                              value: this.lander_text_agegate1_en(),
                              onchange: m.withAttr("value", this.lander_text_agegate1_en),
                          }),
                          m("label", "　default: Remind me later"),
                      ]),
                      m("div", [
                          m("label", "Button text 1"),
                          m("span", "JA:　"),
                          m("input#lander_text_button1_ja[type=text][name=lander_text_button1_ja]", {
                              value: this.lander_text_button1_ja(),
                              onchange: m.withAttr("value", this.lander_text_button1_ja),
                          }),
                          m("label", "　default: 今すぐ読み始める"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "EN:　"),
                          m("input#lander_text_button1_en[type=text][name=lander_text_button1_en]", {
                              value: this.lander_text_button1_en(),
                              onchange: m.withAttr("value", this.lander_text_button1_en),
                          }),
                          m("label", "　default: Start reading now"),
                      ]),
                      m("div", [
                          m("label", "Button text 2"),
                          m("span", "JA:　"),
                          m("input#lander_text_button2_ja[type=text][name=lander_text_button2_ja]", {
                              value: this.lander_text_button2_ja(),
                              onchange: m.withAttr("value", this.lander_text_button2_ja),
                          }),
                          m("label", "　default: 無料体験を始める"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "EN:　"),
                          m("input#lander_text_button2_en[type=text][name=lander_text_button2_en]", {
                              value: this.lander_text_button2_en(),
                              onchange: m.withAttr("value", this.lander_text_button2_en),
                          }),
                          m("label", "　default: Start my free trial"),
                      ]),
                      m("div", [
                          m("label", "Appeal text 1"),
                          m("span", "JA:　"),
                          m("input#lander_text_appeal1_ja[type=text][name=lander_text_appeal1_ja]", {
                              value: this.lander_text_appeal1_ja(),
                              onchange: m.withAttr("value", this.lander_text_appeal1_ja),
                          }),
                          m("label", "　default: 今すぐ無料でおためし"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "EN:　"),
                          m("input#lander_text_appeal1_en[type=text][name=lander_text_appeal1_en]", {
                              value: this.lander_text_appeal1_en(),
                              onchange: m.withAttr("value", this.lander_text_appeal1_en),
                          }),
                          m("label", "　default: Try Komiflo now, for free."),
                      ]),
                      m("div", [m("label", "Lander promotional image"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                      m("div", [
                          m("label", ""),
                          m("span", "Link"),
                          m("input#lander_img_link[type=text][name=lander_img_link]", {
                              value: this.lander_img_link(),
                              onchange: m.withAttr("value", this.lander_img_link),
                          }),
                          m("span", " Relative path (e.g.: /?step=4)"),
                      ]),
                      m("div", [
                          m("label", "Desktop"),
                          m("span", "Image"),
                          m("input#lander_img_d[type=text][name=lander_img_d]", {
                              value: this.lander_img_d(),
                              onchange: m.withAttr("value", this.lander_img_d),
                          }),
                          m("span", " Relative path (e.g.: /assets/image.png)"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "Size (%)"),
                          m("input#lander_img_d_size[type=text][name=lander_img_d_size]", {
                              value: this.lander_img_d_size(),
                              onchange: m.withAttr("value", this.lander_img_d_size),
                          }),
                          m("span", " 0 - 100"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "Position (%)"),
                          m("input#lander_img_d_position[type=text][name=lander_img_d_position]", {
                              value: this.lander_img_d_position(),
                              onchange: m.withAttr("value", this.lander_img_d_position),
                          }),
                          m("span", " 0 - 100 (e.g.: 30, 50)"),
                      ]),
                      m("div", [
                          m("label", "Mobile"),
                          m("span", "Image"),
                          m("input#lander_img_m[type=text][name=lander_img_m]", {
                              value: this.lander_img_m(),
                              onchange: m.withAttr("value", this.lander_img_m),
                          }),
                          m("span", " Relative path (e.g.: /assets/image.png)"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "Size (%)"),
                          m("input#lander_img_m_size[type=text][name=lander_img_m_size]", {
                              value: this.lander_img_m_size(),
                              onchange: m.withAttr("value", this.lander_img_m_size),
                          }),
                          m("span", " 0 - 100"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("span", "Position (%)"),
                          m("input#lander_img_m_position[type=text][name=lander_img_m_position]", {
                              value: this.lander_img_m_position(),
                              onchange: m.withAttr("value", this.lander_img_m_position),
                          }),
                          m("span", " 0 - 100 (e.g.: 30, 50)"),
                      ]),
                      m("div", [m("label", "Registration screens"), m("hr", { style: "transform: translateY(15px);" }), m("br")]),
                      m("div", [
                          m("label[for=register_anime1]", "Animation"),
                          m(
                              "select[name=register_anime1]",
                              {
                                  onchange: m.withAttr("value", this.register_anime1),
                                  value: this.register_anime1(),
                              },
                              [m("option[value=0]", "Type 1 (default)"), m("option[value=2]", "Type 2 (bonus free month)")],
                          ),
                      ]),
                      m("div", [
                          m("label", "Appeal text 1"),
                          m("label", "JP:　"),
                          m("input#register_text_appeal1_ja[type=text][name=register_text_appeal1_ja]", {
                              value: this.register_text_appeal1_ja(),
                              onchange: m.withAttr("value", this.register_text_appeal1_ja),
                          }),
                          m("label", "　default: ●●の無料期間"),
                      ]),
                      m("div", [
                          m("label", ""),
                          m("label", "EN:　"),
                          m("input#register_text_appeal1_en[type=text][name=register_text_appeal1_en]", {
                              value: this.register_text_appeal1_en(),
                              onchange: m.withAttr("value", this.register_text_appeal1_en),
                          }),
                          m("label", "　default: ●● month free trial"),
                      ]),
                  ]
                : null,
            m("div", [m("button[type=submit]", "Save")]),
        ]);
    },
};

const uploader = {
    oninit(vnode) {
        this.busy = false;
        this.progressValue = 0;
        this.progressMax = 1;
        this.progressFile = 0;
        this.progressFileMax = 1;
        this.fileList = [];

        this.content = vnode.attrs.content;

        this.submit = e => {
            const fd = new FormData(e.target);

            if (Array.from(fd.values()).some(e => ["application/zip", "application/x-zip-compressed"].includes(e.type))) {
                if (Array.from(fd.values()).length !== 1) {
                    alert("sorry, currently you can upload 1 zip file per operation.");
                } else {
                    this.uploadZip(fd.values().next().value);
                }

                return;
            }

            const xhr = new XMLHttpRequest();

            xhr.addEventListener("load", ev => {
                this.busy = false;
                vnode.attrs.reload();
            });

            xhr.upload.addEventListener("progress", ev => {
                this.progressValue = ev.loaded;
                this.progressMax = ev.total;
                this.progressFile = this.fileList.filter(f => f < ev.loaded).length;
                m.redraw();
            });

            xhr.addEventListener("error", err => {
                alert("Upload error!!");
                this.busy = false;
            });

            this.progressValue = 0;
            this.progressMax = 1;
            this.busy = true;

            const filelist = document.getElementById("filelist").files;
            const filesize = [];
            let size = 0,
                i = 0;
            for (i; filelist[i]; i++) {
                size += filelist[i].size + 200;
                filesize.push(size);
            }
            this.progressFile = 0;
            this.progressFileMax = i;
            this.fileList = filesize;

            xhr.open("POST", `${baseUrl}/content/id/${vnode.attrs.content().id}/files`);
            xhr.withCredentials = true;
            xhr.send(fd);

            return false;
        };

        this.uploadZip = async file => {
            const res = await getAWSToken();

            const s3 = new AWS.S3({
                region: "ap-northeast-1",
                credentials: res.credentials,
            });
            this.busy = true;
            m.redraw();

            s3.upload(
                {
                    Body: file,
                    ContentType: file.type,
                    Bucket: config.originalsBucket,
                    Key: `zips/contents/${vnode.attrs.content().id}.zip`,
                },
                err => {
                    this.busy = false;
                    m.redraw();
                    if (err) {
                        alert(err);
                    } else {
                        alert("successfully started upload process. please wait ~1 minute, then reload the page.");
                    }
                },
            ).on("httpUploadProgress", ev => {
                this.progressValue = ev.loaded;
                this.progressMax = ev.total;
                m.redraw();
            });
        };
    },

    view() {
        // Only for chapters and articles
        if (!this.content.type == "chapter" && !this.content.type == "article") {
            return;
        }

        return m(
            "form",
            {
                enctype: "multipart/form-data",
                onsubmit: e => {
                    this.submit(e);
                    e.preventDefault();
                },
            },
            [
                m("hr"),
                m("h2", "Upload Files"),
                m("input#filelist[type=file][name=files][multiple=multiple]"),
                !this.busy
                    ? m("button", { type: "submit" }, "Upload")
                    : [
                          m("span", `Uploading...[ ${this.progressFile} / ${this.progressFileMax} ]`),
                          m("progress", {
                              value: this.progressValue,
                              max: this.progressMax,
                          }),
                          m("span", parseInt((this.progressValue / this.progressMax) * 100) + "%"),
                      ],
            ],
        );
    },
};

const imageEditor = {
    oninit(vnode) {
        // Get label attrs
        this.labels = m.prop([]);

        getAttributeChildren("label").then(
            res => {
                this.labels(res.children);
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );

        this.content = vnode.attrs.content;

        this.labelEditing = m.prop(false);

        this.labelToggle = attr => {
            if (this.labelEditing() != attr) {
                this.labelEditing(attr);
            }
        };

        this.labelGrid = m.prop(false);
        this.unLabelGrid = m.prop(false);

        this.assignAttr = img => {
            if (this.labelEditing()) {
                console.log(this.labelEditing());
                // console.log(img);
            }
        };

        this.namedOrigs = {
            cover: null,
            sample: null,
            header: null,
            splash_desktop: null,
            splash_mobile: null,
        };

        if (!this.content()) {
            throw new Error("imageEditor loaded before content fetched");
        }

        this.width = 247;
        this.height = 500;

        this.isCover = img => img.ident === "cover";

        this.isCensoredCover = img => img.ident === "censored_cover";

        this.isHeader = img => img.ident === "header";

        this.isSplashDesktop = img => img.ident === "splash_desktop";

        this.isSplashMobile = img => img.ident === "splash_mobile";

        this.isSample = img => img.original === this.namedOrigs.sample;

        this.setLabel = (img, i) => {
            if (this.labelGrid() || this.unLabelGrid()) {
                if (!img.labels[this.labelEditing()] || typeof img.labels[this.labelEditing()] !== "object") {
                    img.labels[this.labelEditing()] = [];
                }
                // img.labels[this.labelEditing()][i] = !img.labels[this.labelEditing()][i];
                img.labels[this.labelEditing()][i] = Boolean(this.labelGrid());
            } else if (typeof img.labels[this.labelEditing()] === "object") {
                img.labels[this.labelEditing()] = false;
            } else {
                const ordinality = img.ordinality;

                if (this.shiftSelect() === true) {
                    this.shiftSelect(ordinality);
                    m.redraw();
                } else if (this.shiftSelect() !== false) {
                    // Select everything between this.shiftSelect() and img.ordinality
                    let setting = 0;

                    if (ordinality < this.shiftSelect()) {
                        // select from right to left
                        for (let i = this.shiftSelect(); i >= ordinality; --i) {
                            if (!this.content().imgs[i].labels) {
                                this.content().imgs[i].labels = {};
                            }

                            if (setting === 0) {
                                setting = !this.content().imgs[i].labels[this.labelEditing()];
                            }

                            this.content().imgs[i].labels[this.labelEditing()] = setting;
                        }
                    } else if (this.shiftSelect() < ordinality) {
                        // select from left to right
                        for (let i = this.shiftSelect(); i <= ordinality; ++i) {
                            if (!this.content().imgs[i].labels) {
                                this.content().imgs[i].labels = {};
                            }

                            if (setting === 0) {
                                setting = !this.content().imgs[i].labels[this.labelEditing()];
                            }

                            this.content().imgs[i].labels[this.labelEditing()] = setting;
                        }
                    }

                    // reset shiftSelect
                    this.shiftSelect(true);
                } else {
                    img.labels[this.labelEditing()] = !img.labels[this.labelEditing()];
                }
            }
        };

        function addEvent(obj, evt, fn) {
            if (obj.addEventListener) {
                obj.addEventListener(evt, fn, false);
            } else if (obj.attachEvent) {
                obj.attachEvent("on" + evt, fn);
            }
        }

        const self = this;

        this.shiftSelect = m.prop(false);

        addEvent(document, "keydown", function(e) {
            e = e ? e : window.event;
            // if (e.keyCode == 91) {
            //     self.labelGrid(true);
            //     m.redraw();
            // } else if (e.keyCode == 18) {
            //     self.unLabelGrid(true);
            //     m.redraw();
            // }

            if (e.keyCode == 16 && "/^BODY/BUTTON$/".match(e.target.tagName)) {
                self.shiftSelect(true);
                m.redraw();
            }
        });

        addEvent(document, "keyup", function(e) {
            e = e ? e : window.event;
            // if (e.keyCode == 91) {
            //     self.labelGrid(false);
            //     m.redraw();
            // } else if (e.keyCode == 18) {
            //     self.unLabelGrid(false);
            //     m.redraw();
            // }

            if (e.keyCode == 16 && "/^BODY/BUTTON$/".match(e.target.tagName)) {
                self.shiftSelect(false);
                m.redraw();
            }
        });

        let mouseDown = false;

        addEvent(document, "mousedown", function(e) {
            e = e ? e : window.event;
            if (self.shiftSelect()) {
                e.preventDefault();
            }
            mouseDown = true;
        });

        addEvent(document, "mousemove", function(e) {
            e = e ? e : window.event;
            if (self.shiftSelect() && mouseDown) {
                e.preventDefault();
            }
        });

        addEvent(document, "mouseup", function(e) {
            e = e ? e : window.event;
            mouseDown = false;
        });

        let labelsToAssign = {};

        const assignLabels = labels => {
            labelsToAssign = {};

            if (labels) {
                // Pages to assign labels to
                for (let i = 0; i < labels.children.length; ++i) {
                    if (labels.children[i].pages) {
                        for (let j = 0; j < labels.children[i].pages.length; ++j) {
                            if (!labelsToAssign[labels.children[i].pages[j]]) {
                                labelsToAssign[labels.children[i].pages[j]] = [];
                            }
                            labelsToAssign[labels.children[i].pages[j]].push(labels.children[i].slug.replace("label-", ""));
                        }
                    }
                }
            }

            // Loop through images to assign any labels
            for (const i in this.content().imgs) {
                this.content().imgs[i].labels = {};
                const assign = labelsToAssign[this.content().imgs[i].original];
                if (assign) {
                    // Image has at least one label. Assign them.
                    for (let j = 0; j < assign.length; ++j) {
                        this.labelEditing(assign[j]);
                        this.setLabel(this.content().imgs[i], 0);
                    }
                }
            }
            this.labelEditing(false);
        };

        assignLabels(this.content().attributes.label);

        this.labelsReset = _ => {
            assignLabels(this.content().attributes.label);
        };

        this.labelsSave = _ => {
            // Original list of attributes
            const attrsBefore = recomputeAttrs(this.content()).map(a => a.id);

            const labelsToSave = {};
            const labelIds = {};

            // Prepare object of label arrays
            for (let i = 0; i < this.labels().length; ++i) {
                // Remove this attribute from the original array (to delete it)
                const index = attrsBefore.indexOf(this.labels()[i].id);
                if (index >= 0) {
                    attrsBefore.splice(index, 1);
                }

                const slug = this.labels()[i].slug.replace("label-", "");
                labelsToSave[slug] = [];
                labelIds[slug] = this.labels()[i].id;
            }

            // Loop through images and find labels for each image
            for (const i in this.content().imgs) {
                for (const label in this.content().imgs[i].labels) {
                    if (this.content().imgs[i].labels[label] == true) {
                        labelsToSave[label].push(this.content().imgs[i].original);
                    }
                }
            }

            // Build empty array of pages equal to length of original attributes
            const pages = [];
            for (let i = 0; i < attrsBefore.length; ++i) {
                pages.push([]);
            }

            const attrsAfter = [];

            for (const label in labelsToSave) {
                if (labelsToSave[label].length > 0) {
                    attrsAfter.push(labelIds[label]);
                    pages.push(labelsToSave[label]);
                }
            }

            const fieldsBefore = { attribute_ids: attrsBefore };

            const self = this;

            // Start by removing the new attributes (if any)
            // This is because the attribute patch will compare IDs but not pages
            patchContent(self.content().id, fieldsBefore).then(
                res => {
                    // If there are new attributes, (re-)assign them
                    if (attrsAfter.length > 0) {
                        const fieldsAfter = { attribute_ids: attrsBefore.concat(attrsAfter), attribute_pages: pages };

                        patchContent(self.content().id, fieldsAfter).then(
                            res => {
                                // Update list of labels so that Cancel button can later restore to newly saved labels
                                const labels = res.content.attributes.label;

                                if (labels) {
                                    for (let i = 0; i < labels.children.length; ++i) {
                                        labels.children[i].pages = labelsToSave[labels.children[i].slug.replace("label-", "")];
                                    }
                                }
                                self.labelEditing(false);
                                m.redraw();
                            },
                            err => {
                                console.error(err);
                                alert(JSON.stringify(err));
                            },
                        );
                    } else {
                        self.labelEditing(false);
                        m.redraw();
                    }
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        };

        this.setNamedOriginal = (name, img) => {
            setContentOriginalName(this.content().id, img.original, name).then(
                res => {
                    for (var index in this.content().imgs) {
                        const thisImg = this.content().imgs[index];
                        if (thisImg.ident == name) {
                            thisImg.ident = "";
                        } else if (thisImg.id == img.id) {
                            thisImg.ident = name;
                        }
                    }
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        };

        this.setCover = img => {
            this.setNamedOriginal("cover", img);
        };

        this.setCensoredCover = img => {
            this.setNamedOriginal("censored_cover", img);
        };

        this.setHeader = img => {
            this.setNamedOriginal("header", img);
        };

        this.setSplashMobile = img => {
            this.setNamedOriginal("splash_mobile", img);
        };

        this.setSplashDesktop = img => {
            this.setNamedOriginal("splash_desktop", img);
        };

        this.setSample = img => {
            this.setNamedOriginal("sample", img);
        };

        this.setChapterCover = img => {
            if (img.data.content_id) {
                delete img.data.content_id;
            } else {
                img.data.content_id = 'blank';
            }

            setContentOriginalData(this.content().id, img.original, img.data)
                .then(res => {
                    m.redraw();
                }).catch(err => {
                    alert('error');
                    window.location.reload();
                })
        };

        // To prevent changing ordinality while request is pending
        this.canChangeOrdinality = m.prop(true);
        this.approvalChangeOrdinality = m.prop(false);

        // Change ordinality of image
        this.changeOrdinality = (img, currentPos, newPos) => {
            const imagesOriginalOrder = this.images();

            if (newPos == "first") {
                img.ordinality = -1;
            } else if (newPos == "last") {
                img.ordinality = this.images().length;
            } else {
                // switch these two images
                this.images().find(el => el.ordinality == newPos).ordinality = currentPos;
                img.ordinality = newPos;
            }

            // reorder images
            this.images().sort((a, b) => (a.ordinality > b.ordinality ? 1 : b.ordinality > a.ordinality ? -1 : 0));

            // ordinality should always start from 0 with increments of 1
            this.images().map((img, i) => {
                img.ordinality = i;
            });

            const imageIds = this.images().map(el => el.original);
            const imageIdents = this.images().map(el => el.ident || "");

            // Prevent changing ordinality until request is complete
            this.canChangeOrdinality(false);

            // send request
            reorderContentOriginals(this.content().id, imageIds, imageIdents).then(
                res => {
                    this.canChangeOrdinality(true);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    this.canChangeOrdinality(true);

                    // reset order
                    this.images(imagesOriginalOrder);
                    m.redraw();
                },
            );

            m.redraw();
        };

        this.images = m.prop([]);

        // Delete an Original from this Content
        this.deleteImage = img => {
            var deleteImagePrompt = confirm("Are you sure you want to delete this image?");
            if (deleteImagePrompt) {
                deleteContentOriginal(this.content().id, img.original).then(
                    res => {
                        vnode.attrs.reload();
                    },
                    err => {
                        console.error(err);
                    },
                );
            }
        };

        this.deleteAllImages = () => {
            if (confirm("Are you sure you want to delete ALL images of this chapter?")) {
                deleteContentOriginalAll(this.content().id).then(
                    res => {
                        vnode.attrs.reload();
                    },
                    err => {
                        alert(JSON.stringify(err));
                    },
                );
            }
        };
    },

    onbeforeupdate(vnode, old) {
        if ((vnode.attrs.content().imgs ? Object.keys(vnode.attrs.content().imgs).length : 0) != this.images().length) {
            this.labelsReset();
            this.images([]);
        }
    },

    view() {
        const self = this;

        // Only for chapters and articles
        if (!this.content().type == "chapter" && !this.content().type == "article") {
            return;
        }

        let imgMaxwidth, viewport;

        if (this.content().type != "article") {
            imgMaxwidth = 247;
            viewport = "494_desktop_large";
        } else {
            imgMaxwidth = 494;
            viewport = "494_desktop_large";
        }

        if (this.images() && this.images().length < 1 && this.content().imgs) {
            // reset local variable
            var imgs = this.content().imgs;
            for (var index in imgs) {
                imgs[index].ordinality = parseInt(index);
                this.images().push(imgs[index]);
            }
        }

        // Check to see if various named images are set
        var coverIsSet = false,
            splashDesktopIsSet = false,
            splashMobileIsSet = false,
            headerIsSet = false,
            rightStart = false;

        if (this.images() && Object.keys(this.images()).length) {
            this.images().forEach(image => {
                if (image.ident == "cover") {
                    coverIsSet = true;
                }
                if (image.ident == "splash_desktop") {
                    splashDesktopIsSet = true;
                }
                if (image.ident == "splash_mobile") {
                    splashMobileIsSet = true;
                }
                if (image.ident == "header") {
                    headerIsSet = true;
                }
                if (image.labels.rightstart) {
                    rightStart = true;
                }
            });
        }

        const grid = [];
        for (let i = 1; i <= 9; ++i) {
            grid.push(i);
        }

        const imagecount = this.images().length ? "( " + this.images().length + " )" : "";

        return m(
            "div" +
                (this.content().type == "article" ? `.${css.articles}` : "") +
                (this.labelEditing() ? `.${css.labelediting}.label-${this.labelEditing()}` : "") +
                (rightStart ? ".rightStart" : ".leftStart"),
            [
                m("hr"),
                coverIsSet == false && (this.content().type == "chapter" || this.content().type == "volume_affiliate" || this.content().type == "sample")
                    ? m(`p.${css.warn}`, m.trust("<strong>Warning:</strong> cover has not been set"))
                    : "",
                m('div',[
                    m("h2", {style: 'display: inline-block; margin-right: 1em;'}, "Images " + imagecount),
                    !this.images() || !this.images().length
                        ? ''
                        : m(`a.${css.gotoreader}`, { 
                            href: `https://${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/comics/${this.content().id}/read`,
                            target: '_blank'
                        }, "View in reader")
                ]),
                !this.images() || !this.images().length
                    ? [m("p", "(None)")]
                    : [
                          this.labelEditing()
                              ? m(`.${css.labelssave}`, [
                                    m(
                                        "button",
                                        {
                                            onclick: this.labelsSave,
                                        },
                                        "Save",
                                    ),
                                    m(
                                        "button",
                                        {
                                            onclick: this.labelsReset,
                                        },
                                        "Cancel",
                                    ),
                                ])
                              : null,
                          m(
                              "p.labels",
                              this.labels().map(label => {
                                  const labelName = label.slug.replace("label-", "");

                                  return labelName.length > 1
                                      ? m(
                                            "button." + labelName + (this.labelEditing() == labelName ? `.${css.pressed}` : ""),
                                            {
                                                onclick: function() {
                                                    self.labelToggle(labelName);
                                                },
                                            },
                                            label.data.name,
                                        )
                                      : null;
                              }),
                          ),
                          this.labelEditing()
                              ? // ? m("p", "Select blocks by holding command; unselect blocks by holding alt.")
                                m(
                                    "p",
                                    "Click a page to label/unlabel it as containing " +
                                        this.labelEditing() +
                                        ". To label/unlabel multiple pages, hold shift while clicking the first and last pages to label.",
                                )
                              : null,
                          this.images().map(img => {
                              img.test = m.prop(false);

                              img.url =`${this.content().cdn_public}/${contentVariants.includes(viewport) ? viewport : contentVariants[contentVariants.length - 1]}/${img.filename}`;

                              const labels = [];

                              for (const label in img.labels) {
                                  if (img.labels[label] == true) {
                                      labels.push(label);
                                  }
                              }

                              // return m(`div.${css.image}` + (this.isCover(img) ? `.${css.cover}` : "") + (img.labels && img.labels[this.labelEditing()] === true ? `.${css.show}` : "") + (this.shiftSelect() === parseInt(img.ordinality) ? `.${css.shiftselect}` : ""), [
                              return m(
                                  `div.${css.image}` +
                                      (this.isCover(img) ? `.${css.cover}` : "") +
                                      (this.shiftSelect() === img.ordinality ? `.${css.shiftselect}` : ""),
                                  [
                                      m(
                                          `.${css.labels}`,
                                          this.labels().map(label => {
                                              label = label.slug.replace("label-", "");

                                              return m(
                                                  ".label.label-" + label + (typeof img.labels !== "undefined" && img.labels[label] == true ? ".show" : ""),
                                              );
                                          }),
                                      ),
                                      m(`div.${css.controls}`, [
                                          (() => {
                                            if (this.content().type == "chapter" || this.content().type == "volume_affiliate" || this.content().type == "subscription" || this.content().type == "physical") {
                                                return [
                                                    m(
                                                        "button",
                                                        {
                                                            onclick: this.setCover.bind(null, img),
                                                            disabled: this.isCover(img),
                                                        },
                                                        "Cover",
                                                    ),
                                                    m(
                                                        "button",
                                                        {
                                                            onclick: this.setCensoredCover.bind(null, img),
                                                            disabled: this.isCensoredCover(img),
                                                        },
                                                        "Censor",
                                                    ),
                                                ]
                                            } 
                                            if (this.content().type == "sample") {
                                                return [
                                                    m(
                                                        "button",
                                                        {
                                                            onclick: this.setCover.bind(null, img),
                                                            disabled: this.isCover(img),
                                                        },
                                                        "Cover",
                                                    ),
                                                    m(
                                                        "button",
                                                        {
                                                            onclick: this.setChapterCover.bind(null, img),
                                                            // disabled: this.isChapterCover(img),
                                                            style: img.data.content_id ? 'background-color: #0455A0;' : ''
                                                        },
                                                        "Chapter Cover",
                                                    ),
                                                ]
                                            }
                                            return [
                                                  m(
                                                      "button",
                                                      {
                                                          onclick: this.setHeader.bind(null, img),
                                                          disabled: this.isHeader(img),
                                                      },
                                                      "Header",
                                                  ),
                                                  m(
                                                      "button",
                                                      {
                                                          onclick: this.setSplashDesktop.bind(null, img),
                                                          disabled: this.isSplashDesktop(img),
                                                      },
                                                      "Splash desktop",
                                                  ),
                                                  m(
                                                      "button",
                                                      {
                                                          onclick: this.setSplashMobile.bind(null, img),
                                                          disabled: this.isSplashMobile(img),
                                                      },
                                                      "Splash mobile",
                                                  ),
                                              ]
                                          })(),
                                          /* m("button", {
                                    onclick: this.setSample.bind(null, img),
                                    disabled: this.isSample(img)
                                }, "Sample"),
                                !img.placeholder ? "" : m(`button.${css.regen}`, {
                                    onclick: this.resizeOriginal.bind(this, img.original)
                                }, `Regen`),*/
                                          m(
                                              "button",
                                              {
                                                  onclick: this.deleteImage.bind(this, img),
                                              },
                                              "Delete",
                                          ),
                                      ]),
                                      !this.canChangeOrdinality()
                                          ? null
                                          : m(`div.${css.ordinality}`, [
                                                img.ordinality <= 1
                                                    ? m(`button.${css.noaction}`, m.trust("&nbsp;"))
                                                    : m(
                                                          `button.${css.left}.${css.far}`,
                                                          {
                                                              onclick: this.changeOrdinality.bind(this, img, img.ordinality, "first"),
                                                          },
                                                          "⇤",
                                                      ),
                                                img.ordinality == 0
                                                    ? m(`button.${css.noaction}`, m.trust("&nbsp;"))
                                                    : m(
                                                          `button.${css.left}`,
                                                          {
                                                              onclick: this.changeOrdinality.bind(this, img, img.ordinality, img.ordinality - 1),
                                                          },
                                                          "←",
                                                      ),
                                                img.ordinality + 1 >= this.images().length
                                                    ? m(`button.${css.noaction}`, m.trust("&nbsp;"))
                                                    : m(
                                                          `button.${css.right}`,
                                                          {
                                                              onclick: this.changeOrdinality.bind(this, img, img.ordinality, img.ordinality + 1),
                                                          },
                                                          "→",
                                                      ),
                                                img.ordinality + 2 >= this.images().length
                                                    ? m(`button.${css.noaction}`, m.trust("&nbsp;"))
                                                    : m(
                                                          `button.${css.right}.${css.far}`,
                                                          {
                                                              onclick: this.changeOrdinality.bind(this, img, img.ordinality, "last"),
                                                          },
                                                          "⇥",
                                                      ),
                                            ]),

                                      // XXX: Probably want to put controls in here as well.
                                      !img.placeholder
                                          ? [
                                                m(
                                                    `.${css.overlay}` + (this.labelGrid() || this.unLabelGrid() ? `.${css.showoverlay}` : ""),
                                                    grid.map(i =>
                                                        m(
                                                            "div" +
                                                                (img.labels && img.labels[this.labelEditing()] && img.labels[this.labelEditing()][i]
                                                                    ? `.${css.show}`
                                                                    : ""),
                                                            {
                                                                onclick: this.setLabel.bind(this, img, i),
                                                                onmouseover: this.labelGrid() || this.unLabelGrid() ? this.setLabel.bind(this, img, i) : null,
                                                            },
                                                        ),
                                                    ),
                                                ),
                                                m("img", {
                                                    width: imgMaxwidth,
                                                    height: (imgMaxwidth / img.width) * img.height,
                                                    src: img.url,
                                                    onclick: _ => {
                                                        this.assignAttr(img);
                                                    },
                                                }),
                                            ]
                                          : m(
                                                `div.${css.placeholder}`,
                                                {
                                                    style: {
                                                        width: `${img.width - 2}px`,
                                                        height: `${img.height - 2}px`,
                                                        lineHeight: `${img.height - 2}px`,
                                                    },
                                                },
                                                `${img.width}x${img.height}`,
                                            ),
                                  ],
                              );
                          }),
                          m(
                              "p.labels",
                              m(
                                  "button",
                                  {
                                      onclick: _ => {
                                          this.deleteAllImages();
                                      },
                                  },
                                  "DELETE ALL IMAGES",
                              ),
                          ),
                      ],
            ],
        );
    },
};

const childrenList = {
    uniform: function(field) {
        if (!this.content().children) {
            return false;
        }
        Promise.all(this.content().children.map(c => patchContent(c.id, { [field]: this.content()[field] })))
            .then(_ => {
                alert("success!");
            })
            .catch(err => {
                alert(JSON.stringify(err));
            });
    },

    oninit(vnode) {
        this.content = vnode.attrs.content;

        this.deleteChild = (child, i) => {
            if (confirm("Are you sure? This cannot be reversed.")) {
                // user confirmed content unlink
                this.canChangeOrdinality(false);

                deleteContentChild(this.content().id, child.id).then(
                    res => {
                        // remove from array
                        this.content().children.splice(i, 1);
                        this.canChangeOrdinality(true);
                        m.redraw();
                    },
                    err => {
                        console.error(err);
                        this.canChangeOrdinality(true);
                        m.redraw();
                    },
                );
            }
        };

        // To prevent changing ordinality while request is pending
        this.canChangeOrdinality = m.prop(true);
        this.approvalChangeOrdinality = m.prop(false);

        // add ordinality to children (for sorting)
        if (this.content().children) {
            this.content().children.map((el, i) => {
                el.ordinality = i;
            });
        }

        // Change ordinality of children
        this.changeChildrenOrdinality = (child, currentPos, newPos) => {
            if (!this.approvalChangeOrdinality()) {
                return;
            }

            const childrenOriginalOrder = this.content().children;

            if (newPos == "first") {
                child.ordinality = -1;
            } else if (newPos == "last") {
                child.ordinality = this.content().children.length;
            } else {
                // switch these two children
                this.content().children.find(el => el.ordinality == newPos).ordinality = currentPos;
                child.ordinality = newPos;
            }

            // reorder children
            this.content().children.sort((a, b) => (a.ordinality > b.ordinality ? 1 : b.ordinality > a.ordinality ? -1 : 0));

            // ordinality should always start from 0 with increments of 1
            this.content().children.map((el, i) => {
                el.ordinality = i;
            });

            const elIds = this.content().children.map(el => el.id);

            // Prevent changing ordinality until request is complete
            this.canChangeOrdinality(false);

            // send request
            reorderContentChildren(this.content().id, elIds).then(
                res => {
                    this.canChangeOrdinality(true);
                    m.redraw();
                },
                err => {
                    console.error(err);
                    this.canChangeOrdinality(true);

                    // reset order
                    this.content().children(childrenOriginalOrder);
                    m.redraw();
                },
            );

            m.redraw();
        };

        this.setApprovalChangeOrdinality = () => {
            if (!this.approvalChangeOrdinality()) {
                if (confirm("use change order function?")) {
                    this.approvalChangeOrdinality(true);
                } else {
                    return;
                }
            }
        };
    },
    view() {
        // Only for magazines
        if (!this.content || !this.content()) {
            return "ddd";
        }
        if (["article", "volume", "tankoubon"].indexOf(this.content().type) == -1) {
            return;
        }
        if (this.content().type == "volume" && !this.content().attributes.magazine) {
            return;
        }

        return [
            m("hr"),
            m("h2", "Children"),
            m("p", [
                this.content().type == "tankoubon"
                    ? link(`/content/create?type=chapter&magazine=tankoubon_mode&issue=${this.content().id}`, "New chapter")
                    : "",
                this.content().type == "volume"
                    ? link(
                          `/content/create?type=chapter&magazine=${this.content().attributes.magazine.children[0].id}&issue=${this.content().id}`,
                          "New chapter",
                      )
                    : "",
                this.content().type == "article" ? link("/content/create?type=asset", "New asset") : "",
            ]),
            !this.content().children
                ? m("p", "None")
                : m("table", [
                      m("thead", [m("th", "#"), m("th", { colspan: "2" }, "Move"), m("th", "Title"), m("th", "Type") , m("th", "Unlink"), m("th", "# tags"), m("th", "View")]),
                      this.content().children.map((child, i) => {
                          if (!child) {
                              return null;
                          }

                          return m("tr", [
                              m("td", i + 1),
                              m(
                                  "td",
                                  i > 0
                                      ? this.canChangeOrdinality() && this.approvalChangeOrdinality()
                                          ? m(`a.${css.reorder}`, { onclick: this.changeChildrenOrdinality.bind(this, child, i, i - 1) }, "▲")
                                          : m("span", { onclick: this.setApprovalChangeOrdinality.bind() }, "▲")
                                      : "",
                              ),
                              m(
                                  "td",
                                  i + 1 < this.content().children.length
                                      ? this.canChangeOrdinality() && this.approvalChangeOrdinality()
                                          ? m(`a.${css.reorder}`, { onclick: this.changeChildrenOrdinality.bind(this, child, i, i + 1) }, "▼")
                                          : m("span", { onclick: this.setApprovalChangeOrdinality.bind() }, "▼")
                                      : "",
                              ),
                              m("td", link("/content/" + child.id + "/edit", child.data.title ? child.data.title : "#" + child.id)),
                              m("td", child.type),
                              m("td", m(`a.${css.reorder}`, { onclick: this.deleteChild.bind(this, child, i) }, "✕")),
                              m("td", m(`span.${css.tagcount}`, child.attributes_count || 0)),
                              m(
                                  "td",
                                  m(
                                      "a",
                                      {
                                          href: `https://${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/comics/${child.id}`,
                                          target: "_blank",
                                      },
                                      "➡",
                                  ),
                              ),
                          ]);
                      }),
                  ]),
            m(
                "button",
                {
                    onclick: _ => {
                        this.uniform("published");
                    },
                },
                "Uniform Published",
            ),
            m(
                "button",
                {
                    onclick: _ => {
                        this.uniform("expiry");
                    },
                },
                "Uniform Expiry",
            ),
            m(
                "button",
                {
                    onclick: _ => {
                        if (confirm("Overwrite child contents premium with the contents of the parent?")) {
                            this.uniform("premium");
                        }
                    },
                },
                "Uniform Premium",
            ),
        ];
    },
};

const sampleChapterThumbnailList = {
    oninit(vnode) {

        const c = vnode.attrs.content;
        this.parents = c().parents;
        this.type = c().type;
        this.content = vnode.attrs.content;
        this.mode = m.prop(1);
        this.hiddenList = m.prop([]);

        this.perentContentChapter = m.prop(null);
        if (this.parents && this.parents.length && this.parents[0].id && this.type == "sample") {
            getAdminContent(this.parents[0].id).then(
                res => {
                    this.perentContentChapter(res.content.children);
                    m.redraw();
                },
            );
        }
    },

    editInit() {
        this.hiddenList([])
    },

    submit() {

        const viewChapter = []
        this.perentContentChapter().forEach((c, i) => {
            if (c.type == "sample") return;
            let hidden = false;
            if (c.attributes && c.attributes['tags']) {
                c.attributes['tags'].children.forEach(attr => {
                    if (attr.slug == "hyoshi") hidden = true
                })
            }
            if (c.attributes && c.attributes['tags-hidden']) {
                c.attributes['tags-hidden'].children.forEach(attr => {
                    if (attr.slug == "hidden_table_of_content") hidden = true
                })
            } 
            if (hidden) return;
            if (this.hiddenList().indexOf(c.id) !== -1) return

            viewChapter.push(c.id)
        })

        let origids = [];
        let datalist = [];
        let count = 0;
        for (var i in this.content().imgs) {
            if (this.content().imgs[i].data && this.content().imgs[i].data.content_id) {
                origids.push(this.content().imgs[i].original)
                this.content().imgs[i].data.content_id = viewChapter[count] ? viewChapter[count] : 'blank';
                datalist.push(this.content().imgs[i].data)
                count += 1;
            }
        }

        setContentOriginalDatas(this.content().id, {origids, datalist})
        .then(res => {
            this.mode(1)
            m.redraw();
        }).catch(err => {
            alert('error');
            window.location.reload();
        })
    },

    view() {
        if (!this.type == "sample" || !this.perentContentChapter() || !this.content().imgs) {
            return "";
        }

        if (this.mode() == 2) {

            const imgList = [];
            for (var i in this.content().imgs) {
                if (this.content().imgs[i].data && this.content().imgs[i].data.content_id) {
                    imgList.push(this.content().imgs[i])
                }
            }

            let count = 0;

            return [
                m("hr"),
                m("h2", "Chapter Thumbnail"),
                m("table", { style: "width:500px;" }, [
                    m("thead", [m("th", "SampleImg"), m("th", "Name"), m("th", "Hide")]),
                    this.perentContentChapter().map((c, i) => {
                        if (c.type == "sample") return "";
                        let hidden = false;
                        if (c.attributes && c.attributes['tags']) {
                            c.attributes['tags'].children.forEach(attr => {
                                if (attr.slug == "hyoshi") hidden = true
                            })
                        }
                        if (c.attributes && c.attributes['tags-hidden']) {
                            c.attributes['tags-hidden'].children.forEach(attr => {
                                if (attr.slug == "hidden_table_of_content") hidden = true
                            })
                        } 
                        if (hidden) return "";

                        let img = null
                        if (this.hiddenList().indexOf(c.id) === -1 && imgList[count]) {
                            img = imgList[count].url
                            count += 1;
                        }
                        
                        return m("tr", [
                            m("td", 
                                img 
                                    ? m("img", {src: img, style: 'width: 247px; background-color: #f0f0f0;'}) 
                                    : m("img", {src: '/img/cover_bg.png', style: 'width: 240px; background-color: #f0f0f0;'}) 
                            ),
                            m(
                                "td",
                                m(
                                    "a",
                                    {
                                        href: `/content/${c.id}/edit`,
                                        oncreate: m.route.link,
                                        onupdate: m.route.link,
                                    },
                                    c.data.title,
                                ),
                            ),
                            m("td", m('input', {
                                type: 'checkbox',
                                oninput: e => {
                                    if (this.hiddenList().indexOf(c.id) == -1) {
                                        this.hiddenList().push(c.id)
                                    } else {
                                        this.hiddenList().splice(this.hiddenList().indexOf(c.id), 1)
                                    }
                                    m.redraw()
                                },
                                checked: this.hiddenList().indexOf(c.id) !== -1,
                            })),
                        ])
                    }),
                ]),
                m('div', {style: 'margin-bottom: 2em;'}, [
                    m(
                        "button",
                        {
                            onclick: _ => {
                                this.submit()
                            },
                            style: 'margin-right: 3em;'
                        },
                        "Submit",
                    ),
                    m(
                        "a",
                        {
                            onclick: _ => {
                                this.mode(1)
                            },
                            style: 'cursor: pointer;'
                        },
                        "Cancel",
                    ),
                ])
            ];
        }

        return [
            m("hr"),
            m("h2", "Chapter Thumbnail"),
            m('button', {style: ' margin-bottom:.5em', onclick: () => {
                this.mode(2);
                this.editInit();
            }}, 'Edit mode'),
            m("div", { style: "display: flex; align-items: start;" }, [
                this.perentContentChapter().map((c, i) => {
                    if (c.type == "sample") return "";

                    let hidden = false;
                    if (c.attributes && c.attributes['tags']) {
                        c.attributes['tags'].children.forEach(attr => {
                            if (attr.slug == "hyoshi") hidden = true
                        })
                    }
                    if (c.attributes && c.attributes['tags-hidden']) {
                        c.attributes['tags-hidden'].children.forEach(attr => {
                            if (attr.slug == "hidden_table_of_content") hidden = true
                        })
                    } 
                    if (hidden) return "";

                    let imgsrc = '/img/cover_bg.png';
                    for (var i in this.content().imgs) {
                        if (this.content().imgs[i].data && this.content().imgs[i].data.content_id == c.id) {
                            imgsrc = this.content().imgs[i].url;
                        }
                    }
                    return m("div", {style: 'display: inline-block; width: 145px; margin-right:.5em'}, [
                        m("img", {src: imgsrc, style: 'width: 100%; background-color: #f0f0f0;'}),
                        m("div", {style: 'font-size:.8em; height:100px;'}, c.data.title)
                    ])
                }),
            ])
            
        ]
    },
};

const itemsList = {
    oninit(vnode) {
        this.content = vnode.attrs.content;
        this.items = this.content().items;
    },
    view() {
        if (!this.items) {
            return "";
        }

        return [
            m("hr"),
            m("h2", "Items"),
            m("table", { style: "width:500px;" }, [
                m("thead", [m("th", "ID"), m("th", "Name"), m("th", "Type")]),
                this.items.map(i =>
                    m("tr", [
                        m(
                            "td",
                            m(
                                "a",
                                {
                                    href: `/items/${i.id}/edit`,
                                    oncreate: m.route.link,
                                    onupdate: m.route.link,
                                },
                                i.id,
                            ),
                        ),
                        m(
                            "td",
                            m(
                                "a",
                                {
                                    href: `/items/${i.id}/edit`,
                                    oncreate: m.route.link,
                                    onupdate: m.route.link,
                                },
                                i.name,
                            ),
                        ),
                        m("td", i.type),
                    ]),
                ),
            ]),
        ];
    },
};

const deleteContentButton = {
    buttonPressed: function() {
        const confirmPrompt =
            this.content().children && this.content().children.length > 0
                ? confirm("Are you sure this content should be deleted? This cannot be undone, and it will delete it's all children.")
                : confirm("Are you sure this content should be deleted? This cannot be undone.");
        if (confirmPrompt) {
            this.buttonPressConfirm();
        }
    },
    buttonPressConfirm: function() {
        deleteContent(this.content().id).then(
            res => {
                if (this.content().children && this.content().children.length > 0) {
                    Promise.all(this.content().children.map(c => deleteContent(c.id))).catch(err => {
                        alert(`Failed to delete children: ${JSON.stringify(err)}`);
                    });
                }
                alert("The content was deleted successfully");
                m.route.set("/content/type");
            },
            err => {
                console.error(err);
            },
        );
    },

    oninit(vnode) {
        this.content = vnode.attrs.content;
    },
    view() {
        return [
            m("hr"),
            m("h2", "Delete content"),
            m(
                "button",
                {
                    onclick: _ => {
                        this.buttonPressed();
                    },
                },
                "Delete",
            ),
        ];
    },
};

export default {
    $component: {
        handle(vnode) {
            this.content = m.prop(null);
            this.current_content_chapter_ids = m.prop([]);
            this.id = vnode.attrs.id;

            this.reload = () =>
                getAdminContent(this.id).then(
                    res => {
                        this.content(res.content);
                        this.current_content_chapter_ids(res.current_content_chapter_ids || []);
                        m.redraw();
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );

            this.reload();
        },
        oninit(vnode) {
            this.handle(vnode);
        },
        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id != old.attrs.id) {
                this.handle(vnode);
            }
        },
        onupdate(vnode) {
            if (vnode.attrs.id != this.id) {
                this.id = vnode.attrs.id;
                this.content(null);
                this.current_content_chapter_ids([])
                this.reload();
            }
        },

        view() {
            const c = this.content();

            if (!c) {
                return m("h1", "Loading");
            }

            return m("div", [
                m(metaEditor, {
                    content: this.content,
                    current_content_chapter_ids: this.current_content_chapter_ids,
                }),
                m(attrEditor, {
                    content: this.content,
                }),
                m(uploader, {
                    content: this.content,
                    reload: this.reload,
                }),
                m(childrenList, {
                    content: this.content,
                }),
                m(imageEditor, {
                    content: this.content,
                    reload: this.reload,
                }),
                m(sampleChapterThumbnailList, {
                    content: this.content,
                    reload: this.reload,
                }),
                m(itemsList, {
                    content: this.content,
                }),
                m(deleteContentButton, {
                    content: this.content,
                }),
            ]);
        },
    },
};
