"use strict";

import csv from "../utils/csv";
import { getReferrals } from "../api-client";

export const affiliateUnit = (kind) => {
    switch (kind) {
        case "artist":
            return 250;
        case "media":
            return 500;
        default:
            return 0;
    }
};

/**
 * @description Download Affiliate Report CSV
 * @param {string} startDate
 * @param {string} endDate
 */

export const affiliateCsv = (startDate, endDate, kind) => {
    getReferrals(startDate, endDate, 0, 999, kind).then(
        (res) => {
            const results = res.referrals.map((referral) => ({
                [kind]: referral.code,
                user_id: referral.user_id || "Unknown",
                new_users: referral.referrals_completed_count,
                earnings: referral.referrals_completed_count * affiliateUnit(kind),
            }));

            csv(`affiliate-${startDate}~${endDate}`, results);
        },
        (err) => {
            throw err;
        },
    );
};
