import dayjs from "dayjs";

/**
 * @description Delete Z
 * @param {string} utcTime (e.g. "2018-06-19T06:14:56.603Z")
 * @returns {string} Time (e.g. "2018-06-19T06:14:56.603")
 */
const deletez = (utcTime) => utcTime ? utcTime.replace("Z", "") : utcTime;

/**
 * @description Format date
 * @param {string} time Time (e.g. "2018-06-19T06:14:56.603Z")
 * @returns {string} formatted date (e.g. "2018.06.19 15:14")
 */
const dateFormat = (time) =>  {
    const date = dayjs(time)
    if (time && date.isValid()) {
        return date.format("YYYY.MM.DD HH:mm")
    } else {
        return '-'
    }
};

/**
 * @description Format date according to RFC3339
 * @param {string} day ANSIC-ish day, returned from datepicker (e.g. "Mon Jan 02 2006")
 * @param {string} time Time from time picker (e.g. "15:04")
 * @returns {string} RFC3339-formatted date. if day is empty, returns undefined. (e.g. "2006-01-02T15:04:05.999Z")
 */
const dateFormatRFC3339 = (day, time) => day ? new Date(`${day} ${time}`).toJSON() : undefined;

const objExistence = (obj, path) => {
    const keys = path.split('.')
    try {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (!key || !Object.prototype.hasOwnProperty.call(obj, key)) {
                return null
            }
            if (keys.length > 1) {
                return objExistence(obj[key], keys.splice(1).join('.'))
            }
            return obj[key]
        }
    } catch (e) {
        // console.error(e)
        return null
    }
    return null
}

const isStoreMode = () => { return localStorage.getItem('Mode') === 'store' };

export {deletez, dateFormat, dateFormatRFC3339, objExistence, isStoreMode};
