exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IXAYkBOjpcdgjRqPYyYa9 a{\n    width: 40px;\n    padding: .5em;\n    margin-right: .5em;\n    background-color: #ef4274;\n    color: white;\n    font-weight: bold;\n    border-radius: .5em;\n    text-align: center;\n    display: inline-block;\n}\n._1IXAYkBOjpcdgjRqPYyYa9 div{\n    display: inline-block;\n}\n._25xJmVH83osAXLV2wCkKbj a{\n    background-color: white !important;\n    color: #ef4274;\n    pointer-events: none;\n}\n.hc7YZc9u_d6Wq3r6MIw4c {\n    background-color: grey !important;\n    pointer-events: none;\n}\n", ""]);

// exports
exports.locals = {
	"paginate": "_1IXAYkBOjpcdgjRqPYyYa9",
	"now": "_25xJmVH83osAXLV2wCkKbj",
	"disabled": "hc7YZc9u_d6Wq3r6MIw4c"
};