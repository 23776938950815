"use strict";

import m from "mithril";
import config from "../../config";
import csv from "../../utils/csv";
import link from "../../utils/link";
import {
    getAttribute,
    getMetricsReport
} from "../../api-client";

export default {
    $navTitle: "Weeklies",
    $component: {
        oninit(vnode) {
            let self = this;

            self.formatDate = function(date) {
                return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-01";
            }

            self.ranking_type = m.prop('volume');

            self.date = m.prop(new Date());
            self.published_weeklies = m.prop([]);
            self.magazine = m.prop({})

            self.startDate = m.prop(new Date());
            self.startDate(self.formatDate(self.startDate()));
            self.future = m.prop(false);
            self.loading = m.prop(false);

            self.totalViews = m.prop(0);

            self.reports = m.prop([]);
            self.reportsForCSV = m.prop([]);

            self.formatReports = function() {
                let csv = []
                self.reports().forEach((report, index) =>{
                    let obj = {
                        "rank": index + 1,
                        "volume_title": self.magazine().name,
                        "order": report.ordinality,
                        "title": report.content.data.title,
                        "artist_name":  report.content.attributes ? report.content.attributes.artists.children[0].data.name : "",
                        "artist_id":  report.content.attributes ? report.content.attributes.artists.children[0].id : "",
                        "artist_slug":  report.content.attributes ? report.content.attributes.artists.children[0].slug : "",
                        "views_count": report.views_count,
                        "likes_count": report.content.likes_count,
                        "published": report.content.published,
                    }
                    csv.push(obj)
                })
                self.reportsForCSV(csv)
            }

                if(self.published_weeklies().length == 0) {
                    getAttribute(config.weekly_kairakuten_attribute_id).then(
                        (res) => {
                            self.published_weeklies(res.attribute.content);
                            m.redraw();
                        },
                        (err) => {
                            console.error(err);
                            alert(JSON.stringify(err));
                        }
                    );
                }

            self.setup = function() {
                if (new Date(self.startDate()) > new Date()) {
                    self.future(true);
                } else {
                    self.future(false);
                    if(Object.keys(self.magazine()).length != 0) {
                        self.loading(true);
                        getMetricsReport(self.magazine().id, self.ranking_type()).then(
                            (res) => {
                                self.loading(false);
                                self.reports(res.reports);
                                self.totalViews(0);
                                self.date(new Date(res.reports[0].content.published))
                                res.reports.forEach(metric => {
                                    self.totalViews(metric.views_count + self.totalViews());
                                });
                                self.formatReports()
                                m.redraw();
                            },
                            (err) => {
                                console.error(err);
                                self.error(err);
                                self.loading(false);
                            }
                        ); 
                    }
                }
            }

            this.setup();
        },

        view() {
            let self = this;

            let now = new Date();
            let dates = [];

            for (let year = now.getFullYear(); year >= 2017; year--) {
                for (let month = (year == now.getFullYear() ? now.getMonth() : 11); month >= 0; month--) {
                    dates.push(new Date(year, month, 1, 0, 0, 0));
                }
            }

            let lastDayOfMonth = new Date(2017, this.date().getMonth() + 1, 0, 0, 0, 0);

            let rankI = 0;

            return m("form", [
                m("h1", "Weekly magazine performance"),
                m("div", [
                    (this.published_weeklies().length > 0 ? m("div", [
                        m("label[for=magazine]", "Published weekly titles"),
                        m("select[name=magazine][id=magazine]",
                            {
                                onchange: function(e) {
                                self.magazine({"id":e.target.value, "name":e.target.options[e.target.selectedIndex].text});
                                self.setup();
                            },
                                value: this.magazine().id,
                            },
                            [m("option")].concat(
                                this.published_weeklies().map((magazine_title) => {
                                    return m("option", {
                                        value: magazine_title.id
                                    }, magazine_title.data.title);
                                })
                            )
                        ),
                    ]) : [
                            m("label[for=magazine]", "Published weekly titles"),
                            m("label","fetching...")
                        ]
                    ),
                ]),
                m("div", [
                    m("label", "Type"),
                    m("select", {
                            onchange: function(e) {
                                self.ranking_type(e.target.value);
                                self.setup();
                            },
                            value: this.ranking_type(),
                            disabled: this.loading() ? "disabled" : ""
                        },
                        [
                            m("option", { value: "volume", selected: this.ranking_type() == "volume" }, "Magazines"),
                            m("option", { value: "chapter", selected: this.ranking_type() == "chapter" }, "Chapters")
                        ])
                ]),
                this.loading()
                    ? [
                        m(".loading", [
                            m(".load"),
                            m("p", "Generating report..."),
                            m("p", "Please wait up to 1 minute for this report to be generated.")
                        ])
                    ]
                    : this.future()
                        ? m("p", "These metrics will be available in the future.")
                        : [
                            m("h2", this.magazine().name),
                            m("p", `${this.date().getFullYear()}年${this.date().getMonth() + 1}月${this.date().getDate()}日 - ${this.date().getFullYear()}年${this.date().getMonth() + 1}月${this.date().getDate()+7}日`),
                            m("table", [
                                m("thead", [
                                    m("th", "Rank"),
                                    this.ranking_type() == "chapter" ? m("th", "Order") : null,
                                    this.ranking_type() == "chapter" ? m("th", "Volume") : null,
                                    m("th", "Title"),
                                    this.ranking_type() == "chapter" ? m("th", "Artist") : null,
                                    m("th.right", "Views"),
                                    this.ranking_type() == "volume" ? m("th.right", "Share") : null,
                                    this.ranking_type() == "chapter" ? m("th.right", "Likes") : null,
                                ]),
                                this.reports().map(metric => {
                                    ++rankI;

                                    return m("tr", [
                                        m("td", rankI),
                                        this.ranking_type() == "chapter" ? m("td", metric.ordinality) : null,                                        
                                        this.ranking_type() == "chapter" ? m("td", this.magazine().name) : null,
                                        m("td", metric.content.data.title),
                                        this.ranking_type() == "chapter" ? m("td", metric.content.attributes ? link(`/attribute/${metric.content.attributes.artists.children[0].id}/view`, metric.content.attributes.artists.children[0].data.name) : "" ) : null,
                                        m("td.right", metric.views_count.toLocaleString()),
                                        this.ranking_type() == "volume" ? m("td.right", ((metric.views_count / self.totalViews()) * 100).toFixed(2) + "%") : null,
                                        this.ranking_type() == "chapter" ? m("td.right", metric.content.likes_count.toLocaleString()) : null,
                                    ])
                                }),
                                m("tr", [
                                    m("td", "Total"),
                                    this.ranking_type() == "chapter"
                                        ? [
                                            m("td", ""),
                                            m("td", ""),
                                            m("td", ""),
                                        ]
                                        : null,
                                    m("td", ""),
                                    m("td.right", self.totalViews().toLocaleString()),
                                    m("td", ""),
                                ])
                            ]),
                            self.reports().length
                                ? m("p", m("button", { onclick: function(e) { e.preventDefault(); csv(self.magazine().name, self.reportsForCSV()); } }, "Download CSV"))
                                : null,
                        ]
            ]);
        }
    }
}
