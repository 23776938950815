"use strict";

import m from "mithril";
import config from "../../config";
import autocomplete from "../../components/autocomplete";
import {
    postFilterNotification,
} from "../../api-client";
import {timeSelector} from "../../utils/input-component";

class Model {
    constructor(id) {
        this.work = m.prop({});

        this.fields = {
            users_include:   {
                value:  m.prop(""),
                type:   "textarea",
                title:  "group of users",
                output: ["integer", "array"],
                show:   m.prop(false),
            },
            email_addresses:   {
                value:  m.prop(""),
                type:   "textarea",
                output: ["array"],
                show:   m.prop(false),
            },
            content_id:   {
                value:  m.prop(""),
                type:   "text",
                title: "content ID",
            },
            show:   {
                value:  m.prop(false),
                type:   "checkbox",
                title:  "Show",
            },
            can_view_contents:   {
                value:  m.prop(""),
                type:   "select",
                title:  "access",
                options: m.prop([
                    {
                        value: "",
                        label: "either",
                    },
                    {
                        value: "yes",
                        label: "can view contents",
                    },
                    {
                        value: "no",
                        label: "can not view contents",
                    },
                ]),
            },
            subscription_provider: {
                value: m.prop(""),
                type: "select",
                title: "have added payment method",
                options: m.prop([
                    {
                        value: "",
                        label: "either",
                    },
                    {
                        value: "anotherlane",
                        label: "recurring AL subscription (Visa/Mastercard)",
                    },
                    {
                        value: "anotherlane_exclusive",
                        label: "ONLY recurring AL subscription (Visa/Mastercard)",
                    },
                    {
                        value: "anotherlane_main",
                        label: "ONLY subscription main card is AL (Visa/Mastercard)",
                    },
                    {
                        value: "sbps",
                        label: "recurring SBPS subscription (JCB/AmEx/Diners)",
                    },
                    {
                        value: "sbps_exclusive",
                        label: "ONLY recurring SBPS subscription (JCB/AmEx/Diners)",
                    },
                    {
                        value: "sbps_main",
                        label: "ONLY subscription main card is SBPS (JCB/AmEx/Diners)",
                    },
                    {
                        value: "bitcash",
                        label: "bitcash subscription",
                    },
                    {
                        value: "internal",
                        label: "prepaid card/access code",
                    },
                    {
                        value: "never",
                        label: "never had a payment method",
                    },
                ]),
            },
            with_user_attr:   {
                value:  m.prop(""),
                type:   "select",
                title:  "artists",
                options: m.prop([
                    {
                        value: "",
                        label: "include",
                    },
                    {
                        value: "no",
                        label: "exclude",
                    },
                    {
                        value: "yes",
                        label: "only",
                    },
                ]),
            },
            total_receipts_min:   {
                value:  m.prop(""),
                type:   "text",
                title: "minimum charges",
            },
            total_receipts_max:   {
                value:  m.prop(""),
                type:   "text",
                title: "maximum charges",
            },
            user_created:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created on",
            },
            user_created_before:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created before",
            },
            user_created_beyond:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user created on or after",
            },
            user_cancelled_before:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user cancelled, and subscription finished before",
            },
            user_cancelled_beyond:   {
                value:  m.prop(""),
                type:   "date",
                title:  "user cancelled, and subscription finished on or after",
            },
            cancel_reasons: {
                value: m.prop(""),
                type: "text",
                output: ["string", "array"],
            },
            users_exclude:   {
                value:  m.prop(""),
                type:   "textarea",
                title:  "exclude users",
                output: ["integer", "array"],
            },
        };

        this.out = {
            users:  m.prop(""),
        }


        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.schedule = {
            date:       m.prop(tomorrow),
            time:       m.prop(16),
            loading:    m.prop(false),
        }

        this.which_users = m.prop('all');
    }

    save() {
        let data = {};

        const fields = Object.keys(this.fields);

        for (let i = 0; i < fields.length; ++i) {
            const thisField = this.fields[fields[i]];
            data[fields[i]] = thisField.value();

            if (typeof thisField.output !== "undefined") {
                thisField.output.sort();

                for (let j = 0; j < thisField.output.length; ++j) {
                    if (thisField.output[j] === "integer") {
                        if (typeof data[fields[i]] === "object") {
                            for (let k = 0; k < data[fields[i]].length; ++k) {
                                data[fields[i]][k] = parseInt(data[fields[i]][k]);
                            }
                        } else {
                            data[fields[i]] = parseInt(data[fields[i]]);
                        }
                    } else if (thisField.output[j] === "array") {
                        if (data[fields[i]]) {
                            data[fields[i]] = data[fields[i]].replace(/\s/g, "").split(",");
                        } else {
                            data[fields[i]] = [];
                        }
                    }
                }
            }

            if (thisField.type === "date") {
                data[fields[i]] = data[fields[i]] ? new Date(data[fields[i]]).toJSON().split("T")[0] : "";
            }
        }

        // reset lists of users and emails
        this.out["users"]("");

        postFilterNotification(data).then(
            (res) => {
                this.out["users"]("");
                if (this.which_users() !== "emails") {
                    if (!res.users || res.users.length < 1) {
                        alert("Could not find any users with those conditions.")
                    } else {
                        for (let i = 0; i < res.users.length; ++i) {
                            if (this.out["users"]() != "") {
                                this.out["users"](this.out["users"]() + ",")
                            }

                            this.out["users"](this.out["users"]() + res.users[i])
                        }
                    }
                }
                alert("Create success.");
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );

        return false;
    }

    setUsers(which) {
        this.fields.users_include.value("");
        this.which_users(which);
        this.fields.users_include.show(which == "group");
        m.redraw();
    }
};

export default {
    oninit(vnode) {
        this.vm = new Model();
        this.vm = new Model(vnode.attrs.id);
    },

    view() {
        return !this.vm.work()
            ? null
            : this.vm.schedule.loading()
                ? [
                    m(".loading", [
                        m(".load"),
                        m("p", "Creating schedules..."),
                        m("p", "If you have selected many users, this may take a while.")
                    ])
                ]
                : [
                    m("form", { onsubmit : this.vm.save.bind(this.vm) }, [
                        m("h1", "Create filter annoucement"),
                        m("table", [
                            m("tr", [
                                m("th", "Conditions"),
                                m("th"),
                            ]),
                            m("tr", [
                                m("td", "selection pool"),
                                m("td", [
                                    m("p", [
                                        m("label", ""),
                                        m("input[type=radio][name=which_users][id=which_users_1]", {
                                            checked: this.vm.which_users() === "all",
                                            onclick: _ => { this.vm.setUsers("all") },
                                        }),
                                        m("label[for=which_users_1]", "all users"),
                                    ]),
                                    m("p", [
                                        m("label", ""),
                                        m("input[type=radio][name=which_users][id=which_users_2]", {
                                            checked: this.vm.which_users() === "group",
                                            onclick: _ => { this.vm.setUsers("group") },
                                        }),
                                        m("label[for=which_users_2]", "group of users"),
                                    ]),
                                ])
                            ]),
                            Object.keys(this.vm.fields).map(k => {
                                const field = this.vm.fields[k];
                                const label = m(`label[for=${k}]`, field.title || k.replace("_", " "));

                                if (typeof field.type == "undefined" || !field.type) return;

                                let input;

                                if (typeof field.show != 'undefined' && field.show() == false) return;

                                if (this.vm.which_users() === "emails" && k !== "email_addresses") return;

                                if (field.type === "text" || field.type === "date") {
                                    input = m("input[name=name]", {
                                        onchange: m.withAttr("value", field.value),
                                        value: field.value(),
                                        oncreate: (vnode) => {
                                            if (field.type == "date") {
                                                this.pikaday = new Pikaday({
                                                    field: vnode.dom
                                                });
                                            }
                                        },
                                        onremove: () => {
                                            if (field.type == "date") {
                                                this.pikaday.destroy();
                                            }
                                        },
                                    });
                                } else if (field.type === "textarea") {
                                    input = m("textarea[name=name]", {
                                        onkeyup: m.withAttr("value", field.value),
                                        value: field.value(),
                                    });
                                } else if (field.type === "checkbox") {
                                    input = m(`input[name=${k}][type=checkbox]`, {
                                        checked: field.value() == true ? "checked" : "",
                                        onchange: m.withAttr("checked", field.value),
                                    });
                                } else if (field.type === "select") {
                                    if (!field.value() && field.options().length > 0) {
                                        field.value(field.options()[0].value);
                                    }

                                    input = m(`select`,
                                    {
                                        onchange: m.withAttr("value", field.value),
                                        value: field.value(),
                                    },
                                    field.options().map(o => {
                                        return m(`option`, { value: o.value, selected: (o.value == field.value()) }, typeof o.label === "undefined" ? o.value : o.label)
                                    }));
                                }

                                if (!input) return;

                                return m("tr", [
                                    m("td", label),
                                    m("td", input),
                                ]);
                            }),
                            m("tr", [
                                m("td"),
                                m("td", [
                                    m("button[type=submit]", "Create"),
                                ])
                            ])
                        ]),
                    ]),
                    ];
    }
};
