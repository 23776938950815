exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NtKH7BnFkhD0itgSuGRCf {\n    display: inline-block;\n    position: relative;\n    min-width: none;\n}\n\n.NtKH7BnFkhD0itgSuGRCf input {\n    margin-right: 0;\n}\n\n._15SQihBSsOdFl2hr5IQ9fP {\n    position: absolute;\n    width: 100%;\n    z-index: 10;\n    top: 35px;\n}\n\n._3G76z5ILA2IALl3ClA1NdA {\n    position: relative;\n    display: block;\n    box-sizing: border-box;\n    color: #888;\n    background: #FFF;\n    border: 1px solid #888;\n    border-top: none;\n    cursor: pointer;\n}\n._3G76z5ILA2IALl3ClA1NdA:first-child {\n    border-top: 1px solid #888;\n}\n._3G76z5ILA2IALl3ClA1NdA:last-child {\n    border-radius: 0 0 .25em .25em;\n}\n\n._3G76z5ILA2IALl3ClA1NdA > span {\n    color: #444;\n    width: 100%;\n    margin: 0 !important;\n    padding: 4px 0;\n}\n\n._3G76z5ILA2IALl3ClA1NdA> span:hover {\n    color: #fff;\n    background: #ef4274;\n    transition: all 0.1s ease;\n}\n", ""]);

// exports
exports.locals = {
	"component": "NtKH7BnFkhD0itgSuGRCf",
	"results": "_15SQihBSsOdFl2hr5IQ9fP",
	"result": "_3G76z5ILA2IALl3ClA1NdA"
};