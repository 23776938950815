exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2WEfkzcaQIsRIpYXwWfddq {\n    display: inline-block;\n    border: 1px solid #333;\n    background-color: #ddd;\n    vertical-align: top;\n    text-align: center;\n    color: #666;\n    font-size: 22pt;\n    font-weight: bold;\n}\n\n.EZTRh2o1o6fJKzBd7xbvH {\n    border: 1px solid #000;\n    margin: 1em 0;\n}\n\n.EZTRh2o1o6fJKzBd7xbvH img {\n    display: block;\n    width: 100%;\n}\n\n.EZTRh2o1o6fJKzBd7xbvH div {\n    opacity: 0;\n    position: absolute;\n    width: 17.5%;\n    height: 100%;\n    z-index: 1;\n    background: #000;\n    transition: opacity .25s linear;\n    color: #fff;\n    padding: 1em 1.5em;\n    box-sizing: border-box;\n}\n\n.EZTRh2o1o6fJKzBd7xbvH ._3eM6nEC0XaYfAzTMpbqpSY {\n    right: 0;\n}\n\n.EZTRh2o1o6fJKzBd7xbvH:hover div {\n    opacity: 1;\n}\n\n._1rgCL_AaXzGdQGv_d6NEuo {\n    background-color: #ff0000;\n    padding: .75em 1em;\n    border-radius: 5px;\n}\n\n._1rgCL_AaXzGdQGv_d6NEuo strong {\n    color: #fff;\n    text-transform: uppercase;\n}\n\n*[disabled] {\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"placeholder": "_2WEfkzcaQIsRIpYXwWfddq",
	"full": "EZTRh2o1o6fJKzBd7xbvH",
	"after": "_3eM6nEC0XaYfAzTMpbqpSY",
	"danger": "_1rgCL_AaXzGdQGv_d6NEuo"
};