"use strict";

import m from "mithril";
import { getUserAffiliate, createUserAffiliate, patchUserAffiliate, postUserReferralOptIn } from "../../api-client";
import { parseIntForInput } from "../../utils/type-cast";

class Model {
    constructor(user) {
        this.user = user;

        this.loading = true;

        this.affiliate = {};

        getUserAffiliate(user.id)
            .then((res) => {
                this.affiliate = res.referral;
            })
            .finally(() => {
                this.loading = false;
                m.redraw();
            });
    }

    save() {
        if (this.affiliate.id) {
            // User already affiliate
            patchUserAffiliate(this.affiliate.id, {
                code: this.affiliate.code,
                kind: this.affiliate.kind,
                redeemed_count: this.affiliate.referrals_redeemed_count,
            }).then(
                () => {
                    alert("Successfully updated this user's affiliate account information");
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        } else {
            // User not an affiliate
            createUserAffiliate(this.user.id, {
                code: this.affiliate.code,
                kind: this.affiliate.kind,
            }).then(
                (res) => {
                    this.affiliate.id = res.referral.id;

                    alert("Successfully added affiliate account information to this user");
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        }
    }

    activate() {
        postUserReferralOptIn(this.affiliate.user_id)
            .then(() => {
                this.affiliate.active = true;
            })
            .catch((err) => {
                console.error(err);
                alert(JSON.stringify(err));
            })
            .finally(() => {
                m.redraw();
            });
    }
}

let vm = null;

export const affiliateEditor = {
    oninit(vnode) {
        const user = vnode.attrs.user;
        if (!user) {
            console.error("User attribute editor initialized without user");

            return;
        }

        vm = new Model(user);
    },

    view() {
        if (vm.loading) {
            return null;
        }

        return m(
            "form",
            {
                onsubmit: () => {
                    vm.save();
                },
            },
            [
                m("hr"),
                m("h2", "Affiliate program"),
                vm.affiliate.id ? null : m("div", [m("p", "This user is not currently enrolled.")]),
                m("div", [
                    m("label", "Code"),
                    m("input", {
                        type: "text",
                        oninput: (e) => {
                            vm.affiliate.code = e.target.value;
                        },
                        value: vm.affiliate.code,
                    }),
                ]),
                m(
                    "div",
                    m("label", "Referral Type"),
                    m(
                        "select",
                        {
                            onchange: (e) => {
                                vm.affiliate.kind = e.target.value;
                            },
                            value: vm.affiliate.kind || "",
                        },
                        m("option", { value: "" }, "Select..."),
                        m("option", { value: "normal_user" }, "Normal User"),
                        m("option", { value: "artist" }, "Artist"),
                        m("option", { value: "media" }, "Media"),
                    ),
                ),
                vm.affiliate.id
                    ? [
                          m("div", [
                              m("label", "Status"),
                              vm.affiliate.active
                                  ? m("label", "Opted-in")
                                  : m(
                                        "button",
                                        {
                                            style: "margin-left: 0",
                                            type: "button",
                                            onclick: () => {
                                                vm.activate();
                                            },
                                        },
                                        "Pending (Click to activate)",
                                    ),
                          ]),
                          m("div", [m("label", "Pending"), m("label", vm.affiliate.referrals_pending_count)]),
                          m("div", [m("label", "Completed"), m("label", vm.affiliate.referrals_completed_count)]),
                          m("div", [
                              m("label", "Redeemed"),
                              m("input", {
                                  oninput: (e) => {
                                      vm.affiliate.referrals_redeemed_count = parseIntForInput(e.target.value);
                                  },
                                  value: vm.affiliate.referrals_redeemed_count,
                              }),
                          ]),
                      ]
                    : null,
                m("div", [m("button", { type: "submit" }, "Save")]),
            ],
        );
    },
};
