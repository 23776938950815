exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._22GRP8pXthve_9zqBO8gpw:hover > .H6tDTBy4sZyGsBoB0hxxe img, ._22GRP8pXthve_9zqBO8gpw:hover > .H6tDTBy4sZyGsBoB0hxxe ._1q51QA2cCxlsne0A-v8bdP {\n    /*opacity: .25;*/\n}\n\n.H6tDTBy4sZyGsBoB0hxxe {\n    float: left;\n    padding: 0 1em 1em;\n    width: 160px;\n}\n\n.H6tDTBy4sZyGsBoB0hxxe p {\n    text-align: center;\n    line-height: 1em;\n    height: 1em;\n}\n\n._1q51QA2cCxlsne0A-v8bdP, .H6tDTBy4sZyGsBoB0hxxe > img {\n    position: relative;\n    display: block;\n    width: 160px;\n    height: 226px;\n    -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.25);\n    box-shadow: 0 1px 10px rgba(0,0,0,.25);\n    -webkit-transition: transform .25s ease-out,\n        opacity .25s linear;\n    transition: transform .25s ease-out,\n        opacity .25s linear;\n}\n\n._1q51QA2cCxlsne0A-v8bdP {\n    background-color: #444;\n}\n\n.H6tDTBy4sZyGsBoB0hxxe:hover ._1q51QA2cCxlsne0A-v8bdP, .H6tDTBy4sZyGsBoB0hxxe:hover > img {\n    -webkit-transform: scale(1.2);\n    transform: scale(1.2);\n    -webkit-transition: transform .1s linear,\n        opacity .1s linear;\n    transition: transform .1s linear,\n        opacity .1s linear;\n    opacity: 1 !important;\n}\n\n.slC0ZdBjNKoj0YdujzQQG {\n    clear: left;\n}\n", ""]);

// exports
exports.locals = {
	"volumes": "_22GRP8pXthve_9zqBO8gpw",
	"volume": "H6tDTBy4sZyGsBoB0hxxe",
	"img_placeholder": "_1q51QA2cCxlsne0A-v8bdP",
	"clear": "slC0ZdBjNKoj0YdujzQQG"
};