"use strict";

import m from "mithril";
import Editor from "./schedule_editor";

export default {
    $navTitle: "Create new",
    $component: {
        oninit(vnode) {
            this.attrs = vnode.attrs;
        },

        onupdate(vnode) {
            this.attrs = vnode.attrs;
        },

        onbeforeupdate(vnode, old) {
            if (old.attrs.id != vnode.attrs.id) {
                this.attrs = vnode.attrs;
            }
        },

        view() {
            return [
                m(Editor, this.attrs),
            ];
        },
    },
};
