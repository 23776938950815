"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import css from "../../global.css";
import {
    getCollection,
    patchCollection,
    addContentToCollection,
    modifyContentInCollection,
    deleteContentFromCollection,
    deleteAllContentFromCollection,
    getContent,
} from "../../api-client";
import config from "../../config";

const metaEditor = {
    oninit(vnode) {
        const c = vnode.attrs.reading_list;
        if (!c()) {
            return console.error(
                "Collection metadata editor initialized without collection",
            );
        }

        this.id = c().id;
        this.name = m.prop(c().name.split("|")[0] || "");
        this.subtitle = m.prop(c().name.split("|")[2] || "");
        this.affiliate_links = m.prop(c().name.split("|")[1] || "");
        this.list_type = m.prop(c().list_type);
        this.expiry = m.prop(c().expiry ? new Date(c().expiry) : null);

        this.submit = () => {
            const name = m.prop(
                this.name() + "|" + this.affiliate_links() + "|" + this.subtitle(),
            );
            const expiry = this.expiry() ? new Date(this.expiry()).toJSON() : null;
            patchCollection(c().id, name, expiry, this.list_type).then(
                res => {
                    vnode.attrs.reading_list(res.reading_list);
                    alert("Save successful");
                    window.location.reload();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );

            return false;
        };

        return this;
    },

    view() {
        return m("form", { onsubmit: this.submit }, [
            m("h1", "Edit collection"),
            m("div", [m("label", "ID"), m("span", this.id)]),
            m("div", [
                m("label[for=name]", "Title"),
                m("input[type=text][name=name]", {
                    value: this.name(),
                    onchange: m.withAttr("value", this.name),
                }),
            ]),
            m("div", [
                m("label[for=subtitle]", "Subtitle"),
                m("input[type=text][name=subtitle]", {
                    value: this.subtitle(),
                    onchange: m.withAttr("value", this.subtitle),
                }),
                " ",
                m(
                    "a",
                    {
                        href: "#",
                        onclick: () => {
                            this.subtitle("[blank]");

                            return false;
                        },
                    },
                    "blank",
                ),
            ]),
            m("div", [
                m("label[for=expiry]", "Expiry date"),
                m("input[type=text][name=expiry][autocomplete=off]", {
                    onchange: m.withAttr("value", this.expiry),
                    value: this.expiry(),
                    oncreate: function(vnode) {
                        this.pikaday = new Pikaday({
                            field: vnode.dom,
                        });
                    },
                    onremove: function() {
                        this.pikaday.destroy();
                    },
                }),
            ]),
            m("div", [
                m("label[for=affiliate_links]", "Affiliate links"),
                m("input[type=text][name=affiliate_links]", {
                    value: this.affiliate_links(),
                    onchange: m.withAttr("value", this.affiliate_links),
                }),
            ]),
            m("div", [m("button[type=submit]", "Save")]),
        ]);
    },
};

const contentEditor = {
    oninit(vnode) {
        const c = vnode.attrs.reading_list;
        if (!c()) {
            return console.error(
                "Collection content editor initialized without collection",
            );
        }

        this.ordinalitiesUpdated = m.prop(false);

        this.recomputeContents = (id, add) => {
            if (add === true) {
                const self = this;

                // Temporarily add this content
                this.contents.unshift({
                    id: id,
                    loading: true,
                });

                // Properly get this content
                getContent(id).then(
                    res => {
                        // Remove this
                        self.recomputeContents(id, false);

                        // Add it again
                        self.contents.push(res.content);
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );
            } else {
                for (let i = 0; i < this.contents.length; i += 1) {
                    if (this.contents[i].id === id) {
                        this.contents.splice(i, 1);
                    }
                }
            }

            this.new_content(null);
            m.redraw();
        };

        this.id = c().id;
        this.contents = c().contents;
        this.new_content = m.prop();

        return this;
    },

    onbeforeupdate(vnode) {
        this.contents = vnode.attrs.reading_list().contents;
    },

    view({ attrs }) {
        const removeContent = id => {
            const self = this;

            deleteContentFromCollection(this.id, id).then(
                () => {
                    // Remove it from this.contents
                    self.recomputeContents(id, false);

                    return false;
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));

                    return false;
                },
            );

            return true;
        };

        const createTask = (readingListID, contentID, self) => () => {
            const parsedId = parseInt(contentID, 10);

            const ordinality = self.contents.length + 1;

            return isNaN(parsedId)
                ? () => Promise.resolve()
                : addContentToCollection(
                      readingListID,
                      parsedId,
                      ordinality,
                  ).then(() => {
                      self.recomputeContents(parsedId, true);
                  });
        };

        const addContent = (readingListID, contentIDs) => {
            const self = this;

            const contents = contentIDs().split(/\D+/);

            contents
                .map(contentId =>
                    createTask(readingListID, parseInt(contentId, 10), self),
                )
                .reduce(
                    (promise, task) =>
                        promise.then(task, err => {
                            throw new Error(err);
                        }),
                    Promise.resolve(),
                );
        };

        const shift = (content, direction) => {
            const pos = this.contents.findIndex(c => c.id === content.id);
            this.contents.forEach((c, i) => {
                switch (direction) {
                    case "up":
                        if (i === pos || i === pos - 1) {
                            c.loading = true;
                        }
                        break;
                    case "down":
                        if (i === pos || i === pos + 1) {
                            c.loading = true;
                        }
                        break;
                    case "top":
                        if (i <= pos) {
                            c.loading = true;
                        }
                        break;
                    case "bottom":
                        if (i >= pos) {
                            c.loading = true;
                        }
                        break;
                    default:
                    // do nothing
                }
            });
            m.redraw();

            modifyContentInCollection(this.id, content.id, direction)
                .then(() => {
                    attrs.reload();
                })
                .finally(() => {
                    this.contents.forEach(c => {
                        c.loading = false;
                    });
                    m.redraw();
                });
        };

        let i = 0;

        return [
            m("h2", "Child contents"),
            m(
                "form",
                {
                    onsubmit: () => {
                        addContent(this.id, this.new_content);

                        return false;
                    },
                },
                [
                    m("h3", "Add new child by content ID"),
                    m("div", [
                        m("label[for=new_content]", "Content ID"),
                        m("textarea[name=new_content]", {
                            value: this.new_content(),
                            onchange: m.withAttr("value", this.new_content),
                        }),
                    ]),
                    m("div", [m("button[type=submit]", "Add")]),
                ],
            ),
            this.contents.length < 1
                ? m("p", "(None)")
                : m("table", [
                      m("thead", [
                          m("th", "ID"),
                          m("th", "Title"),
                          m("th", ""),
                          m("th", ""),
                          m("th", ""),
                          m("th", ""),
                          m("th", ""),
                      ]),
                      this.contents.map(c => {
                          i += 1;

                          return m("tr", [
                              m(
                                  "td",
                                  m(
                                      "a",
                                      {
                                          href: `/content/${c.id}/edit`,
                                          oncreate: m.route.link,
                                      },
                                      c.id,
                                  ),
                              ),
                              m("td", c.data.title),
                              c.loading
                                  ? m(`td.${css.hover}`, i > 1 ? "⤒" : "")
                                  : m(
                                        `td.${css.hover}`,
                                        i > 1
                                            ? m(
                                                  "a",
                                                  {
                                                      href: "#",
                                                      onclick: e => {
                                                          e.preventDefault();
                                                          shift(c, "top");
                                                      },
                                                  },
                                                  "⤒",
                                              )
                                            : null,
                                    ),
                              c.loading
                                  ? m(`td.${css.hover}`, i > 1 ? "↑" : "")
                                  : m(
                                        `td.${css.hover}`,
                                        i > 1
                                            ? m(
                                                  "a",
                                                  {
                                                      href: "#",
                                                      onclick: e => {
                                                          e.preventDefault();
                                                          shift(c, "up");
                                                      },
                                                  },
                                                  "↑",
                                              )
                                            : null,
                                    ),
                              c.loading
                                  ? m(
                                        `td.${css.hover}`,
                                        i < this.contents.length ? "↓" : "",
                                    )
                                  : m(
                                        `td.${css.hover}`,
                                        i < this.contents.length
                                            ? m(
                                                  "a",
                                                  {
                                                      href: "#",
                                                      onclick: e => {
                                                          e.preventDefault();
                                                          shift(c, "down");
                                                      },
                                                  },
                                                  "↓",
                                              )
                                            : null,
                                    ),
                              c.loading
                                  ? m(
                                        `td.${css.hover}`,
                                        i < this.contents.length ? "⤓" : "",
                                    )
                                  : m(
                                        `td.${css.hover}`,
                                        i < this.contents.length
                                            ? m(
                                                  "a",
                                                  {
                                                      href: "#",
                                                      onclick: e => {
                                                          e.preventDefault();
                                                          shift(c, "bottom");
                                                      },
                                                  },
                                                  "⤓",
                                              )
                                            : null,
                                    ),
                              c.loading
                                  ? m(`td.${css.hover}`, "x")
                                  : m(
                                        `td.${css.hover}`,
                                        m(
                                            "a",
                                            {
                                                href: "#",
                                                onclick: e => {
                                                    e.preventDefault();
                                                    c.loading = true;
                                                    c.loading = removeContent(
                                                        c.id,
                                                    );
                                                },
                                            },
                                            "x",
                                        ),
                                    ),
                          ]);
                      }),
                  ]),
        ];
    },
};

export default {
    $component: {
        handle(vnode) {
            this.page = m.prop(0);
            this.perPage = m.prop(20);
            this.totalPages = m.prop(1);

            this.reading_list = m.prop(null);
            this.id = vnode.attrs.id;

            this.load();
            m.redraw();
        },
        load(page = 0) {
            getCollection(this.id, page).then(
                res => {
                    this.page(res.page);
                    this.totalPages(res.total_pages);
                    this.reading_list(res.results);

                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        },
        removeAll() {
            if (
                !confirm(
                    "Are you sure you want to remove all contents from this collection?",
                )
            ) {
                return;
            }

            deleteAllContentFromCollection(this.id)
                .then(() => {
                    const rl = this.reading_list();
                    rl.contents = [];
                    this.reading_list(rl);
                })
                .catch(err => {
                    alert(err);
                })
                .finally(() => {
                    m.redraw();
                });
        },
        oninit(vnode) {
            this.handle(vnode);
        },
        onupdate(vnode) {
            if (vnode.attrs.id !== this.id) {
                this.id = vnode.attrs.id;
                this.reading_list(null);
                this.load(this.page());
            }
        },

        view() {
            const c = this.reading_list();

            if (!c) {
                return m("div", [
                    m("h1", "Edit collection"),
                    m(".loading", [m(".load"), m("p", "Loading...")]),
                ]);
            }

            const linkURL = `https://${
                config.baseUrl.includes("staging") ? "staging." : ""
            }komiflo.com/collection/${c.id}`;

            return m("div", [
                m(metaEditor, {
                    reading_list: this.reading_list,
                }),
                m("hr"),
                m("form", [
                    m("div", [
                        m("label", "Link"),
                        m("input[type=text]", {
                            value: linkURL,
                            onclick: () => {
                                if (
                                    window.clipboardData &&
                                    window.clipboardData.setData
                                ) {
                                    // IE specific code path to prevent textarea being shown while dialog is visible.
                                    alert("Copied link to clipboard.");
                                    window.clipboardData.setData(
                                        "Text",
                                        linkURL,
                                    );

                                    return false;
                                } else if (
                                    document.queryCommandSupported &&
                                    document.queryCommandSupported("copy")
                                ) {
                                    var textarea = document.createElement(
                                        "textarea",
                                    );
                                    textarea.textContent = linkURL;
                                    // Prevent scrolling to bottom of page in MS Edge.
                                    textarea.style.position = "fixed";
                                    document.body.appendChild(textarea);
                                    textarea.select();
                                    try {
                                        // Security exception may be thrown by some browsers.
                                        document.execCommand("copy");

                                        return false;
                                    } catch (ex) {
                                        alert(
                                            "Copy to clipboard failed. Please try again.",
                                        );

                                        return false;
                                    } finally {
                                        alert("Copied link to clipboard.");
                                        document.body.removeChild(textarea);
                                    }
                                }

                                return false;
                            },
                        }),
                        m(
                            "button",
                            {
                                onclick: e => {
                                    e.preventDefault();
                                    window.open(linkURL);
                                },
                            },
                            "Go to the collection page",
                        ),
                    ]),
                ]),
                m("hr"),
                m(contentEditor, {
                    reading_list: this.reading_list,
                    reload: () => {
                        this.load(this.page);
                    },
                }),
                m("hr"),
                m("div", [
                    m("h2", "Danger Zone"),
                    m(
                        "button",
                        { onclick: () => this.removeAll() },
                        "REMOVE ALL CONTENTS",
                    ),
                ]),
                m(pagination, { vm: this }),
            ]);
        },
    },
};
