import m from "mithril";
import { getReceipts } from "../../api-client";
import link from "../../utils/link";
import { dateFormat } from "../../utils/helper";
import { receiptStat } from "../receipt/utils";
import pagination from "../../components/pagination";

const model = {
    receiptList: [],
    page: m.prop(0),
    total_pages: m.prop(0),
    loading: m.prop(true),
    status: m.prop(''),
    load(page) {
        model.loading(false);
        getReceipts(page, true, model.status())
        .then((res) => {
            model.receiptList = res.results;
            model.page(res.page);
            model.total_pages(res.total_pages);
        })
        .catch((err) => {
            alert(err);
        }).finally(() => {
            model.loading(false);
            m.redraw();
        });
    },
};

export default {
    $navTitle: "Practical item receipts",
    $component: {
        oninit() {
            model.load(model.page());
        },
        view() {
            if (model.loading()) {
                return m(".loading", [m(".load"), m("p", "Loading receipt..."), m("p", "Please wait.")]);
            }

            return m("div", [
                m("h1", "Receipts"),
                m("div", [
                    m("label", "Status"),
                    m("select",
                    {
                        name: "status",
                        onchange: (e) => {
                            model.status(e.target.value)
                            model.load(model.page());
                        },
                        value: model.status(),
                    },
                    [
                        m("option"),
                        m("option[value='no_shipped']", "Not shipped"),
                        m("option[value='shipped']", "Shipped"),
                    ])
                ]),
                m('hr'),
                m("table", [
                    m("thead", [m("th", "ID"), m("th", "userID"), m("th", "created"), m("th", "ship_status"), m("th", "tracking_number")]),
                    model.receiptList.map((receipt) => {
                        return m("tr", [
                            m("td", link(`/receipt/${receipt.id}`, receipt.id)),
                            m("td", link(`/user/id/${receipt.user_id}`, receipt.user_id)),
                            m("td", dateFormat(receipt.created)),
                            m("td", receipt.ship_to ? receipt.ship_to.status : ""),
                            m("td", receipt.ship_to && receipt.ship_to.tracking_number ? receipt.ship_to.tracking_number : ""),
                        ]);
                    }),
                ]),
                m(pagination, {vm: {
                    page: model.page,
                    totalPages: model.total_pages,
                    load: model.load,
                }}),
            ]);
        },
    },
};
