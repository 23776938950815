"use strict";

import Login from "./pages/login";
import Unadmin from "./pages/unadmin";
import { logout } from "./api-client";
import m from "mithril";
import layout from "./layout";
import { Subscription, Store } from "./routes";

function buildRouteHash(hash, work, table) {
    work = work || "";
    table = table || {};

    Object.keys(hash).forEach((k) => {
        let v = hash[k];
        let path = `${work}/${k}`;

        if (v.$component) {
            // XXX: We want to wrap this with an authentication bit here so
            // that routes can only appear for users of the appropriate access
            // levels.

            table[path] = {
                $component: v.$component,
                $layout: layout,
            };
            return;
        }

        return buildRouteHash(v, path, table);
    });

    return table;
}

export default {
    buildRoutes() {
        const route = localStorage.getItem('Mode') === 'store' ? Store : Subscription
        let hash = buildRouteHash(route);

        // Special-case: the rest of the routes require an active session and
        // verify that the user's access level exceeds a certain amount (so
        // that we don't display non-working UI). The login route doesn't have
        // any such logic.
        hash["/login"] = {
            $component: Login,
        };

        hash["/unadmin"] = {
            $component: Unadmin,
        };

        hash["/logout"] = {
            $component: {
                oninit() {
                    logout().then(() => {
                        m.route.set("/login")
                        location.reload();
                    });
                },
                view() {
                    return m("");
                }
            },
        };

        // Rewrap all of the weird-style routes with our own crap, applying
        // any options specified in the route hashes.
        hash = Object.keys(hash).reduce(
            (a, key) => {
                let component = hash[key];

                // For pages which specify a layout, return a component
                // that returns the layout instanciated with the component as
                // the content.
                if (component.$layout) {
                    a[key] = {
                        oninit(vnode) {
                            this.attrs = vnode.attrs;
                        },
                        onbeforeupdate(vnode) {
                            this.attrs = vnode.attrs;
                        },
                        view() {
                            return m(component.$layout, Object.assign(
                                { content: component.$component },
                                this.attrs
                            ));
                        }
                    };
                } else {
                    // And for pages without a layout, just use them directly.
                    a[key] = component.$component;
                }

                return a;
            },
            {}
        );

        hash["/:any..."] = {
            oninit() {
                console.log(hash);
                //alert("INVALID ROUTE");
                setTimeout(() => { location.href = '/' }, 1000)
            },
            view() {
                return 'INVALID ROUTE';
            }
        };

        return hash;
    }
};
