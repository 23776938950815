exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PtJIBbdcqiTiPSKjTdOjB {\n}\n\n._1Rn8A3WH2cKxrXd0Zv4bxR {\n    width: 100%;\n}\n\n.HpNvMuBo15lgJBV3z_BpW {\n    display: inline-block;\n    width: 200px;\n    font-weight: bold;\n}\n\n._3ZuUsabeyDcsI2lwJIejeA {\n    margin-left: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"table": "_3PtJIBbdcqiTiPSKjTdOjB",
	"field": "_1Rn8A3WH2cKxrXd0Zv4bxR",
	"heading": "HpNvMuBo15lgJBV3z_BpW",
	"value": "_3ZuUsabeyDcsI2lwJIejeA"
};