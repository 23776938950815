exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2oXbNzxVF-zeJiYWdyA5BZ {\n    margin-left: .5em;\n    font-size: .7em;\n    color: #fff;\n    background: #888;\n    border-radius: 1em;\n    padding: .1em .5em .2em;\n}", ""]);

// exports
exports.locals = {
	"count": "_2oXbNzxVF-zeJiYWdyA5BZ"
};