export function parseIntForInput(str) {
    const val = parseInt(str, 10);
    if (isNaN(val)) {
        return "";
    }

    return val;
}

export function parseFloatForInput(str) {
    if (str.endsWith(".")) {
        return str;
    }

    const val = parseFloat(str);
    if (isNaN(val)) {
        return "";
    }

    return val;
}
