exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body {\n    background: #fafafa;\n}\n\n._1uRMLij39nsmH7qWuxQzpo {\n    position: relative;\n    display: block;\n    max-width: 400px;\n    padding: 2em;\n    background: #fff;\n    margin: 100px auto;\n    text-align: center;\n    border-radius: .25em;\n    box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);\n    -webkit-animation: _1yJoU_KimS7XX-xQ9Lgcgr .25s linear;\n}\n\n._1uRMLij39nsmH7qWuxQzpo h1 {\n    margin-top: .5em;\n    color: #444;\n}\n\n._1uRMLij39nsmH7qWuxQzpo ._1rAaepUYgH0kV8t-pP-gh0{\n    color: #aaa;\n    padding: 2em;\n}\n\n@-webkit-keyframes _1yJoU_KimS7XX-xQ9Lgcgr {\n    0% {\n        opacity: 0;\n        -webkit-transform: translate(0,30px) scale(0.9);\n    } 100% {\n        opacity: 1;\n        -webkit-transform: translate(0,0) scale(1);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"unadmin": "_1uRMLij39nsmH7qWuxQzpo",
	"appear": "_1yJoU_KimS7XX-xQ9Lgcgr",
	"error": "_1rAaepUYgH0kV8t-pP-gh0"
};