"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import PaginatedModel from "../../utils/paginated-model";
import link from "../../utils/link";
import { getComments, deleteComment } from "../../api-client";
import { dateFormat } from "../../utils/helper";
import { parseIntForInput } from "../../utils/type-cast";
import csv from "../../utils/csv";
import dayjs from "dayjs";

class Model {
    constructor(opts = {}) {
        this.loading = true;
        this.term = "";
        this.artist = "";
        this.content_id = null;
        this.start_date = null;
        this.end_date = null;
        this.page = m.prop(0);
        this.perPage = 25;
        this.totalPages = m.prop(0);
        this.elements = [];
    }

    params() {
        const started = dayjs(this.start_date);
        const ended = dayjs(this.end_date);
        return {
            term: this.term,
            artist: this.artist,
            content_id: this.content_id || '',
            start_date: started.isValid() ? started.format('YYYY-MM-DD') : '',
            end_date: ended.isValid() ? ended.format('YYYY-MM-DD') : '',
        };
    }

    get() {
        this.loading = true;
        getComments(this.page(), this.perPage, this.params()).then(res => {
            this.totalPages(res.total_pages);
            this.elements = res.results;
        }).catch(err => {
            console.error(err);
            alert(JSON.stringify(err));
        }).finally(() => {
            this.loading = false;
            m.redraw();
        });
    }

    csv() {
        this.loading = true;
        getComments(0, 100000, this.params()).then(res => {
            csv("comments", res.results.map(c => ({
                "Content ID": c.content ? c.content.id : "",
                "Content Title": c.content ? c.content.data.title : "",
                "Posted": dateFormat(c.created),
                "Body": `"${c.body.replaceAll('"', '""')}"`,
            })));
        }).catch(err => {
            console.error(err);
            alert(JSON.stringify(err));
        }).finally(() => {
            this.loading = false;
            m.redraw();
        });
    }

    deleteComment(i, content_id, comment_id) {
        if (confirm("Are you sure you want to delete this comment?")) {
            this.elements[i].loading = true;

            deleteComment(content_id, comment_id).then(
                res => {
                    // Delete was successful
                    this.get();
                },
                err => {
                    console.error(err);
                }
            );
        }
    }

    load(page) {
        this.page(page);
        this.get();
    }
}

export default {
    $navTitle: "Comments",
    $component: {
        oninit({ state }) {
            state.vm = new Model();
            state.vm.load(0);
        },
        view({ state }) {
            return m("div", [
                m("h1", "Comments"),
                m("form",{
                    onsubmit: () => { state.vm.load(0); },
                },[
                    m("div", [
                        m("span", "Comment"),
                        m("input",{
                            type: 'text',
                            onchange: (e) => { state.vm.term = e.currentTarget.value; },
                            value: state.vm.term,
                        }),
                    ]),
                    m("div", [
                        m("span", "Artist"),
                        m("input",{
                            type: 'text',
                            placeholder: 'slug or name',
                            onchange: (e) => { state.vm.artist = e.currentTarget.value; },
                            value: state.vm.artist,
                        }),
                    ]),
                    m("div", [
                        m("span", "Content ID"),
                        m("input",{
                            type: 'text',
                            onchange: (e) => { state.vm.content_id = parseIntForInput(e.currentTarget.value); },
                            value: state.vm.content_id,
                        }),
                    ]),
                    m("div", [
                        m("span", "Posted between"),
                        m("input", {
                            type: "text",
                            onchange: e => { state.vm.start_date = e.currentTarget.value; },
                            value: state.vm.start_date,
                            oncreate(vnode) { this.pikaday = new Pikaday({ field: vnode.dom }); },
                            onremove() { this.pikaday.destroy(); },
                        }),
                        ' ~ ',
                        m("input", {
                            type: "text",
                            onchange: e => { state.vm.end_date = e.currentTarget.value; },
                            value: state.vm.end_date,
                            oncreate(vnode) { this.pikaday = new Pikaday({ field: vnode.dom }); },
                            onremove() { this.pikaday.destroy(); },
                        }),
                    ]),
                    m("div", m("input", {
                        type: 'submit',
                        value: 'Apply filter',
                        disabled: state.vm.loading,
                    })),
                ]),
                m("div", m("button", {
                    style: { "margin-bottom": "1em", "width": "6.7em" },
                    onclick: () => { state.vm.csv() },
                    disabled: state.vm.loading,
                }, "CSV")),
                state.vm.loading
                    ? m(".loading", [
                        m(".load"),
                        m("p", "Loading..."),
                    ])
                    : m("div",
                        state.vm.elements.map((c, i) => {

                        let type = "Anonymous"
                        if (c.author) type = `Public ${c.author} comment`

                        return c.loading
                            ? m("p", "Loading...")
                            : m("table.comment", [
                                m("tr", [
                                    m("td", "ID"),
                                    m("td", c.id),
                                ]),
                                c.content ?
                                    m("tr", [
                                        m("td", "Content"),
                                        m("td", [
                                            link(`/content/${c.content.id}/edit`, `${c.content.id} ${c.content.data.title}`),
                                            m.trust("&nbsp;"),
                                            m("a", { href: "https://komiflo.com/comics/" + c.content.id, target: "_blank" }, "Ⓚ")
                                        ]),
                                    ])
                                : "",
                                c.created_by ?
                                    m("tr", [
                                        m("td", "UserID"),
                                        m("td", [
                                            link(`/user/id/${c.created_by}`, `${c.created_by}`),
                                            m.trust("&nbsp;"),
                                        ]),
                                    ])
                                : "",
                                c.artist ?
                                m("tr", [
                                    m("td", "Artist"),
                                    m("td", c.artist[0] ? c.artist[0].data.name : ''),
                                ])
                                : "",
                                m("tr", [
                                    m("td", "Posted"),
                                    m("td", dateFormat(c.created)),
                                ]),
                                m("tr", [
                                    m("td", "Type"),
                                    m("td", type),
                                ]),
                                m("tr", [
                                    m("td", "Body"),
                                    m("td.comment-body", c.body),
                                ]),
                                m("tr", [
                                    m("td", ""),
                                    m("td", [
                                        m("a", { href: "", onclick: e => { e.preventDefault(); state.vm.deleteComment(i, c.content.id, c.id) } }, "Delete")
                                    ]),
                                ]),
                            ]);
                    })),
                m(pagination, { vm: state.vm }),
            ]);
        },
    },
};
