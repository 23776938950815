"use strict";

import m from "mithril";

export default class PaginatedModel {
    constructor(loadFn) {
        this.loadFn = loadFn;
        this.page = m.prop(0);
        this.perPage = m.prop(50);
        this.sort = m.prop('');
        this.filter = m.prop('');
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
        this.params = m.prop();
    }

    load(page, params, sort, filter) {
        this.page(page);
        this.params(params);

        if (sort) { this.sort(sort); }
        if (filter) { this.filter(filter); }

        this.loading(true);

        this.loadFn(this.page(), this.perPage(), this.sort(), this.filter(), this.params()).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }
};
