"use strict";

import m from "mithril";

export default function link(href, opts, text) {
    if (typeof text === "undefined") {
        text = opts;
        opts = {};
    }

    opts.oncreate = m.route.link;
    opts.onupdate = m.route.link;

    if (href != "") {
        return m(`a[href=${href}]`, opts, text);
    } else {
        return m(`div`, text);
    }
}
