import m from "mithril";
import { createAccessCode } from "../../api-client";
import csv from "../../utils/csv";
import { parseIntForInput } from "../../utils/type-cast";
import { AccessCodeType } from "./utils";
import dayjs from "dayjs";

export default {
    $navTitle: "Create new",
    $component: {
        oninit() {
            this.mode = m.prop("auto");

            this.duration = m.prop(90);
            this.amount = m.prop(1);
            this.type = 2;

            this.code = m.prop("");
            this.expiry = m.prop();
            this.available = m.prop(1);
            this.applicable_condition = m.prop("");
        },

        submit(e) {
            e.preventDefault();

            const params = {
                duration: parseInt(this.duration()),
                amount: parseInt(this.amount()),
                type: this.type,
            };

            if (this.mode() === 'manually') {
                if (this.code().length < 4) {
                    alert("Code must be at least 4 characters long.");
                }
                params.code = this.code()
                const expiry = dayjs(this.expiry());
                params.expiry_date = this.expiry() && expiry.isValid() ? expiry.toISOString() : ""
                params.amount = 1
                if (this.available() && +this.available() > 0) {
                    params.available = +this.available()
                }
                if (this.applicable_condition()) {
                    params.applicable_condition = this.applicable_condition()
                }
            }

            createAccessCode(params)
                .then(res => {
                    let access_codes = res.access_codes
                    let output = []

                    access_codes.forEach((resp) => {
                        const codeFormat = resp.access_code.match(new RegExp('.{1,4}', 'g'));
                        const formated_access_code = codeFormat ? codeFormat.join("-") : '';
                        const expiry = new Date(resp.expiry_date)
                        output.push({
                            "access_code": `${formated_access_code.toUpperCase()}`,
                            "duration": resp.duration,
                            "expiry_date": `${expiry.getFullYear()}/${expiry.getMonth()+1}/${expiry.getDate()} ${expiry.getHours()}:${expiry.getMinutes()}:${expiry.getSeconds()}`
                        })
                    })
                    csv("access_code", output);
                    m.route.set("/access_codes/list");
                })
                .catch(error => {
                    alert(error);
                });
        },

        view() {
            return m('div', [
                m('form', { onsubmit: e => this.submit(e) }, [
                    m('h2', 'Generate New Access Code'),
                    m("div", [
                        m("label", {for: "mode"}, "Create Mode"),
                        m("select",
                        {
                            name: "mode",
                            onchange: (e) => {
                                e.preventDefault();
                                this.mode(e.target.value)
                                if (e.target.value == 'manually') {
                                    this.amount(1)
                                } else {
                                    this.code("")
                                }
                                m.redraw()
                            },
                            value: this.mode(),
                        },
                        [
                            m("option[value=auto]", "Auto"),
                            m("option[value=manually]", "Manually"),
                        ])
                    ]),
                    m("div", [
                        m("label", {for: "type"}, "Code Type"),
                        m("select", {
                            name: "type",
                            onchange: (e) => { e.preventDefault(); this.type = parseIntForInput(e.target.value); },
                            value: this.type,
                        }, Object.entries(AccessCodeType).map(([k, v]) => m("option", {value: k}, v))),
                    ]),
                    this.type === 4
                        ? m("div", [
                            m("label", {for: "duration"}, "Balance (JPY"),
                            m("input", {
                                name: "duration",
                                type: "text",
                                oninput: m.withAttr("value", this.duration),
                                value: this.duration(),
                            }),
                        ])
                        : m("div", [
                            m("label", {for: "duration"}, "Duration (days)"),
                            m("select",
                            {
                                name: "duration",
                                onchange: m.withAttr("value", this.duration),
                                value: this.duration(),
                            },
                            [
                                m("option"),
                                m("option[value=7]", "1 week"),
                                m("option[value=14]", "2 weeks"),
                                m("option[value=30]", "30 days"),
                                m("option[value=60]", "60 days"),
                                m("option[value=90]", "90 days"),
                                m("option[value=180]", "180 days"),
                                m("option[value=365]", "365 days"),
                            ])
                        ]),
                    m("div", [
                        m("label", {for: "amount"}, "Amount of codes"),
                        m("input", {
                            name: "amount",
                            type: "text",
                            oninput: m.withAttr("value", this.amount),
                            value: this.amount(),
                            disabled: this.mode() === 'manually'
                        }),
                    ]),
                    this.mode() !== 'auto'
                        ? [
                            m("div", [
                                m("label", {for: "code"}, "Code"),
                                m("input", {
                                    name: "code",
                                    type: "text",
                                    oninput: m.withAttr("value", this.code),
                                    value: this.code(),
                                }),
                                this.code().length == 16 || this.code().length >= 1 && this.code().search(/[0123456789abcdefghjklmnpqrstuvwxy]/g)
                                    ? m("p", {style: "color: red;"}, "Invalid characters. (if code length 16, i, o, z is not allowed)")
                                    : ''
                            ]),
                            m("div", [
                                m("label", {for: "available"}, "Available"),
                                m("input", {
                                    name: "available",
                                    type: "text",
                                    oninput: m.withAttr("value", this.available),
                                    value: this.available(),
                                }),
                            ]),
                            m("div", [
                                m("label", "Expiry date"),
                                m("input", {
                                    type: "text",
                                    onchange: m.withAttr("value", this.expiry),
                                    value: this.expiry(),
                                    oncreate(vnode) {
                                        this.pikaday = new Pikaday({
                                            field: vnode.dom,
                                        });
                                    },
                                    onremove() {
                                        this.pikaday.destroy();
                                    },
                                }),
                            ]),
                            m("div", [
                                m("label", {for: "applicable_condition"}, "User status"),
                                m("select",
                                {
                                    name: "applicable_condition",
                                    onchange: m.withAttr("value", this.applicable_condition),
                                    value: this.applicable_condition(),
                                },
                                [
                                    m("option", "Any"),
                                    m("option[value=NotHasSubscription]", "Not have a subscription now (including expired user)"),
                                    m("option[value=FirstSubscription]", "Never had a subscription (only user)"),
                                ])
                            ]),
                        ]
                        : '',
                    m('div', [
                        m('span'),
                        m('input[type=submit][value=Generate]')
                    ]),
                ]),
            ]);
        },
    },
};
