"use strict";

import { deletez, dateFormat } from "../../utils/helper";
import m from "mithril";
import link from "../../utils/link";
import {
    getUserAdminId,
    getUserAdminEmail,
    getUserSubscriptions,
    getUserSubscriptionCancels,
    getUserPaymentMethods,
    getUserEdits,
    getUserAttrEdits,
    getUserReferrer,
} from "../../api-client";
import table from "../../components/user";
import css from "./view.css";
import { userViewReceipts } from "./view_receipt";

function bodyHistoryView({ patch, reverse, created }) {
    return {
        patchView: JSON.stringify(patch, null, " "),
        reverseView: JSON.stringify(reverse, null, " "),
        createdView: dateFormat(created),
    };
}

function attrHistoryView({ patch, reverse, created }) {
    // XXX: assuming patched attribute is up tp 1 per edit
    return {
        patchView: patch.length === 1 ? link(`/attribute/${patch}/view`, patch) : "",
        reverseView: reverse.length === 1 ? link(`/attribute/${reverse}/view`, reverse) : "",
        createdView: dateFormat(created),
    };
}

function pagenate(status, type) {
    let params = "";
    let pageMax = 0;
    let now = 0;

    if (type === "sess") {
        params = `?sess=___&subcancel=${status.subscCancelPage()}`;
        pageMax = Number(status.sessionPageCount());
        now = Number(status.sessionPage());
    }
    if (type === "subcancel") {
        params = `?sess=${status.sessionPage()}&subcancel=___`;
        pageMax = Number(status.subscCancelPageCount());
        now = Number(status.subscCancelPage());
    }

    let count = 0;
    let start = -4;

    return [
        m(`div.${css.paginate}`, [
            Number(now) > 0 ? link(`/user/id/${status.id()}${params.replace("___", 0)}`, {}, "<<") : m(`a.${css.disabled}`, "<<"),
            Number(now) > 0 ? link(`/user/id/${status.id()}${params.replace("___", Number(now) - 1)}`, {}, "<") : m(`a.${css.disabled}`, "<"),
            [...Array(10)].map(() => {
                start += 1;
                if (Number(now) + start >= 0 && Number(now) + start <= pageMax && count < 7) {
                    count += 1;

                    return m(
                        `${start === 0 ? `.${css.now}` : ""}`,
                        link(`/user/id/${status.id()}${params.replace("___", Number(now) + start)}`, {}, Number(now) + start + 1),
                    );
                }

                return "";
            }),
            Number(now) < pageMax ? link(`/user/id/${status.id()}${params.replace("___", Number(now) + 1)}`, {}, ">") : m(`a.${css.disabled}`, ">"),
            Number(now) < pageMax ? link(`/user/id/${status.id()}${params.replace("___", Number(pageMax))}`, {}, ">>") : m(`a.${css.disabled}`, ">>"),
        ]),
    ];
}

export default {
    $component: {
        oninit(vnode) {
            return this.handle(vnode);
        },

        onbeforeupdate(vnode, old) {
            if (vnode.attrs.id !== old.attrs.id) {
                this.handle(vnode);
            }
            if (vnode.attrs.sess !== old.attrs.sess) {
                this.sessionPage = m.prop(vnode.attrs.sess || 0);
            }
            if (vnode.attrs.subcancel !== old.attrs.subcancel) {
                this.subscCancelPage = m.prop(vnode.attrs.subcancel || 0);
            }
            if (vnode.attrs.plusReceipts !== old.attrs.plusReceipts) {
                this.plusReceiptsPage = m.prop(vnode.attrs.plusReceipts || 0);
            }
            if (vnode.attrs.storeReceipts !== old.attrs.storeReceipts) {
                this.storeReceiptsPage = m.prop(vnode.attrs.storeReceipts || 0);
            }
        },

        handle(vnode) {
            this.user = m.prop(null);
            this.sessions = m.prop([]);
            this.subscriptionCancels = m.prop([]);
            this.subscriptions = m.prop(null);
            this.error = m.prop(null);
            this.id = m.prop(null);
            this.email = m.prop(null);
            this.editBodyHistory = m.prop([]);
            this.editAttrHistory = m.prop([]);
            this.referrer = null;
            this.paymentMethods = [];

            this.sessionPage = m.prop(vnode.attrs.sess || 0);
            this.subscCancelPage = m.prop(vnode.attrs.subcancel || 0);
            this.plusReceiptsPage = m.prop(vnode.attrs.plusReceipts || 0);
            this.storeReceiptsPage = m.prop(vnode.attrs.storeReceipts || 0);
            this.subscCancelPage = m.prop(vnode.attrs.subcancel || 0);
            this.sessionPageCount = m.prop(0);
            this.subscCancelPageCount = m.prop(0);

            if (vnode.attrs.id) {
                this.id(vnode.attrs.id);

                Promise.all([
                    getUserAdminId(this.id).then(
                        res => {
                            this.user(res.user);
                            this.sessions(res.sessions);
                        },
                        err => err,
                    ),
                    getUserSubscriptions(this.id).then(
                        res => {
                            this.subscriptions(res.results);
                        },
                        err => err,
                    ),
                    getUserSubscriptionCancels(this.id).then(
                        res => {
                            this.subscriptionCancels(res.subscription_cancels);
                        },
                        err => err,
                    ),
                    getUserPaymentMethods(this.id).then(
                        res => {
                            this.paymentMethods = res.payment_methods;
                        },
                        err => err,
                    ),
                    getUserEdits(this.id).then(
                        res => {
                            this.editBodyHistory(res.user);
                        },
                        err => err,
                    ),
                    getUserAttrEdits(this.id).then(
                        res => {
                            this.editAttrHistory(res.user);
                        },
                        err => err,
                    ),
                    getUserReferrer(this.id).then(
                        res => {
                            this.referrer = res.referrer;
                        },
                        err => err,
                    ),
                ])
                    .catch(reason => {
                        reason.forEach(r => {
                            console.log(r);
                        });
                    })
                    .finally(() => {
                        m.redraw();
                    });
            } else {
                this.email(vnode.attrs.email);
                getUserAdminEmail(this.email()).then(
                    res => {
                        this.id(res.user.id);
                        this.user(res.user);
                        m.redraw();
                    },
                    err => {
                        console.error(err);
                        this.error(err);
                    },
                );
            }
        },

        view() {
            const now = new Date();

            this.sessionPageCount(this.sessions().length / 20 || 0);
            this.subscCancelPageCount(this.subscriptionCancels().length / 20 || 0);

            if (!this.user()) {
                return m(".loading", [m(".load"), m("p", "Retrieving user..."), m("p", "Please wait.")]);
            }

            const user = this.user();
            return m("div", [
                m("h1", `User ${this.id()}`),
                m("p", [m("a", { href: `/user/id/${this.id()}/edit`, oncreate: m.route.link }, "Edit")]),
                m("p", [
                    m("a", { href: `/user/id/${parseInt(this.id(), 10) - 1}`, oncreate: m.route.link }, "Previous"),
                    m.trust("&nbsp;&nbsp;&nbsp;"),
                    m("a", { href: `/user/id/${parseInt(this.id(), 10) + 1}`, oncreate: m.route.link }, "Next"),
                ]),
                m(table, { user: this.user }),
                m("hr"),
                m("h2", "Subscriptions"),
                this.subscriptions() && this.subscriptions().length > 0
                    ? m("table", [
                          m("thead", [m("th", "ID"), m("th", "Created"), m("th", "Next billed"), m("th", "Finished"), m("th", "Code"), m("th", "Force")]),
                          this.subscriptions().map(s =>
                              m("tr" + (s.finished ? "" : ".highlight"), [
                                  m("td", link(`/user/subscription/${s.id}`, s.id)),
                                  m("td", dateFormat(deletez(s.created))),
                                  m("td", dateFormat(deletez(s.next_billed))),
                                  m("td", dateFormat(deletez(s.finished))),
                                  m("td", s.cancel_reason_code || "-"),
                                  m("td", s.force_billing || "false"),
                              ]),
                          ),
                      ])
                    : m("p", "(None)"),
                m("hr"),
                m("h2", "Sessions"),
                this.sessions() && this.sessions().length > 0
                    ? [
                          m("table", [
                              m("thead", [m("th", "Created"), m("th", "Expires"), m("th", "IP address"), m("th", "User agent")]),
                              this.sessions()
                                  .slice(this.sessionPage() * 20, this.sessionPage() * 20 + 20)
                                  .map(session => {
                                      const created = new Date(session.created);
                                      const expires = new Date(session.expires);

                                      return m("tr" + (expires > now ? ".highlight" : ""), [
                                          m("td", dateFormat(created)),
                                          m("td", dateFormat(expires, false)),
                                          m("td", session.ip_address),
                                          m(`td.${css.useragent}`, session.user_agent),
                                      ]);
                                  }),
                          ]),
                          pagenate(this, "sess"),
                      ]
                    : m("p", "(None)"),
                m("hr"),
                m("h2", "Payment Methods"),
                this.paymentMethods && this.paymentMethods.length > 0
                    ? m("table", [
                          m("thead", [
                              m("th", "ID"),
                              m("th", "Provider"),
                              m("th", "Card identifier"),
                              m("th", "Provider Identifier"),
                              m("th.right", "Created"),
                              m("th.right", "Deleted"),
                          ]),
                          this.paymentMethods.map(pm => {
                              return m("tr", [
                                  m("td", pm.id),
                                  m("td", pm.provider),
                                  m("td", pm.unique_identifier),
                                  m("td", pm.provider_identifier || m("p.warn", "None")),
                                  m("td.right", dateFormat(deletez(pm.created))),
                                  m("td.right", dateFormat(deletez(pm.deleted))),
                              ]);
                          }),
                      ])
                    : m("p", "(None)"),
                m("hr"),
                m("h2", "Subscription cancellations"),
                this.subscriptionCancels() && this.subscriptionCancels().length > 0
                    ? [
                          m("table", [
                              m("thead", [m("th", "Subscription ID"), m("th", "Action"), m("th", "Date")]),
                              this.subscriptionCancels()
                                  .slice(this.subscCancelPage() * 20, this.subscCancelPage() * 20 + 20)
                                  .map(sc => {
                                      const id = sc.subscription_id;
                                      const actionName = (ct => {
                                          switch (ct) {
                                              case "cancel":
                                                  return "cancel";
                                              default:
                                                  return "immmediate billing / undo cancel";
                                          }
                                      })(sc.cancel_type);

                                      return m("tr", [m("td", link(`/user/subscription/${id}`, id)), m("td", actionName), m("td", dateFormat(sc.created))]);
                                  }),
                          ]),
                          pagenate(this, "subcancel"),
                      ]
                    : m("p", "(None)"),
                m(userViewReceipts, { id: this.id, type: "komiplus", pages: this.plusReceiptsPage, user: this.user }),
                m(userViewReceipts, { id: this.id, type: "store", pages: this.storeReceiptsPage, user: this.user }),
                m("hr"),
                m("h2", "Referrer Info"),
                m("p", "visit", link(`/user/id/${this.id}/edit`, " edit page "), "to see referral partner info"),
                this.referrer
                    ? [
                          m("div", m("span", "Referral Code"), m("span", this.referrer.referral)),
                          m(
                              "div",
                              m("span", "Referral User"),
                              this.referrer.referrer_id
                                  ? m("span", link(`user/id/${this.referrer.referrer_id}`, this.referrer.referrer_id))
                                  : m("span", "Not a user"),
                          ),
                          m("div", m("span", "Can Be Unlocked"), m("span", this.referrer.redeemable)),
                      ]
                    : m("p", "This user is not referred by anyone."),
                m("hr"),
                m("h2", "Edit histories"),
                m("p", "Only displaying changed part of the user data."),
                this.editBodyHistory().length > 0 || this.editAttrHistory().length > 0
                    ? m("table", [
                          m("thead", [m("th", "Removed"), m("th", "Added"), m("th.right", "Changed Date")]),
                          this.mergedHistory().map(eh => m("tr", [m("td", eh.reverseView), m("td", eh.patchView), m("td.right", eh.createdView)])),
                      ])
                    : m("p", "(No changes for this user yet)"),
                m("hr"),
                m("h2", "Delete status"),
                m("table", [
                    m("thead", [m("th", "Status"), m("th", "Action Date"), m("th", "Delete schedule date")]),
                    m("tr", [
                        m(
                            "td",
                            function() {
                                if (user.delete_status === 0) return "Mail check";
                                if (user.delete_status === 1) return "Waiting";
                                if (user.delete_status === 2) return "Deleted";
                                if (user.delete_status === 3) return "Cancelled";
                                return "None"
                            }(),
                        ),
                        m(
                            "td",
                            function() {
                                if (user.delete_status === 0) return dateFormat(user.delete_send_at);
                                if (user.delete_status === 1) return dateFormat(user.delete_application_at);
                                if (user.delete_status === 2) return dateFormat(user.deleted);
                                if (user.delete_status === 3) return dateFormat(user.delete_cancel_at);
                                return "--"
                            }()
                        ),
                        m(
                            "td",
                            function() {
                                if (user.delete_status === 1) return dateFormat(user.delete_available_at);
                                if (user.delete_status === 2) return dateFormat(user.delete_available_at);
                                return 
                            }()
                        ),
                    ]),
                ]),
                m("hr"),
                m("h2", "Filter Notifications"),
                this.user().filter_notifications && this.user().filter_notifications.length > 0
                ?
                m("table", [
                    m("thead", [m("th", "Content ID"), m("th", "Show")]),
                          this.user().filter_notifications.map(fn => {
                              return m("tr", [
                                  m("td", link(`/content/${fn.content_id}/edit`, fn.content_id)),
                                  m("td", String(fn.show)),
                              ]);
                          }),
                ])
                : m("p", "(None)"),
                m("hr"),
                m("h2", "Address"),
                this.user().user_addresses && this.user().user_addresses.length > 0
                ?
                m("table", [
                    m("thead", [m("th", "default"), m("th", "hidden"), m("th", "name"), m("th", "zip_code"), m("th", "telephone"), m("th", "address"), m("th", "created"), m("th", "deleted")]),
                          this.user().user_addresses.map(a => {
                              return m("tr", [
                                  m("td", String(a.is_default)),
                                  m("td", String(a.is_hidden)),
                                  m("td", a.name),
                                  m("td", a.zip_code),
                                  m("td", a.telephone),
                                  m("td", `${a.state}${a.address_line3}${a.address_line2}${a.address_line1}`),
                                  m("td", a.created),
                                  m("td", a.deleted),
                              ]);
                          }),
                ])
                : m("p", "(None)"),
            ]);
        },

        mergedHistory() {
            const merged = [];
            let i = 0;
            let j = 0;
            let current = 0;

            while (current < this.editBodyHistory().length + this.editAttrHistory().length) {
                const isEditHistoryDepleted = i >= this.editBodyHistory().length;
                const isEditAttrHistoryDepleted = j >= this.editAttrHistory().length;

                if (!isEditHistoryDepleted && (isEditAttrHistoryDepleted || this.editBodyHistory()[i].created > this.editAttrHistory()[j].created)) {
                    merged[current] = bodyHistoryView(this.editBodyHistory()[i]);
                    i += 1;
                } else {
                    merged[current] = attrHistoryView(this.editAttrHistory()[j]);
                    j += 1;
                }
                current += 1;
            }

            return merged;
        },
    },
};
