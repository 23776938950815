export const receiptStat = (receipt) => {
    if (!receipt) {
        return "-";
    }

    const latestEvent = (receipt.shop_receipt_events || []).findLast((event) => event.status === "complete");
    if (!latestEvent) {
        return receipt.status;
    }
    switch (latestEvent.event_type) {
        case "refund":
            return "refunded";
        case "repeat":
            return "partial refunded";
        default:
            return receipt.status;
    }
};
