"use strict";

import m from "mithril";
import config from "../../config";
import {
    createCollection,
} from "../../api-client";

export default {
    $navTitle: "Create new",
    $component: {
        oninit(vnode) {
            this.name = m.prop("");
            this.subtitle = m.prop("");
            this.expiry = m.prop(null);
            this.affiliate_links = m.prop("");

            this.submit = () => {
                const name = this.name() + '|' + this.affiliate_links() + '|' + this.subtitle();
                const expiry = this.expiry() ? new Date(this.expiry()).toJSON() : null;
                createCollection(name, expiry, "public").then(
                    (res) => {
                        // Nothing else to do
                        m.route.set(`/collection/${res.reading_list.id}/edit`);
                    },
                    (err) => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    }
                );

                return false;
            };

            return this;
        },

        view() {
            return m("form", {
                onsubmit: this.submit,
            }, [
                m("h1", "New collection"),
                m("div", [
                    m("label[for=name]", "Title"),
                    m("input[type=text][name=name]", {
                        onchange: m.withAttr("value", this.name),
                        value: this.name(),
                    })
                ]),
                m("div", [
                    m("label[for=subtitle]", "Subtitle"),
                    m("input[type=text][name=subtitle]", {
                        onchange: m.withAttr("value", this.subtitle),
                        value: this.subtitle(),
                    }), ' ',
                    m("a",{
                        onclick: _ => { this.subtitle('[blank]'); },
                    }, "blank")
                ]),
                m("div", [
                    m("label[for=expiry]", "Expiry date"),
                    m("input[type=text][name=expiry][autocomplete=off]", {
                        onchange: m.withAttr("value", this.expiry),
                        value: this.expiry(),
                        oncreate: function(vnode) {
                            this.pikaday = new Pikaday({
                                field: vnode.dom,
                            });
                        },
                        onremove: function() {
                            this.pikaday.destroy();
                        },
                    }),
                ]),
                m("div", [
                    m("label[for=affiliate_links]", "Affiliate links"),
                    m("input[type=text][name=affiliate_links]", {
                        onchange: m.withAttr("value", this.affiliate_links),
                        value: this.affiliate_links(),
                    })
                ]),
                m("div", [
                    m("button[type=submit]", "Create")
                ])
            ]);
        },
    },
};
