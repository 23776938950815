"use strict";

import m from "mithril";
import autocomplete from "../../components/autocomplete";
import {
    getUserAdminEmail,
    getUserAdminId,
    getUserSubscription,
    patchUserAdmin,
    putUserAttributesAdmin,
    putUserExpireAdmin,
    putUserSubscription,
    putUserUnexpireAdmin,
} from "../../api-client";
import { affiliateEditor } from "./edit_affiliate";
import { pointEditor } from "./edit_point";

export default {
    $component: {
        oninit(vnode) {
            this.handle(vnode);
        },

        handle(vnode) {
            this.id = m.prop();
            this.user = m.prop();
            this.email = m.prop("");
            this.deleted = null;
            this.can_view_contents = m.prop("");
            this.password = m.prop("");
            this.notes = m.prop("");
            this.store_name = m.prop("");
            this.access_level = m.prop("");
            this.attributes = m.prop("");
            this.core = m.prop(false);

            this.subscription = m.prop("");
            this.subscription_charge_date = m.prop("");
            this.subscription_charges_count = m.prop("");

            this.error = m.prop("");
            this.success = m.prop("");

            this.populateUser = res => {
                const user = res.user;

                this.user(user);
                this.id(user.id);
                this.email(user.email);
                this.deleted = user.deleted;
                this.can_view_contents(user.can_view_contents);
                this.access_level(user.access_level);
                this.notes(user.data.notes || "");
                this.store_name(user.data.store_name || "");
                this.attributes(user.attributes);

                this.core(user.core);
                // Get subscription
                getUserSubscription(user.id).then(
                    resSubscription => {
                        this.subscription(resSubscription.subscription);
                        this.subscription_charges_count(resSubscription.subscription_receipts.ChargesCount);
                        if (!resSubscription.subscription.cancel_reason_code) {
                            this.subscription_charge_date(resSubscription.subscription.next_billed);
                        }
                        m.redraw();
                    },
                    errSubscription => {
                        // console.error(errSubscription);
                    },
                );

                m.redraw();
            };

            if (vnode.attrs.id) {
                const id = vnode.attrs.id;

                getUserAdminId(id).then(
                    res => {
                        this.populateUser(res);
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );
            } else {
                const email = vnode.attrs.email;

                getUserAdminEmail(email).then(
                    res => {
                        this.populateUser(res);
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );
            }

            this.save = () => {
                const fields = {
                    email: this.email(),
                    password: this.password(),
                    access: String(this.access_level()),
                    data: {
                        notes: this.notes() || undefined,
                        store_name: this.store_name() || undefined,
                    },
                };

                patchUserAdmin(this.id(), fields).then(
                    res => {
                        alert("Successfully updated this user");
                        if (vnode.attrs.user) {
                            vnode.attrs.user(res.user);
                            m.redraw();
                        }
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );

                return false;
            };

            this.subscriptionCancel = _ => {
                const confirmCancel = confirm("Are you sure you want to cancel this user's subscription?");

                if (confirmCancel) {
                    // Cancel the user's subscription

                    putUserSubscription(this.id(), { status: "cancel" }).then(
                        res => {
                            alert("The subscription was cancelled");
                            location.reload();
                        },
                        err => {
                            console.error(err);
                            alert(JSON.stringify(err));
                        },
                    );
                }

                return false;
            };

            this.saveSubscription = _ => {
                var expiry_date = new Date(this.subscription_charge_date()).toJSON();

                putUserSubscription(this.id(), { expiry_date: expiry_date, status: "uncancel" }).then(
                    res => {
                        alert("Successfully applied the new billing date");
                        location.reload();
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );

                return false;
            };
        },

        onbeforeupdate(vnode, old) {
            return vnode.attrs.id !== old.attrs.id ? this.handle(vnode) : true;
        },

        view() {
            const now = new Date();

            if (!this.id()) {
                return m(".loading", [m(".load"), m("p", "Loading...")]);
            }

            return [
                m(
                    "form",
                    {
                        onsubmit: this.save,
                    },
                    [
                        m("h2", "Edit User"),
                        m("div.error", this.error()),
                        this.deleted
                            ? m("div", [m("p", this.email), m("p", "This user's account has been closed.")])
                            : [
                                  m("div", [
                                      m("label[for=title]", "Email"),
                                      m("input[type=text][placeholder=email][name=email]", {
                                          oninput: m.withAttr("value", this.email),
                                          value: this.email(),
                                      }),
                                  ]),
                                  m("div", [
                                      m("label", "Password"),
                                      m("input[type=text][placeholder=Password]", {
                                          oninput: m.withAttr("value", this.password),
                                          value: this.password(),
                                      }),
                                      m("br"),
                                      m("label", "Leave blank to use the same password"),
                                  ]),
                                  m("div", [
                                      m("label", "Notes"),
                                      m("textarea", {
                                          oninput: m.withAttr("value", this.notes),
                                          value: this.notes(),
                                      }),
                                  ]),
                                  this.access_level() == 3
                                      ? m("div", [
                                            m("label", "Store name"),
                                            m("input[type=text]", {
                                                oninput: m.withAttr("value", this.store_name),
                                                value: this.store_name(),
                                            }),
                                        ])
                                      : null,
                                  m("div", [
                                      m("label", "Access level"),
                                      m(
                                          "select",
                                          {
                                              onchange: m.withAttr("value", this.access_level),
                                          },
                                          [
                                              // m("option[value=0]", { selected: this.access_level() == 0 }, "Anonymous"),
                                              m("option[value=1]", { selected: this.access_level() == 1 }, "User"),
                                              m("option[value=2]", { selected: this.access_level() == 2 }, "Artist/Subscriber"),
                                              m("option[value=3]", { selected: this.access_level() == 3 }, "Business"),
                                              m("option[value=8]", { selected: this.access_level() == 8 }, "Publisher"),
                                              m("option[value=10]", { selected: this.access_level() == 10 }, "Editor"),
                                              m("option[value=15]", { selected: this.access_level() == 15 }, "Admin"),
                                              // m("option[value=20]", { selected: this.access_level() == 20 }, "Root"),
                                          ],
                                      ),
                                  ]),
                                  m("div", [m("button[type=submit]", "Edit")]),
                              ],
                    ],
                ),
                m("hr"),
                m(
                    "form",
                    {
                        onsubmit: this.saveSubscription,
                    },
                    [
                        m("h2", "Subscription"),
                        !this.subscription() || (this.subscription().finished && this.subscription().finished < now)
                            ? m("div", [m("p", "This user does not have an active subscription")])
                            : m("div", [
                                  m(
                                      "p",
                                      "This user's subscription will " +
                                          (this.subscription_charge_date() ? "renew" : "expire") +
                                          " on " +
                                          this.subscription().finished,
                                  ),
                              ]),
                        m("div", [
                            m("label", "Set charge date"),
                            m("input[type=text][name=expiry_date][autocomplete=off]", {
                                onchange: m.withAttr("value", this.subscription_charge_date),
                                value: this.subscription_charge_date(),
                                oncreate: function(vnode) {
                                    this.pikaday = new Pikaday({
                                        field: vnode.dom,
                                    });
                                },
                                onremove: function() {
                                    this.pikaday.destroy();
                                },
                            }),
                        ]),
                        m("div", [m("button[type=submit]", "Save")]),
                        !this.subscription_charge_date() ? null : m("div", [m("button", { onclick: this.subscriptionCancel }, "Cancel subscription")]),
                    ],
                ),
                m(attrEditor, {
                    user: this.user,
                }),
                m(affiliateEditor, {
                    user: this.user(),
                }),
                m(coreMemberview, {
                    core: this.core,
                    charges_count: this.subscription_charges_count,
                }),
                m(pointEditor, {
                    id: this.id(),
                }),
                m(expireEditor, {
                    user: this.user,
                }),
            ];
        },
    },
};

const attrEditor = {
    oninit(vnode) {
        const user = vnode.attrs.user;
        if (!user()) {
            return console.error("User attribute editor initialized without user");
        }

        // Need to coerce the attributes object into a list, which is a bit
        // annoying but alas. hue hue hue.
        const attributeIds = [];

        this.recomputeAttrs = () => {
            attributeIds.length = 0;
            user.run(user => {
                for (let i = 0; i < user.attributes.length; ++i) {
                    attributeIds.push(user.attributes[i].id);
                }
                this.attributes = user.attributes;
            });
        };
        this.recomputeAttrs();

        const patch = newAttrIds => {
            const fields = { attribute_ids: newAttrIds };

            putUserAttributesAdmin(user().id, fields).then(
                res => {
                    vnode.attrs.user(res.user);
                    this.recomputeAttrs();
                    m.redraw();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        };

        this.addAttr = attr => {
            patch(attributeIds.concat([attr.id]));
        };

        this.removeAttr = attr => {
            patch(attributeIds.filter(id => id != attr.id));
        };

        this.autocompleteOpts = autocomplete.opts.adminAttrs({
            onclick: this.addAttr,
            filter: attr => attr.parent && attributeIds.indexOf(attr.id) === -1,
        });

        return this;
    },

    view() {
        return m("div", [
            m("hr"),
            m("h2", "Attributes"),
            m("div", [m("span", "Add Attributes"), m(autocomplete, this.autocompleteOpts)]),
            this.attributes
                ? this.attributes.map(attr =>
                      m("div.tag", [m("span", attr.data.name), m("button", { onclick: this.removeAttr.bind(null, attr) }, m.trust("&nbsp;"))]),
                  )
                : "",
        ]);
    },
};

const coreMemberview = {
    oninit(vnode) {
        this.coreMember = {
            status: m.prop(vnode.attrs.core),
            canuse: m.prop(vnode.attrs.charges_count),
        };

        return this;
    },

    view() {
        return [
            m("hr"),
            m("h2", "Core member"),
            m("div", [m("label", "Can use"), m("label", this.coreMember.canuse() != 0 ? "Yes" : "No (Trial user)")]),
            m("div", [m("label", "Status"), m("label", this.coreMember.status() == true ? "Enable" : "Disable")]),
        ];
    },
};

const expireEditor = {
    oninit(vnode) {
        const user = vnode.attrs.user;
        if (!user()) {
            return console.error("Close account editor initialized without user");
        }

        this.expire = _ => {
            const deleteConfirm = confirm(
                "Are you sure this user's account should be closed? They will no longer be able to log in to Komiflo or access content.",
            );

            if (deleteConfirm) {
                putUserExpireAdmin(user().id).then(
                    res => {
                        user.deleted = res.deleted;
                        location.reload();
                    },
                    err => {
                        console.error(err);
                        alert(JSON.stringify(err));
                    },
                );
            }

            return false;
        };

        this.unexpire = _ => {
            putUserUnexpireAdmin(user().id).then(
                res => {
                    user.deleted = res.deleted;
                    location.reload();
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );

            return false;
        };

        return this;
    },

    view(vnode) {
        return m("div", [
            m("hr"),
            m("h2", "Danger zone"),
            !vnode.attrs.user().deleted
                ? m("button", { onclick: this.expire }, "Close this user's account")
                : m("button", { onclick: this.unexpire }, "Open this user's account"),
            m("p", "Closed accounts will not be billed. After closing a user's account, you do not need to remove/change their subscription."),
        ]);
    },
};
