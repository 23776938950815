"use strict";

import { affiliateUnit, affiliateCsv } from "../../utils/affiliate-csv";
import dayjs from "dayjs";
import { getReferrals } from "../../api-client";
import m from "mithril";
import pagination from "../../components/pagination";

const periods = (interval) => {
    const today = dayjs();
    let target = dayjs("2017-10-1");

    const p = [];

    do {
        p.unshift({
            start: target,
            end: target
                .add(interval - 1, "month")
                .endOf("month")
                .endOf("day"),
        });

        target = target.add(interval, "month").startOf("month");
    } while (target.isBefore(today));

    return p;
};

class Model {
    constructor() {
        this.page = m.prop(0);
        this.perPage = 25;
        this.totalPages = m.prop(0);
        this.loading = true;

        this.elements = [];
        this.kind = "artist";
        this.initSelector();

        this.load();
    }

    initSelector() {
        switch (this.kind) {
            case "artist":
                this.selectors = periods(4);
                break;
            case "media":
                this.selectors = periods(1);
                break;
            default:
                this.selectors = periods(4);
                break;
        }
        this.startDate = this.selectors[0].start;
        this.endDate = this.selectors[0].end;
    }

    load(page) {
        this.page(page);
        this.loading = true;

        getReferrals(dayjs(this.startDate).toJSON(), dayjs(this.endDate).toJSON(), page, this.perPage, this.kind).then(
            (res) => {
                this.elements = res.referrals;
                this.loading = false;
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    }
}

export default {
    $navTitle: "Affiliate",
    $component: {
        oninit() {
            this.vm = new Model();
        },

        view() {
            return m("div", [
                m("h1", "Affiliate"),
                m("form", [
                    m("div", [
                        m("label", "Referral Kind"),
                        m(
                            "select",
                            {
                                onchange: (e) => {
                                    this.vm.kind = e.target.value;
                                    this.vm.initSelector();
                                    this.vm.load();
                                },
                                value: this.vm.kind,
                            },
                            m("option", { value: "artist" }, "Artist"),
                            m("option", { value: "media" }, "Media"),
                            m("option", { value: "normal_user" }, "Normal User"),
                        ),
                    ]),
                    m("div", [
                        m("label", "Period"),
                        m(
                            "select",
                            {
                                onchange: (e) => {
                                    this.vm.startDate = e.target.selectedOptions[0].getAttribute("start");
                                    this.vm.endDate = e.target.selectedOptions[0].getAttribute("end");
                                    this.vm.load();
                                },
                            },
                            this.vm.selectors.map((s) =>
                                m("option", { start: s.start, end: s.end }, `${s.start.format("YYYY-MM-DD")} ~ ${s.end.format("YYYY-MM-DD")}`),
                            ),
                        ),
                    ]),
                    m("div", [
                        m(
                            "button",
                            {
                                onclick: (e) => {
                                    e.preventDefault();
                                    affiliateCsv(dayjs(this.vm.startDate).toJSON(), dayjs(this.vm.endDate).toJSON(), this.vm.kind);
                                },
                            },
                            "Download CSV",
                        ),
                    ]),
                ]),

                this.vm.loading
                    ? m(".loading", [m(".load"), m("p", "Generating report..."), m("p", "Please wait up to 30 seconds for this report to be generated.")])
                    : m("table", [
                          m("tr", [m("th", "Code"), m("th", "User ID"), m("th", "Referrals"), m("th", "Earnings")]),
                          this.vm.elements.map((ref) =>
                              m("tr", [
                                  m("td", ref.code),
                                  m(
                                      "td",
                                      m(
                                          "a",
                                          {
                                              href: `/user/id/${ref.user_id}`,
                                              oncreate: m.route.link,
                                          },
                                          ref.user_id,
                                      ),
                                  ),
                                  m("td", ref.referrals_completed_count),
                                  m("td", (ref.referrals_completed_count * affiliateUnit(this.vm.kind)).toLocaleString()),
                              ]),
                          ),
                      ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
