"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import autocomplete from "../../components/autocomplete";
import link from "../../utils/link";
import { getContentExpired } from "../../api-client";
import config from "../../config";
import { dateFormat } from "../../utils/helper";

class Model {
    constructor(opts = {}) {
        this.author = opts.author;

        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.totalPages = m.prop(0);
        this.loading = true;
        this.elements = [];
        this.attributes = [];
        this.magazine = "";
    }

    load(page) {
        this.page(page);
        this.loading = true;

        getContentExpired("chapter", this.attributes, this.magazine, this.page(), this.perPage()).then(
            (res) => {
                this.totalPages(res.total_pages);
                this.elements = res.results;
                this.loading = false;
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    }

    removeAttribute(slug) {
        this.attributes = this.attributes.filter((a) => a !== slug);
        this.load(0);
    }

    removeMagazine() {
        this.magazine = "";
        this.load(0);
    }
}

export default {
    $navTitle: "Expired",
    $component: {
        oninit() {
            this.vm = new Model();

            this.artistAutoOpts = autocomplete.opts.adminAttrs({
                filter: (e) =>
                    e.parent &&
                    (e.parent.slug === "artists" || e.parent.slug === "tags" || e.parent.slug === "tags-hidden") &&
                    !this.vm.attributes.includes(e.slug),
                onclick: (new_attr) => {
                    this.vm.attributes.push(new_attr.slug);
                    this.vm.load(0);
                },
            });

            this.magazineAutoOpts = autocomplete.opts.adminAttrs({
                filter: (e) => e.parent && e.parent.slug === "magazine",
                onclick: (new_magazine) => {
                    this.vm.magazine = new_magazine.slug;
                    this.vm.load(0);
                },
            });

            this.vm.load(0);
        },

        view() {
            if (!this.vm) {
                return m(".loading", [m(".load"), m("p", "Initializing...")]);
            }

            return m("div", [
                m("h1", "Expired Content"),
                m("div", [
                    m("hr"),
                    m("h3", "Filter by attributes"),
                    m(autocomplete, this.artistAutoOpts),
                    " ",
                    this.vm.attributes.map((a) =>
                        m("div.tag", [
                            m("span", a),
                            m(
                                "button",
                                {
                                    onclick: (e) => {
                                        e.preventDefault();
                                        this.vm.removeAttribute(a);
                                    },
                                },
                                m.trust("&nbsp;"),
                            ),
                        ]),
                    ),
                ]),
                m("div", [
                    m("hr"),
                    m("h3", "Filter by magazine"),
                    m(autocomplete, this.magazineAutoOpts),
                    " ",
                    this.vm.magazine === ""
                        ? ""
                        : m("div.tag", [
                              m("span", this.vm.magazine),
                              m(
                                  "button",
                                  {
                                      onclick: (e) => {
                                          e.preventDefault();
                                          this.vm.removeMagazine();
                                      },
                                  },
                                  m.trust("&nbsp;"),
                              ),
                          ]),
                ]),
                m("hr"),
                this.vm.loading
                    ? m(".loading", [m(".load"), m("p", "Retrieving content..."), m("p", "Please wait.")])
                    : m("table", [
                          m("thead", [m("th", "Title"), m("th", "Expiry"), m("th", "Artist"), m("th", "# tags"), m("th", "")]),
                          this.vm.elements.map((c) =>
                              m("tr", [
                                  m(
                                      "td",
                                      link(
                                          `/content/${c.id}/edit`,
                                          `${c.slug || ""} ${c.id}: ` +
                                              (c.type === "announcement" ? c.body.substring(0, 14) + (c.body.length > 14 ? "..." : "") : c.data.title),
                                      ),
                                  ),
                                  m("td", dateFormat(new Date(c.expiry))),
                                  m(
                                      "td",
                                      c.attributes && c.attributes.artists
                                          ? link("/attribute/" + c.attributes.artists.children[0].id + "/view", c.attributes.artists.children[0].data.name)
                                          : "-",
                                  ),
                                  m("td", c.attributes && c.attributes.tags ? c.attributes.tags.children.length : "-"),
                                  m(
                                      "td",
                                      c.type === "chapter" || c.type === "article"
                                          ? m(
                                                "a",
                                                {
                                                    href:
                                                        `https://${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/` +
                                                        (c.type === "chapter" ? "comics/" : "articles/") +
                                                        (c.slug || c.id),
                                                    target: "_blank",
                                                },
                                                "➡",
                                            )
                                          : "",
                                  ),
                              ]),
                          ),
                      ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
