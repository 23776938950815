import m from "mithril";

import { getContentList } from "../../api-client";
import csv from "../../utils/csv";
import { dateFormat } from "../../utils/helper";

const model = {
    startDate: null,
    endDate: null,
};

const magToPub = mag => {
    switch (mag) {
        case "bavel":
            return "bunendo";
        case "beast":
        case "comichappining":
        case "kairakuten":
        case "shitsurakuten":
        case "wani-anthology":
        case "weekly-kairakuten":
        case "x-eros":
        case "xtc":
            return "wani";
        case "gaira":
        case "hotmilk":
        case "hotmilk-koime":
        case "megastorealpha":
            return "core";
        default:
            return "unknown";
    }
};

function downloadChapterList() {
    getContentList("chapter", model.startDate, model.endDate).then(res => {
        csv(
            "chapter",
            res.results.map(r => {
                r.publisher = magToPub(r.publisher);

                return r;
            }),
        );
    });
}

function downloadMagazineList() {
    getContentList("magazine", model.startDate, model.endDate).then(res => {
        csv(
            "magazine",
            res.results.map(r => {
                r.publisher = magToPub(r.publisher);

                return r;
            }),
        );
    });
}

function downloadAffiliateList() {
    getContentList("volume_affiliate", model.startDate, model.endDate).then(res => {
        csv("volume_affiliate", res.results);
    });
}

export default {
    $navTitle: "Download List",
    $component: {
        view() {
            return [
                m("h1", "Download Content List"),
                m("h2", "Publish date range"),
                m(
                    "",
                    m("span", "start:"),
                    m(
                        "label",
                        m("input", {
                            type: "radio",
                            checked: !model.startDate,
                            onchange: e => {
                                if (e.target.checked) {
                                    model.startDate = null;
                                }
                            },
                        }),
                        "All",
                    ),
                    m(
                        "label",
                        m("input", {
                            type: "radio",
                            checked: model.startDate,
                            onchange: e => {
                                if (e.target.checked) {
                                    model.startDate = new Date();
                                }
                            },
                        }),
                        m("input", {
                            type: "text",
                            autocomplete: "off",
                            onchange: e => {
                                model.startDate = e.target.value;
                            },
                            value: dateFormat(model.startDate),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                    onSelect: date => {
                                        model.startDate = date;
                                        m.redraw();
                                    },
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        }),
                    ),
                ),
                m(
                    "",
                    m("span", "end:"),
                    m(
                        "label",
                        m("input", {
                            type: "radio",
                            checked: !model.endDate,
                            onchange: e => {
                                if (e.target.checked) {
                                    model.endDate = null;
                                }
                            },
                        }),
                        "Today",
                    ),
                    m(
                        "label",
                        m("input", {
                            type: "radio",
                            checked: model.endDate,
                            onchange: e => {
                                if (e.target.checked) {
                                    model.endDate = new Date();
                                }
                            },
                        }),
                        m("input", {
                            type: "text",
                            autocomplete: "off",
                            value: dateFormat(model.endDate),
                            oncreate: function(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                    onSelect: date => {
                                        model.endDate = date;
                                        m.redraw();
                                    },
                                });
                            },
                            onremove: function() {
                                this.pikaday.destroy();
                            },
                        }),
                    ),
                ),
                m("hr"),
                m("h2", "Download CSV"),
                m("button", { onclick: downloadChapterList }, "Chapter"),
                m("button", { onclick: downloadMagazineList }, "Magazine"),
                m("button", { onclick: downloadAffiliateList }, "Affiliate"),
            ];
        },
    },
};
