"use strict";

import m from "mithril";
import {
    getAttributeRoots,
    getAdminAutoAttributes
} from "../../api-client";
import { attributeView } from "./view";
import autocomplete from "../../components/autocomplete";
// import pagination from "../../components/pagination";
import link from "../../utils/link";

export default {
    $navTitle: "Browse",
    $component: {
        oninit(vnode) {
            this.attrs = m.prop([]);

            this.page = m.prop(0);
            this.perPage = m.prop(999);
            // this.totalPages = m.prop(0);

            getAttributeRoots(this.page(), this.perPage()).then(
                (res) => {
                    // this.totalPages(res.children_pagination.total_pages);
                    this.attrs(res.results);
                    m.redraw();
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );

            this.move = (attr) => {
                m.route.set(`/attribute/${attr.id}/view`);
            };

            this.autocompleteOpts = autocomplete.opts.adminAttrs({
                placeholder: "Search for an attribute...",
                onclick: this.move,
            });
        },

        view() {
            return m("div", [
                m("h1", "Top-Level Attributes"),
                m(autocomplete, this.autocompleteOpts),
                m("div", this.attrs().map((attr) => attributeView(attr))),
                // m(pagination, { vm: this }),
            ]);
        }
    }
};
