"use strict";

import m from "mithril";
import { getCoupons } from "../../api-client";
import pagination from '../../components/pagination';
import { dateFormat } from '../../utils/helper';
import tablestyle from '../../table.css';
import link from '../../utils/link';

class Model {
    constructor() {
        this.page = m.prop(0);
        this.perPage = 25;
        this.totalPages = m.prop(1);
        this.loading = true;
        this.coupons = [];
    }

    load(page) {
        this.page(page);
        this.loading = true;

        getCoupons(page, this.perPage).then(res => {
            this.loading = false;

            this.totalPages(res.total_pages);
            this.page(res.page);
            this.coupons = res.coupons;
            m.redraw();
        })
        .catch(err => alert(JSON.stringify(err, null, 2)));
    }
}

export default {
    $navTitle: "Browse",
    $component: {
        oninit() {
            this.vm = new Model();
            this.vm.load(0);
        },

        view() {

            if (this.loading) {
                return m('div', [
                    m('.loading', [
                        m('.load'),
                        m('p', 'Loading...'),
                    ])
                ])
            }

            return m("div", [
                m("h1", "Coupons"),
                m("table", [
                    m("thead", [
                        m("th", "ID"),
                        m("th"),
                        m("th", "Name"),
                        m("th", "Start Date"),
                        m("th", "Expiry Date"),
                        m("th", "# Of Users"),
                        m("th", "# Of Items"),
                    ]),
                    this.vm.coupons.map(coupon =>
                        m("tr", [
                            m("td", m("a", link(`/coupon/${coupon.id}/view`, coupon.id))),
                            m("td", m("a", link(`/coupon/${coupon.id}/edit`, 'Edit'))),
                            m("td", coupon.name),
                            m("td", dateFormat(coupon.start_date)),
                            m("td", dateFormat(coupon.expiry_date)),
                            m("td", m(`span.${tablestyle.count}`, coupon.users_count || 0)),
                            m("td", m(`span.${tablestyle.count}`, coupon.items_count || 0)),
                        ]),
                    ),
                ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
