"use strict";

import m from "mithril";
import link from "../../utils/link";
import {
    getCensorWords,
    postCensorWords,
    deleteCensorWords
} from "../../api-client";

class Model {
    constructor(opts = {}) {
        this.loading = m.prop(true);
        this.word = m.prop("");
        this.replaceWord = m.prop("");
        this.wordlist = m.prop([]);
    }

    get() {
        getCensorWords().then(
            (res) => {
                this.wordlist(res.results);
                this.loading(false);
                m.redraw();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    post() {
        postCensorWords(this.word(), this.replaceWord()).then(
            (res) => {
                this.word("");
                this.replaceWord("")
                this.get();
            },
            (err) => {
                console.error(err);
                alert(JSON.stringify(err));
            }
        );
    }

    delete(id) {
        if (confirm("Delete this?")) {
            deleteCensorWords(id).then(
                (res) => {
                    this.wordlist(this.wordlist().filter(w => w.Id !== id))
                    m.redraw();
                },
                (err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                }
            );
        }
    }

    load() {
        this.loading(true);
        this.get();
    }
}

export default {
    $navTitle: "Censor Words",
    $component: {
        oninit(vnode) {
            this.vm = new Model();
            this.vm.load();
        },

        view() {
            return m("div", [
                m("h1", "Censor Words"),
                this.vm.loading()
                    ? m(".loading", [m(".load"), m("p", "Loading...")])
                    : m("div", {style: 'margin-bottom: 1em;'},

                        m("div", [
                            m("span", "Word"),
                            m("input[type=text]",{
                                onchange: m.withAttr("value", this.vm.word),
                                value: this.vm.word(),
                            }),
                        ]),
                        m("div", [
                            m("span", "Replace Word"),
                            m("input[type=text]",{
                                onchange: m.withAttr("value", this.vm.replaceWord),
                                value: this.vm.replaceWord(),
                            }),
                        ]),
                        m("button", {onclick: e => { e.preventDefault(); this.vm.post(); }}, 'Submit'),
                    ),
                m("table.comment",
                    [
                        m("tr", [
                            m("td", "ID"),
                            m("td", "Word"),
                            m("td", "Replace Word"),
                            m("td", ""),
                        ]),
                        this.vm.wordlist().length 
                            ? this.vm.wordlist().map(w => {
                                return m("tr", [
                                    m("td", w.Id),
                                    m("td", w.Word),
                                    m("td", w.ReplaceWord),
                                    m("td", 
                                        m("button", {onclick: e => { e.preventDefault(); this.vm.delete(w.Id); }}, 'Delete')
                                    ),
                                ]);
                            })
                            : ''
                    ]
                ),
            ]);
        },
    },
};
