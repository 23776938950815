"use strict";

import m from "mithril";
import pagination from "../../components/pagination";
import link from "../../utils/link";
import { getCurrentUser, getContentUnpublished } from "../../api-client";
import config from "../../config";
import { isStoreMode } from "../../utils/helper";

class Model {
    constructor(opts = {}) {
        this.author = opts.author;

        this.page = m.prop(0);
        this.perPage = m.prop(25);
        this.loading = m.prop(true);
        this.totalPages = m.prop(0);
        this.elements = m.prop([]);
    }

    setAuthor(id) {
        this.author = id;
    }

    load(page) {
        this.page(page);
        this.loading(true);

        getContentUnpublished(this.page(), this.perPage(), "published_asc", null, isStoreMode() ? "store" : "subscription").then(
            res => {
                this.totalPages(res.total_pages);
                this.elements(res.results);
                this.loading(false);
                m.redraw();
            },
            err => {
                console.error(err);
                alert(JSON.stringify(err));
            },
        );
    }
}

export default {
    $navTitle: "Unpublished",
    $component: {
        oninit() {
            this.content = m.prop([]);
            this.vm = new Model();

            getCurrentUser().then(
                res => {
                    this.vm.setAuthor(res.id);
                    this.vm.load(0);
                },
                err => {
                    console.error(err);
                    alert(JSON.stringify(err));
                },
            );
        },

        view() {
            const dateFormat = function(date) {
                return date.getFullYear() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + ("0" + date.getDate()).slice(-2);
            };

            return m("div", [
                m("h1", "Unpublished Content"),
                this.vm.loading()
                    ? m(".loading", [m(".load"), m("p", "Retrieving content..."), m("p", "Please wait.")])
                    : m("table", [
                          m("thead", [m("th", "Title"), m("th", "Published"), m("th", "Artist"), m("th", "# tags"), m("th", "")]),
                          this.vm.elements().map(c => {
                              const published = new Date(c.published);

                              return m("tr", [
                                  m(
                                      "td",
                                      link(
                                          `/content/${c.id}/edit`,
                                          `${c.slug || ""} ${c.id}: ` +
                                              (c.type === "announcement" ? c.body.substring(0, 14) + (c.body.length > 14 ? "..." : "") : c.data.title),
                                      ),
                                  ),
                                  m("td", published > new Date() ? dateFormat(published) : "-"),
                                  m(
                                      "td",
                                      c.attributes && c.attributes.artists
                                          ? link("/attribute/" + c.attributes.artists.children[0].id + "/view", c.attributes.artists.children[0].data.name)
                                          : "-",
                                  ),
                                  m("td", c.attributes && c.attributes.tags ? c.attributes.tags.children.length : "-"),
                                  m(
                                      "td",
                                      c.type === "chapter" || c.type === "article"
                                          ? m(
                                                "a",
                                                {
                                                    href: `https://${config.baseUrl.includes("staging") ? "staging." : ""}komiflo.com/${
                                                        c.type === "chapter" ? "comics" : "articles"
                                                    }/${c.slug || c.id}`,
                                                    target: "_blank",
                                                },
                                                "➡",
                                            )
                                          : "",
                                  ),
                              ]);
                          }),
                      ]),
                m(pagination, { vm: this.vm }),
            ]);
        },
    },
};
