exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3oQdxIO8-XKEFOo7AxMa3V:not(:hover) {\n    max-width: 20em;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n._3LjiFGFhAkdX65nWLkAOMR a{\n    width: 40px;\n    padding: .5em;\n    margin-right: .5em;\n    background-color: #ef4274;\n    color: white;\n    font-weight: bold;\n    border-radius: .5em;\n    text-align: center;\n    display: inline-block;\n}\n._3LjiFGFhAkdX65nWLkAOMR div{\n    display: inline-block;\n}\n._3Jfl_iFfU5pYrHrb8krRxu a{\n    background-color: white !important;\n    color: #ef4274;\n    pointer-events: none;\n}\n._4jbxvSGmvVnDfJYqG9AlA {\n    background-color: grey !important;\n    pointer-events: none;\n} \n", ""]);

// exports
exports.locals = {
	"useragent": "_3oQdxIO8-XKEFOo7AxMa3V",
	"paginate": "_3LjiFGFhAkdX65nWLkAOMR",
	"now": "_3Jfl_iFfU5pYrHrb8krRxu",
	"disabled": "_4jbxvSGmvVnDfJYqG9AlA"
};