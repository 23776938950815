import m from 'mithril';
import { getCoupon, getCouponItems, getCouponUsers, getUsersSloppy, updateCouponUsers } from '../../api-client';
import { CouponTypes } from './const';
import { dateFormat } from '../../utils/helper';
import link from '../../utils/link';

export default {
    $component: {
        coupon: {},
        items: [],
        users: [],

        giveout_user_ids: [],
        giveout_candidates: [],
        search_email: '',
        loading: false,

        oninit(vnode) {
            this.loadCoupon(vnode.attrs.id);
            this.loadItems(vnode.attrs.id);
            this.loadUsers(vnode.attrs.id);
        },

        loadCoupon(id) {
            this.loading = true;
            getCoupon(id)
                .then(res => {
                    this.coupon = res.coupon;
                })
                .catch(err => {
                    alert(JSON.stringify(err, null, 2));
                })
                .finally(_ => {
                    this.loading = false;
                    m.redraw();
                })
        },
        loadItems(id) {
            getCouponItems(id)
            .then(res => {
                this.items = res.items;
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))})
        },
        loadUsers(id) {
            getCouponUsers(id)
            .then(res => {
                this.users = res.users;
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))})
            .finally(_ => m.redraw())
        },
        loadCandidates() {
            getUsersSloppy({ page: 1, page_size: 10, email: this.search_email, email_look_after: true } )
            .then(res => {
                this.giveout_candidates = res.results.filter(user => !user.deleted);
                m.redraw();
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))})
        },

        giveout() {
            this.giveout_user_ids = this.giveout_user_ids.map(id => parseInt(id)).filter(id => !isNaN(id));
            updateCouponUsers(this.coupon.id, this.giveout_user_ids)
            .then(() => {
                alert('Successfully delivered the coupon to users')
                this.giveout_user_ids = [];
                this.loadUsers(this.coupon.id);
                m.redraw();
            })
            .catch(err => {alert(JSON.stringify(err, null, 2))})
        },

        view() {

            if (this.loading) {
                return m('div', [
                    m('.loading', [
                        m('.load'),
                        m('p', 'Loading...'),
                    ])
                ])
            }

            return m('div', [
                m('h1', 'Coupon info'),
                m('p', link(`/coupon/${this.coupon.id}/edit`, 'Edit')),
                m('div', [m('span', 'ID'), m('span', this.coupon.id)]),
                m('div', [m('span', 'Name'), m('span', this.coupon.name)]),
                m('div', [m('span', 'Max Per User'), m('span', this.coupon.max_per_user)]),
                m('div', [m('span', 'Start Date'), m('span', dateFormat(this.coupon.start_date))]),
                m('div', [m('span', 'Expiry Date'), m('span', dateFormat(this.coupon.expiry_date))]),

                m('hr'),

                m('h2', 'Applicable Items'),
                m('table', [
                    m('tr', [
                        m('th', 'ID'),
                        m('th', 'Name'),
                        m('th', 'Discount Value'),
                        m('th', 'Commission'),
                    ]),
                    this.items.map(item =>
                        m('tr', [
                            m('td',
                                m('a', link(`/items/${item.item_id}/view`, item.item_id)),
                            ),
                            m('td', item.item_name),
                            m('td', `${item.value} ${CouponTypes.find(ct => ct.value === item.type).name}`),
                            m('td', item.commission_terms),
                        ]),
                    ),
                ]),

                m('hr'),
                m('h2', 'Users who has this coupon'),
                m('table', [
                    m('tr', [
                        m('th', 'ID'),
                        m('th', 'Name'),
                        m('th', '# Remaining'),
                    ]),
                    this.users.map(user => {
                        return m('tr', [
                            m('td',
                                m('a', link(`/user/${user.user_id}/view`, user.user_id)),
                            ),
                            m('td', user.user_email),
                            m('td', user.remaining),
                        ]);
                    }),
                ]),

                m('hr'),

                m('h2', 'User IDs to pass this coupon'),
                m('input[type=text][placeholder=12,34,56,78,...]', {
                    oninput: e => {this.collectUserIds(e.target.value)},
                    value: this.giveout_user_ids.join(','),
                }),
                m('div', m('button', { onclick: _ => this.giveout() }, 'Pass Out to Users Above')),

                m('h2', 'Search Users by email'),
                m('input[type=text][placeholder=Enter Email]', {
                    oninput: e => {
                        this.search_email = e.target.value;
                        this.loadCandidates();
                    },
                    value: this.search_email,
                }),
                m('table', [
                    m('tr', [
                        m('th', 'ID'),
                        m('th', 'Email'),
                        m('th', 'Add'),
                    ]),
                    this.giveout_candidates.map(candidate => {
                        return m('tr', [
                            m('td', m('a', link(`/user/${candidate.id}/view`, candidate.id))),
                            m('td', candidate.email),
                            m('td', m('a', { onclick: () => this.giveout_user_ids.push(candidate.id) }, '✔')),
                        ]);
                    }),
                ]),
            ]);
        },

        collectUserIds(idstr) {
            this.giveout_user_ids = idstr.split(',');
        }
    },
};
