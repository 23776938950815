import m from "mithril";
import { deleteReplyTemplate, getReplyTemplate, patchReplyTemplate } from "../../api-client";
import { Editor } from "./editor";

const model = {
    id: 0,
    loading: false,
    template: null,

    load: (id) => {
        model.id = id;
        model.loading = true;
        getReplyTemplate(id)
            .then((res) => {
                model.template = res.template;
            })
            .finally(() => {
                model.loading = false;
                m.redraw();
            });
    },
    submit: () => {
        patchReplyTemplate(model.id, model.template)
            .then((res) => {
                model.template = res.template;
                alert("Saved!");
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },
    delete: () => {
        if (confirm("Are you sure you want to delete this template?")) {
            deleteReplyTemplate(model.id).then(() => {
                m.route.set("/support/list");
            });
        }
    },
};

export const Edit = {
    $component: {
        oninit({ attrs }) {
            model.load(attrs.id);
        },
        view() {
            if (model.loading) {
                return m(".loading", [m(".load"), m("p", "Loading...")]);
            }

            return m(
                "div",
                m("h2", "Edit support reply template"),
                m(Editor, { onsubmit: model.submit, template: model.template }),
                m("hr"),
                m(
                    "button",
                    {
                        onclick: (e) => {
                            e.preventDefault();
                            model.delete();
                        },
                    },
                    "Delete",
                ),
            );
        },
    },
};
