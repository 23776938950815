import { dateFormat } from "../../utils/helper";
import m from "mithril";
import link from "../../utils/link";
import css from "./view.css";
import { getUserReceipts } from "../../api-client";
import { receiptStat } from "../receipt/utils";

const model = {
    store: {
        items: [],
        pages: 0,
        total: 0,
    },
    komiplus: {
        items: [],
        pages: 0,
        total: 0,
    },
};

function pagenate(url, now, pageMax) {
    let count = 0;
    let start = -4;

    return [
        m(`div.${css.paginate}`, [
            Number(now) > 0 ? link(`${url.replace("___", 0)}`, {}, "<<") : m(`a.${css.disabled}`, "<<"),
            Number(now) > 0 ? link(`${url.replace("___", Number(now) - 1)}`, {}, "<") : m(`a.${css.disabled}`, "<"),
            [...Array(10)].map(() => {
                start += 1;
                if (Number(now) + start >= 0 && Number(now) + start <= Number(pageMax) && count < 7) {
                    count += 1;

                    return m(`${start === 0 ? `.${css.now}` : ""}`, link(`${url.replace("___", Number(now) + start)}`, {}, Number(now) + start + 1));
                }

                return "";
            }),
            Number(now) < Number(pageMax) ? link(`${url.replace("___", Number(now) + 1)}`, {}, ">") : m(`a.${css.disabled}`, ">"),
            Number(now) < Number(pageMax) ? link(`${url.replace("___", Number(pageMax))}`, {}, ">>") : m(`a.${css.disabled}`, ">>"),
        ]),
    ];
}

export const userViewReceipts = {
    oninit({ attrs }) {
        getUserReceipts(attrs.id, attrs.type)
            .then((res) => {
                model[attrs.type].items = res.results || [];
                model[attrs.type].total = Math.floor((model[attrs.type].items.length || 0) / 20)
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                m.redraw();
            });
    },

    view({ attrs }) {
        if (model[attrs.type].length === 0) {
            return null;
        }
        model[attrs.type].pages = attrs.pages()
        return m.fragment({}, [
            m("hr"),
            m("h2", `Purchased ${attrs.type} receipts`),
            m("table", [
                m("thead", [m("th", "Receipts ID"), m("th", "Items"), m("th", "Created"), m("th", "Status"), m("th", "Data")]),
                model[attrs.type].items.slice(attrs.pages * 20, attrs.pages * 20 + 20).map((r) => {
                    if (!r.items) {
                        return "";
                    }

                    const itemName = r.items.reduce((prev, item, i, array) => {
                        if (i > 2) {
                            return prev;
                        }
                        if (i === 2) {
                            return `${prev} and ${array.length - 2} more`;
                        }

                        return `${prev} ${item.shop_item.name}`;
                    }, "");

                    return m("tr", [
                        m("td", link(`/receipt/${r.receipt_id_original}`, r.receipt_id_original)),
                        m("td", itemName),
                        m("td", dateFormat(r.created)),
                        m("td", receiptStat(r)),
                        m("td", (()=>{
                          if (r.status != 'provisional' &&  r.provider == 'atone' || r.provider == 'atone_tsudo') return `consoleID: ${attrs.user().id}_store_${r.receipt_id_original}`
                          if (r.partial_paid) return `partial paid value: ${r.partial_paid}`
                          return ""
                        })()),
                    ]);
                }),
            ]),
            pagenate(
                `/user/id/${attrs.id}?plusReceipts=${attrs.type == 'komiplus'? '___' : model['komiplus'].pages}&storeReceipts=${attrs.type == 'store'? '___' : model['store'].pages}`,
                attrs.pages,
                model[attrs.type].total
                )
        ]);
    },
};
