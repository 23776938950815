"use strict";

import m from "mithril";
import css from "./index.css";
import {
    getAdminTimestamps,
    postBillingCleanUp,
    postAPICacheCleanup,
    adminTimestampStatus,
} from "../../api-client";
import link from "../../utils/link";

const timeFormat = function (d) {
    d = new Date(d);

    return (
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2)
    );
};

class Model {
    constructor() {
        this.loading = false;
        this.cardsReset = false;
        this.APICacheStarted = false;
        // this.users = m.prop([]);
    }

    load() {
        // this.loading(true);
        //
        // getAdminTimestamps().then(
        //     res => {
        //         this.loading(false);
        //         this.users(res.users);
        //         m.redraw();
        //     },
        //     err => {
        //         console.error(err);
        //         this.loading(false);
        //     }
        // )

        this.loading = false;
    }

    resetCards() {
        const resetConfirm = confirm("Are you sure?");
        if (!resetConfirm) {
            this.cardsReset = false;
        }

        postBillingCleanUp().then((res) => {
            console.log(res);
            this.cardsReset = true;
            m.redraw();
        });
    }

    refleshAPICache() {
        this.APICacheStarted = true;
        postAPICacheCleanup().then((res) => {
            alert("Started reflesh process. this may take ~2 minutes.");
        }).catch((err) => {
            alert(err);
        });
    }

}

const tsEmoji = {
    "shinjuku1565_in": "　",
    "shinjuku1565_out": "　",
    "shinjuku1565_lunch": "🥪",
    "shinjuku1565_out_of_office": "🕴",
    "remote_in": "Ｒ",
    "remote_out": "Ｒ",
    "remote_lunch": "🥪",
    "remote_out_of_office": "🕴",
}

export default {
    $navTitle: "Home",
    $component: {
        oninit: function({ state }) {
            state.vm = new Model();
            state.vm.load();
        },

        view: function({ state }) {
            return m("div", [
                m("h1", "Komiflo CMS"),
                // m(`h3.${css.i_am_funny}`, "Lawton was here")
                m("div", m("button", {
                    onclick: () => { state.vm.cardsReset(); },
                    classList: state.vm.cardsReset ? css.begone : "",
                    disabled: state.vm.cardsReset,
                }, state.vm.cardsReset ? "Success" : "Reset test cards")),
                m("div", m("button", {
                    style: { marginTop: "10px" },
                    onclick: () => { state.vm.refleshAPICache(); },
                    disabled: state.vm.APICacheStarted,
                }, state.vm.APICacheStarted ? "Refleshing..." : "Reflesh API Cache")),
                m("p", link("/validateArtist", {}, "Validate Artists")),
                // m("hr"),
                // m("h2", "Team status"),
                // this.vm.loading()
                //     ? m("p", "Loading available users...")
                //     : this.vm.users().length == 0
                //         ? m("p", "Nobody is available right now.")
                //         : m(`table.${css.status}`, [
                //             this.vm.users().map((user) =>
                //                 m("tr", [
                //                     m(`td.${css[user.latest_timestamp.Type]}`, { title: adminTimestampStatus[user.latest_timestamp.Type] }, tsEmoji[user.latest_timestamp.Type]),
                //                     m("td", timeFormat(user.latest_timestamp.Created)),
                //                     m("td", m("a", { href: `/user/id/${user.id}`, oncreate: m.route.link }, `${user.email}`)),
                //                 ]),
                //                 // m("l" + (user.deleted ? ".closed" : "") + (user.access_level == 2 ? ".access_level_2" : "") + (user.can_view_contents == true ? ".can_view_contents" : ""), [
                //                 //     m("td", link(`/user/id/${user.id}`, user.id)),
                //                 //     m("td", m(`a.button`, { href: `/user/id/${user.id}/edit`, oncreate: m.route.link, onupdate: m.route.link }, "Edit")),
                //                 //     m("td", user.email),
                //                 //     m("td", user.access),
                //                 //     m("td", user.deleted ? "Closed" : "Open"),
                //                 //     // m("td", user.created)
                //                 // ])
                //             )
                //         ]),
            ]);
        },
    },
};
