import Create from "./create";
import Edit from "./edit";
import List from "./list";
import View from "./view";
import Comment from "./comments";

export default {
    ":id/edit": Edit,
    ":id/view": View,
    create: Create,
    list: List,
    comment: Comment,
};
