import m from "mithril";

const timeSelector = (setter, getter) => [
    m(
        "select",
        {
            onchange: m.withAttr("value", setter),
            value: getter,
        },
        Reflect.apply(Array, null, { length: 24 }).map((_, i) =>
            m(
                "option",
                {
                    value: i + ":00",
                    selected: i + ":00" === getter || null,
                },
                ("0" + i).slice(-2) + ":00",
            ),
        ),
    ),
];

export { timeSelector };

