import m from "mithril";
import { postReplyTemplate } from "../../api-client";
import { Editor } from "./editor";

const model = {
    template: {
        userIntent: "",
        textJA: "",
        textEN: "",
    },

    submit: () => {
        postReplyTemplate(model.template)
            .then((res) => {
                m.route.set(`/support/template/id/${res.template.id}/edit`);
            })
            .catch((err) => {
                alert(err);
            });
    },
};

export const Create = {
    $navTitle: "New reply template",
    $component: {
        view: () => m("div", m("h2", "Create new support reply template"), m(Editor, { onsubmit: model.submit, template: model.template })),
    },
};
