"use strict";

import m from "mithril";
// For some reason, the date format is broken when this is declared.
// import Pikaday from "pikaday";
import { createItem } from "../../api-client";
import dayjs from "dayjs";
import { timeSelector } from "../../utils/input-component";
import { isStoreMode } from "../../utils/helper";
import { parseIntForInput } from "../../utils/type-cast";

const ItemTypes = ["komiplus", "bolton", "subscription", "store"];

const pricePresets = {
    free: { t: 0, c: 0 },
    komiplus: { t: 100, c: 50 },
};

export default {
    $navTitle: "Create new",
    $component: {
        oninit() {
            this.name = m.prop("");
            this.type = m.prop(isStoreMode() ? "store" : "komiplus");
            // this.published = m.prop(new Date());
            // this.expiry = m.prop(
            //     dayjs()
            //         .add(1, "year")
            //         .toDate(),
            // );
            this.prePublished = m.prop();
            this.published = m.prop();
            this.publishedTime = m.prop("0:00");
            this.expiry = m.prop();
            this.public = m.prop(false);
            this.quantityTotal = m.prop("");
            this.unlimited = true;
            this.maxPerUser = 1;
            this.totalValuePreTax = "";
            this.commissionValuePreTax = "";
            this.priceEditable = true;
            this.loading = false;
        },
        submit(e) {
            e.preventDefault();
            this.loading = true;

            const quantityTotal = this.unlimited ? null : parseInt(this.quantityTotal(), 10);

            const prePublished = dayjs(this.prePublished());
            const published = dayjs(this.published() + " " + this.publishedTime());
            const expiry = dayjs(this.expiry());

            const params = {
                quantityTotal,
                name: this.name(),
                type: this.type(),
                pre_published: this.prePublished() && prePublished.isValid() ? prePublished.toISOString() : "",
                published: this.published() && published.isValid() ? published.toISOString() : "",
                expiry: this.expiry() && expiry.isValid() ? expiry.toISOString() : "",
                public: this.public(),
                max_per_user: this.maxPerUser,
                total_value_pre_tax: this.totalValuePreTax,
                commission_value_pre_tax: this.commissionValuePreTax,
            };

            createItem(params)
                .then((res) => {
                    const id = res.shop_item.Id;
                    m.route.set(`/items/${id}/edit`);
                })
                .catch((err) => {
                    console.error(err);
                    alert(JSON.stringify(err));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        view() {
            return m("div", [
                m("form", { onsubmit: (e) => this.submit(e) }, [
                    m("h2", "Create Item"),
                    m("div", [
                        m("label", "Title"),
                        m("input", {
                            type: "text",
                            placeholder: "Name",
                            required: true,
                            oninput: m.withAttr("value", this.name),
                            value: this.name(),
                            name: "name",
                        }),
                    ]),
                    m("div", [
                        m("label", "Type"),
                        m(
                            "select",
                            {
                                onchange: m.withAttr("value", this.type),
                            },
                            ItemTypes.map((itemType) =>
                                m(
                                    "option",
                                    {
                                        selected: this.type() === itemType,
                                        value: itemType,
                                    },
                                    itemType,
                                ),
                            ),
                        ),
                    ]),
                    m("div", [
                        m("label", "Pre Release date"),
                        m("input", {
                            type: "text",
                            onchange: m.withAttr("value", this.prePublished),
                            value: this.prePublished(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Release date"),
                        m("input", {
                            type: "text",
                            onchange: m.withAttr("value", this.published),
                            value: this.published(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                        "　",
                        ...timeSelector(this.publishedTime, this.publishedTime()),
                    ]),
                    m("div", [
                        m("label", "Expiry date"),
                        m("input", {
                            type: "text",
                            onchange: m.withAttr("value", this.expiry),
                            value: this.expiry(),
                            oncreate(vnode) {
                                this.pikaday = new Pikaday({
                                    field: vnode.dom,
                                });
                            },
                            onremove() {
                                this.pikaday.destroy();
                            },
                        }),
                    ]),
                    m("div", [
                        m("label", "Public"),
                        m("input", {
                            type: "checkbox",
                            value: this.public(),
                            onchange: m.withAttr("checked", this.public),
                        }),
                    ]),
                    m("div", [
                        m("label", "Price"),
                        m(
                            "select",
                            {
                                oninput: (e) => {
                                    const prices = pricePresets[e.target.selectedOptions[0].value];
                                    if (prices) {
                                        this.totalValuePreTax = prices.t;
                                        this.commissionValuePreTax = prices.c;
                                        this.priceEditable = false;
                                    } else {
                                        this.priceEditable = true;
                                    }
                                },
                            },
                            m("option", "manual input"),
                            m("option", "free"),
                            m("option", "komiplus"),
                        ),
                    ]),
                    m("div", [
                        m("label", ""),
                        m("label", { style: "display:inline-block; width: 200px;" }, "Total value pre tax"),
                        m("input", {
                            type: "text",
                            required: true,
                            oninput: (e) => {
                                this.totalValuePreTax = parseIntForInput(e.target.value);
                            },
                            value: this.totalValuePreTax,
                            disabled: !this.priceEditable,
                        }),
                    ]),
                    m("div", [
                        m("label", ""),
                        m("label", { style: "display:inline-block; width: 200px;" }, "Commission value pre tax"),
                        m("input", {
                            type: "text",
                            required: true,
                            oninput: (e) => {
                                this.commissionValuePreTax = parseIntForInput(e.target.value);
                            },
                            value: this.commissionValuePreTax,
                            disabled: !this.priceEditable,
                        }),
                    ]),

                    m("div", [
                        m("label", "Quantity"),
                        m("input", {
                            type: "text",
                            placeholder: "Quantity Total",
                            oninput: m.withAttr("value", this.quantityTotal),
                            value: this.quantityTotal(),
                            disabled: this.unlimited,
                        }),
                        m("label", [
                            m("input", {
                                type: "checkbox",
                                style: { "margin-left": "1em" },
                                checked: this.unlimited,
                                onchange: (e) => {
                                    this.unlimited = e.target.checked;
                                },
                            }),
                            "Unlimited",
                        ]),
                    ]),
                    m("div", [
                        m("label", "Max per user"),
                        m("input", {
                            type: "text",
                            placeholder: "Max per User",
                            required: true,
                            oninput: (e) => {
                                this.maxPerUser = parseIntForInput(e.target.value) || 1;
                            },
                            value: this.maxPerUser,
                        }),
                    ]),
                    m("div", [m("span"), m("input", {
                        type: 'submit',
                        value: this.loading ? '...' : 'Save',
                        disabled: this.loading,
                    })]),
                ]),
            ]);
        },
    },
};
