"use strict";

import dayjs from "dayjs";
import m from "mithril";
import "whatwg-fetch";

import config from "./config";

let baseUrl = config.baseUrl;

var currentUser = null;

const userLevels = [
    "anonymous",
    "registered",
    "subscriber",
    "business",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    "editor",
    undefined,
    undefined,
    undefined,
    undefined,
    "admin",
    undefined,
    undefined,
    undefined,
    undefined,
    "super"
];

function configureXHR(xhr) {
    xhr.withCredentials = true;
    return xhr;
}

function doRequest(method, endpoint, params) {
    let options = {
        url: endpoint.split("http").length > 1 ? endpoint : baseUrl + endpoint,
        method: method,
        data: params,
        config: configureXHR
    };

    return new Promise((resolve, reject) => {
        return (m.request(options)
            .run((res) => {
                if (!res.success) {
                    if (res.error === "login_required") {
                        currentUser = null;
                    }

                    return reject(res);
                }

                return resolve(res);
            })
            .catch((err) => {
                // Mithril's request stuff is stupid as fuck and doesn't
                // give us access to the raw XHR. We can assume that if there's
                // no response text (stupidly passed via .message or .error)
                // the backend server is not responding properly.
                if (err.message === "" && err.error === "") {
                    return alert("Please try again. If this continues, please contact the engineers.");
                }

                return reject(err);
            })
        );
    });
}

function post(endpoint, params) {
    return doRequest("POST", endpoint, params);
}

function get(endpoint) {
    return doRequest("GET", endpoint);
}

function patch(endpoint, params) {
    return doRequest("PATCH", endpoint, params);
}

function put(endpoint, params) {
    return doRequest("PUT", endpoint, params);
}

function del(endpoint, params) {
    return doRequest("DELETE", endpoint, params);
}

export function getCurrentUser() {
    if (currentUser) {
        return Promise.resolve(currentUser);
    }

    return get("/session/user").then(
        (res) => {
            res.user.access = userLevels[res.user.access_level];
            currentUser = res.user;
            return res.user;
        },
        (err) => Promise.reject(err)
    );
}

export function login(email, password) {
    let ret = post("/session/login", {
        email,
        password,
    });
    return ret.then((res) => {
        res.user.access = userLevels[res.user.access_level];
        currentUser = res.user;
        return res;
    });
}

export function logout() {
    currentUser = null;
    return post("/session/logout");
}

export const getUserSubscriptions = id => get(`/admin/user/id/${id}/subscriptions`);
export const getUserPaymentMethods = id => get(`/user/id/${id}/payment_methods?provider=all`);
export const getUserAffiliate = id => get(`/admin/user/id/${id}/referral`);
export const getUserReferrer = id => get(`/admin/user/id/${id}/referrer`);

export function createUserAffiliate(userId, params) {
    return post(`/admin/user/id/${userId}/referral`, params);
}
export function patchUserAffiliate(id, rP) {
    return patch(`/admin/user/id/${id}/referral`, rP);
}
export function getUserSubscriptionCancels(id) {
    return get(`/admin/user/id/${id}/subscriptions/cancels`);
}
export function postUserReferralOptIn(id) {
    return post(`/user/id/${id}/referral/opt-in`);
}

export const getSubscription = id => get(`/admin/subscription/id/${id}`);
export const getSubscriptionPaymentMethods = id => get(`/admin/subscription/id/${id}/payment_methods`);
export const getSubscriptionReceipts = (id, page, perPage) => get(`/admin/subscription/id/${id}/receipts?page=${page}&page_size=${perPage}`);
export const getSubscriptionEdits = id => get(`/admin/subscription/id/${id}/edits`);

export const createUser = (email, password) => post("/user", {
        email,
        password,
        password2: password,
})

export const createUserAdmin = (email, access, password) => post("/admin/user", {
        email,
        access,
        password,
});

export function getUserAdminId(id) {
    return get(`/admin/user/id/${id}`);
}

export function getUserAdminEmail(email) {
    return get(`/admin/user/email/${email}`);
}

export function patchUserAdmin(id, user) {
    return patch(`/admin/user/id/${id}`, user)
}

export function putUserExpireAdmin(id) {
    return put(`/admin/user/id/${id}/expire`)
}

export function putUserUnexpireAdmin(id) {
    return put(`/admin/user/id/${id}/unexpire`)
}

export function putUserAttributesAdmin(id, attributes) {
    return put(`/admin/user/id/${id}/attributes`, attributes)
}

export function getUserSubscription(id) {
    return get(`/admin/user/id/${id}/subscription`);
}

export function putUserSubscription(id, subscription) {
    return put(`/admin/user/id/${id}/subscription`, subscription)
}

export function getUserEdits(id) {
    return get(`/admin/user/id/${id}/edits`);
}

export function getUserAttrEdits(id) {
    return get(`/admin/user/id/${id}/attributes/edits`);
}

export function getUsers(page, perPage, sort, filter, params) {
    let p = params();
    return get(`/admin/users?page=${page}&page_size=${perPage}&sort=${sort}&filter=${filter}&access_level=${p.access_level}&created_before=${p.created_before}&created_after=${p.created_after}&email=${encodeURIComponent(p.email)}&email_look_after=${p.email_look_after}&email_look_before=${p.email_look_before}&status=${p.status}&cancel_reason_code=${p.cancel_reason_code}&ip_addresses=${p.ip_addresses}&user_ids=${p.user_ids}&unique_identifiers=${p.unique_identifiers}&payment_methods_search_type=${p.payment_methods_search_type()}&payment_methods=${p.payment_methods().join(",")}&with_payment_method=${p.with_payment_method()}&referral=${p.referral}&referral_exact=${p.referral_exact}&access_code_use=${p.access_code_use}&register_url=${p.register_url}`).then((res) => {
        res.results.forEach((user) => {
            user.access = userLevels[user.access_level];
        });

        return res;
    });
}

export const getUsersSloppy = params => get(`/admin/users${params ? `?${Object.entries(params).map(e =>{ console.log(e);return `${e[0]}=${e[1]}`}).join('&')}` : ''}`);

export function getAttributeRoots(page, perPage) {
    return get(`/attributes/top?page=${page || 0}&page_size=${perPage}`);
}

export function createAttribute(slug, name_read, published, parentId, data, visibility) {
    if (parentId) {
        parentId = parseInt(parentId, 10) || 0;
    }

    return post(`/attribute`, {
        slug,
        name_read,
        published,
        data,
        "parent": parentId,
        visibility,
    });
}

export function patchAttribute(id, attr) {
    return patch(`/attribute/id/${id}`, attr);
}

export function getAttributeContent(id, page, perPage) {
    return get(`/attribute/id/${id}/content?page=${page || 0}&page_size=${perPage}`);
}

export function getAttribute(idOrSlug) {
    return get(`/attribute/${!isNaN(idOrSlug) ? 'id' : 'slug'}/${idOrSlug}`);
}

export function getAttributeChildren(idOrSlug, page, perPage) {
    return get(`/attribute/${!isNaN(idOrSlug) ? 'id' : 'slug'}/${idOrSlug}/children?page=${page || 0}&page_size=${perPage}`);
}

export function deleteAttribute(id) {
    return del(`/attribute/id/${id}`);
}

export function createContent(data, slug, type, published, expiry, body, is_public, premium) {
    return post(`/content`, {
        slug,
        type,
        published,
        expiry,
        body,
        is_public,
        data,
        premium,
    });
}

export function patchContent(id, content) {
    return patch(`/content/id/${id}`, content);
}

export function getContent(id) {
    return get(`/admin/content/id/${id}`);
}

export function deleteContent(id) {
    return del(`/content/id/${id}`);
}

export function getAdminContent(id) {
    return get(`/admin/content/id/${id}`);
}

export function getContentByType(page, perPage, type, child_limit = "") {
    return get(`/content/published?type=${type}&page=${page || 0}&page_size=${perPage}&child_limit=${child_limit || ""}`);
}

export function getContentSearch(page, perPage, start, end, type, name, hasItem, itemType, artist, magazine) {
    return get(`/admin/content/published?page=${page || 0}&page_size=${perPage}&type=${type || ''}&name=${name || ''}&has_item=${hasItem || ''}&item_type=${itemType || ''}&start_date=${start || ''}&end_date=${end || ''}&artist=${artist || ''}&magazine=${magazine || ''}`);
}

export function getContentUnpublished(page, perPage, sort, author, service) {
    return get(`/content/unpublished?author=${author || ""}&page=${page || 0}&page_size=${perPage}&sort=${sort}&service=${service || ""}`);
}

export function getContentExpired(type, attributes, magazine, page, perPage) {
    return get(
        `/admin/content/expired?type=${type || ""}&attributes=${attributes.join("+")}&magazine=${magazine || ""}&page=${page || 0}&page_size=${perPage || 20}`,
    );
}

export function postContentAttrPublished(id, attrid, data) {
    return post(`/admin/content/id/${id}/attribute/${attrid}`, data);
}

export function getContentList(type, start, end) {
    let startFormatted = "";
    let endFormatted = "";
    if (start instanceof Date) {
        startFormatted = start.toISOString();
    }
    if (end instanceof Date) {
        endFormatted = end.toISOString();
    }

    return get(`/admin/content/list/${type}?start=${startFormatted}&end=${endFormatted}`);
}

export function getComments(page, perPage, filter) {
    return get(`/comments?page=${page || 0}&page_size=${perPage}&${(new URLSearchParams(filter)).toString()}`);
}

export function deleteComment(content_id, comment_id) {
    return del(`/content/id/${content_id}/comments/${comment_id}`);
}

export function getFeedback(page, perPage) {
    return get(`/feedback?page=${page || 0}&page_size=${perPage}`);
}

export function createSurvey(data, start_date, end_date, listed) {
    return post(`/survey`, {
        data,
        start_date,
        end_date,
        listed
    });
}

export function patchSurvey(id, survey) {
    return patch(`/survey/id/${id}`, survey)
}

export function deleteSurvey(id) {
    return del(`/survey/id/${id}`);
}

export function getSurveys(page, perPage) {
    return get(`/surveys?page=${page || 0}&page_size=${perPage}`);
}

export function getSurvey(id) {
    return get(`/survey/id/${id}`);
}

export function getSurveyResults(id, page, perPage) {
    return get(`/survey/id/${id}/results?page=${page || 0}&page_size=${perPage}`);
}

export function createSurveyQuestion(survey_id, ordinality, data, options) {
    return post(`/surveys/id/${survey_id}/questions`, {
        ordinality,
        data,
        options
    });
}

export function patchSurveyQuestion(survey_id, question_id, ordinality, data, options) {
    return patch(`/surveys/id/${survey_id}/questions/id/${question_id}`, {
        ordinality,
        data,
        options
    });
}

export function deleteSurveyQuestion(survey_id, question_id) {
    return del(`/surveys/id/${survey_id}/questions/id/${question_id}`);
}

export function getAdminAutoAttributes(term) {
    return get(`/admin/auto/attributes/${encodeURIComponent(term)}`);
}

export function getAdminAutoContent(term) {
    return get(`/admin/auto/content/${encodeURIComponent(term)}`);
}

export function postAdminContentIdRegen(id) {
    return post(`/admin/content/id/${id}/regen`, {});
}

export function reorderContentChildren(contentId, ids) {
    return post(`/content/id/${contentId}/children/reorder`, { ids });
}

export function deleteContentChild(contentId, childId) {
    return del(`/content/id/${contentId}/child/${childId}`);
}

export function postAdminOriginalIdRegen(id) {
    return post(`/admin/original/id/${id}/regen`, {});
}

export function setAttributeOriginalName(attrId, origId, name) {
    return post(`/attribute/id/${attrId}/original/${origId}`, { name });
}

export function setContentOriginalName(contentId, origId, name) {
    return post(`/content/id/${contentId}/original/${origId}`, { name });
}

export function reorderContentOriginals(contentId, ids, idents) {
    return post(`/content/id/${contentId}/originals/reorder`, { ids, idents });
}

export function deleteContentOriginal(contentId, origId) {
    return del(`/content/id/${contentId}/original/${origId}`);
}

export function deleteContentOriginalAll(contentId) {
    return del(`/content/id/${contentId}/originals`);
}

export function setContentOriginalData(contentId, origId, data) {
    return post(`/content/id/${contentId}/original_data/${origId}`, { data });
}

export function setContentOriginalDatas(contentId, datas) {
    return post(`/content/id/${contentId}/original_datas`, datas);
}

export function getMetricsSubscriptionsSuccessful(start_date, end_date) {
    return get(`/metrics/subscriptions/successful?start_date=${start_date}&end_date=${end_date}`);
}

export function getMetricsSubscriptionsFailed(start_date, end_date) {
    return get(`/metrics/subscriptions/failed?start_date=${start_date}&end_date=${end_date}`);
}

export function getMetricsRoyalty(start_date, end_date) {
    return get(`/metrics/content/royalty?start_date=${start_date}&end_date=${end_date}`);
}

export function getMetricsRanking(start_date, end_date, ranking_type) {
    return get(`/metrics/content/rankings?start_date=${start_date}&end_date=${end_date}&type=${ranking_type}`);
}

export function getMetricsReport(content_id, ranking_type) {
    return get(`/metrics/content/reports?content_id=${content_id}&type=${ranking_type}`);
}

export function getIsbnSearch(isbn) {
    return get(`/admin/isbn_search/wani/${isbn}`);
}

export function getCollections() {
    return get(`/admin/reading_lists`);
}

export function getCollection(id, page = 0, perPage = 20) {
    return get(`/reading_list/id/${id}?page=${page}&page_size=${perPage}`);
}

export function createCollection(name, expiry, list_type) {
    return post(`/reading_lists`, {
        name,
        expiry,
        list_type
    });
}

export function patchCollection(id, name, expiry, list_type) {
    return patch(`/reading_list/id/${id}`, {
        name,
        expiry,
        list_type
    });
}

export function addContentToCollection(id, content_id, ordinality) {
    return post(`/reading_list/id/${id}/contents`, {
        content_id,
        ordinality
    });
}

export function modifyContentInCollection(id, content_id, direction) {
    return patch(`/reading_list/id/${id}/content/id/${content_id}`, {
        direction
    });
}

export function deleteContentFromCollection(id, content_id) {
    return del(`/reading_list/id/${id}/contents`, {
        content_id
    });
}

export function deleteAllContentFromCollection(id) {
    return del(`/reading_list/id/${id}/contents/all`);
}

export function getReferrals(startDate, endDate, page = 0, perPage = 20, kind) {
    return get(`/admin/referrals?start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${perPage}&kind=${kind}`);
}

export function getEmailTemplates(page, perPage) {
    return get(`/admin/emails/templates?page=${page || 0}&page_size=${perPage}`);
}

export function getEmailTemplate(id) {
    return get(`/admin/emails/template/${id}`);
}

export function createEmailTemplate(data) {
    return post(`/admin/emails/template`, data);
}

export function putEmailTemplate(id, data) {
    return put(`/admin/emails/template/${id}`, data);
}

export function postEmail(data) {
    return post(`/admin/emails`, data);
}

export function deleteEmailTemplate(id) {
    return del(`/admin/emails/template/${id}`, id);
}

export function postEmailSchedule(data) {
    return post(`/admin/emails/schedule`, data);
}

export function getEmailSources() {
    return get(`/admin/emails/sources`);
}

export function getEmailSchedulesFuture(page, perPage) {
    return get(`/admin/emails/schedules/future?page=${page || 0}&page_size=${perPage}`);
}

export function deleteEmailSchedule(id) {
    return del(`/admin/emails/schedule/${id}`, id);
}

export function deleteEmailSchedulesAll() {
    return del(`/admin/emails/schedules/all`);
}

export function postEmailSchedulePrepare(data) {
    return post(`/admin/emails/schedule/prepare`, data);
}

export function postFilterNotification(data) {
    return post(`/admin/filter_notification`, data);
}

export function getFilterNotifications() {
    return get(`/admin/filter_notifications`);
}

export const adminTimestampStatus = {
    "shinjuku1565_in": "Check in",
    "shinjuku1565_out": "Check out",
    "shinjuku1565_lunch": "Lunch",
    "shinjuku1565_out_of_office": "Out of office",
    "remote_in": "Check in (remote)",
    "remote_out": "Check out (remote)",
    "remote_lunch": "Lunch (out of office)",
    "remote_out_of_office": "Out of office (remote)",
}

export function getAdminTimestamps() {
    return get(`/admin/timestamps`);
}

export function postAdminTimestamp(statusType) {
    return post(`/admin/timestamp`, {
        "status_type": statusType,
    });
}

export function postBillingCleanUp() {
    return post(`/admin/billing/clean_up`);
}
export const postAPICacheCleanup = () => post(`/admin/cache_invalidation`);

export const getArtists = (pageSize = 9999) =>
    get(`/attribute/slug/artists?page_size=${pageSize}&content_limit=0&sort=related`);

export const getAttributeContents = (id) =>
    get(`/attribute/id/${id}/content?page_size=999`);

export const getItems = (page, perPage, itemType, keyword, contentType) =>
    get(
        `/admin/shop/items?page=${page}&page_size=${perPage}&type=${itemType}&keyword=${keyword}&ctype=${contentType}&keyword_target=ia`,
    );

export const createItem = params => post("/admin/shop/item", params);

export const getItem = id => get(`/admin/shop/item/id/${id}`);

export const updateItem = (id, params) => patch(`/admin/shop/item/id/${id}`, params);

export const deleteItem = id => del(`/admin/shop/item/id/${id}`);

export const getItemAttributes = id =>
    get(`/admin/shop/item/id/${id}/attributes`);

export const getItemContents = id => get(`/admin/shop/item/id/${id}/contents`);

export const postItemOriginals = (id, params) =>
    post(`/admin/shop/item/id/${id}/originals`, params);

export const linkAttributesToItem = (
    itemId,
    attribute_ids,
    attribute_weights
) =>
    put(`/admin/shop/item/id/${itemId}/attributes`, {
        attribute_ids,
        attribute_weights,
    });

export const linkContentsToItem = (itemId, contentIDs) =>
    put(`/admin/shop/item/id/${itemId}/contents`, { content_ids: contentIDs });

export const prepareDownloadContent = (
    itemID,
    resolution,
    amount,
    interval,
    ratio,
) => {
    const params = {
        resolution,
    };
    if (amount) {
        params.amount = amount;
    }
    if (interval) {
        params.shrink_interval = interval;
    }
    if (ratio) {
        params.shrink_ratio = ratio;
    }

    return post(`/admin/shop/item/id/${itemID}/download/prepare`, params);
};
export const downloadItem = (id, name, resolution) => {
    const req = new XMLHttpRequest();
    req.open("GET", `${baseUrl}/shop/item/id/${id}/download?resolution=${resolution}`);
    req.responseType = "blob";
    req.withCredentials = true;

    req.onload = function() {
        var blob = new Blob([req.response], {
            type: "application/octetstream",
        });

        const url = window.URL || window.webkitURL;
        const a = document.createElement("a");
        a.setAttribute("download", `${name}.pdf`);
        a.setAttribute("href", url.createObjectURL(blob));
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    req.send();
};
export const getItemStocks = itemID =>
    get(`/admin/shop/item/id/${itemID}/stocks`);
export const patchItemStock = (itemID, stockID, data) =>
    patch(`/admin/shop/item/id/${itemID}/stocks/${stockID}`, data);

export const getPrices = () => get("/admin/shop/prices");

export const getPrice = id => get(`/admin/shop/price/id/${id}`);

export const updatePrice = (id, params) =>
    patch(`/admin/shop/price/id/${id}`, params);

export const deletePrice = id => del(`/admin/shop/price/id/${id}`);

export const createPrice = params => post("/admin/shop/price", params);

export const getUserContents = id => get(`/user/id/${id}/contents`);

export const getUserReceipts = (id, filter) => get(`/user/id/${id}/receipts?filter=${filter}&sort=desc&page_size=999&admin=true`);

export const getAccessCodes = (params, page, perPage) =>
    get(
        // eslint-disable-next-line prettier/prettier
        `/admin/access_codes?type=${params.type}&code=${params.code}&created=${params.created ? dayjs(params.created).format('YYYY-MM-DD') : ''}&used=${params.used}&user_id=${params.user_id}&page_size=${perPage}&page=${page}`,
    );

export const getAccessCodeUsers = (id) => get(`/admin/access_code/${id}`);

export const createAccessCode = params => post("/admin/access_code", params);

export const expireAccessCode = id => { return put(`/admin/access_code/${id}/expire`) };

export const getMissionList = () => get(`/admin/missions`);
export const createMission = mission => post(`/admin/missions`, mission);
export const getMission = id => get(`/admin/missions/id/${id}`);
export const putMission = (id, data) => put(`/admin/missions/id/${id}`, data);
export const deleteMission = id => del(`/admin/missions/id/${id}`);

export const getBusinessReports = (startDate, endDate, email, page, perPage, sort) => get(`/admin/reports/pageviews/users?page_size=${perPage}&page=${page}&start_date=${startDate}&end_date=${endDate}&email=${email}&sort=${sort}`);

export const getTasks = max => get(`/admin/tasks?page_size=${max}&from=${dayjs().toJSON()}`);

export const getTask = id => { return get(`/admin/task/${id}`) };

export const getPendingBanners = () => { return get(`/admin/pending_banners`) };

export const getPendingBannerByAttrId = (id) => { return get(`/admin/attribute/id/${id}/pending_banner`) };

export const postApproveBanner = (id) => { return post(`/admin/attribute/id/${id}/approve_banner`) }

export const postBulkAssignAttribute = (id, targetID) =>
    post(`/admin/attribute/id/${id}/extensions/attribute/id/${targetID}`);
export const deleteBulkRemoveAttribute = id =>
    del(`/admin/attribute/id/${id}/extensions`);

export const postAttributeSibling = (id, assocID) =>
    post(`/admin/attribute/id/${id}/sibling/${assocID}`);
export const deleteAttributeSibling = (id, assocID) =>
    del(`/admin/attribute/id/${id}/sibling/${assocID}`);

export const createTask = params => post("/admin/task", params);
export const updateTask = (id, params) => put(`/admin/task/${id}`, params);
export const patchTask = id => patch(`/admin/task/${id}`);
export const deleteTask = id => del(`/admin/task/${id}`);

export const createCoupon = coupon => post("/admin/shop/coupons", coupon);
export const updateCoupon = (id, coupon) =>
    put(`/admin/shop/coupons/id/${id}`, coupon);
export const updateCouponItems = (id, coupon) =>
    post(`/admin/shop/coupons/id/${id}/items`, coupon);
export const updateCouponUsers = (id, userids) =>
    post(`/admin/shop/coupons/id/${id}/users`, userids);
export const deleteCoupon = id => del(`/admin/shop/coupons/id/${id}`);
export const deleteCouponItems = (id, itemids) =>
    del(`/admin/shop/coupons/id/${id}/items`, itemids);
export const deleteCouponUsers = (id, userids) =>
    del(`/admin/shop/coupons/id/${id}/users`, userids);
export const getCoupons = (page, perPage, sort, filter) =>
    get(
        `/admin/shop/coupons?page=${page}&per_page=${perPage}&sort=${
            sort ? sort : ""
        }&filter=${filter ? filter : ""}`,
    );
export const getCoupon = id => get(`/admin/shop/coupons/id/${id}`);
export const getCouponItems = id => get(`/admin/shop/coupons/id/${id}/items`);
export const getCouponUsers = id => get(`/admin/shop/coupons/id/${id}/users`);

export const getAWSToken = () => get("/admin/aws_token");

export { baseUrl };

export const getShopItemLists = () => get("/store/list/public");
export const postShopItemList = (name, explanation, expiry, list_type) => post("/store/list", { name, explanation, expiry, list_type });
export const getShopItemList = id => get(`/store/list/id/${id}?page_size=999`);
export const putShopItemList = (listid, name, explanation, expiry, attr_id) => put(`/store/list/id/${listid}`, { name, explanation, expiry, attr_id });
export const putShopItemListItems = (listid, ids) => put(`/store/list/id/${listid}/item/ids`, { ids: ids });
export const delShopItemListItem = (listid, id) =>  del(`/store/list/id/${listid}/item/id/${id}`);
export const delShopItemListItemAll = (listid) => del(`/store/list/id/${listid}/all`);
export const patchShopItemListOrder = (listid, id, direction) => patch(`/store/list/id/${listid}/item/id/${id}/order`, { direction });


export function getReview(page, perPage, itemid, status, keyword) {
    return get(
        `/admin/reviews_search?page=${page || 0}&page_size=${perPage}${
            itemid ? `&itemid=${itemid}` : ""
        }${status ? `&status=${status}` : ""}${keyword ? `&keyword=${keyword}` : ""}`,
    );
}

export function putReviewStatus(id, params) {
    return put(`/admin/reviews_status/${id}`, params);
}

export function getPreRegistrations(type, send_blank) {
    return get(`/admin/pre_registrations?type=${type}&send_blank=${send_blank}`);
}

export function postPreRegistrations(type) {
    return post(`/admin/pre_registrations`, {'type': type });
}

export function postRefund(id) {
    return post(`/admin/receipts/${id}/events`, { type: "refund" });
}

export function getReceipts(page, exist_address, status) {
    return get(`/admin/receipts?page=${page}&exist_address=${exist_address ? 'true' : 'false'}&status=${status ? status:''}`);
}

export function postRepeatReceipt(id, items) {
    return post(`/admin/receipts/${id}/events`, { type: "repeat", items: items });
}

export function postShipToReceipt(id, status, tracking) {
    return post(`/admin/receipts/${id}/ship_to`, { status: status, tracking_number: tracking });
}

export function getReceipt(id) {
    return get(`/admin/receipts/${id}`);
}
export function getReceiptOwner(id) {
    return get(`/admin/receipts/${id}/owner`);
}

export function getUserPoints(id) {
    return get(`/user/id/${id}/points`);
}
export function postUserPoints(id, amount) {
    return post(`/user/id/${id}/points`, { amount });
}

export function getReplyTemplates(page) {
    return get(`/admin/support/templates?page=${page || 0}`);
}
export function getReplyTemplate(id) {
    return get(`/admin/support/templates/id/${id}`);
}
export function postReplyTemplate(params) {
    return post("/admin/support/templates", params);
}
export function patchReplyTemplate(id, params) {
    return patch(`/admin/support/templates/id/${id}`, params);
}
export function deleteReplyTemplate(id) {
    return del(`/admin/support/templates/id/${id}`);
}
export function getCensorWords() {
    return get(`/admin/censor_words`);
}

export function postCensorWords(word, replace_word) {
    return post(`/admin/censor_words`, {word: word, replace_word: replace_word});
}

export function deleteCensorWords(id) {
    return del(`/admin/censor_words/${id}`);
}

export function getPointMutiple(id) {
    return get(`/admin/shop/point_multiples/${id}`);
}
